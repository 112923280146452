import React, { useMemo } from 'react'
import {
  Button,
  Grid,
  Dialog,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Alert
} from '@barracuda-internal/bds-core'
import { useAddDomainLogic } from 'components/pages/domains/addDomain/useAddDomainLogic'
import { useFormatMessage } from 'lib/localization'
import styles from 'components/pages/domains/addDomain/addDomainStyles'
import WarningDialog from 'components/libs/dialog/warningDialog/WarningDialog'

const BASE_I18N_KEY = 'ess.domains'

export interface AddDomainProps {
  onClose: () => void
  open: boolean
}

export const AddDomain = ({ onClose, open }: AddDomainProps) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [state, eventHandlers] = useAddDomainLogic({ onClose })
  const classes = styles()

  return useMemo(
    () => (
      <Dialog open={open} onClose={onClose}>
        <WarningDialog
          title=""
          description={formatMessage('add_domain.tld_warning')}
          open={state.tldDialog.isTldDialogOpen}
          onCancel={eventHandlers.tldDialog.onCancelTldDialog}
          onConfirm={eventHandlers.tldDialog.onConfirmTldDialog}
        />
        <DialogTitle disableTypography>
          <Typography variant="h6">{formatMessage('add_domain.title')}</Typography>
        </DialogTitle>
        <DialogContent>
          {state.apiError && (
            <Alert className={classes.error} severity="error">
              {state.apiError}
            </Alert>
          )}
          <Grid container>
            <Grid item xs={12} className={classes.formRow}>
              <TextField
                label={formatMessage('add_domain.domain_name')}
                size="small"
                name="domainName"
                value={state.formData.domainName}
                onChange={eventHandlers.onChangeFormData}
                fullWidth
                error={!!state.formErrors.domainName}
                helperText={state.formErrors.domainName ? formatMessage(state.formErrors.domainName) : ''}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={formatMessage('add_domain.mail_server')}
                size="small"
                name="mailServer"
                value={state.formData.mailServer}
                onChange={eventHandlers.onChangeFormData}
                fullWidth
                error={!!state.formErrors.mailServer}
                helperText={state.formErrors.mailServer ? formatMessage(state.formErrors.mailServer) : ''}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={eventHandlers.onAddDomain}
            color="primary"
            isLoading={state.isAddDomainPending}
            disabled={state.isAddDomainPending}
          >
            {formatMessage('add_domain.add')}
          </Button>
          <Button onClick={eventHandlers.onCancel} color="primary" disabled={state.isAddDomainPending}>
            {formatMessage('add_domain.cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    ),
    [
      open,
      onClose,
      formatMessage,
      state.tldDialog.isTldDialogOpen,
      state.apiError,
      state.formData.domainName,
      state.formData.mailServer,
      state.formErrors.domainName,
      state.formErrors.mailServer,
      state.isAddDomainPending,
      eventHandlers.tldDialog.onCancelTldDialog,
      eventHandlers.tldDialog.onConfirmTldDialog,
      eventHandlers.onChangeFormData,
      eventHandlers.onAddDomain,
      eventHandlers.onCancel,
      classes.error,
      classes.formRow
    ]
  )
}
