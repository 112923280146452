import { MetadataResponse } from './general'

export interface Attachment {
  cid: string
  content?: string
  contentDisp: string
  contentType: string
  filename: string
  id: string
  size: number
}

export enum Action {
  none = 'none',
  allowed = 'allowed',
  allowedPartial = 'allowed:partial',
  blocked = 'blocked',
  blockedPartial = 'blocked:partial',
  deferred = 'deferred',
  deferredPartial = 'deferred:partial',
  quarantined = 'quarantined',
  encrypted = 'encrypted'
}

export enum DeliveryStatus {
  delivered = 'delivered',
  rejected = 'rejected'
}

export enum Direction {
  INBOUND = 'inbound',
  OUTBOUND = 'outbound'
}

export enum MessageDirection {
  INBOUND = '0',
  OUTBOUND = '1'
}

export interface Actions {
  deliver: string[]
}

export interface RecipientInfo {
  action: Action
  deliveryDetail: string
  deliveryStatus: DeliveryStatus
  deliveryTime?: string[]
  effRecipient: string
  effRecipientIp: string
  envelopeTo: string
  flags: string
  latency: string
  originalDeliveryStatus?: DeliveryStatus
  policySource: string
  reason: string
  reasonExtra: string
  score: string
  tlsOut: number
  barracudaResponsible: boolean
}

export interface Server {
  id: string
}

export interface Fields {
  actions: Actions | Record<string, never>
  attachment: string | undefined
  did: string
  didOutbound: string
  envelopeFrom: string
  flags: string
  geoip?: string
  globalMessageId: string
  headerFrom: string
  headerTo: string
  ip: string
  language?: string
  latency: string
  logTime: string
  mid: string
  ptr?: string
  recipientInfo?: RecipientInfo[]
  server: Server
  size: string
  subject: string
  timestamp: string
  tlsIn: number
}

export type Headers = {
  [key: string]: string
}

export interface ActionMessageRecipientInfo {
  action: Action
  envelopeTo: string
  reason: string
}

export interface Message {
  attachments: Attachment[]
  barracudaResponsible: boolean
  body: string
  did: string
  fields: Fields
  hasImages: boolean
  headers: Headers
  mid: string
  virus?: any[] // TODO: check type of virus field
  attributes: string[]
  allowMsgBody: boolean
  headersOnly: boolean
  showMarkSpam: boolean
  showMarkHam: boolean
}

export interface MessageSource {
  did: string
  fields: Fields
  message: string
  messageCharset: string
  mid: string
}

export interface RecipientReason {
  reason: string
  reasonExtra: string
}

export interface ReportMessage {
  attachments: Attachment[]
  date: string
  deliveryStatus: DeliveryStatus
  did: string
  envelopeFrom: string
  headerFrom: string
  mid: string
  reasons: string[]
  recipientsReasons: RecipientReason[]
  remediated: boolean
  remediatedBy: string | null
  timestamp: number | null
  score: number
  size: number
  status: Action
  subject: string
  tlsIn: boolean
  tlsOut: string
  to: string
  selected?: boolean
  isChecked?: boolean
  recipientInfo: ActionMessageRecipientInfo[]
  didOutbound: string
}

export interface RedeliverMessage {
  mid: string
  status: Action
  did: number
  envelopeFrom: string
  headerFrom: string
  to: string
  subject: string
  recipientInfo: ActionMessageRecipientInfo[]
  didOutbound: string
}

export enum DeliverStatus {
  QUEUED = 'QUEUED',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  NOT_FOUND = 'NOT_FOUND',
  RETRY = 'RETRY'
}

export enum MessageStatus {
  NEW = 'NEW',
  DELIVER = 'DELIVER',
  REDELIVER = 'REDELIVER'
}

export interface ActionMessageInfo {
  did: number
  mid: string
  envelope_from: string
  header_from: string
  to: string
  subject: string
  recipient_info: {
    action: Action
    envelope_to: string
    reason: string
  }[]
  did_outbound: string
}

export interface RedeliverMessageAction {
  mid: string
  did: number
  from: string
  to: string
  header_from?: string
  account_id: string
  subject?: string
  status: string
  description?: string
}
export interface DeliveredMessages {
  id: string
  action: MessageStatus
  domainId: string
  accountId: string
  from: string
  to: string
  queuedDate: Date
  status: DeliverStatus
  messageId?: string
  headerFrom?: string
  cc?: string
  bcc?: string
  subject?: string
  description?: string
  errorDescription?: string
  withRetry?: boolean
  dbExpirationTime?: number
}

export interface InvalidMessages extends ActionMessageInfo {
  reason: string
}

export interface ModifiedReportMessage extends ReportMessage {
  action: {
    text: string
    tooltip: string
    suffix: string
    datetime?: string
  }
  actionColor: string
  isChecked: boolean
  selected: boolean
  exportDate: string
  formattedDate: string
  formattedSize: string
  encryption: {
    text: string
    icon: any
  }
  reason: string
  statusMapped: {
    text: string
    icon: any
  }
  from: string
}

export interface Search {
  start: number
  end: number
  offset: number
  limit: number
  search_str: string
  user_id: string | undefined
  domain_id: string | undefined
  outbound: MessageDirection
  dateRange: number | undefined
  keepPagination?: boolean
}

export interface SavedSearch {
  name: string
  searchTerm: Search
  searchFilter: SearchFilter
}

export interface MessageMetadata {
  accountId: string
  bccAccountId: string
  domainId: number
  userId: string
}

export interface ResultConfig {
  id: string
  action: string
  from: string
  to: string[]
  subject: string
  queuedDate: Date
  status: string
  messageId: string
  headerFrom: string[] | null
  cc: string[] | null
  bcc: string[] | null
  description: string | null
  errorDescription: string | null
  withRetry: boolean
  domainId: number
}

export interface MessageUpdateReport {
  metadata: MessageMetadata
  results: ResultConfig[]
}

export interface MessageReport {
  messageCount: number
  messages: ReportMessage[]
}

export interface MessageReportResult extends MessageReport {
  offset: number
  metadata: MetadataResponse
}

export interface AttachmentDetail {
  attachment: Attachment
  did: string
  mid: string
}

export interface RedeliverMessageResult {
  id: string
  action: Action
  domainId: number
  accountId: string
  from: string
  to: string[]
  subject: string
  queuedDate: string
  status: string // TODO: could this be narrowed down?
  messageId: string

  headerFrom?: string
  cc?: string[]
  bcc?: string[]
  description?: string
  errorDescription?: string
  withRetry?: boolean
  isDeleted?: null | boolean
}

export type SearchDates = {
  start: number
  end: number
}

export interface AdvancedSearchForm {
  subject: string
  header_from: string
  envelope_from: string
  header_to: string
  envelope_to: string
  attachment: string
  basic_search_term: string
}

export enum ActionTaken {
  any = 'any',
  allowed = 'allowed',
  blocked = 'blocked',
  deferred = 'deferred',
  quarantined = 'quarantined',
  encrypted = 'encrypted',
  emailContinuity = 'email_continuity'
}

export enum ActionStatus {
  any = 'any',
  delivered = 'delivered',
  notDelivered = 'not_delivered',
  deferred = 'deferred',
  rejected = 'rejected',
  spooled = 'spooled'
}

export enum Reason {
  any = 'any',
  ATPExempt = 'atd_exemption',
  ATPScanInconclusive = 'service_error_batd',
  AVServiceUnavailable = 'service_error_av',
  AccountSuspended = 'suspended',
  AdvancedThreatProtection = 'atd_full',
  AntiFraud = 'bafi',
  Antivirus = 'av',
  AttachmentContent = 'content_attachment',
  AttachmentFilter = 'attachment_filter',
  BarracudaRealTimeSystems = 'brts',
  BarracudaReputation = 'bbl',
  BodyContent = 'content_body',
  BulkEmail = 'bulk_email',
  CloudscanServiceUnavailable = 'service_error_cs',
  ContentProtected = 'content_protected',
  ContentURL = 'content_url',
  DKIM = 'dkim',
  DMARC = 'dmarc',
  EmailCategorization = 'emailcat',
  EncryptionExemption = 'encryption_exemption',
  Extortion = 'taxonomy_blackmail',
  FromAddress = 'content_sender',
  GeoIPPolicies = 'geoip',
  HeaderContent = 'content_header',
  IPAddressPolicies = 'ip',
  ImageAnalysis = 'image_analysis',
  IntentAnalysis = 'intent',
  InvalidRecipient = 'invalid_recipient',
  LanguagePolicies = 'language',
  Malformed = 'malformed',
  MessageDeliveryInterrupted = 'sender_disconnect',
  MessageTooLarge = 'message_size',
  NoPTRRecord = 'no_ptr',
  NoSPFRecord = 'no_spf',
  OfficeProtected = 'content_protected_msdoc',
  PasswordProtectedPDFFiltering = 'password_protected_pdf',
  PendingScan = 'patd_scan_pending',
  Phishing = 'taxonomy_phishing',
  PossibleMailLoop = 'message_loop',
  PredefinedAttachmentContent = 'predefined_content_attachment',
  PredefinedBodyContent = 'predefined_content_body',
  PredefinedFilterExemptions = 'predefined_filter_exemption',
  PredefinedFromAddress = 'predefined_content_sender',
  PredefinedHeaderContent = 'predefined_content_header',
  PredefinedSubjectContent = 'predefined_content_subject',
  PredefinedToCCAddress = 'predefined_content_recipient',
  RateControl = 'rate_control',
  RealtimeBlockList = 'rbl',
  Recipient = 'recipient',
  RecipientsList = 'recipient_limit',
  RemediatedbyIncidentResponse = 'remediated_by_forensics',
  RemediatedbyImpersonationProtection = 'remediated_by_sentinel',
  Score = 'score',
  SecurityAwarenessTraining = 'phishline',
  SenderEmailAddress = 'barracuda_email_blacklist',
  SenderPolicies = 'sender',
  SenderPolicyFramework = 'spf',
  SenderSpoofProtection = 'sender_spoof',
  SenttoSpamCategorization = 'mark_spam',
  Spam = 'taxonomy_spam',
  SubjectContent = 'content_subject',
  Suspicious = 'suspicious',
  SystemSenderPolicies = 'system-sender',
  TLSRequired = 'tls_required',
  ToCCAddressUIDelivered = 'content_recipient',
  UIDelivered = 'ui_delivered'
}

export type SearchFilter = {
  delivery_status: ActionStatus
  reason: Reason
  action: ActionTaken
}

export type SelectedSearch = {
  name: string
  value: string
  datePreset: string
  searchFilter: SearchFilter
  searchDates: SearchDates
}

export interface MessageActionItem {
  messageId: string
  domainId: number
}

export enum FpFnType {
  FN = 'fn',
  FP = 'fp'
}

export enum BannerType {
  SIDEBAR = 'sidebar',
  NEWINTERFACE = 'new_interface'
}
