import { createStyles, makeStyles, colors } from '@barracuda-internal/bds-core'

export default makeStyles(theme =>
  createStyles({
    domainColumn: {
      paddingLeft: theme.spacing(1)
    },
    domainColumContainer: {
      flexWrap: 'nowrap',
      alignItems: 'center',
      wordBreak: 'break-word'
    },
    iconWrapper: {
      display: 'flex'
    },
    copyIcon: {
      marginLeft: theme.spacing(1),
      color: colors.scaleGray600,
      cursor: 'pointer',
      width: 15,
      marginTop: theme.spacing(-0.5)
    },
    copiedMessage: {
      '& > *': {
        minWidth: 'auto'
      }
    }
  })
)
