import React, { useMemo } from 'react'

import {
  Drawer,
  Grid,
  IconButton,
  SelectWrapper,
  Typography,
  SelectLabel,
  Select,
  SelectMenuItem,
  ButtonGroup,
  Button,
  TextField,
  LinearProgress
} from '@barracuda-internal/bds-core'
import { Close } from '@barracuda-internal/bds-core/dist/Icons/Core'
import { useFormatMessage } from 'lib/localization'
import {
  INPUT_NAMES,
  UseScheduleReportLogicProps,
  useScheduleReportLogic
} from 'components/libs/scheduleReport/useScheduleReportLogic'
import styles from 'components/libs/scheduleReport/scheduleReportStyles'
import { ScheduleReportEveryOption, ScheduleReportFrequency } from 'types/reports'

const BASE_REPORT_I18N_KEY = 'ess.reports.predefined_report_types'
const BASE_I18N_KEY = 'ess.reports.schedule_report'

const ScheduleReport: React.FC<UseScheduleReportLogicProps> = props => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatReportMessage = useFormatMessage(BASE_REPORT_I18N_KEY)
  const [state, eventHandlers] = useScheduleReportLogic(props)
  return useMemo(
    () => (
      <Drawer anchor="right" open onClose={props.onClose} data-testid="drawer">
        {!state.isLoaded && <LinearProgress data-testid="loading-state" />}
        {state.isLoaded && (
          <Grid className={classes.drawer}>
            <Grid className={classes.title}>
              <Typography variant="h3">{formatMessage('title')}</Typography>
              <IconButton data-testid="close-icon" onClick={props.onClose}>
                <Close />
              </IconButton>
            </Grid>
            <Grid className={classes.row}>
              <SelectWrapper fullWidth variant="outlined">
                {props.report?.id ? (
                  <Typography variant="h4">{formatReportMessage(state.form.report_id)}</Typography>
                ) : (
                  <>
                    <SelectLabel>{formatMessage('report_name')}</SelectLabel>
                    <Select
                      data-testid="report-name-selector"
                      label={formatMessage('report_name')}
                      fullWidth
                      onChange={eventHandlers.handleOnInputChange}
                      value={state.form.report_id}
                      name={INPUT_NAMES.REPORT_ID}
                      disabled={!!props.report?.id}
                    >
                      {state.predefinedBarracudaReports?.map(report => (
                        <SelectMenuItem data-testid={report.id} key={report.id} value={report.id}>
                          {formatReportMessage(report.id)}
                        </SelectMenuItem>
                      ))}
                    </Select>
                  </>
                )}
              </SelectWrapper>
            </Grid>
            <Grid className={classes.row}>
              <SelectWrapper fullWidth variant="outlined">
                <SelectLabel>{formatMessage('time_range')}</SelectLabel>
                <Select
                  data-testid="time-range-selector"
                  label={formatMessage('time_range')}
                  fullWidth
                  onChange={eventHandlers.handleOnInputChange}
                  value={state.form.relative_date_range}
                  name={INPUT_NAMES.RELATIVE_DATE_RANGE}
                >
                  {state.timeRanges?.map(({ original, transformed }) => (
                    <SelectMenuItem key={original} data-testid={original} value={original}>
                      {formatMessage(transformed)}
                    </SelectMenuItem>
                  ))}
                </Select>
              </SelectWrapper>
            </Grid>
            <Grid className={classes.row}>
              <SelectWrapper fullWidth variant="outlined">
                <SelectLabel>{formatMessage('format')}</SelectLabel>
                <Select
                  data-testid="format-selector"
                  label={formatMessage('format')}
                  fullWidth
                  onChange={eventHandlers.handleOnInputChange}
                  value={state.form.format}
                  name={INPUT_NAMES.FORMAT}
                >
                  {state.formats.map(format => (
                    <SelectMenuItem key={format} data-testid={format} value={format}>
                      {formatMessage(format)}
                    </SelectMenuItem>
                  ))}
                </Select>
              </SelectWrapper>
            </Grid>
            <Grid className={classes.row}>
              <Typography variant="body2">{formatMessage('frequency')}</Typography>
              <ButtonGroup fullWidth color="primary">
                {state.frequency.map(freq => (
                  <Button
                    key={freq}
                    name={freq}
                    className={`${freq === state.form.scheduled_frequency ? classes.selected : ''}`}
                    onClick={() => eventHandlers.handleFrequencyClick(freq)}
                  >
                    {formatMessage(freq.toLowerCase())}
                  </Button>
                ))}
              </ButtonGroup>
            </Grid>
            {state.form.scheduled_frequency === ScheduleReportFrequency.monthly && (
              <Grid container alignItems="center" className={classes.row}>
                <Grid item sm={2}>
                  <Typography variant="body2" align="center">
                    {formatMessage('every')}
                  </Typography>
                </Grid>
                <Grid item sm={10}>
                  <SelectWrapper size="small" fullWidth>
                    <Select
                      className={classes.roundedSelect}
                      onChange={eventHandlers.handleOnInputChange}
                      value={state.form.scheduled_day_of_month}
                      name={INPUT_NAMES.SCHEDULED_DAY_OF_MONTH}
                    >
                      {state.everyOptions?.map(({ key, value }) => (
                        <SelectMenuItem key={key} data-testid={value} value={value}>
                          {formatMessage(key)}
                        </SelectMenuItem>
                      ))}
                    </Select>
                  </SelectWrapper>
                </Grid>
              </Grid>
            )}
            {state.form.scheduled_frequency !== ScheduleReportFrequency.daily &&
              state.form.scheduled_day_of_month !== ScheduleReportEveryOption.specific_date && (
                <Grid container alignItems="center" className={classes.row}>
                  <Grid item sm={2}>
                    <Typography variant="body2" align="center">
                      {formatMessage('on')}
                    </Typography>
                  </Grid>
                  <Grid item sm={10}>
                    <Grid container justifyContent="space-between">
                      {state.days.map(day => (
                        <Button
                          key={day}
                          size="small"
                          variant="outlined"
                          color="secondary"
                          className={`${classes.circle} ${
                            day === state.form.scheduled_day_of_week ? classes.selected : ''
                          }`}
                          name={String(day)}
                          onClick={() => eventHandlers.handleDayClick(String(day))}
                        >
                          {formatMessage(String(day))}
                        </Button>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            {state.form.scheduled_frequency === ScheduleReportFrequency.monthly &&
              state.form.scheduled_day_of_month === ScheduleReportEveryOption.specific_date && (
                <Grid container alignItems="center" className={classes.row}>
                  <Grid item sm={2}>
                    <Typography variant="body2" align="center">
                      {formatMessage('day')}
                    </Typography>
                  </Grid>
                  <Grid item sm={10}>
                    <SelectWrapper size="small" fullWidth>
                      <Select
                        className={classes.roundedSelect}
                        onChange={eventHandlers.handleOnInputChange}
                        value={state.form.scheduled_specific_date}
                        name={INPUT_NAMES.SCHEDULED_SPECIFIC_DATE}
                      >
                        {state.specificDateOptions?.map(({ key, value }) => (
                          <SelectMenuItem key={key} data-testid={value} value={value}>
                            {formatMessage(`specific_dates.${key}`)}
                          </SelectMenuItem>
                        ))}
                      </Select>
                    </SelectWrapper>
                  </Grid>
                </Grid>
              )}
            <Grid className={classes.recipientRow}>
              <TextField
                label={formatMessage('recipients')}
                multiline
                minRows={3}
                variant="outlined"
                fullWidth
                onChange={eventHandlers.handleOnInputChange}
                value={state.form.recipients}
                name={INPUT_NAMES.RECIPIENTS}
                InputLabelProps={{
                  shrink: true
                }}
                error={!!state.recipientsError}
                helperText={
                  state.recipientsError
                    ? formatMessage(state.recipientsError, { email: state.firstInvalidRecipient })
                    : ''
                }
              />
            </Grid>
            <Grid className={classes.actions}>
              <Button color="secondary" variant="text" onClick={props.onClose} data-testid="cancel-button">
                {formatMessage('cancel')}
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={eventHandlers.onSchedule}
                data-testid="schedule-button"
                isLoading={state.scheduleInProgress}
              >
                {formatMessage('schedule')}
              </Button>
            </Grid>
          </Grid>
        )}
      </Drawer>
    ),
    [classes, formatMessage, formatReportMessage, state, eventHandlers, props]
  )
}

export default ScheduleReport
