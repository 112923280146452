import React, { useCallback, useEffect, useMemo, useState } from 'react'

import {
  Button,
  Grid,
  LinearProgress,
  Menu,
  MenuItem,
  BitEmptyStateIcon,
  BitHorizontalBarChartIcon,
  BitStackedBarChartIcon
} from '@barracuda-internal/bds-core'

import { DropDownArrow } from '@barracuda-internal/bds-core/dist/Icons/Controls'
import { HelpOutline } from '@barracuda-internal/bds-core/dist/Icons/Core'

import { useFormatMessage } from 'lib/localization'
import { PageContainer } from 'components/libs/layout/pages/PageContainer'
import { ChartIndex, useReportsListLogic } from 'components/pages/reports/reportList/useReportsListLogic'
import styles, { CHART_PADDING, SIDE_MENU_WIDTH, SIDEBAR_WIDTH } from 'components/pages/reports/reportsStyles'
import { GROUP_TITLE, TOPICS, useSupportModalLogic } from 'components/pages/support/useSupportModalLogic'
import ReportThisDomain from 'components/pages/support/help/reportsDomain/ReportsDomain'
import ReportsAllDomains from 'components/pages/support/help/reportsAllDomains/ReportsAllDomains'
import useUserRights, { UserRole } from 'components/libs/userRights/useUserRights'
import { BarracudaReportTypes, ChartTypes, ReportIntervals } from 'types/reports'
import { LineChart } from 'components/pages/reports/reportList/customizedBDSComponents/LineChart'
import { BarChart } from 'components/pages/reports/reportList//customizedBDSComponents/BarChart'
import { StackedLineChartIcon } from 'components/pages/reports/reportList/customizedBDSComponents/StackedLineChartIcon'
import { VerticalBarChartStackedIcon } from 'components/pages/reports/reportList/customizedBDSComponents/VerticalBarChartStackedIcon'
import { ChartTotalBox } from 'components/pages/reports/reportList/customizedBDSComponents/ChartTotalBox'
import { UnifiedReportingSideNavigation } from 'components/pages/reports/reportList/customizedBDSComponents/UnifiedReportingSideNavigation'
import { UnifiedReportingTitleBar } from 'components/pages/reports/reportList/customizedBDSComponents/UnifiedReportingTitleBar'
import ReportTable from 'components/pages/reports/reportList/ReportTable'
import ContentLoaderMessage from 'components/libs/contentLoader/ContentLoaderMessage'
import ScheduleReport from 'components/libs/scheduleReport/ScheduleReport'

const BASE_I18N_KEY = 'ess.reports'

export type Stacked = boolean
export type Vertical = boolean
export type Icon = (props: any) => JSX.Element
export type ChartRenderConfigObject = [(props: any) => JSX.Element, Stacked, Vertical, Icon]
export type ChartRenderConfig = { [keys in ChartTypes]: ChartRenderConfigObject }
const CHART_RENDER_CONFIG: ChartRenderConfig = {
  [ChartTypes.linear]: [LineChart, false, false, StackedLineChartIcon],
  [ChartTypes.bar]: [BarChart, false, false, BitStackedBarChartIcon],
  [ChartTypes.barStacked]: [BarChart, true, false, BitHorizontalBarChartIcon],
  [ChartTypes.verticalBar]: [BarChart, false, true, VerticalBarChartStackedIcon],
  [ChartTypes.verticalBarStacked]: [BarChart, true, true, VerticalBarChartStackedIcon]
}
const INTERVAL_SELECTOR_COMPATIBLE_CHART_TYPES: BarracudaReportTypes[] = [
  BarracudaReportTypes.inboundTraffic,
  BarracudaReportTypes.outboundTraffic,
  BarracudaReportTypes.inboundBlockedEmails
]

const MAX_EMAIL_LENGTH_IN_CHARTS = 50

const ReportList = () => {
  const [state, eventHandlers, titleBarConfig, chartData] = useReportsListLogic()
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const { isUserType } = useUserRights()
  const isDomainUser = isUserType(UserRole.DOMAIN_USER)
  const [onOpenSupport, HelpModal] = useSupportModalLogic({
    content: isDomainUser ? ReportThisDomain : ReportsAllDomains,
    title: isDomainUser ? TOPICS.generateReportThisDomain : TOPICS.generateReportAllDomains,
    groupTitle: GROUP_TITLE.reportTopics
  })
  const [chartWidth, setChartWidth] = useState<number>(0)

  const updateChartWidth = useCallback(() => {
    setChartWidth(Math.max((window?.innerWidth || 0) - SIDE_MENU_WIDTH - SIDEBAR_WIDTH - CHART_PADDING * 2, 0))
  }, [])

  // update chart width
  useEffect(() => {
    updateChartWidth()
    window.addEventListener('resize', updateChartWidth)
    return () => {
      window.removeEventListener('resize', updateChartWidth)
    }
  }, [updateChartWidth])

  const Title = useCallback(
    () => (
      <data>
        {formatMessage('title')}
        <HelpOutline data-testid="on-open-support" onClick={onOpenSupport} className={classes.helpIcon} />
      </data>
    ),
    [formatMessage, onOpenSupport, classes]
  )

  return useMemo(() => {
    const [CurrentChart, isStacked, isVertical] = CHART_RENDER_CONFIG[chartData.type]

    return (
      <PageContainer isLoading={state.isInitialLoading} isFailedToLoad={state.isGetReportsListFailed} noPadding>
        {state.isFailedInitialLoad && <ContentLoaderMessage />}
        <HelpModal />
        <Grid className={classes.reportContainer} container direction="row">
          {/* Side navigation section */}
          <Grid item className={classes.sideMenu}>
            <UnifiedReportingSideNavigation
              scrollableItemContainer
              dropdowns={state.reportsList as any}
              onSearchChange={(e: any) => eventHandlers.onSearch(e.target.value)}
              onClickListItem={eventHandlers.onSelectListItem}
              selectedDropdownItem={state.selectedListItem}
              onClickListItemPin={eventHandlers.onPinnedListItem}
              pinnedDropdownItem={state.pinnedListItem}
              placeholderText={formatMessage('search')}
              title={(<Title />) as unknown as string} // force to convert the type to string
            />
          </Grid>
          <Grid item className={classes.contentWrapper} xs>
            {state.selectedListItem && (
              <Grid container direction="column">
                {/* Title bar section */}
                <Grid item xs style={{ width: chartWidth + CHART_PADDING * 2 }} className={classes.headerWrapper}>
                  <UnifiedReportingTitleBar {...titleBarConfig} reportName={state.selectedListItem?.label || ''} />
                </Grid>

                {(state.isFetchingReport || state.isSavingReport) && (
                  <LinearProgress data-testid="table-loading-state" />
                )}

                {/* Chart section */}
                <Grid container className={classes.chartWrapper} direction="column">
                  {/* Chart controllers */}
                  <Grid container justifyContent="flex-end" direction="row">
                    {state.selectedListItem?.type &&
                      !state.intervalDropdownConfig[1] &&
                      INTERVAL_SELECTOR_COMPATIBLE_CHART_TYPES.includes(state.selectedListItem.type) && (
                        <>
                          <Button
                            className={classes.intervalButton}
                            disabled={state.isFetchingReport || state.isFailedToFetchingReport}
                            size="small"
                            variant="text"
                            color="secondary"
                            endIcon={<DropDownArrow />}
                            onClick={(e: any) => {
                              eventHandlers.onSetIntervalAnchorEl(e.currentTarget)
                            }}
                          >
                            {formatMessage(`intervals.${state.selectedInterval.toLowerCase()}`)}
                          </Button>
                          <Menu
                            getContentAnchorEl={null}
                            anchorEl={state.intervalAnchorEl}
                            keepMounted
                            open={!!state.intervalAnchorEl}
                            onClose={() => {
                              eventHandlers.onSetIntervalAnchorEl(null)
                            }}
                            anchorOrigin={{
                              vertical: 40,
                              horizontal: 20
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'center'
                            }}
                          >
                            {Object.values(ReportIntervals).map((interval: string) => (
                              <MenuItem
                                disabled={state.intervalDropdownConfig[0].includes(interval as ReportIntervals)}
                                key={interval}
                                value={interval}
                                onClick={() => {
                                  eventHandlers.onSelectInterval(interval as ReportIntervals)
                                }}
                              >
                                {formatMessage(`intervals.${interval.toLowerCase()}`)}
                              </MenuItem>
                            ))}
                          </Menu>
                        </>
                      )}
                    {[...chartData.icons].reverse().map((chartType: ChartTypes, idx: number) => {
                      const [, , , Icon] = CHART_RENDER_CONFIG[chartType]
                      const activeChartIndex = chartData.icons.length > 1 ? Math.abs(idx - 1) : 0
                      const isActiveChart = state.selectedChartIndex === activeChartIndex

                      return (
                        <Grid
                          key={activeChartIndex}
                          item
                          className={isActiveChart ? classes.selectedChartIconWrapper : classes.chartIconWrapper}
                          onClick={() => {
                            eventHandlers.onSelectChartIndex(activeChartIndex as ChartIndex)
                          }}
                        >
                          <Icon className={isActiveChart ? classes.selectedChartIcon : classes.chartIcon} />
                        </Grid>
                      )
                    })}
                  </Grid>

                  {/* chart */}
                  <CurrentChart
                    chartData={chartData.data || {}}
                    chartDimension={{
                      height: '300px',
                      width: chartWidth
                    }}
                    emptyState={{
                      icon: <BitEmptyStateIcon className={classes.errorChartIcon} data-testid="empty-state" />,
                      text: state.isFailedToFetchingReport ? formatMessage('unable_to_load') : ''
                    }}
                    hasEntries={!!chartData.data.datasets?.length}
                    stacked={isStacked}
                    horizontalBarChart={!isVertical}
                    lineChartOptions={{
                      scales: { y: { beginAtZero: true } }
                    }}
                    barChartOptions={{
                      scales: {
                        x: { ticks: { precision: 0 } },
                        y: {
                          ticks: {
                            callback: (value: number) => {
                              const label = chartData?.data?.labels?.[value] as string
                              if (!label) {
                                return ''
                              }
                              if (label.length > MAX_EMAIL_LENGTH_IN_CHARTS) {
                                return `${label.substring(0, MAX_EMAIL_LENGTH_IN_CHARTS)}...`
                              }
                              return label
                            }
                          }
                        }
                      }
                    }}
                  />

                  {/*  chart totals */}
                  <Grid container direction="row">
                    {chartData.data.datasets.map(dataset => {
                      const total = chartData.totals[(dataset as any).id] || 0
                      return (
                        <Grid key={`${dataset.type}${dataset.label}`} item>
                          <ChartTotalBox
                            color={(dataset.borderColor as string) || (dataset.backgroundColor as string) || ''}
                            text={dataset.label || ''}
                            count={total || 0}
                          />
                        </Grid>
                      )
                    })}
                  </Grid>
                </Grid>
              </Grid>
            )}

            {/* Table section */}
            <ReportTable selectedListItem={state.selectedListItem} />
          </Grid>
        </Grid>
      </PageContainer>
    )
  }, [state, HelpModal, classes, Title, eventHandlers, formatMessage, titleBarConfig, chartWidth, chartData])
}
export default ReportList
