import { useCallback, useEffect, useMemo } from 'react'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { getOldMxRecords, setOldMxRecordsError } from 'redux/features/emailServer/emailServerSlice'
import { OldMxRecords } from 'types/emailServer'
import { isFailed, isPending, isSuccess } from 'redux/toolkit/api'
import { ProviderWithMxLinks } from 'components/pages/setupFlow/views/emailFlow/sections/providerTypes'
import { useDetectProviderLinksLogic } from 'components/pages/setupFlow/views/emailFlow/sections/useDetectProviderLinksLogic'

export interface State {
  oldMxRecords: OldMxRecords | undefined
  getOldMxRecordsInProgress: boolean
  verifyUpdateButtonStatuses: { disabled: boolean; inProgress: boolean }
  providerWithMxLinks: ProviderWithMxLinks | undefined
  isMissedToVerify: boolean[]
}

export interface EventHandlers {
  onVerifyUpdate: () => void
}

export type RemoveOldRecordsLogic = [State, EventHandlers]

export const useRemoveOldMxRecordsLogic = (): RemoveOldRecordsLogic => {
  const dispatch = useAppDispatch()
  const {
    detectedDomain,
    getOldMxRecordsInProgress,
    getOldMxRecordsSuccess,
    getOldMxRecordsFailed,
    oldMxRecords,
    oldRecordsError
  } = useAppSelector(_store => ({
    detectedDomain: _store.emailServer.emailServer?.domainName,
    getOldMxRecordsInProgress: isPending(_store.emailServer.getOldMxRecordsApiStatus),
    getOldMxRecordsSuccess: isSuccess(_store.emailServer.getOldMxRecordsApiStatus),
    getOldMxRecordsFailed: isFailed(_store.emailServer.getOldMxRecordsApiStatus),
    oldMxRecords: _store.emailServer.oldMxRecords,
    oldRecordsError: _store.emailServer.emailFlowState.oldRecordsError
  }))
  const [providerWithMxLinks] = useDetectProviderLinksLogic()

  useEffect(() => {
    if (detectedDomain) {
      dispatch(getOldMxRecords(detectedDomain))
    }
  }, [dispatch, detectedDomain])

  const isMissedToVerify = useMemo(() => {
    const isAlreadyHitVerifyButton: boolean = !oldRecordsError && (getOldMxRecordsSuccess || getOldMxRecordsFailed)

    return (oldMxRecords || []).map(record => !record.verified && isAlreadyHitVerifyButton)
  }, [oldRecordsError, getOldMxRecordsSuccess, getOldMxRecordsFailed, oldMxRecords])

  const onVerifyUpdate = useCallback(() => {
    if (detectedDomain) {
      dispatch(setOldMxRecordsError())
      dispatch(getOldMxRecords(detectedDomain))
    }
  }, [dispatch, detectedDomain])

  const verifyUpdateButtonStatuses = useMemo(
    () => ({
      disabled: !oldMxRecords,
      inProgress: getOldMxRecordsInProgress
    }),
    [oldMxRecords, getOldMxRecordsInProgress]
  )

  return useMemo(
    () => [
      {
        oldMxRecords,
        getOldMxRecordsInProgress,
        verifyUpdateButtonStatuses,
        providerWithMxLinks,
        isMissedToVerify
      },
      { onVerifyUpdate }
    ],
    [
      oldMxRecords,
      getOldMxRecordsInProgress,
      verifyUpdateButtonStatuses,
      providerWithMxLinks,
      isMissedToVerify,
      onVerifyUpdate
    ]
  )
}
