import { colors, createStyles, makeStyles } from '@barracuda-internal/bds-core'

export const SIDE_MENU_WIDTH = 270
export const SIDEBAR_WIDTH = 48
export const CHART_PADDING = 16

export default makeStyles(theme => {
  const DEFAULT_STYLES = createStyles({
    chartIcon: {
      width: 20,
      height: 20,
      color: '#646464',
      zoom: '0.80',
      display: 'block',
      marginLeft: 4
    },
    chartIconWrapper: {
      cursor: 'pointer',
      width: 24,
      height: 24,
      borderRadius: '50%',
      backgroundColor: 'white',
      marginLeft: theme.spacing(1)
    },
    selector: {
      height: 40
    }
  })

  return {
    container: {
      backgroundColor: colors.white
    },
    sideMenu: {
      position: 'absolute',
      left: 0,
      top: 0,
      bottom: 0,
      width: SIDE_MENU_WIDTH,
      borderRight: `1px solid ${colors.scaleGray100}`
    },
    reportContainer: {
      height: '100%'
    },
    contentWrapper: {
      marginLeft: SIDE_MENU_WIDTH,
      position: 'relative',
      height: 'calc(100vh - 110px)',
      overflowY: 'hidden'
    },
    helpIcon: {
      cursor: 'pointer',
      marginLeft: theme.spacing(0.5),
      width: theme.spacing(2.5),
      marginTop: theme.spacing(1),
      color: colors.blue600
    },
    headerWrapper: {
      maxHeight: theme.spacing(8)
    },
    chartWrapper: {
      backgroundColor: colors.scaleGray025,
      padding: CHART_PADDING
    },
    tableControlsWrapper: {
      padding: theme.spacing(2)
    },
    tableControllerItem: {
      marginRight: theme.spacing(1)
    },
    intervalButton: {
      marginTop: -theme.spacing(0.5)
    },
    chartIconWrapper: {
      ...DEFAULT_STYLES.chartIconWrapper
    },
    selectedChartIconWrapper: {
      ...DEFAULT_STYLES.chartIconWrapper,
      backgroundColor: '#E7F4FE'
    },
    chartIcon: {
      ...DEFAULT_STYLES.chartIcon
    },
    selectedChartIcon: {
      ...DEFAULT_STYLES.chartIcon,
      color: '#067CC1'
    },
    selector: {
      height: 40
    },
    domainsWrapper: {
      maxWidth: 250
    },
    dataTableWrapper: {
      height: '100%',
      backgroundColor: colors.white,
      marginBottom: theme.spacing(3),
      '& .MuiPaper-root': {
        boxShadow: 'none'
      }
    },
    customFilterWrapper: {
      padding: theme.spacing(1.5)
    },
    fixedDateRangeWarningText: {
      marginTop: theme.spacing(2),
      color: colors.statusCritical
    },
    expiredDateRangeText: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    errorChartIcon: {
      width: theme.spacing(8),
      height: theme.spacing(8),
      color: colors.scaleGray200
    },
    errorTableIcon: {
      marginTop: theme.spacing(10),
      marginBottom: theme.spacing(1),
      width: theme.spacing(8),
      height: theme.spacing(8),
      color: colors.scaleGray200
    },
    emptyTableText: {
      marginTop: theme.spacing(10)
    },
    stickeyTabs: {
      position: 'sticky',
      top: 0,
      zIndex: 1100,
      backgroundColor: colors.white
    }
  }
})
