/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react'
import { AccountSwitcher as BDSAccountSwitcher } from '@barracuda-internal/bds-core-v2'
import { Grid } from '@barracuda-internal/bds-core'
import { useAccountSwitcherLogic } from 'components/libs/accountSwitcher/useAccountSwitcherLogic'
import appConfig from 'config/appConfig'
import { useFormatMessage } from 'lib/localization'

import MaskLoader from 'components/libs/contentLoader/MaskLoader'
import useUserRights from '../userRights/useUserRights'
import styles from './accountSwitcherStyles'

const BASE_I18N_KEY = 'ess.account_switcher'

const AccountSwitcher = () => {
  const { userFeatures } = useUserRights()
  const classes = styles({ nonClickable: appConfig.APP.IS_WIZARD })
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [state, eventHandlers] = useAccountSwitcherLogic()

  return useMemo(() => {
    if (
      (!appConfig.APP.IS_ADMIN && !(appConfig.APP.IS_WIZARD && userFeatures?.convergedSetupFlowRollout)) ||
      !state.accounts ||
      state.accounts?.length < 2
    ) {
      return null
    }

    return (
      <Grid className={classes.wrapper}>
        <MaskLoader
          open={state.accountSwitchingInProgress}
          fullPage
          message={`${formatMessage('switching_account', { account: state.selectedAccount?.name || '' })}`}
        />
        <BDSAccountSwitcher
          accounts={state.accounts}
          onSelectAccountCallback={eventHandlers.onSelectAccountCallback}
          initAccount={state.activeAccountId}
        />
      </Grid>
    )
  }, [classes, eventHandlers, state, formatMessage, userFeatures])
}

export default AccountSwitcher
