import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DateTime } from 'luxon'
import { isEqual } from 'lodash'
import { ApiStatus, failedResponse, inIdle, inProgress, successResponse } from 'redux/toolkit/api'
import {
  ALL_DOMAINS,
  EmailFlows,
  ListTop,
  PaginationState,
  RelativeDateRanges,
  ReportIntervals,
  ReportsList,
  ReportsListItem,
  ReportsResponse,
  ScheduledReport,
  TableFilters
} from 'types/reports'
import {
  createReportParams,
  setCustomReportsList,
  setPinnedReportId,
  deleteScheduledReport,
  getReport,
  getReportsList,
  getScheduledReportList,
  createScheduleReport,
  updateScheduledReport,
  downloadReport,
  DownloadReportResponse
} from 'redux/features/reports/reportsApiThunks'

export const DEFAULT_FILTERS: TableFilters = {
  search: '',
  startDate: DateTime.now().minus({ day: 30 }).toISODate(),
  endDate: DateTime.now().toISODate(),
  relativeDateRange: RelativeDateRanges.lastMonth,
  domainIds: [ALL_DOMAINS],
  interval: ReportIntervals.daily,
  direction: EmailFlows.inbound,
  listTop: ListTop.top5,
  page: 1,
  pageSize: 10,
  isDirty: false,
  columnFilters: {},
  isValid: true,
  errors: {}
}

export interface ReportsState {
  api: {
    getReportsListApiStatus: ApiStatus
    getReportApiStatus: ApiStatus
    downloadReportApiStatus: ApiStatus
    setCustomReportsListApiStatus: ApiStatus
    setPinnedReportIdApiStatus: ApiStatus
    getScheduledReportsListApiStatus: ApiStatus
    scheduleReportApiStatus: ApiStatus
    updateScheduledReportApiStatus: ApiStatus
    deleteScheduledReportApiStatus: ApiStatus
  }
  list?: ReportsList
  report?: ReportsResponse
  downloadReportData?: DownloadReportResponse
  filters: TableFilters
  savedReportId?: string
  searchValue: string
  isInitialFilters: boolean
  scheduledList: ScheduledReport[]
  selectedListItem?: ReportsListItem
}

// initialState
export const INITIAL_STATE: ReportsState = {
  api: {
    getReportsListApiStatus: inIdle,
    getReportApiStatus: inIdle,
    downloadReportApiStatus: inIdle,
    setCustomReportsListApiStatus: inIdle,
    setPinnedReportIdApiStatus: inIdle,
    getScheduledReportsListApiStatus: inIdle,
    scheduleReportApiStatus: inIdle,
    updateScheduledReportApiStatus: inIdle,
    deleteScheduledReportApiStatus: inIdle
  },
  list: undefined,
  report: undefined,
  downloadReportData: undefined,
  filters: DEFAULT_FILTERS,
  searchValue: '',
  isInitialFilters: true,
  scheduledList: [],
  selectedListItem: undefined
}

/* eslint-disable no-param-reassign */
export const reportsSlice = createSlice({
  name: 'REPORTS',
  initialState: INITIAL_STATE,
  reducers: {
    resetReportsList: state => {
      state.api.getReportsListApiStatus = INITIAL_STATE.api.getReportsListApiStatus
      state.list = INITIAL_STATE.list
    },
    resetReport: state => {
      state.api.getReportApiStatus = INITIAL_STATE.api.getReportApiStatus
      state.report = INITIAL_STATE.report
    },
    resetDownloadReport: state => {
      state.api.downloadReportApiStatus = INITIAL_STATE.api.downloadReportApiStatus
      state.downloadReportData = INITIAL_STATE.downloadReportData
    },
    resetSetCustomReportsList: state => {
      state.api.setCustomReportsListApiStatus = INITIAL_STATE.api.setCustomReportsListApiStatus
    },
    resetSetPinnedReportId: state => {
      state.api.setPinnedReportIdApiStatus = INITIAL_STATE.api.setPinnedReportIdApiStatus
    },
    setSelectedListItem: (state, action: PayloadAction<ReportsListItem | undefined>) => {
      state.selectedListItem = action.payload
    },
    updateFilters: (state, action: PayloadAction<Partial<TableFilters>>) => {
      const updateFilters = { ...state.filters, ...action.payload } as TableFilters
      // update only the filters and set to dirty state when there are changes
      if (!isEqual(updateFilters, state.filters)) {
        state.filters = { ...updateFilters, isDirty: true }
        state.isInitialFilters = false
      }
    },
    updatePagination: (state, action: PayloadAction<PaginationState>) => {
      state.filters.page = action.payload.page
      state.filters.pageSize = action.payload.pageSize
      state.filters.isDirty = !!action.payload.isDirty
    },
    setSavedReportId: (state, action: PayloadAction<string | undefined>) => {
      state.savedReportId = action.payload
    },
    setSearchValue: (state, action: PayloadAction<string>) => {
      state.searchValue = action.payload
    },
    resetScheduledReportsList: state => {
      state.api.getScheduledReportsListApiStatus = INITIAL_STATE.api.getScheduledReportsListApiStatus
      state.scheduledList = INITIAL_STATE.scheduledList
    },
    resetScheduledReportUpdate: state => {
      state.api.scheduleReportApiStatus = INITIAL_STATE.api.scheduleReportApiStatus
      state.api.updateScheduledReportApiStatus = INITIAL_STATE.api.updateScheduledReportApiStatus
      state.api.deleteScheduledReportApiStatus = INITIAL_STATE.api.deleteScheduledReportApiStatus
    },
    reset: () => ({
      ...INITIAL_STATE
    })
  },
  extraReducers: builder => {
    builder
      // get reports list
      .addCase(getReportsList.pending, state => {
        state.api.getReportsListApiStatus = inProgress
      })
      .addCase(getReportsList.fulfilled, (state, action) => {
        state.api.getReportsListApiStatus = successResponse
        state.list = action.payload
      })
      .addCase(getReportsList.rejected, (state, action) => {
        state.api.getReportsListApiStatus = failedResponse(action.payload)
      })

      // get report
      .addCase(getReport.pending, (state, payload) => {
        if (payload?.meta?.arg?.reportIsChanged) {
          state.filters = INITIAL_STATE.filters
          state.searchValue = INITIAL_STATE.searchValue
          state.isInitialFilters = INITIAL_STATE.isInitialFilters
        }
        state.api.getReportApiStatus = inProgress
        state.filters = { ...state.filters, isDirty: false }
      })
      .addCase(getReport.fulfilled, (state, action) => {
        state.api.getReportApiStatus = successResponse
        state.report = action.payload
      })
      .addCase(getReport.rejected, (state, action) => {
        state.api.getReportApiStatus = failedResponse(action.payload)
        state.report = INITIAL_STATE.report
      })

      // download report
      .addCase(downloadReport.pending, (state, payload) => {
        state.api.downloadReportApiStatus = inProgress
      })
      .addCase(downloadReport.fulfilled, (state, action) => {
        state.api.downloadReportApiStatus = successResponse
        state.downloadReportData = action.payload
      })
      .addCase(downloadReport.rejected, (state, action) => {
        state.api.downloadReportApiStatus = failedResponse(action.payload)
      })

      // set custom reports list
      .addCase(setCustomReportsList.pending, state => {
        state.api.setCustomReportsListApiStatus = inProgress
      })
      .addCase(setCustomReportsList.fulfilled, (state, action) => {
        state.api.setCustomReportsListApiStatus = successResponse
        if (state.list) {
          state.list.customReports = action.payload.items
        }
      })
      .addCase(setCustomReportsList.rejected, (state, action) => {
        state.api.setCustomReportsListApiStatus = failedResponse(action.payload)
      })

      // set pinned reports id
      .addCase(setPinnedReportId.pending, state => {
        state.api.setPinnedReportIdApiStatus = inProgress
      })
      .addCase(setPinnedReportId.fulfilled, (state, action) => {
        state.api.setPinnedReportIdApiStatus = successResponse
        if (state.list) {
          state.list.pinnedReportId = action.payload.id
        }
      })
      .addCase(setPinnedReportId.rejected, (state, action) => {
        state.api.setPinnedReportIdApiStatus = failedResponse(action.payload)
      })

      // get scheduled reports list
      .addCase(getScheduledReportList.pending, state => {
        state.api.getScheduledReportsListApiStatus = inProgress
      })
      .addCase(getScheduledReportList.fulfilled, (state, action) => {
        state.api.getScheduledReportsListApiStatus = successResponse
        state.scheduledList = action.payload
      })
      .addCase(getScheduledReportList.rejected, (state, action) => {
        state.api.getScheduledReportsListApiStatus = failedResponse(action.payload)
      })

      // schedule report
      .addCase(createScheduleReport.pending, (state, payload) => {
        state.api.scheduleReportApiStatus = inProgress
      })
      .addCase(createScheduleReport.fulfilled, (state, action) => {
        state.api.scheduleReportApiStatus = successResponse
      })
      .addCase(createScheduleReport.rejected, (state, action) => {
        state.api.scheduleReportApiStatus = failedResponse(action.payload)
      })

      // update scheduled report
      .addCase(updateScheduledReport.pending, (state, payload) => {
        state.api.updateScheduledReportApiStatus = inProgress
      })
      .addCase(updateScheduledReport.fulfilled, (state, action) => {
        state.api.updateScheduledReportApiStatus = successResponse
      })
      .addCase(updateScheduledReport.rejected, (state, action) => {
        state.api.updateScheduledReportApiStatus = failedResponse(action.payload)
      })

      // delete scheduled report
      .addCase(deleteScheduledReport.pending, (state, payload) => {
        state.api.deleteScheduledReportApiStatus = inProgress
      })
      .addCase(deleteScheduledReport.fulfilled, (state, action) => {
        state.api.deleteScheduledReportApiStatus = successResponse
      })
      .addCase(deleteScheduledReport.rejected, (state, action) => {
        state.api.deleteScheduledReportApiStatus = failedResponse(action.payload)
      })
  }
})
/* eslint-enable no-param-reassign */

export const {
  resetReportsList,
  resetReport,
  resetDownloadReport,
  resetSetCustomReportsList,
  resetSetPinnedReportId,
  setSavedReportId,
  setSearchValue,
  updateFilters,
  updatePagination,
  resetScheduledReportsList,
  resetScheduledReportUpdate,
  reset,
  setSelectedListItem
} = reportsSlice.actions

export {
  getReportsList,
  getReport,
  downloadReport,
  setCustomReportsList,
  createReportParams,
  setPinnedReportId,
  getScheduledReportList,
  createScheduleReport,
  updateScheduledReport,
  deleteScheduledReport
}

export default reportsSlice.reducer
