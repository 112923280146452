import { useMemo, useCallback, useEffect, useState } from 'react'
import { snakeCase } from 'lodash'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { isPending, isSuccess } from 'redux/toolkit/api'
import { getReportsList, createScheduleReport, updateScheduledReport } from 'redux/features/reports/reportsSlice'
import {
  BarracudaReport,
  BarracudaReportTypes,
  ParsedScheduleReport,
  RelativeDateRanges,
  ScheduleReportEveryOption,
  ScheduleReportFormat,
  ScheduleReportFrequency,
  SpecificDateOptions
} from 'types/reports'
import { SHORT_DAYS } from 'types/Settings'
import { ScheduleReportPayload, UpdateScheduledReportPayload } from 'redux/features/reports/reportsApiThunks'
import { isEmailValid } from 'lib/validation'
import { DEFAULT_TIMEZONE } from 'lib/datetime'
import { getAvailableDomains } from 'redux/features/user/userSlice'

type EveryOption = {
  key: string
  value: ScheduleReportEveryOption
}

type DateOption = {
  key: string
  value: SpecificDateOptions
}

type TimeRangeItem = {
  original: RelativeDateRanges
  transformed: string
}
export interface State {
  isLoaded: boolean
  scheduleInProgress: boolean
  form: ScheduleReportPayload
  timeRanges: TimeRangeItem[]
  formats: string[]
  everyOptions: EveryOption[]
  frequency: string[]
  specificDateOptions: DateOption[]
  days: number[]
  predefinedBarracudaReports: BarracudaReport[]
  recipientsError: string
  firstInvalidRecipient: string
}

export interface EventHandlers {
  handleOnInputChange: (
    event: React.ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>
  ) => void
  handleFrequencyClick: (value: string) => void
  handleDayClick: (value: string) => void
  onSchedule: () => void
}

export interface UseScheduleReportLogicProps {
  name?: string
  report?: ParsedScheduleReport
  onClose: () => void
}

const DEFAULT_FORMAT = ScheduleReportFormat.csv
const DEFAULT_FREQUENCY = ScheduleReportFrequency.daily

export const enum INPUT_NAMES {
  REPORT_ID = 'report_id',
  RELATIVE_DATE_RANGE = 'relative_date_range',
  SCHEDULED_FREQUENCY = 'scheduled_frequency',
  SCHEDULED_DAY_OF_MONTH = 'scheduled_day_of_month',
  SCHEDULED_DAY_OF_WEEK = 'scheduled_day_of_week',
  SCHEDULED_SPECIFIC_DATE = 'scheduled_specific_date',
  RECIPIENTS = 'recipients',
  FORMAT = 'format'
}

export const useScheduleReportLogic = (props: UseScheduleReportLogicProps): [State, EventHandlers] => {
  const dispatch = useAppDispatch()
  const {
    bccAccountId,
    accountId,
    appTimezone,
    reportsList,
    isGetReportsListSuccess,
    isScheduleReportSuccess,
    isScheduleReportPending,
    isUpdateScheduledReportPending,
    isUpdateScheduledReportSuccess,
    isGetAvailableDomainsSuccess,
    domains
  } = useAppSelector(_store => ({
    bccAccountId: _store.auth.accessTokenObject?.bccAccountId || '',
    accountId: _store.auth.accessTokenObject?.accountId || '',
    appTimezone: _store.app.timezone,
    isGetReportsListSuccess: isSuccess(_store.reports.api.getReportsListApiStatus),
    isScheduleReportSuccess: isSuccess(_store.reports.api.scheduleReportApiStatus),
    isScheduleReportPending: isPending(_store.reports.api.scheduleReportApiStatus),
    isUpdateScheduledReportSuccess: isSuccess(_store.reports.api.updateScheduledReportApiStatus),
    isUpdateScheduledReportPending: isPending(_store.reports.api.updateScheduledReportApiStatus),
    reportsList: _store.reports.list,
    isGetAvailableDomainsSuccess: isSuccess(_store.user.api.getAvailableDomainsApiStatus),
    domains: _store.user.availableDomains
  }))

  const mapApiToEnumRelativeDateRange = (value: string): RelativeDateRanges | undefined => {
    switch (value) {
      case 'last_day':
        return RelativeDateRanges.lastDay
      case 'last_week':
        return RelativeDateRanges.last7Days
      case 'last_month':
        return RelativeDateRanges.lastMonth
      default:
        return undefined
    }
  }

  const [formObject, setFormObject] = useState<ScheduleReportPayload>({
    bcc_id: bccAccountId,
    account_id: accountId,
    report_id: props.name || props.report?.reportId || '',
    relative_date_range: props.report?.relativeDateRange
      ? mapApiToEnumRelativeDateRange(props.report.relativeDateRange) || RelativeDateRanges.lastMonth
      : RelativeDateRanges.lastMonth,
    scheduled_frequency: props.report?.scheduledFrequency || DEFAULT_FREQUENCY,
    scheduled_day_of_month: props.report?.scheduledDayOfMonth || ScheduleReportEveryOption.first,
    scheduled_day_of_week: props.report?.scheduledDayOfWeek || SHORT_DAYS[0],
    scheduled_specific_date: props.report?.scheduledSpecificDate || String(SpecificDateOptions.First),
    recipients: props.report?.recipients || [],
    domain_ids: isGetAvailableDomainsSuccess ? (domains || []).map(domain => Number(domain.domainId)) : [],
    timezone: appTimezone || DEFAULT_TIMEZONE,
    format: props.report?.format || DEFAULT_FORMAT,
    enabled: props.report?.enabled || true
  })

  const [recipientsError, setRecipientsError] = useState<string>('')
  const [firstInvalidRecipient, setFirstInvalidRecipient] = useState<string>('')
  const [timeRanges, setTimeRanges] = useState<TimeRangeItem[]>([])

  // init
  useEffect(() => {
    dispatch(getReportsList())
    dispatch(getAvailableDomains())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const predefinedBarracudaReports = useMemo(
    () =>
      (reportsList?.barracudaReports || []).filter((report: BarracudaReport) =>
        Object.values(BarracudaReportTypes).includes(report.id)
      ),
    [reportsList]
  )

  useEffect(() => {
    if (isGetReportsListSuccess && !props.name && !props.report && !formObject[INPUT_NAMES.REPORT_ID]) {
      setFormObject({ ...formObject, [INPUT_NAMES.REPORT_ID]: reportsList?.barracudaReports[0].id || '' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGetReportsListSuccess, props.name, props.report, reportsList])

  function parseCronExpression(cron: string) {
    // Split the cron expression into its components
    const cronParts = cron.split(' ')

    // Extract the default day of the month and day of the week
    let scheduled_day_of_month = cronParts[2]
    let scheduled_day_of_week = cronParts[4]

    // Handle the "Nth weekday of the month" format (e.g., "2#2" means the 2nd Tuesday)
    if (cronParts[4].includes('#')) {
      const [dayOfMonth, dayOfWeek] = cronParts[4].split('#')
      scheduled_day_of_month = dayOfMonth // Set the scheduled day of the month
      scheduled_day_of_week = dayOfWeek // Set the scheduled day of the week
    }

    return {
      // If the day of the week is "?", it means a specific date is used, so return null
      scheduled_day_of_month: cronParts[4] === '?' ? null : scheduled_day_of_month,

      // Convert the scheduled day of the week to a number (or return null if "?")
      scheduled_day_of_week: cronParts[4] === '?' ? null : Number(scheduled_day_of_week),

      // If the day of the week is "?", it means the specific date is used instead
      scheduled_specific_date: cronParts[4] === '?' ? cronParts[2] : undefined
    }
  }

  useEffect(() => {
    if (isGetAvailableDomainsSuccess && domains) {
      setFormObject(prevState => ({
        ...prevState,
        domain_ids: domains.map(domain => Number(domain.domainId))
      }))
    }
  }, [isGetAvailableDomainsSuccess, domains])

  useEffect(() => {
    const rawTimeRanges = [RelativeDateRanges.lastMonth, RelativeDateRanges.last7Days, RelativeDateRanges.lastDay]
    const mappedTimeRanges = rawTimeRanges.map(item => ({
      original: item,
      transformed: snakeCase(item)
    }))
    setTimeRanges(mappedTimeRanges)
  }, [])

  function mapCronToScheduledDayOfMonth(dayOfMonth: string | null): ScheduleReportEveryOption | null {
    if (dayOfMonth === null) {
      return ScheduleReportEveryOption.specific_date
    }

    switch (dayOfMonth) {
      case '1':
        return ScheduleReportEveryOption.first
      case '2':
        return ScheduleReportEveryOption.second
      case '3':
        return ScheduleReportEveryOption.third
      case '4':
        return ScheduleReportEveryOption.fourth
      case 'L':
        return ScheduleReportEveryOption.last
      default:
        return null
    }
  }

  const handleOnInputChange = useCallback(
    (
      event: React.ChangeEvent<{
        name?: string | undefined
        value: unknown
      }>
    ) => {
      const { name, value } = event.target
      if (name) {
        setFormObject({
          ...formObject,
          [name]: value,
          ...(name === INPUT_NAMES.SCHEDULED_DAY_OF_MONTH &&
            (value === ScheduleReportEveryOption.specific_date
              ? {
                  [INPUT_NAMES.SCHEDULED_SPECIFIC_DATE]: String(SpecificDateOptions.First),
                  [INPUT_NAMES.SCHEDULED_DAY_OF_WEEK]: null
                }
              : {
                  [INPUT_NAMES.SCHEDULED_DAY_OF_WEEK]: SHORT_DAYS[0]
                }))
        })
      }
    },
    [formObject]
  )

  const handleFrequencyClick = useCallback(
    (value: string) => {
      setFormObject({
        ...formObject,
        [INPUT_NAMES.SCHEDULED_FREQUENCY]: value,
        [INPUT_NAMES.SCHEDULED_DAY_OF_MONTH]:
          value === ScheduleReportFrequency.monthly ? ScheduleReportEveryOption.first : undefined,
        [INPUT_NAMES.SCHEDULED_DAY_OF_WEEK]: value !== ScheduleReportFrequency.daily ? SHORT_DAYS[0] : undefined
      })
    },
    [formObject]
  )

  const handleDayClick = useCallback(
    (value: string) => {
      setFormObject({
        ...formObject,
        [INPUT_NAMES.SCHEDULED_DAY_OF_WEEK]: Number(value),
        [INPUT_NAMES.SCHEDULED_SPECIFIC_DATE]: undefined
      })
    },
    [formObject]
  )

  const onSchedule = useCallback(() => {
    if (!formObject.recipients || formObject.recipients.length === 0) {
      setRecipientsError('missing_recipients')
      return
    }

    const recipientsString = Array.isArray(formObject.recipients)
      ? formObject.recipients.join(',')
      : formObject.recipients

    const emailArray = recipientsString
      .split(',')
      .map((email: string) => email.trim())
      .filter((email: string) => email !== '')

    const firstInvalidEmail = emailArray.find((email: string) => !isEmailValid(email))
    if (firstInvalidEmail) {
      setRecipientsError('invalid_recipient')
      setFirstInvalidRecipient(firstInvalidEmail)
      return
    }

    const uniqueEmails: Set<string> = new Set()
    const duplicates: string[] = []
    emailArray.forEach(email => {
      if (uniqueEmails.has(email)) {
        // Email is a duplicate
        duplicates.push(email)
      } else {
        // Email is unique, add it to the set
        uniqueEmails.add(email)
      }
    })
    if (duplicates.length > 0) {
      setRecipientsError('duplicate_recipient')
      setFirstInvalidRecipient(duplicates[0])
      return
    }

    if (emailArray.length > 50) {
      setRecipientsError('too_many_recipients')
      return
    }

    let formData = {
      ...formObject,
      recipients: emailArray
    }

    // If monthly frequency is selected and the scheduled day is "specific_date",
    // check if the specific date equals "Last" (i.e. 'L').
    // If so, update the scheduled day of month to be saved as 'last'.
    // Also, update the specific date to be undefined.
    if (
      formData.scheduled_frequency === ScheduleReportFrequency.monthly &&
      formData.scheduled_day_of_month === ScheduleReportEveryOption.specific_date &&
      formData.scheduled_specific_date === SpecificDateOptions.Last
    ) {
      formData = {
        ...formData,
        scheduled_day_of_month: ScheduleReportEveryOption.last,
        scheduled_specific_date: undefined
      }
    }

    if (props.report) {
      const updatePayload: UpdateScheduledReportPayload = {
        id: [props.report?.id],
        ...formData
      }

      dispatch(updateScheduledReport(updatePayload))
    } else {
      dispatch(createScheduleReport(formData))
    }
  }, [dispatch, formObject, props.report])

  useEffect(() => {
    if (isScheduleReportSuccess || isUpdateScheduledReportSuccess) {
      props.onClose()
    }
  }, [isScheduleReportSuccess, isUpdateScheduledReportSuccess, props])

  useEffect(() => {
    if (props.report && props.report.cron) {
      const { scheduled_day_of_month, scheduled_day_of_week, scheduled_specific_date } = parseCronExpression(
        props.report.cron
      )

      const scheduledFrequencyValue = props.report.scheduledFrequency
      const mappedDayOfMonth = mapCronToScheduledDayOfMonth(scheduled_day_of_month)

      const mappedRelativeDateRange = mapApiToEnumRelativeDateRange(props.report.relativeDateRange)
      setFormObject(prevState => ({
        ...prevState,
        [INPUT_NAMES.SCHEDULED_DAY_OF_MONTH]: mappedDayOfMonth ? String(mappedDayOfMonth) : undefined,
        [INPUT_NAMES.SCHEDULED_DAY_OF_WEEK]: scheduled_day_of_week,
        [INPUT_NAMES.SCHEDULED_FREQUENCY]: scheduledFrequencyValue ? scheduledFrequencyValue.toUpperCase() : '',
        [INPUT_NAMES.RELATIVE_DATE_RANGE]: mappedRelativeDateRange as RelativeDateRanges,
        [INPUT_NAMES.SCHEDULED_SPECIFIC_DATE]: scheduled_specific_date
      }))
    }
  }, [props.report])

  return useMemo(
    () => [
      {
        isLoaded: isGetReportsListSuccess,
        scheduleInProgress: isScheduleReportPending || isUpdateScheduledReportPending,
        form: formObject,
        timeRanges,
        formats: Object.values(ScheduleReportFormat),
        everyOptions: Object.entries(ScheduleReportEveryOption).map(([key, value]) => ({
          key,
          value
        })),
        frequency: Object.values(ScheduleReportFrequency),
        specificDateOptions: Object.entries(SpecificDateOptions).map(([key, value]) => ({
          key,
          value
        })),
        days: SHORT_DAYS,
        predefinedBarracudaReports,
        recipientsError,
        firstInvalidRecipient
      },
      { handleOnInputChange, handleFrequencyClick, handleDayClick, onSchedule }
    ],
    [
      formObject,
      timeRanges,
      isGetReportsListSuccess,
      isUpdateScheduledReportPending,
      isScheduleReportPending,
      predefinedBarracudaReports,
      recipientsError,
      firstInvalidRecipient,
      handleOnInputChange,
      handleFrequencyClick,
      handleDayClick,
      onSchedule
    ]
  )
}
