import React from 'react'
import styled from '@emotion/styled'
import { Paper, Link, Divider, Chip } from '@barracuda-internal/bds-core-v2'

const CenteredPaper = styled(Paper)`
  width: 400px;
  height: 600px;
  margin: 30px auto;
  padding: 20px;
`

const TestBDSV2: React.FC = () => (
  <CenteredPaper>
    <Link bdsFontSize="default" href="https://v2.cudaui.com/" underline="hover">
      BDS V2 documentation &raquo;
    </Link>
    <br />
    <Divider />
    <br />
    This is a test page to verify usage of BDS v2 components.
    <br />
    <br />
    <Chip bdsType="successNeutral" label="BDS v2 success chip" />
    <br />
    <br />
    <Chip bdsType="errorNeutral" label="BDS v2 error chip" />
    <br />
    <br />
    <Chip bdsType="warningNeutral" label="BDS v2 warning chip" />
    <br />
    <br />
  </CenteredPaper>
)

export default TestBDSV2
