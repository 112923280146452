import { createSlice } from '@reduxjs/toolkit'
import { inIdle, inProgress, successResponse, failedResponse, ApiStatus } from 'redux/toolkit/api'
import { getPamProductData, PamProductDataResponse } from './pamApiThunks'

export const EGD_PRODUCT_ID = '6'
export const IP_PRODUCT_ID = '20'
export const IR_PRODUCT_ID = '25'

export interface OAuth2State {
  api: {
    getProductData: ApiStatus
  }
  productData: PamProductDataResponse | undefined
}

// initialState
export const INITIAL_STATE: OAuth2State = {
  api: {
    getProductData: inIdle
  },
  productData: undefined
}

/* eslint-disable no-param-reassign */
export const pamSlice = createSlice({
  name: 'PAM',
  initialState: INITIAL_STATE,
  reducers: {
    reset: () => ({
      ...INITIAL_STATE
    }),
    resetProductData: state => {
      state.api.getProductData = INITIAL_STATE.api.getProductData
      state.productData = INITIAL_STATE.productData
    }
  },
  extraReducers: builder => {
    builder
      // getCurrentSession
      .addCase(getPamProductData.pending, state => {
        state.api.getProductData = inProgress
      })
      .addCase(getPamProductData.fulfilled, (state, action) => {
        state.api.getProductData = successResponse
        state.productData = action.payload
      })
      .addCase(getPamProductData.rejected, (state, action) => {
        state.api.getProductData = failedResponse(action.payload)
      })
  }
})
/* eslint-enable no-param-reassign */

export const { reset, resetProductData } = pamSlice.actions

export { getPamProductData }

export default pamSlice.reducer
