import { RootState } from 'redux/toolkit/store'
import {
  SearchSettingsRepository,
  SearchSettingsViewConfig,
  SearchSettingsViewType
} from 'lib/services/searchSettings/searchSettingsRepository'
import { ApiStorageSearchSettingsRepository } from 'lib/services/searchSettings/ApiSearchSettingsRepository'
import { UserRole } from 'config/userRole'
import { MlogViewType } from 'redux/features/mstore/mstoreSlice'
import { LocalStorageSearchSettingsRepository } from './LocalStorageSearchSettingsRepository'

export const SearchSettingsRepositoryFactory = () => {
  const getViewType = (type: MlogViewType | undefined): SearchSettingsViewType => {
    if (type && type === MlogViewType.MESSAGE_LOG) {
      return SearchSettingsViewType.MESSAGE_LOG
    }
    if (type && type === MlogViewType.OUTBOUND_QUARANTINE) {
      return SearchSettingsViewType.QUARANTINE
    }
    throw new Error('Failed to determine viewType')
  }

  const getDomainId = (domainId: number, pdDomainId: string | null, roleType: UserRole) => {
    // domain level
    if (pdDomainId) {
      return pdDomainId
    }
    if (roleType === UserRole.USER) {
      return domainId.toString()
    }
    // accountId
    return undefined
  }

  const fromReduxState = (state: RootState): SearchSettingsRepository => {
    if (!state.auth.accessTokenObject) {
      throw new Error('Could not determine applicable repository')
    }
    const { accountId, domainId, pdDomainId, roleType } = state.auth.accessTokenObject
    const viewConfig: SearchSettingsViewConfig = {
      type: getViewType(state.mstore.viewConfig?.type),
      accountId,
      domainId: getDomainId(domainId, pdDomainId, roleType)
    }
    if (viewConfig.type === SearchSettingsViewType.MESSAGE_LOG) {
      return ApiStorageSearchSettingsRepository(viewConfig)
    }
    return LocalStorageSearchSettingsRepository(viewConfig)
  }

  return Object.freeze({
    fromReduxState
  })
}
