import { useMemo } from 'react'

import { useAppSelector } from 'redux/toolkit/hooks'
import useUserRights, { UserRights, hasRight } from 'components/libs/userRights/useUserRights'
import { useMessageVariables } from 'components/libs/message/useMessageVariables'
import { UserRole } from 'config/userRole'
import routesConfig from 'lib/routesConfig'
import { Reason } from 'types/Messages'

export interface UserRightHelpers {
  isPdDomainIdSet: boolean
}

export interface ValidatedRights {
  helpers: UserRightHelpers
  isMessagePreviewAvailable: boolean
  isMessageSourceAvailable: boolean
  canDownloadAttachments: boolean
  canViewBlockedMessages: boolean
  canViewQuarantinedMessages: boolean
  hasRightToSkipSendUserAction: boolean
  canDownloadMessage: boolean
  canBlockMessage: boolean
  canOpenMessage: boolean
  canViewAtpReports: boolean
  isDeliverableMessage: boolean
  hasAdminDeliverRights: boolean
  isDeliverConfirmationRequired: boolean
  canDeliverAndAddSenderToAllowList: boolean
  canDeleteMessage: boolean
  canReplyOrForwardMessage: boolean
  canViewMessageHistory: boolean
  canUseAdminVersionOfMessageLog: boolean
  canSearchInAllDomains: boolean
  canFilterAboutDomains: boolean
  canFilterAboutDirections: boolean
  canFilterAboutEmailContinuity: boolean
  canFilterAboutQuarantinedAndEncryptedMessages: boolean
  canRecategorizeMessage: boolean
  canCreateNewEmail: boolean
  reasons: string[]
  canDeliverBlockedQnMessages: boolean
  isMessagePreviewAvailableHelpdesk: boolean
}

export type UseMessageLogRights = ValidatedRights

export enum MessageLogUserRights {
  USER_BLOCKED = UserRole.USER,
  USER_QUARANTINE_BLOCKED = UserRole.USER,
  CAN_DELIVER_AND_ADD_SENDER_TO_ALLOW_LIST = UserRole.ACCOUNT_USER + UserRole.DOMAIN_USER,
  CAN_BLOCK_MESSAGE = UserRole.ACCOUNT_USER + UserRole.DOMAIN_USER + UserRole.HELPDESK_ROLE,
  CAN_VIEW_ATP_REPORTS = UserRole.ACCOUNT_USER,
  IS_ADMIN_DELIVER_MESSAGE = UserRole.ACCOUNT_USER,
  IS_DELIVER_CONFIRMATION_REQUIRED = UserRole.ACCOUNT_USER,
  CAN_DELETE_MESSAGE = UserRole.USER + UserRole.DOMAIN_USER + UserRole.HELPDESK_ROLE,
  CAN_REPLY_OR_FORWARD_MESSAGE = UserRole.USER + UserRole.DOMAIN_USER + UserRole.HELPDESK_ROLE,
  CAN_USE_ADMIN_VERSION_OF_MESSAGE_LOG = UserRole.ACCOUNT_USER,
  CAN_SEARCH_IN_ALL_DOMAINS = UserRole.ACCOUNT_USER,
  CAN_FILTER_ABOUT_DOMAINS = UserRole.ACCOUNT_USER,
  SKIP_TO_SEND_USER_ID_FOR_MESSAGE_ACTIONS = UserRole.ACCOUNT_USER,
  CAN_FILTER_ABOUT_DIRECTION = UserRole.ACCOUNT_USER,
  CAN_FILTER_ABOUT_EMAIL_CONTINUITY = UserRole.USER + UserRole.DOMAIN_USER + UserRole.HELPDESK_ROLE
}

export const useMessageLogRights = (): UseMessageLogRights => {
  const { isOutboundQuarantineView } = useAppSelector(_stores => ({
    isOutboundQuarantineView: _stores.app.activePath?.id === routesConfig.OVERVIEW_OUTBOUND_QUARANTINE?.id
  }))

  const {
    userBlocked,
    userQuarantineBlocked,
    canExempt,
    canBlock,
    hasVirus,
    encrypted,
    showMsgBody,
    headersOnly,
    atdMessage,
    atdQuarantine,
    avError,
    isAtpAffected,
    isLargeMessage,
    hasContent,
    emergencyInbox,
    hasInferenceAccess
  } = useMessageVariables()
  const { validatedUserRights, isUserType, isImpersonatedUser, isDefaultPortalId, isPdDomainIdSet, isCplAccount } =
    useUserRights([
      MessageLogUserRights.SKIP_TO_SEND_USER_ID_FOR_MESSAGE_ACTIONS,
      MessageLogUserRights.USER_BLOCKED,
      MessageLogUserRights.USER_QUARANTINE_BLOCKED,
      MessageLogUserRights.CAN_DELIVER_AND_ADD_SENDER_TO_ALLOW_LIST,
      MessageLogUserRights.CAN_BLOCK_MESSAGE,
      MessageLogUserRights.CAN_VIEW_ATP_REPORTS,
      MessageLogUserRights.IS_ADMIN_DELIVER_MESSAGE,
      MessageLogUserRights.IS_DELIVER_CONFIRMATION_REQUIRED,
      MessageLogUserRights.CAN_DELETE_MESSAGE,
      MessageLogUserRights.CAN_REPLY_OR_FORWARD_MESSAGE,
      MessageLogUserRights.CAN_USE_ADMIN_VERSION_OF_MESSAGE_LOG,
      MessageLogUserRights.CAN_SEARCH_IN_ALL_DOMAINS,
      MessageLogUserRights.CAN_FILTER_ABOUT_DOMAINS,
      MessageLogUserRights.CAN_FILTER_ABOUT_DIRECTION,
      MessageLogUserRights.CAN_FILTER_ABOUT_EMAIL_CONTINUITY
    ])
  const [
    hasRightToSkipSendUserActionType,
    isBlockedUserType,
    isBlockedUserQuarantineType,
    canDeliverAndAddSenderToAllowListType,
    canBlockMessageType,
    canViewAtpReportsType,
    isAdminDeliverMessageType,
    isDeliverConfirmationRequiredType,
    canDeleteMessageType,
    canReplyOrForwardMessageType,
    canUseAdminVersionOfMessageLogType,
    canSearchInAllDomainsType,
    canFilterAboutDomainsType,
    canFilterAboutDirectionType,
    canFilterAboutEmailContinuityType
  ] = validatedUserRights
  const isImpersonatedHelpdeskUser = isUserType(UserRole.HELPDESK_ROLE) && isImpersonatedUser
  const canDeliverBlockedQnMessages = hasRight(UserRights.REDELIVER_ACCOUNT_LEVEL) || !!isPdDomainIdSet
  const showMessageBody = useMemo(() => isDefaultPortalId || showMsgBody, [showMsgBody, isDefaultPortalId])
  const isBlockedUser = useMemo(() => userBlocked && isBlockedUserType, [isBlockedUserType, userBlocked])
  const isQuarantineBlockedUser = useMemo(
    () => userQuarantineBlocked && isBlockedUserQuarantineType,
    [userQuarantineBlocked, isBlockedUserQuarantineType]
  )

  const isMessagePreviewAvailable = useMemo(() => {
    // views/quarantine/read_base #124
    // if($show_msg_body)
    const baseRequirement = hasContent && showMessageBody && !hasVirus && !isLargeMessage
    if (isOutboundQuarantineView) {
      return baseRequirement
    }

    // views/log/read_base #162
    // if(!$is_helpdesk && !$user_blocked && !$user_quarantine_blocked)
    return baseRequirement && !isAtpAffected && !isBlockedUser && !isQuarantineBlockedUser
  }, [
    isOutboundQuarantineView,
    isLargeMessage,
    isAtpAffected,
    hasVirus,
    isBlockedUser,
    isQuarantineBlockedUser,
    showMessageBody,
    hasContent
  ])

  const isMessagePreviewAvailableHelpdesk = useMemo(() => {
    if (isImpersonatedHelpdeskUser && isPdDomainIdSet) {
      return !isImpersonatedHelpdeskUser
    }

    return true
  }, [isImpersonatedHelpdeskUser, isPdDomainIdSet])

  const isMessageSourceAvailable = useMemo(() => {
    // views/quarantine/read_base #124
    // if($show_msg_body)
    const baseRequirement = hasContent
    if (isOutboundQuarantineView) {
      return baseRequirement
    }

    // views/log/read_base #162
    // if(!$is_helpdesk && !$user_blocked && !$user_quarantine_blocked)
    return baseRequirement && !isAtpAffected && !isBlockedUser && !isQuarantineBlockedUser
  }, [isOutboundQuarantineView, isAtpAffected, isBlockedUser, isQuarantineBlockedUser, hasContent])

  // views/log/read_base #177
  // if(!$this->user->is_type(User::USER) || $can_block && !$is_helpdesk)
  const canBlockMessage = useMemo(
    () => canBlockMessageType || (canBlock && !isImpersonatedHelpdeskUser),
    [canBlockMessageType, canBlock, isImpersonatedHelpdeskUser]
  )

  const canDownloadMessage = useMemo(() => {
    // views/quarantine/read_base #132
    //  if($show_msg_body && !$has_virus && !$encrypted)
    const baseRequirement = !encrypted && !hasVirus && showMessageBody && !isAtpAffected
    if (isOutboundQuarantineView) {
      return baseRequirement
    }

    // views/log/read_base #188
    // if($show_msg_body && !$has_virus && !$encrypted && !$is_helpdesk && !$user_blocked && !$user_quarantine_blocked)
    return baseRequirement && !isImpersonatedHelpdeskUser && !isBlockedUser && !isQuarantineBlockedUser
  }, [
    isOutboundQuarantineView,
    isBlockedUser,
    isQuarantineBlockedUser,
    isImpersonatedHelpdeskUser,
    hasVirus,
    encrypted,
    showMessageBody,
    isAtpAffected
  ])

  // views/quarantine/read_base #44
  //    <?php if(!empty($attachments) && !$encrypted): ?>
  //     ...
  //       <?php if($show_msg_body): ?>
  // views/log/read_base #46
  //      <?php if(!empty($attachments) && !$encrypted && !$atd_message): ?>
  //       ...
  //         <?php if($show_msg_body && !$user_blocked && !$is_helpdesk && !$user_quarantine_blocked): ?>
  const canDownloadAttachments = useMemo(() => canDownloadMessage, [canDownloadMessage])

  // views/log/read_base #202
  // if($is_ajax && !$headers_only)
  const canOpenMessage = useMemo(() => !headersOnly, [headersOnly])

  // views/log/read_base #286
  // if($this->user->is_type(User::ACCOUNT_USER) && ($atd_message || $atd_quarantine))
  const canViewAtpReports = useMemo(
    () => canViewAtpReportsType && (atdMessage || atdQuarantine),
    [canViewAtpReportsType, atdMessage, atdQuarantine]
  )

  const isDeliverableMessage = useMemo(() => {
    // views/quarantine/read_base #141
    // if(!$encrypted)
    const baseRequirement = !encrypted
    if (isOutboundQuarantineView) {
      return baseRequirement
    }

    // views/log/read_base #211
    // if(!$encrypted && !$user_blocked && !$user_quarantine_blocked)
    return baseRequirement && !isBlockedUser && !isQuarantineBlockedUser
  }, [isOutboundQuarantineView, encrypted, isBlockedUser, isQuarantineBlockedUser])

  // views/log/read_base #213
  // if($this->user->is_type(User::ACCOUNT_USER) && ($atd_message || $atd_pending || $atd_quarantine))
  // additional atd checks are in component level
  const hasAdminDeliverRights = useMemo(
    () => !isOutboundQuarantineView && isAdminDeliverMessageType,
    [isOutboundQuarantineView, isAdminDeliverMessageType]
  )

  const isDeliverConfirmationRequired = useMemo(() => {
    if (isOutboundQuarantineView) {
      return false
    }

    // views/log/read_base #219
    // $is_admin && ($atd_quarantine || $av_error)
    return isDeliverConfirmationRequiredType && (atdQuarantine || avError)
  }, [isOutboundQuarantineView, isDeliverConfirmationRequiredType, atdQuarantine, avError])

  // views/log/read_base #170
  // if(!$this->user->is_type(User::USER) || $can_exempt != "0" && !$user_blocked && !$user_quarantine_blocked)
  // views/log/message_log #313
  // if($mode !== 'outbound' && (!$this->user->is_type(User::USER) || $can_exempt != "0"))
  const canDeliverAndAddSenderToAllowList = useMemo(
    () => (canDeliverAndAddSenderToAllowListType || canExempt) && !isBlockedUser && !isQuarantineBlockedUser,
    [canExempt, canDeliverAndAddSenderToAllowListType, isBlockedUser, isQuarantineBlockedUser]
  )

  // views/log/read_base #243
  // if(!$headers_only && $this->user->is_type(User::USER) && !$this->user->has_role(User::ACCOUNT_USER) && !$this->session->pd_domain_id && $this->user->is_logged_in() && $emergency_inbox['emergency_inbox_enabled'])
  const canReplyOrForwardMessage = useMemo(
    () => !headersOnly && canReplyOrForwardMessageType && !isPdDomainIdSet && emergencyInbox,
    [headersOnly, canReplyOrForwardMessageType, isPdDomainIdSet, emergencyInbox]
  )

  const hasRightToSkipSendUserAction = useMemo(
    () => hasRightToSkipSendUserActionType || isPdDomainIdSet,
    [hasRightToSkipSendUserActionType, isPdDomainIdSet]
  )

  // views/log/read_base #194
  // if(!$headers_only && !$is_helpdesk && !$user_blocked && !$user_quarantine_blocked)
  const canViewMessageHistory = useMemo(
    () => !headersOnly && !isImpersonatedHelpdeskUser && !isBlockedUser && !isQuarantineBlockedUser,
    [headersOnly, isImpersonatedHelpdeskUser, isBlockedUser, isQuarantineBlockedUser]
  )

  // views/log/message_log #118
  // if(!$this->user->is_type(User::USER) && !$this->helpers->account->is_cpl_account())
  const canFilterAboutDirections = useMemo(
    () => canFilterAboutDirectionType && !isCplAccount,
    [canFilterAboutDirectionType, isCplAccount]
  )

  // views/log/message_log #148
  // if(!$this->user->is_type(User::USER))
  const canFilterAboutDomains = useMemo(() => canFilterAboutDomainsType, [canFilterAboutDomainsType])

  // views/log/message_log #41
  // if($this->user->is_type(User::USER) && !$this->user->has_role(User::ACCOUNT_USER) && !$this->session->pd_domain_id && $this->user->is_logged_in() && $emergency_inbox['emergency_inbox_enabled'])
  const canFilterAboutEmailContinuity = useMemo(
    () => canFilterAboutEmailContinuityType && !isPdDomainIdSet && emergencyInbox,
    [canFilterAboutEmailContinuityType, isPdDomainIdSet, emergencyInbox]
  )

  // views/log/message_log.php #348
  // if($this->user->is_type(User::USER) && !$this->user->has_role(User::ACCOUNT_USER) && !$this->session->pd_domain_id && $this->user->is_logged_in() && $emergency_inbox['emergency_inbox_enabled'])
  const canCreateNewEmail = useMemo(() => canFilterAboutEmailContinuity, [canFilterAboutEmailContinuity])

  // views/log/message_log.php #365
  // if($this->helpers->account->is_cpl_account()) {
  //   unset($filter_options['quarantined']);
  //   unset($action_options['quarantined']);
  //   unset($action_options['encrypted']);
  // }
  const canFilterAboutQuarantinedAndEncryptedMessages = useMemo(() => !isCplAccount, [isCplAccount])

  // views/log/message_log.php #317
  // if(!$this->helpers->account->is_cpl_account())
  const canRecategorizeMessage = useMemo(() => !isCplAccount, [isCplAccount])

  // views/log/message_log.php #336
  // if($this->user->is_type(User::USER))
  const canDeleteMessage = useMemo(
    () => isOutboundQuarantineView || canDeleteMessageType,
    [isOutboundQuarantineView, canDeleteMessageType]
  )

  const canSearchInAllDomains = useMemo(
    () => canSearchInAllDomainsType && !isPdDomainIdSet,
    [canSearchInAllDomainsType, isPdDomainIdSet]
  )

  const reasons = useMemo(() => {
    if (!hasInferenceAccess) {
      const reasonsToExclude = [Reason.Extortion, Reason.Phishing, Reason.Spam]
      return Object.values(Reason).filter(r => !reasonsToExclude.includes(r))
    }
    return Object.values(Reason)
  }, [hasInferenceAccess])

  return useMemo(
    () => ({
      helpers: {
        isPdDomainIdSet
      },
      isMessagePreviewAvailable,
      isMessageSourceAvailable,
      canDownloadAttachments,
      canViewBlockedMessages: !isBlockedUser,
      canViewQuarantinedMessages: !isQuarantineBlockedUser,
      canBlockMessage,
      canDownloadMessage,
      canOpenMessage,
      canViewAtpReports,
      isDeliverableMessage,
      hasAdminDeliverRights,
      isDeliverConfirmationRequired,
      canDeliverAndAddSenderToAllowList,
      hasRightToSkipSendUserAction,
      canDeleteMessage,
      canReplyOrForwardMessage,
      canViewMessageHistory,
      canUseAdminVersionOfMessageLog: canUseAdminVersionOfMessageLogType,
      canSearchInAllDomains,
      canFilterAboutDomains,
      canFilterAboutDirections,
      canFilterAboutEmailContinuity,
      canFilterAboutQuarantinedAndEncryptedMessages,
      canRecategorizeMessage,
      canCreateNewEmail,
      reasons,
      canDeliverBlockedQnMessages,
      isMessagePreviewAvailableHelpdesk
    }),
    [
      isPdDomainIdSet,
      hasAdminDeliverRights,
      canDownloadAttachments,
      isMessagePreviewAvailable,
      isMessageSourceAvailable,
      hasRightToSkipSendUserAction,
      isBlockedUser,
      isQuarantineBlockedUser,
      canBlockMessage,
      isDeliverableMessage,
      canDownloadMessage,
      canDeliverAndAddSenderToAllowList,
      canOpenMessage,
      canViewAtpReports,
      canDeleteMessage,
      canReplyOrForwardMessage,
      isDeliverConfirmationRequired,
      canViewMessageHistory,
      canUseAdminVersionOfMessageLogType,
      canSearchInAllDomains,
      canFilterAboutDomains,
      canFilterAboutDirections,
      canFilterAboutEmailContinuity,
      canFilterAboutQuarantinedAndEncryptedMessages,
      canRecategorizeMessage,
      canCreateNewEmail,
      reasons,
      canDeliverBlockedQnMessages,
      isMessagePreviewAvailableHelpdesk
    ]
  )
}
