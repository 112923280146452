import React from 'react'
import { Bar } from 'react-chartjs-2'
import { BarController, BarElement, CategoryScale, Chart, Legend, LinearScale, Tooltip } from 'chart.js'
import deepmerge from 'deepmerge'

import {
  Grid,
  EmptyState,
  EmptyStateProps,
  BarChartData,
  BarChartOptions,
  ChartDimension
} from '@barracuda-internal/bds-core'
import * as bdsTheme from '@barracuda-internal/bds-core/dist/styles/themes/index'

export type BarChartProps = {
  /**
   * Chart data that is to be displayed in the chart.  See the chart.js docs for data structure
   *
   * Chart.js docs: https://www.chartjs.org/docs/latest/charts/bar.html
   */
  chartData: BarChartData
  /**
   * Object that has height and width of the chart
   */
  chartDimension: ChartDimension
  /**
   * Props to render the empty state component when there is no data
   */
  emptyState: EmptyStateProps
  /**
   * Boolean indicating if there is data to display in the chart
   */
  hasEntries: boolean
  /**
   * Optional: Object that overrides chart and/or tooltip options
   *
   * Chart options: https://www.chartjs.org/docs/latest/charts/bar.html
   * Tooltip options: https://www.chartjs.org/docs/latest/configuration/tooltip.html
   */
  barChartOptions?: BarChartOptions | undefined
  /**
   * Optional: boolean to create a horizontal bar chart. Set to display vertical bar chart by default
   */
  horizontalBarChart?: boolean
  /**
   * Optional: boolean to maintain the original canvas aspect ratio (width / height) when resizing. For this option to work properly the chart must be in its own dedicated container.  Set to false by default
   *
   * See chart.js docs: https://www.chartjs.org/docs/latest/api/interfaces/Defaults.html#maintainaspectratio
   */
  maintainAspectRatio?: boolean
  /**
   * Optional: function that returns a string to show in the footer
   * @param tooltipItem is the tooltip context that chartjs passes to the tooltip callbacks
   *
   * See chart.js docs for more info https://www.chartjs.org/docs/latest/configuration/tooltip.html#tooltip-item-context
   */
  customFooter?: (tooltipItem: any) => string | string[] | undefined
  /**
   * Optional: show footer at the bottom of tooltip. Footer shown by default
   */
  showFooter?: boolean
  /**
   * Optional: boolean to display chart legend.  Set to false by default
   */
  showLegend?: boolean
  /**
   * Optional: boolean to display stacked bar chart.  Set to false by default
   */
  stacked?: boolean
}

Chart.register(BarController, BarElement, CategoryScale, Legend, LinearScale, Tooltip)

export function BarChart({
  chartDimension,
  chartData,
  emptyState,
  hasEntries,
  barChartOptions = undefined,
  horizontalBarChart = false,
  maintainAspectRatio = false,
  customFooter,
  showFooter = true,
  showLegend = false,
  stacked = false
}: BarChartProps) {
  const { height, width } = chartDimension

  // Chart config for horizontal bar chart
  const horizontalScaleConfig = {
    x: {
      stacked,
      grid: {
        display: true
      }
    },
    y: {
      stacked,
      grid: {
        display: false
      }
    }
  }

  // Base axis of the dataset for horizontal bar chart
  const horizontalDataset = 'y' as const

  // Chart config for vertical bar chart
  const verticalScaleConfig = {
    x: {
      stacked,
      grid: {
        display: false
      }
    },
    y: {
      stacked,
      grid: {
        display: true
      }
    }
  }

  // Base axis of the dataset for vertical bar chart
  const verticalDataset = 'x' as const

  // Default footer function to get sum of all tooltip items
  const footer = (tooltipItems: any) => {
    const sum = tooltipItems.reduce(
      (acc: any, tooltipItem: any) => acc + tooltipItem.parsed[horizontalBarChart ? 'x' : 'y'],
      0
    )

    return `Total: ${sum}`
  }

  // Default bar chart config (vertical bar chart by default)
  const defaultChartConfig = {
    maintainAspectRatio,
    plugins: {
      legend: {
        display: showLegend
      },
      tooltip: {
        borderColor: bdsTheme.default.palette.divider,
        borderWidth: 2,
        boxPadding: 15,
        titleColor: bdsTheme.default.palette.text.primary,
        titleFont: {
          size: 14
        },
        titleMarginBottom: 15,
        interaction: {
          mode: 'index'
        },
        backgroundColor: bdsTheme.default.palette.background.default,
        bodyColor: bdsTheme.default.palette.text.primary,
        bodySpacing: 6,
        padding: bdsTheme.default.spacing(1.5),
        bodyFont: {
          size: 14
        },
        usePointStyle: true,
        callbacks: {
          footer: showFooter ? customFooter || footer : undefined
        },
        footerAlign: 'right',
        footerColor: bdsTheme.default.palette.text.primary,
        footerMarginTop: 15
      }
    },
    indexAxis: horizontalBarChart ? horizontalDataset : verticalDataset,
    scales: horizontalBarChart ? horizontalScaleConfig : verticalScaleConfig
  } as BarChartOptions

  // If barChartOptions are provided merge them into defaultChartConfig, if not use defaultChartConfig
  const options = barChartOptions ? deepmerge(defaultChartConfig, barChartOptions) : defaultChartConfig

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ width, height }}
    >
      {hasEntries ? <Bar data={chartData as any} options={options as any} /> : <EmptyState {...emptyState} />}
    </Grid>
  )
}
