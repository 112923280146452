import { createAsyncThunk } from '@reduxjs/toolkit'

import restClient, { ApiRejectResponse, validateApiError } from 'lib/api/restClient'
import apiRoutes from 'lib/api/apiRoutes'

import { setErrorSnackBar, setSuccessSnackBar, setWarningSnackBar } from 'redux/features/app/appSlice'
import { FpFnType } from 'types/Messages'
import { hasRight, UserRights } from 'components/libs/userRights/useUserRights'

export interface FpfnItem {
  messageId: string
  domainId: string
  type: FpFnType
}

export type PostFpfnReportPayload = FpfnItem
export type PostFpfnReport = { redirectUrl: string; sizeLimitExceeded: boolean }[]

export const postFpfnReport = createAsyncThunk<PostFpfnReport, PostFpfnReportPayload, ApiRejectResponse>(
  'FPFN/postFpfnReport',
  async (payload, { rejectWithValue, dispatch }) => {
    const endpoint = hasRight(UserRights.ACCOUNT_LEVEL_FPFN)
      ? apiRoutes.FPFN_REPORT_ACCOUNT
      : apiRoutes.FPFN_REPORT_USER

    try {
      const resp = await restClient(endpoint, {
        data: payload,
        urlParams: {
          messageId: payload.messageId
        }
      })

      const { sizeLimitExceeded } = resp.data.results[0]
      if (sizeLimitExceeded) {
        dispatch(
          setWarningSnackBar({
            message: payload.type === FpFnType.FN ? 'post_fn_report_warning' : 'post_fp_report_warning'
          })
        )
      } else {
        dispatch(
          setSuccessSnackBar({
            message: payload.type === FpFnType.FN ? 'post_fn_report_success' : 'post_fp_report_success'
          })
        )
      }

      return resp.data.results
    } catch (e) {
      dispatch(
        setErrorSnackBar({
          message: payload.type === FpFnType.FN ? 'post_fn_report_failure' : 'post_fp_report_failure'
        })
      )

      return rejectWithValue(validateApiError(e))
    }
  }
)
