import React, { PropsWithChildren, ReactElement, useMemo } from 'react'

import { Grid, Typography } from '@barracuda-internal/bds-core'
import { GridSize } from '@material-ui/core'

import { FormText } from 'components/libs/layout/pages/FormText'
import styles from './formRowStyles'

export type FormRowProps = {
  rowTitle: string | ReactElement
  alignTitleToTop?: boolean
  contentIndent?: GridSize
  nested?: boolean
  caption?: string
}

export const FormRow = ({
  alignTitleToTop = false,
  rowTitle,
  contentIndent = 3,
  nested = false,
  children,
  caption
}: PropsWithChildren<FormRowProps>) => {
  const classes = styles({ nested })
  return useMemo(
    () => (
      <Grid container alignItems={alignTitleToTop ? 'flex-start' : 'center'}>
        <Grid className={classes.label} item xs={contentIndent}>
          <FormText>{rowTitle}</FormText>
          {caption && (
            <Typography className={classes.caption} variant="caption">
              {caption}
            </Typography>
          )}
        </Grid>
        <Grid item xs>
          {children}
        </Grid>
      </Grid>
    ),
    [classes, rowTitle, contentIndent, children, alignTitleToTop, caption]
  )
}
