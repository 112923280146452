/* eslint-disable no-console */
import { v4 as makeUuid } from 'uuid'
import axios from 'axios'

import React, { useCallback, useMemo } from 'react'

const TestIPIRFlowStart: React.FC = () => {
  const startFlow = useCallback(async () => {
    console.log('::ipirFlowStart')
    const authProcessId = makeUuid()
    const metadata = {
      mock_get_authorize_url: `${process.env.REACT_APP_GATEWAY_URL}/internal/oauth2/idp-callback?state=setup|${authProcessId}`,
      mock_handle_token_exchange: 'foobar',
      foo: 'BAR'
    }
    await axios.post(`${process.env.REACT_APP_GATEWAY_URL}/internal/oauth2/process/${authProcessId}`, { metadata })
    window.location.href = `${process.env.REACT_APP_GATEWAY_URL}/internal/oauth2/authorize?auth_process_id=setup|${authProcessId}&connection=ipir&redirect_uri=${window.location.origin}/webui/test/ipir-flow/callback`
  }, [])

  return useMemo(
    () => (
      <div>
        <button onClick={startFlow}>Start flow</button>
      </div>
    ),
    [startFlow]
  )
}

export default TestIPIRFlowStart
