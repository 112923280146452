import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'

import { SelectChangeEvent } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { resetRegion, setSelectedRegion } from 'redux/features/emailServer/emailServerSlice'
import { isPending } from 'redux/toolkit/api'
import { CountryData, getCountryFromCode2, getFilteredCountryList } from 'lib/isoCountries'
import { isMyFeatureOn } from 'lib/splitio'
import { FEATURES } from 'lib/splitioFeatures'
import { setIntercomUser } from 'lib/monitoring/monitoringService'

export interface State {
  countries: CountryData[]
  selectedRegion: CountryData | undefined
  isSetSelectedRegionInProgress: boolean
  selectedRegionError: boolean
}

export interface EventHandlers {
  onSelectRegion: (event: SelectChangeEvent<unknown>, child: ReactNode) => void
}

export type RegionSelectionLogic = [State, EventHandlers]

export const useRegionSelectionLogic = (): RegionSelectionLogic => {
  const dispatch = useAppDispatch()
  const { user, accessToken, isSetSelectedRegionInProgress, selectedRegion, selectedRegionError } = useAppSelector(
    _store => ({
      user: _store.auth.accessTokenObject,
      accessToken: _store.auth.accessToken,
      isSetSelectedRegionInProgress: isPending(_store.emailServer.setRegionApiStatus),
      selectedRegion: _store.emailServer.selectedRegion,
      selectedRegionError: _store.emailServer.selectedRegionError
    })
  )
  const VALID_COUNTRIES = isMyFeatureOn(FEATURES.EGD_React_India_Deployment)
    ? ['US', 'GB', 'DE', 'CA', 'AU', 'IN']
    : ['US', 'GB', 'DE', 'CA', 'AU']
  const countries = getFilteredCountryList(VALID_COUNTRIES)

  // init
  useEffect(
    () => {
      if (user && accessToken) {
        setIntercomUser(user, accessToken, { company: { finished_wizard: false } })
      }
    },
    // eslint-disable-next-line
    []
  )

  const onSelectRegion: EventHandlers['onSelectRegion'] = useCallback(
    e => {
      const newRegion = getCountryFromCode2((e.target.value || '') as string)

      if (newRegion) {
        dispatch(setSelectedRegion(newRegion))
      }
    },
    [dispatch]
  )

  return useMemo(
    () => [
      {
        countries,
        selectedRegion,
        isSetSelectedRegionInProgress,
        selectedRegionError
      },
      {
        onSelectRegion
      }
    ],
    [selectedRegion, onSelectRegion, countries, isSetSelectedRegionInProgress, selectedRegionError]
  )
}
