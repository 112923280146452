import { Box, useTokens } from '@barracuda-internal/bds-core-v2'
import React, { PropsWithChildren, useMemo } from 'react'

const ContentSidePaddingComponent: React.FC<PropsWithChildren> = ({ children }) => {
  const tokens = useTokens()

  return useMemo(
    () => (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          paddingRight: tokens.aliasesSpaceContainerDefault,
          paddingLeft: tokens.aliasesSpaceContainerDefault,
          gap: tokens.aliasesSpaceContainerDense
        }}
      >
        {children}
      </Box>
    ),
    [tokens, children]
  )
}

export default ContentSidePaddingComponent
