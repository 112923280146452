import React, { useCallback, useMemo, useState } from 'react'

import { useFormatMessage } from 'lib/localization'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { postRedeliverMessage, whitelist, postAllowSender, deliverMessage } from 'redux/features/mstore/mstoreSlice'
import { setErrorSnackBar } from 'redux/features/app/appSlice'
import { Action, ActionMessageInfo, MessageActionItem } from 'types/Messages'
import { useMessageLogRights } from 'components/libs/userRights/pages/useMessageLogRights'
import { useUserDomainIdOverride } from 'lib/hooks/useUserDomainIdOverride'
import { DeliverMessagePayload, WhitelistPayload } from 'redux/features/mstore/mstoreApiThunks'
import { isMyFeatureOn } from 'lib/splitio'
import { FEATURES } from 'lib/splitioFeatures'

export interface UseRedeliveryDialogLogic {
  deliverDialogForm: string
  setDeliverDialogForm: (e: React.ChangeEvent<HTMLInputElement>) => void
  userAllowlistEnabled: boolean
  handleDeliverDialogClick: () => void
}

export interface UseRedeliveryDialogLogicProps {
  onClose: () => void
}

const BASE_I18N_KEY = 'ess.message_log.action'

export const useRedeliveryDialogLogic = (props: UseRedeliveryDialogLogicProps): UseRedeliveryDialogLogic => {
  const overrideDomainId = useUserDomainIdOverride()
  const dispatch = useAppDispatch()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const isWhitelistRollout = isMyFeatureOn(FEATURES.EGD_React_Whitelist_Rollout)
  const isDeliverRollout = isMyFeatureOn(FEATURES.EGD_React_Deliver_Rollout)
  const [deliverDialogForm, setDeliverDialogFormChange] = useState('deliverOnly')
  const { accountPermissions, redeliveryQueueMessages, userDomainId } = useAppSelector(_store => ({
    accountPermissions: _store.settings.accountPermissions,
    redeliveryQueueMessages: _store.mstore.redeliveryQueueMessages,
    userDomainId: _store.auth.accessTokenObject?.domainId
  }))
  const { canDeliverAndAddSenderToAllowList, canDeliverBlockedQnMessages } = useMessageLogRights()

  const { onClose } = props

  const setDeliverDialogForm = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setDeliverDialogFormChange(e.target.value)
  }, [])

  const handleDeliverDialogClick = useCallback(() => {
    // collect the redelivery data of each of the checked messages
    const composedMessages: MessageActionItem[] = []
    const actionMessages: ActionMessageInfo[] = []
    let blockedMessage = null
    redeliveryQueueMessages.forEach(message => {
      const isQuarantineDisabled =
        (message.status?.startsWith(Action.quarantined) || message.status?.startsWith(Action.allowedPartial)) &&
        accountPermissions?.defaultUserQuarDeliverEnabled !== 1
      const isBlockedDisabled =
        message.status?.startsWith(Action.blocked) && accountPermissions?.defaultUserDeliverEnabled !== 1

      const blockedState = isBlockedDisabled ? Action.blocked : null
      blockedMessage = isQuarantineDisabled ? Action.quarantined : blockedState

      if (blockedMessage && !canDeliverBlockedQnMessages) {
        return
      }

      const domainId = Number(overrideDomainId(message?.did))

      composedMessages.push({ messageId: message.mid, domainId })
      actionMessages.push({
        did: message.did,
        mid: message.mid,
        envelope_from: message.envelopeFrom,
        header_from: message.headerFrom,
        to: message.to,
        subject: message.subject,
        recipient_info: message.recipientInfo.map(recipient => ({ ...recipient, envelope_to: recipient.envelopeTo })),
        did_outbound: message.didOutbound
      })
    })

    if (blockedMessage && !canDeliverBlockedQnMessages) {
      dispatch(
        setErrorSnackBar({
          message: 'stop_delivery_admin',
          params: [formatMessage(blockedMessage)]
        })
      )
      onClose()
      return
    }
    const payload = {
      items: composedMessages,
      atpBypass: false
    }
    if (deliverDialogForm === 'deliverOnly') {
      if (isDeliverRollout) {
        const deliverPayload: DeliverMessagePayload = {
          items: actionMessages,
          atp_bypass: false
        }
        dispatch(deliverMessage(deliverPayload))
      } else {
        dispatch(postRedeliverMessage(payload))
      }
    } else if (deliverDialogForm === 'deliverAllowSender') {
      if (isWhitelistRollout) {
        const whitelistPayload: WhitelistPayload = {
          items: actionMessages,
          atp_bypass: false
        }
        dispatch(whitelist(whitelistPayload))
      } else {
        dispatch(postAllowSender(payload))
      }
    }
    onClose()
  }, [
    redeliveryQueueMessages,
    canDeliverBlockedQnMessages,
    deliverDialogForm,
    onClose,
    accountPermissions?.defaultUserQuarDeliverEnabled,
    accountPermissions?.defaultUserDeliverEnabled,
    overrideDomainId,
    dispatch,
    formatMessage,
    isWhitelistRollout,
    isDeliverRollout
  ])

  return useMemo(
    () => ({
      deliverDialogForm,
      setDeliverDialogForm,
      userAllowlistEnabled: canDeliverAndAddSenderToAllowList,
      handleDeliverDialogClick
    }),
    [deliverDialogForm, setDeliverDialogForm, canDeliverAndAddSenderToAllowList, handleDeliverDialogClick]
  )
}
