import util from 'util'
import React, { useCallback, useEffect, useMemo } from 'react'

import { ExitToApp, OpenInNew, RecentActors, Settings, SupervisorAccount } from '@material-ui/icons'
import { CircularProgress, Grid, Switch, SwitchLabel, Typography } from '@barracuda-internal/bds-core'
import { ProfileMenu, BaseOption, ProductSwitcher } from '@barracuda-internal/bds-core-v2'
import { Apps, Earth as EarthIcon } from '@barracuda-internal/bds-core/dist/Icons/Core'
import routesConfig, { UiRoute } from 'lib/routesConfig'
import { useAppSelector, useAppDispatch } from 'redux/toolkit/hooks'

import { appTypeRelatedPaths } from 'config/uiPaths'
import { useOverviewSubmenuItemsLogic } from 'components/libs/layout/navbar/submenuItems/useOverviewSubmenuItemsLogic'
import { useInboundSettingsSubmenuItemsLogic } from 'components/libs/layout/navbar/submenuItems/useInboundSettingsSubmenuItemsLogic'
import { useOutboundSettingsSubmenuItemsLogic } from 'components/libs/layout/navbar/submenuItems/useOutboundSettingsSubmenuItemsLogic'
import { useUsersSubmenuItemsLogic } from 'components/libs/layout/navbar/submenuItems/useUsersSubmenuItemsLogic'
import { useReportsSubmenuItemsLogic } from 'components/libs/layout/navbar/submenuItems/useReportsSubmenuItemsLogic'
import { useSettingsLogic } from 'components/libs/layout/navbar/submenuItems/useSettingsSubmenuItemsLogic'
import { useLogoutMenuLogic } from 'components/libs/layout/navbar/useLogoutMenuLogic'
import { SubMenuItem } from 'components/libs/layout/navbar/sidebarTypes'
import { useFormatMessage } from 'lib/localization'
import { ActivePath, closeSnackBar } from 'redux/features/app/appSlice'
import useLogout from 'lib/useLogout'
import useUserRights, { UserRights } from 'components/libs/userRights/useUserRights'
import { getSessionCookie } from 'lib/cookies'
import appConfig from 'config/appConfig'
import { isMyFeatureOn } from 'lib/splitio'
import { FEATURES } from 'lib/splitioFeatures'
import styles from 'components/libs/layout/navbar/sidebarStyles'
import { useUiSwitcherRights } from 'components/libs/userRights/libs/useUiSwitcherRights'
import { getPamProductData, EGD_PRODUCT_ID } from 'redux/features/pam/pamSlice'
import { isPending } from 'redux/toolkit/api'

const BASE_I18N_SNACKBAR = 'ess.snackbar'
const BASE_I18N_KEY = 'ess.sidemenu'
const BASE_I18N_COMMON = 'ess_language.language'

export type SideMenuItem = UiRoute

const MAIN_MENU_ITEMS = [
  // admin paths
  routesConfig.EMAIL_LOG,
  routesConfig.OVERVIEW,
  routesConfig.DOMAINS,
  routesConfig.INBOUND_SETTINGS,
  routesConfig.OUTBOUND_SETTINGS,
  routesConfig.ATP_SETTINGS,
  routesConfig.USERS,
  routesConfig.REPORT_LIST,
  routesConfig.ACCOUNT_MANAGEMENT,

  // enduser paths
  routesConfig.MESSAGE_LOG,
  routesConfig.SETTINGS,

  // common paths
  routesConfig.SUPPORT
]

export interface SideMenuLogic {
  sideMenuItems: SideMenuItem[]
  onSelectListItem: (selectedRouteId: string) => void
  subMenuItems: { [key: string]: SubMenuItem[] }
  isSideBarVisible: boolean
  isUsedOnetimePasscode: boolean
  isBlockedApp: boolean
  handleCloseSnackBar: (event?: React.SyntheticEvent, reason?: string) => void
  snackbarOpen: boolean
  snackbarSeverity: string
  snackBarMessage: string
  options: BaseOption[]
  isLanguageMenuDialogOpen: boolean
  onCloseLanguageMenu: () => void
  activePath: ActivePath
}

export const useSideBarLogic = (): SideMenuLogic => {
  const classes = styles()
  const {
    appType,
    userName,
    userEmail,
    userFeatures,
    isImpersonatedUser,
    isValidatedSession,
    snackBar,
    isUsedOnetimePasscode,
    isBlockedApp,
    activePath,
    isImpersonated,
    productData,
    productDataLoading
  } = useAppSelector(_store => ({
    appType: _store.app.appType,
    userName: _store.auth.accessTokenObject?.userInfo?.name,
    userEmail: _store.auth.accessTokenObject?.userId,
    userFeatures: _store.auth.accessTokenObject?.features,
    isImpersonatedUser: !!_store.auth.accessTokenObject?.isImpersonated,
    isValidatedSession: _store.auth.isValidatedSession,
    snackBar: _store.app.snackBar,
    isUsedOnetimePasscode: !!_store.auth.accessTokenObject?.isUsedOnetimePasscode,
    isBlockedApp: _store.app.isBlocked,
    activePath: _store.app.activePath,
    isImpersonated: !!_store.auth.accessTokenObject?.isImpersonated,
    productData: _store.pam.productData,
    productDataLoading: isPending(_store.pam.api.getProductData)
  }))
  const [overviewSubMenuItems] = useOverviewSubmenuItemsLogic()
  const [InboundSettingswSubMenuItems] = useInboundSettingsSubmenuItemsLogic()
  const [outboundSettingsListItems] = useOutboundSettingsSubmenuItemsLogic()
  const [usersSubMenuItems] = useUsersSubmenuItemsLogic()
  const [reportsSubMenuItems] = useReportsSubmenuItemsLogic()
  const [settingsSubMenuItems] = useSettingsLogic()
  const dispatch = useAppDispatch()
  const snackFormatMessage = useFormatMessage(BASE_I18N_SNACKBAR)
  const [logout, logoutImpersonatedEnduser] = useLogout()
  const { userHasRight } = useUserRights()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const commonFormatMessage = useFormatMessage(BASE_I18N_COMMON)
  const onLogout = useCallback(() => {
    if (isImpersonated) {
      logoutImpersonatedEnduser()
      return
    }
    logout(true)
  }, [isImpersonated, logoutImpersonatedEnduser, logout])
  const {
    handleLogout,
    onOpenLanguageMenu,
    preferredLanguage,
    isLicenseComplianceOn,
    onOpenReviewLicense,
    isLanguageMenuDialogOpen,
    onCloseLanguageMenu,
    onSwitchToOldUi
  } = useLogoutMenuLogic({
    onLogout,
    handleClose: () => false
  })
  const isReportingOn = isMyFeatureOn(FEATURES.EGD_React_Reporting_Rollout)
  const { canViewPhaseTwoFeature } = useUiSwitcherRights()

  const onOpenBccProfile = useCallback(() => {
    window.open(`${appConfig.BCC_PATH}/account/profile`, '_blank')
  }, [])

  const onOpenBccAdmin = useCallback(() => {
    window.open(`${appConfig.BCC_PATH}/account`, '_blank')
  }, [])

  useEffect(() => {
    if (appConfig.APP.IS_ADMIN) {
      dispatch(getPamProductData())
    }
  }, [dispatch])

  const onClickCookiePreferences = useCallback(() => {
    const el = document.getElementById('ot-sdk-btn')
    if (el) {
      el.click()
    }
  }, [])

  const showLogoutMenu = useMemo(() => {
    if (appConfig.APP.IS_WIZARD) {
      return false
    }

    const isAdminMessageDetails =
      userHasRight(UserRights.VIEW_ADMIN_MESSAGES_DETAILS) && activePath.id === routesConfig.MESSAGE_DETAIL.id
    return !!getSessionCookie() && !isAdminMessageDetails
  }, [userHasRight, activePath])

  const availableAppListItems = useMemo(() => {
    const menuItemFilter = (uiRoute: UiRoute) => {
      if (!appTypeRelatedPaths[appType].includes(uiRoute?.id)) {
        return false
      }
      if (uiRoute.id === routesConfig.REPORT_LIST?.id && !isReportingOn) {
        return false
      }
      if (uiRoute.id === routesConfig.EMAIL_LOG?.id && !userFeatures?.isEmailLogEnabled) {
        return false
      }
      return true
    }
    return MAIN_MENU_ITEMS.filter(menuItemFilter)
  }, [appType, isReportingOn, userFeatures?.isEmailLogEnabled])

  const getInitials = useMemo(() => {
    let initials = ''
    if (userName) {
      initials = userName.match(/(\b\S)?/g)?.join('') || ''
    } else if (!userName && userEmail) {
      initials = userEmail.charAt(0)
    }

    return initials
  }, [userEmail, userName])

  const onSelectListItem = useCallback(
    (selectedRouteId: string) => {
      const selectedRoute = Object.values(routesConfig).find((uiRoute: UiRoute) => uiRoute.path === selectedRouteId)
      if (selectedRoute && selectedRoute.path !== activePath.id) {
        selectedRoute.goto()
      }
    },
    [activePath]
  )

  const subMenuItems = useMemo(
    () =>
      MAIN_MENU_ITEMS.reduce((prev, uiRoute) => {
        if (!uiRoute) {
          return prev
        }

        switch (uiRoute.id) {
          case routesConfig.OVERVIEW.id:
            return {
              ...prev,
              [uiRoute.id]: overviewSubMenuItems
            }
          case routesConfig.INBOUND_SETTINGS.id:
            return {
              ...prev,
              [uiRoute.id]: InboundSettingswSubMenuItems
            }
          case routesConfig.OUTBOUND_SETTINGS.id:
            return {
              ...prev,
              [uiRoute.id]: outboundSettingsListItems
            }
          case routesConfig.USERS.id:
            return {
              ...prev,
              [uiRoute.id]: usersSubMenuItems
            }
          case appConfig.APP.IS_ADMIN && routesConfig.REPORT_LIST.id:
            return {
              ...prev,
              [uiRoute.id]: reportsSubMenuItems
            }
          case appConfig.APP.IS_ENDUSER && routesConfig.SETTINGS.id:
            return {
              ...prev,
              [uiRoute.id]: settingsSubMenuItems
            }
          default:
            return prev
        }
      }, {}),
    [
      InboundSettingswSubMenuItems,
      outboundSettingsListItems,
      overviewSubMenuItems,
      reportsSubMenuItems,
      settingsSubMenuItems,
      usersSubMenuItems
    ]
  )

  const snackBarMessage = useMemo(() => {
    if (!snackBar.message) {
      return ''
    }

    let message
    if (snackBar.message.startsWith('@literal:')) {
      message = snackBar.message.substring(9)
    } else {
      message = snackFormatMessage(snackBar.message)
    }

    if (snackBar.params) {
      return util.format(message, ...snackBar.params)
    }
    return message
  }, [snackBar, snackFormatMessage])

  const handleCloseSnackBar = useCallback(
    (_?: React.SyntheticEvent, reason?: string) => {
      if (reason === 'clickaway') {
        return
      }

      dispatch(closeSnackBar())
    },
    [dispatch]
  )

  const options = useMemo(() => {
    const sidebarOptions = []
    const menuItems = []
    if (appConfig.APP.IS_ADMIN) {
      sidebarOptions.push({
        name: formatMessage('settings'),
        icon: <Settings />,
        menu: (
          <Grid container justifyContent="space-between" direction="column">
            <ProfileMenu
              menuItems={[
                {
                  name: formatMessage('bcc_admin'),
                  onClick: onOpenBccAdmin,
                  endIcon: <OpenInNew />
                }
              ]}
            />
            {canViewPhaseTwoFeature && (
              <SwitchLabel
                className={classes.optOut}
                control={<Switch size="small" checked onChange={onSwitchToOldUi} color="primary" />}
                label={<Typography variant="body1">{formatMessage('new_interface')}</Typography>}
              />
            )}
          </Grid>
        )
      })
      if (productDataLoading) {
        sidebarOptions.push({
          name: '',
          disabled: true,
          icon: <CircularProgress />,
          menu: null
        })
      } else if (productData && productData.data) {
        sidebarOptions.push({
          name: formatMessage('your_apps'),
          icon: <Apps />,
          menu: (
            <ProductSwitcher
              currentProductId={EGD_PRODUCT_ID}
              subscriptionGroups={productData.data.subscriptionGroups}
            />
          )
        })
      }
      menuItems.push({
        name: formatMessage('manage_profile'),
        onClick: onOpenBccProfile,
        icon: <SupervisorAccount />,
        endIcon: <OpenInNew />
      })
      if (appConfig.APP.IS_ADMIN && isLicenseComplianceOn) {
        menuItems.push({
          name: formatMessage('review_licenses'),
          onClick: onOpenReviewLicense,
          icon: <RecentActors />
        })
      }
    }

    if (showLogoutMenu) {
      const cookiePreferencesItem = appConfig.ONETRUST_ID
        ? [
            {
              name: formatMessage('cookie_preferences'),
              onClick: onClickCookiePreferences,
              icon: ' '
            }
          ]
        : []
      menuItems.push(
        {
          name: formatMessage('language_selector', { language: commonFormatMessage(preferredLanguage) }),
          onClick: onOpenLanguageMenu,
          icon: <EarthIcon />
        },
        ...cookiePreferencesItem,
        {
          name: formatMessage('logout'),
          onClick: handleLogout,
          icon: <ExitToApp />
        }
      )
    }

    sidebarOptions.push({
      name: formatMessage('profile'),
      initials: getInitials.toUpperCase(),
      menu: <ProfileMenu initials={getInitials.toUpperCase()} name={userName} email={userEmail} menuItems={menuItems} />
    })
    return sidebarOptions
  }, [
    showLogoutMenu,
    formatMessage,
    getInitials,
    userName,
    userEmail,
    onOpenBccAdmin,
    canViewPhaseTwoFeature,
    productData,
    productDataLoading,
    classes.optOut,
    onSwitchToOldUi,
    onOpenBccProfile,
    isLicenseComplianceOn,
    onOpenReviewLicense,
    commonFormatMessage,
    preferredLanguage,
    onOpenLanguageMenu,
    onClickCookiePreferences,
    handleLogout
  ])

  return useMemo(
    () => ({
      sideMenuItems: availableAppListItems,
      onSelectListItem,
      subMenuItems,
      isSideBarVisible: isValidatedSession && (!!userEmail || isImpersonatedUser),
      isUsedOnetimePasscode,
      isBlockedApp,
      handleCloseSnackBar,
      snackbarOpen: snackBar.open,
      snackbarSeverity: snackBar.severity,
      snackBarMessage,
      options,
      isLanguageMenuDialogOpen,
      onCloseLanguageMenu,
      activePath
    }),
    [
      availableAppListItems,
      onSelectListItem,
      subMenuItems,
      isValidatedSession,
      isImpersonatedUser,
      isUsedOnetimePasscode,
      isBlockedApp,
      handleCloseSnackBar,
      snackBar.open,
      snackBar.severity,
      snackBarMessage,
      options,
      isLanguageMenuDialogOpen,
      userEmail,
      onCloseLanguageMenu,
      activePath
    ]
  )
}
