import { useMemo, useState, useCallback, useEffect } from 'react'

import { trim } from 'lodash'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { isPending, isSuccess, isFailed } from 'redux/toolkit/api'
import {
  detectEmailServer,
  verifyEmailServer,
  resetVerifyEmailServer,
  setEmailAddress,
  setUpdatedEmailServer,
  setIsEmailServerConfigError,
  setIsMissedServerVerify,
  setIsEmailInputError,
  setIsMissedServerDetection
} from 'redux/features/emailServer/emailServerSlice'
import { EmailServerConfig } from 'types/emailServer'
import usePreviousValue from 'lib/usePreviousValue'
import { isEmailValid } from 'lib/validation'

export interface State {
  emailAddress: string
  isEmailInputError: boolean
  isMissedServerDetection: boolean
  isMissedServerVerify: boolean
  isDetectEmailServerInProgress: boolean
  defaultEmailServerConfig: EmailServerConfig | undefined
  emailServerConfig: EmailServerConfig | undefined
  isVerifiedServer: boolean
  isVerifyEmailServerInProgress: boolean
  isVerifyEmailServerFailed: boolean
  isEmailServerConfigError: boolean
  isEditEmailServer: boolean
  isEditButtonDisabled: boolean
}

export interface EventHandlers {
  onChangeEmailAddress: (e: React.ChangeEvent<HTMLInputElement>) => void
  onEdit: () => void
  onChangeEmailServer: (e: React.ChangeEvent<HTMLInputElement>) => void
  onChangeServerPort: (e: React.ChangeEvent<HTMLInputElement>) => void
  onFinishEdit: () => void
  onDetectEmailServer: () => void
  onVerifyServer: () => void
}

export type SpecifyEmailServerLogic = [State, EventHandlers]

export const useVerifyDomainLogic = (): SpecifyEmailServerLogic => {
  const dispatch = useAppDispatch()

  const {
    emailAddress,
    isDetectEmailServerInProgress,
    defaultEmailServerConfig,
    emailServerConfig,
    isVerifyEmailServerInProgress,
    isVerifyEmailServerSuccess,
    isVerifyEmailServerFailed,
    isEmailInputError,
    isMissedServerDetection,
    isMissedServerVerify,
    isEmailServerConfigError
  } = useAppSelector(_store => ({
    emailAddress: _store.emailServer.emailAddress,
    isDetectEmailServerInProgress: isPending(_store.emailServer.detectEmailServerApiStatus),
    // use the first email server config as default
    defaultEmailServerConfig: _store.emailServer.emailServer,
    emailServerConfig: _store.emailServer.updatedEmailServer,
    isVerifyEmailServerInProgress: isPending(_store.emailServer.verifyEmailServerApiStatus),
    isVerifyEmailServerSuccess: isSuccess(_store.emailServer.verifyEmailServerApiStatus),
    isVerifyEmailServerFailed: isFailed(_store.emailServer.verifyEmailServerApiStatus),
    isEmailInputError: _store.emailServer.verifyDomainState.isEmailInputError,
    isMissedServerDetection: _store.emailServer.verifyDomainState.isMissedServerDetection,
    isMissedServerVerify: _store.emailServer.verifyDomainState.isMissedServerVerify,
    isEmailServerConfigError: _store.emailServer.verifyDomainState.isEmailServerConfigError
  }))
  const [isEditEmailServer, setIsEditEmailServer] = useState<boolean>(false)

  const prevDefaultEmailServerConfig: EmailServerConfig | undefined = usePreviousValue(defaultEmailServerConfig)

  const isValidEmail = useMemo(() => isEmailValid(trim(emailAddress)), [emailAddress])
  const isValidEmailServerConfig = useMemo(
    () => !!emailServerConfig?.server.length && !!String(emailServerConfig?.port).length,
    [emailServerConfig]
  )

  // server is verified successfully
  useEffect(() => {
    if (isVerifyEmailServerSuccess) {
      dispatch(setIsMissedServerVerify(false))
    }
  }, [dispatch, isVerifyEmailServerSuccess, emailServerConfig])

  // new email server config is arrived
  useEffect(() => {
    if (
      defaultEmailServerConfig &&
      (defaultEmailServerConfig.server !== prevDefaultEmailServerConfig?.server ||
        defaultEmailServerConfig.port !== prevDefaultEmailServerConfig?.port)
    ) {
      dispatch(resetVerifyEmailServer())

      dispatch(
        setUpdatedEmailServer({
          server: defaultEmailServerConfig.server,
          port: defaultEmailServerConfig.port
        })
      )
      setIsEditEmailServer(!defaultEmailServerConfig.server.length)
      dispatch(setIsMissedServerVerify(false))
    }
  }, [defaultEmailServerConfig, emailServerConfig, prevDefaultEmailServerConfig, dispatch])

  const onChangeEmailAddress: EventHandlers['onChangeEmailAddress'] = useCallback(
    e => {
      if (isEmailInputError) {
        dispatch(setIsEmailInputError(false))
      }

      dispatch(setEmailAddress(e.target.value.replace(/\s/g, '')))
    },
    [dispatch, isEmailInputError]
  )

  const onDetectEmailServer = useCallback(() => {
    if (!isValidEmail) {
      dispatch(setIsEmailInputError(true))
    } else {
      const fixedEmail = trim(emailAddress)

      dispatch(setEmailAddress(fixedEmail))
      dispatch(setUpdatedEmailServer())
      dispatch(setIsEmailServerConfigError(false))
      dispatch(setIsMissedServerDetection(false))
      dispatch(detectEmailServer(fixedEmail))
      dispatch(resetVerifyEmailServer())
    }
  }, [dispatch, isValidEmail, emailAddress])

  const onEdit = useCallback(() => {
    dispatch(resetVerifyEmailServer(true))
    setIsEditEmailServer(true)
  }, [dispatch])

  const onFinishEdit = useCallback(() => {
    if (isValidEmailServerConfig) {
      setIsEditEmailServer(false)
    } else {
      dispatch(setIsEmailServerConfigError(true))
    }
  }, [dispatch, isValidEmailServerConfig])

  const onChangeEmailServer: EventHandlers['onChangeEmailServer'] = useCallback(
    e => {
      dispatch(setIsEmailServerConfigError(false))
      const newConfig = {
        server: e.target.value.replace(/\s/g, ''),
        port: emailServerConfig?.port || ''
      }
      dispatch(setUpdatedEmailServer(newConfig))
    },
    [dispatch, emailServerConfig]
  )

  const onChangeServerPort: EventHandlers['onChangeServerPort'] = useCallback(
    e => {
      dispatch(setIsEmailServerConfigError(false))
      const newConfig = {
        server: emailServerConfig?.server || '',
        port: e.target.value.length ? Number(e.target.value.replace(/\D/g, '')) : ''
      }
      dispatch(setUpdatedEmailServer(newConfig))
    },
    [dispatch, emailServerConfig]
  )

  const onVerifyServer = useCallback(() => {
    if (emailServerConfig && isValidEmailServerConfig) {
      setIsEditEmailServer(false)
      dispatch(
        verifyEmailServer({
          email: trim(emailAddress),
          server: emailServerConfig.server,
          port: Number(emailServerConfig.port)
        })
      )
    } else {
      dispatch(setIsEmailServerConfigError(true))
    }
  }, [dispatch, emailAddress, emailServerConfig, isValidEmailServerConfig])

  return useMemo(
    () => [
      {
        emailAddress,
        isMissedServerDetection,
        isMissedServerVerify,
        isEmailInputError,
        isDetectEmailServerInProgress,
        defaultEmailServerConfig,
        emailServerConfig,
        isVerifiedServer: !!defaultEmailServerConfig?.verified,
        isVerifyEmailServerInProgress,
        isVerifyEmailServerFailed,
        isEditEmailServer,
        isEmailServerConfigError,
        isEditButtonDisabled: isVerifyEmailServerInProgress
      },
      {
        onChangeEmailAddress,
        onDetectEmailServer,
        onVerifyServer,
        onEdit,
        onFinishEdit,
        onChangeEmailServer,
        onChangeServerPort
      }
    ],
    [
      emailAddress,
      isMissedServerDetection,
      isMissedServerVerify,
      isEmailInputError,
      isDetectEmailServerInProgress,
      defaultEmailServerConfig,
      emailServerConfig,
      isVerifyEmailServerInProgress,
      isVerifyEmailServerFailed,
      isEmailServerConfigError,
      isEditEmailServer,
      onChangeEmailAddress,
      onEdit,
      onDetectEmailServer,
      onVerifyServer,
      onFinishEdit,
      onChangeEmailServer,
      onChangeServerPort
    ]
  )
}
