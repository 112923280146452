import { useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { isSuccess } from 'redux/toolkit/api'

import appConfig from 'config/appConfig'
import { AppTypes } from 'types/AppTypes'
import { getBannerSettings, updateHasMailFlownSetting } from 'redux/features/banners/bannersSlice'
import { UserRole } from 'config/userRole'
import { AccountStatusBannerSelectorProps } from 'components/libs/banner/accountStatusBanner/AccountStatusBannerSelector'
import { AccountStatusBannerType } from 'types/banners'

export interface State {
  incompleteWizardBanner: boolean
  preMailflowBanner: boolean
  wizardUrl: string | undefined
  accountStatusBannerSelectorProps: AccountStatusBannerSelectorProps
}

export const useBannersLogic = (): [State] => {
  const dispatch = useAppDispatch()

  const {
    bannerSettings,
    accessToken,
    mstoreSearch,
    isCplAccount,
    isAccountAdmin,
    appType,
    updateHasMailFlownSettingSuccess,
    getBannerSettingsSuccess
  } = useAppSelector(_stores => ({
    bannerSettings: _stores.banners?.bannerSettings,
    accessToken: _stores.auth.accessToken,
    mstoreSearch: _stores.mstore.search,
    isCplAccount: !!_stores.auth.accessTokenObject?.isCplAccount,
    isAccountAdmin: _stores.auth.accessTokenObject?.roleType === UserRole.ACCOUNT_USER,
    appType: _stores.app.appType,
    updateHasMailFlownSettingSuccess: isSuccess(_stores.banners.api.updatedHasMailFlownSettingApiStatus),
    getBannerSettingsSuccess: isSuccess(_stores.banners.api.getBannerSettingsApiStatus)
  }))

  // init
  useEffect(() => {
    if (accessToken) {
      dispatch(getBannerSettings())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken])

  // get BannerSettings again only after has_mail_flown is updated
  useEffect(() => {
    if (updateHasMailFlownSettingSuccess) {
      dispatch(getBannerSettings())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateHasMailFlownSettingSuccess])

  useEffect(() => {
    if (isAccountAdmin && !bannerSettings.hasMailFlown && mstoreSearch.messageCount > 1) {
      dispatch(updateHasMailFlownSetting())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mstoreSearch.messageCount])

  const isWizardBannersVisible =
    !isCplAccount && appType === AppTypes.admin && isAccountAdmin && getBannerSettingsSuccess

  const accountStatusBannerSelectorProps = useMemo((): AccountStatusBannerSelectorProps => {
    if (!bannerSettings?.accountStatusBanner) {
      return {
        banner: {
          banner_type: AccountStatusBannerType.NONE,
          expires_at: 0,
          trial_days_remaining: 0
        }
      }
    }

    return {
      banner: bannerSettings?.accountStatusBanner
    }
  }, [bannerSettings?.accountStatusBanner])

  return useMemo(
    () => [
      {
        incompleteWizardBanner:
          isWizardBannersVisible && !bannerSettings.wizardComplete && !bannerSettings.hasVerifiedDomains,
        preMailflowBanner: isWizardBannersVisible && bannerSettings.wizardComplete && !bannerSettings.hasMailFlown,
        wizardUrl: `${window.location.origin}/${appConfig.APP_NAMES.WIZARD}/bcc-login`,
        accountStatusBannerSelectorProps
      }
    ],
    [
      accountStatusBannerSelectorProps,
      bannerSettings.hasMailFlown,
      bannerSettings.hasVerifiedDomains,
      bannerSettings.wizardComplete,
      isWizardBannersVisible
    ]
  )
}
