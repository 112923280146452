import { Box, useTokens } from '@barracuda-internal/bds-core-v2'
import React, { PropsWithChildren, useMemo } from 'react'

const SetupFlowContainer: React.FC<PropsWithChildren> = ({ children }) => {
  const tokens = useTokens()

  return useMemo(
    () => (
      <Box
        data-testid="SetupFlowContainer"
        sx={{
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          padding: tokens.aliasesSpaceContainerSpacious,
          height: 'calc(100% - 62px)',
          overflowY: 'auto'
        }}
      >
        {children}
      </Box>
    ),
    [children, tokens.aliasesSpaceContainerSpacious]
  )
}

export default SetupFlowContainer
