import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { deliverMessage, postRedeliverMessage } from 'redux/features/mstore/mstoreSlice'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { getAtpReport, getAtpSearch, reset, setSearchTerm } from 'redux/features/atp/atpSlice'
import { Message } from 'types/Messages'
import { getErrorMessage, isPending, isSuccess } from 'redux/toolkit/api'
import { atpIsFuture, atpStatus, atpStatusIcon } from 'lib/atp'
import { isMyFeatureOn } from 'lib/splitio'
import { FEATURES } from 'lib/splitioFeatures'
import { DeliverMessagePayload } from 'redux/features/mstore/mstoreApiThunks'

export enum UiState {
  ERROR,
  LOADING,
  READY
}

export interface State {
  atpItems: Array<{
    cuid: string
    isFuture: boolean
    status: string
    fileName: string
    fileType: string
    reportUrl: string
    icon: ((props: any) => JSX.Element) | undefined
  }>
  error: string | undefined
  isOpen: boolean
  uiState: UiState
  isAtpReportPending: boolean
  isDeliverEnabled: boolean
  isRedeliverPending: boolean
  messageActionItem: Message | undefined
}

export interface EventHandlers {
  handleClose: () => void
  handleConfirm: () => void
  handleOpen: (item: Message) => void
  handleClickViewReport: (cuid: string) => void
  handleToggleEnableDeliver: () => void
}

export type ConfirmRedeliverDialogLogic = [State, EventHandlers]

export const useConfirmRedeliverDialogLogic = (): ConfirmRedeliverDialogLogic => {
  const dispatch = useAppDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const [isDeliverEnabled, setIsDeliverEnabled] = useState(false)
  const messageActionItem = useRef<Message>()
  const isDeliverRollout = isMyFeatureOn(FEATURES.EGD_React_Deliver_Rollout)

  const { atpAttachments, error, isAtpReportPending, isGetAtpLogSuccess, isRedeliverPending, isRedeliverSuccess } =
    useAppSelector(_store => ({
      error: getErrorMessage(_store.atp.getAtpLogStatus),
      atpAttachments: _store.atp.search?.result || [],
      isAtpReportPending: isPending(_store.atp.getAtpReportStatus),
      isGetAtpLogSuccess: isSuccess(_store.atp.getAtpLogStatus),
      isRedeliverPending: isPending(_store.mstore.postRedeliveryApiStatus),
      isRedeliverSuccess: isSuccess(_store.mstore.postRedeliveryApiStatus)
    }))

  const handleClose = useCallback(() => {
    messageActionItem.current = undefined
    setIsOpen(false)
  }, [])

  const handleConfirm = useCallback(() => {
    if (messageActionItem.current) {
      if (isDeliverRollout) {
        const deliverPayload: DeliverMessagePayload = {
          items: [
            {
              did: Number(messageActionItem.current.did),
              mid: messageActionItem.current.mid,
              envelope_from: messageActionItem.current.fields.envelopeFrom,
              header_from: messageActionItem.current.fields.headerFrom,
              to: messageActionItem.current.fields.headerTo,
              subject: messageActionItem.current.fields.subject,
              recipient_info: (messageActionItem.current.fields.recipientInfo || []).map(recipient => ({
                ...recipient,
                envelope_to: recipient.envelopeTo
              })),
              did_outbound: messageActionItem.current.fields.didOutbound
            }
          ],
          atp_bypass: true
        }
        dispatch(deliverMessage(deliverPayload))
      } else {
        dispatch(
          postRedeliverMessage({
            items: [
              {
                messageId: messageActionItem.current.mid,
                domainId: Number(messageActionItem.current.did)
              }
            ],
            atpBypass: true
          })
        )
      }
    }
  }, [dispatch, isDeliverRollout])

  const handleOpen = useCallback(
    (item: Message) => {
      messageActionItem.current = item
      dispatch(reset())
      dispatch(setSearchTerm({ messageId: item.mid }))
      dispatch(getAtpSearch())
      setIsDeliverEnabled(false)
      setIsOpen(true)
    },
    [dispatch]
  )

  const handleClickViewReport = useCallback(
    (cuid: string) => {
      dispatch(getAtpReport({ cuid }))
    },
    [dispatch]
  )

  const handleToggleEnableDeliver = useCallback(() => {
    setIsDeliverEnabled(!isDeliverEnabled)
  }, [isDeliverEnabled])

  const uiState = useMemo(() => {
    switch (true) {
      case isGetAtpLogSuccess:
        return UiState.READY
      case !!error:
        return UiState.ERROR
      default:
        return UiState.LOADING
    }
  }, [isGetAtpLogSuccess, error])

  const atpItems = useMemo(
    () =>
      atpAttachments.map(attachment => {
        const status = atpStatus(attachment.status)
        const isFuture = atpIsFuture(status, attachment.determination)
        return {
          cuid: attachment.cuid,
          isFuture,
          status,
          fileName: attachment.filename,
          fileType: attachment.fileType,
          reportUrl: '',
          icon: atpStatusIcon(status)
        }
      }),
    [atpAttachments]
  )

  useEffect(
    () => () => {
      dispatch(reset())
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    if (isRedeliverSuccess) {
      handleClose()
    }
  }, [handleClose, isRedeliverSuccess])

  return useMemo(
    () => [
      {
        error,
        isOpen,
        uiState,
        atpItems,
        isAtpReportPending,
        isDeliverEnabled,
        isRedeliverPending,
        messageActionItem: messageActionItem.current
      },
      {
        handleClose,
        handleConfirm,
        handleOpen,
        handleClickViewReport,
        handleToggleEnableDeliver
      }
    ],
    [
      atpItems,
      error,
      handleClickViewReport,
      handleClose,
      handleConfirm,
      handleOpen,
      isAtpReportPending,
      isOpen,
      uiState,
      isDeliverEnabled,
      handleToggleEnableDeliver,
      isRedeliverPending
    ]
  )
}
