import { createAsyncThunk } from '@reduxjs/toolkit'

import restClient, { ApiRejectResponse, validateApiError } from 'lib/api/restClient'
import apiRoutes from 'lib/api/apiRoutes'
import { setErrorSnackBar, setSuccessSnackBar } from 'redux/features/app/appSlice'
import {
  AddMailServerRequest,
  AddMailServerResponse,
  EmailServerResult,
  EmailServerSettings,
  MxRecords,
  OldMxRecords,
  Provider,
  RemoveMailServerRequest,
  RemoveMailServerResponse,
  UpdateMailServerRequest,
  UpdateMailServerResponse,
  TestMailServerRequest,
  TestMailServerResponse
} from 'types/emailServer'
import { RootState } from 'redux/toolkit/store'
import logger from 'lib/logger'
import {
  insertDomainMailServer,
  removeDomainMailServer,
  updateDomainMailServer
} from 'redux/features/domains/domainsActions'
import { SetupFlowSteps } from 'components/pages/setupFlow/types/egdWizardTypes'

export type DetectEmailServerPayload = string
export type GetProviderPayload = string
export type GetMxRecordsPayload = string
export type GetOldMxRecordsPayload = string

export interface VerifyEmailServerPayload {
  email: string
  server: string
  port: number
}

export interface SetEmailServerSettingsPayload {
  completed: boolean
  nextStep?: SetupFlowSteps
}
export interface SetRegionPayload {
  region: string
  nextStep?: SetupFlowSteps
}
export type SaveDeploymentMethodPayload = SetupFlowSteps
export type SaveSetupFlowStepPayload = string
export type SaveSetupFlowVersionPayload = 'v1' | 'v2'

export const detectEmailServer = createAsyncThunk<EmailServerResult, DetectEmailServerPayload, ApiRejectResponse>(
  'EMAIL_SERVER/detect',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const resp = await restClient(apiRoutes.DETECT_EMAIL_SERVER, {
        params: { email: payload }
      })

      return resp.data?.results?.[0]
    } catch (e) {
      // not found
      if (e?.status === 404) {
        return { server: '', port: '' }
      }

      // server error
      dispatch(
        setErrorSnackBar({
          message: 'detect_email_server_failure'
        })
      )
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const verifyEmailServer = createAsyncThunk<EmailServerResult, VerifyEmailServerPayload, ApiRejectResponse>(
  'EMAIL_SERVER/verify',
  async (payload, { rejectWithValue }) => {
    try {
      const resp = await restClient(apiRoutes.VERIFY_EMAIL_SERVER, {
        params: { email: payload.email, server: payload.server, port: payload.port }
      })

      return resp.data?.results?.[0]?.[0]
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const getProvider = createAsyncThunk<Provider[], GetProviderPayload, ApiRejectResponse>(
  'EMAIL_SERVER/getProvider',
  async (payload, { rejectWithValue }) => {
    try {
      const resp = await restClient(apiRoutes.GET_PROVIDER, {
        params: { domain: payload }
      })

      return resp.data?.results
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const getMxRecords = createAsyncThunk<MxRecords, GetMxRecordsPayload, ApiRejectResponse>(
  'EMAIL_SERVER/getMxRecords',
  async (payload, { rejectWithValue }) => {
    try {
      const resp = await restClient(apiRoutes.GET_MX_RECORDS, {
        params: { domain: payload }
      })

      return resp.data?.results?.[0]
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const getOldMxRecords = createAsyncThunk<OldMxRecords, GetOldMxRecordsPayload, ApiRejectResponse>(
  'EMAIL_SERVER/getOldMxRecords',
  async (payload, { rejectWithValue }) => {
    try {
      const resp = await restClient(apiRoutes.GET_OLD_MX_RECORDS, {
        params: { domain: payload }
      })

      return resp.data?.results?.[0]
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const getEmailServerSettings = createAsyncThunk<EmailServerSettings, undefined, ApiRejectResponse>(
  'EMAIL_SERVER/getEmailServerSettings',
  async (_, { rejectWithValue }) => {
    try {
      const resp = await restClient(apiRoutes.GET_EMAIL_SERVER_SETTINGS, {})

      return resp.data?.results?.[0]?.settings
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const getIpirScanStatus = createAsyncThunk<{ onboarding_completed: boolean }, undefined, ApiRejectResponse>(
  'EMAIL_SERVER/getIpirScanStatus',
  async (payload, { rejectWithValue, getState }) => {
    try {
      const { emailServerSettings } = (getState() as RootState).emailServer
      const resp = await restClient(apiRoutes.GET_SCAN_STATUS, {
        data: { ipirAccountId: emailServerSettings?.ipirAccountId, ipirTokenId: emailServerSettings?.ipirTokenId }
      })
      return resp.data
    } catch (e) {
      return rejectWithValue('Get scan status error')
    }
  }
)

export const setEmailServerSettings = createAsyncThunk<
  EmailServerSettings,
  SetEmailServerSettingsPayload,
  ApiRejectResponse
>('EMAIL_SERVER/setEmailServerSettings', async (payload, { rejectWithValue, getState, dispatch }) => {
  try {
    const emailServerStore = (getState() as RootState).emailServer
    const requestBody: Omit<EmailServerSettings, 'region' | 'url' | 'migrateToken'> = {
      email: emailServerStore.emailAddress || null,
      primaryMx: emailServerStore.mxRecords?.primary || null,
      backupMx: emailServerStore.mxRecords?.backup || null,
      emailServers: emailServerStore.updatedEmailServer
        ? [
            {
              ...emailServerStore.updatedEmailServer,
              port: Number(emailServerStore.updatedEmailServer.port),
              verified: !!emailServerStore.emailServer?.verified,
              domainId: emailServerStore.emailServer?.domainId,
              domainName: emailServerStore.emailServer?.domainName
            }
          ]
        : null,
      provider: emailServerStore.provider || null,
      oldMxRecords: emailServerStore.oldMxRecords || null,
      version: emailServerStore.emailServerSettings?.version || null,
      setupFlowStep: emailServerStore.emailServerSettings?.setupFlowStep || null,
      ipirAccountId: emailServerStore.emailServerSettings?.ipirAccountId || null,
      ipirTokenId: emailServerStore.emailServerSettings?.ipirTokenId || null,
      wizardCompleted: payload.completed
    }

    if (payload.nextStep) {
      requestBody.setupFlowStep = payload.nextStep
    }

    const resp = await restClient(apiRoutes.SET_EMAIL_SERVER_SETTINGS, { data: requestBody })

    return resp.data?.results?.[0]?.settings
  } catch (e) {
    dispatch(setErrorSnackBar({ message: 'wizard_save_error' }))

    return rejectWithValue(validateApiError(e))
  }
})

export const setRegion = createAsyncThunk<EmailServerSettings, SetRegionPayload, ApiRejectResponse>(
  'EMAIL_SERVER/setRegion',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const resp = await restClient(apiRoutes.SET_REGION, {
        data: { region: payload.region, setupFlowStep: payload.nextStep }
      })

      return resp.data?.results?.[0]?.settings
    } catch (e) {
      dispatch(setErrorSnackBar({ message: 'wizard_save_error' }))

      return rejectWithValue(validateApiError(e))
    }
  }
)

export const saveSetupFlowStep = createAsyncThunk<EmailServerSettings, SaveSetupFlowStepPayload, ApiRejectResponse>(
  'EMAIL_SERVER/saveSetupFlowStep',
  async (payload, { rejectWithValue }) => {
    try {
      const resp = await restClient(apiRoutes.SAVE_SETUP_FLOW_STEP, { data: { setupFlowStep: payload } })

      return resp.data?.results?.[0]?.settings
    } catch (e) {
      return rejectWithValue('Save setup flow step error')
    }
  }
)

export const saveSetupFlowVersion = createAsyncThunk<
  EmailServerSettings,
  SaveSetupFlowVersionPayload,
  ApiRejectResponse
>('EMAIL_SERVER/saveSetupFlowVersion', async (payload, { rejectWithValue }) => {
  try {
    const resp = await restClient(apiRoutes.SAVE_SETUP_FLOW_VERSION, { data: { version: payload } })

    return resp.data?.results?.[0]?.settings
  } catch (e) {
    return rejectWithValue('Save setup flow version error')
  }
})

export const saveDeploymentMethod = createAsyncThunk<
  EmailServerSettings,
  SaveDeploymentMethodPayload,
  ApiRejectResponse
>('EMAIL_SERVER/saveDeploymentMethod', async (payload, { rejectWithValue, getState }) => {
  try {
    const emailServerStore = (getState() as RootState).emailServer

    const resp = await restClient(apiRoutes.SAVE_DEPLOYMENT_METHOD, {
      data: { deploymentMethod: emailServerStore.deploymentSetup, setupFlowStep: payload }
    })

    return resp.data?.results?.[0]?.settings
  } catch (e) {
    return rejectWithValue('Save deployment error')
  }
})

export const addMailServer = createAsyncThunk<AddMailServerResponse, AddMailServerRequest, ApiRejectResponse>(
  'EMAIL_SERVER/add',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { domainId, ...data } = payload
      const resp = await restClient(apiRoutes.ADD_MAIL_SERVER, { data, urlParams: { domainId } })
      dispatch(setSuccessSnackBar({ message: 'add_mail_server_success' }))
      dispatch(insertDomainMailServer(resp.data))
      return resp.data
    } catch (e) {
      if (e?.message) {
        logger.error(`Failed to add mail server: ${e.message}`)
      }
      const message = e?.status === 409 ? 'add_mail_server_failure.duplicate' : 'add_mail_server_failure.default'
      dispatch(setErrorSnackBar({ message }))
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const removeMailServer = createAsyncThunk<RemoveMailServerResponse, RemoveMailServerRequest, ApiRejectResponse>(
  'EMAIL_SERVER/remove',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { domainId, serverId } = payload
      const resp = await restClient(apiRoutes.REMOVE_MAIL_SERVER, { urlParams: { domainId, serverId } })
      dispatch(setSuccessSnackBar({ message: 'remove_mail_server_success' }))
      dispatch(removeDomainMailServer(resp.data))
      return resp.data
    } catch (e) {
      if (e?.message) {
        logger.error(`Failed to remove mail server: ${e.message}`)
      }
      const message = e?.status === 409 ? 'remove_mail_server_failure.not_found' : 'add_mail_server_failure.default'
      dispatch(setErrorSnackBar({ message }))
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const updateMailServer = createAsyncThunk<UpdateMailServerResponse, UpdateMailServerRequest, ApiRejectResponse>(
  'EMAIL_SERVER/update',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { domainId, serverId, ...data } = payload
      const resp = await restClient(apiRoutes.UPDATE_MAIL_SERVER, { data, urlParams: { domainId, serverId } })
      dispatch(setSuccessSnackBar({ message: 'update_mail_server_success' }))
      dispatch(updateDomainMailServer(resp.data))
      return resp.data
    } catch (e) {
      if (e?.message) {
        logger.error(`Failed to update mail server: ${e.message}`)
      }
      let message: string
      switch (true) {
        case e?.data?.detail === 'EmailServerNotFoundException':
          message = 'update_mail_server_failure.not_found'
          break
        case e?.data?.detail === 'DuplicateEmailServerHostException':
          message = 'update_mail_server_failure.duplicate'
          break
        default:
          message = 'update_mail_server_failure.default'
      }
      dispatch(setErrorSnackBar({ message }))
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const testMailServer = createAsyncThunk<TestMailServerResponse, TestMailServerRequest, ApiRejectResponse>(
  'EMAIL_SERVER/test',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { domainId, serverId, domainName, ...data } = payload
      const resp = await restClient(apiRoutes.TEST_MAIL_SERVER, { data, urlParams: { domainId, serverId } })
      dispatch(setSuccessSnackBar({ message: 'test_mail_server_success', params: [payload.recipient, domainName] }))
      return resp.data
    } catch (e) {
      if (e?.message) {
        logger.error(`Failed to update mail server: ${e.message}`)
      }
      const message =
        e?.data?.detail?.exception === 'EmailServerNotFoundException'
          ? 'test_mail_server_failure.not_found'
          : 'test_mail_server_failure.default'
      dispatch(setErrorSnackBar({ message }))
      return rejectWithValue(e?.data?.detail?.message || validateApiError(e))
    }
  }
)
