import React from 'react'

import styles from 'components/pages/support/help/helpStyles'
import { HelpComponentProps } from 'types/Help'

function UsersList(props: HelpComponentProps) {
  const classes = styles()
  return (
    <div className={classes.content} data-testid="content">
      <p>
        Use the <b>Users List</b> page to view a list of all users or filter based on assigned roles and domain
        permissions.
      </p>
      <br />
      <br />
      <h3>User Role</h3>
      From the <b>User Role</b> drop-down menu, select a role on which to filter the user list. Select to display{' '}
      <b>All</b> users, or select to view only users in the <b>User</b>, <b>Help Desk</b>, or <b>Admin role</b>. All
      matching users display in the table.
      <br />
      <br />
      <h3>Domains</h3>
      Use the <b>Domains</b> drop-down menu to filter the user list based on the selected domain. If you selected any
      role other than <b>User</b> from the <b>User Role</b> drop-down menu, then from the <b>Domains</b> drop-down menu,
      select <b>All</b> to filter on all domains, or select a single domain on which to filter the user list. All
      matching users display in the table.
      <br />
      <br />
      <h3>Assign Roles and Domain Permissions</h3>
      To assign a user role and domain permissions:
      <ol>
        <li>
          Use the <b>User Role</b> and <b>Domains</b> drop-down menus to filter the user list.
        </li>
        <li>
          For the desired user, click <b>Edit</b> in the <b>Actions</b> column; the <b>Edit User</b> page displays.
        </li>
        <li>
          From the <b>User Role</b> drop-down menu, select the role you want to assign to the user.
        </li>
        <li>
          If you assign the user either the <b>Help Desk</b> or <b>Domain Admin</b> role, in the <b>Domains</b> section,
          select one or more domains you want the user to manage.
        </li>
        <li>
          Click <b>Save</b>.
        </li>
      </ol>
      <br />
      <h3>Add Users</h3>
      To manually add users:
      <ol>
        <li>
          Click <b>Add/Update Users</b>; the <b>Add/Update Users</b> page displays.
        </li>
        <li>
          Use the <b>User Accounts</b> field to enter user email addresses one at a time, or in bulk as a list.
        </li>
        <li>
          Select any additional configuration options, and click <b>Save Changes</b> to add the user(s) and return to
          the <b>Users &gt; Users List</b> page.
        </li>
      </ol>
      To use directory services:
      <ol>
        <li>
          Go to the <b>Domains &gt; Domain Settings</b> page, and select whether to configure recipient verification via
          LDAP or Azure AD.
        </li>
        <li>
          Select <b>Automatic</b> in the <b>Synchronization options</b> section.
        </li>
        <ul>
          <li>
            If you select <b>LDAP</b>, {props.productName} automatically synchronizes your LDAP users to its database on
            a regular basis for recipient verification.
          </li>
          <li>
            If you select <b>Azure AD</b>, {props.productName} automatically synchronizes with your Microsoft Entra ID
            (formerly Azure AD) at regular intervals throughout the day and adds/updates your users.
          </li>
        </ul>
      </ol>
      <br />
      <h3>Delete Users</h3>
      To delete a user:
      <ul>
        <li>
          Click <b>Delete</b> in the <b>Actions</b> column for a single user.
        </li>
      </ul>
      To delete multiple users:
      <ol>
        <li>
          Select individual users, or select the checkbox to the left of <b>User Account (Email Address)</b> to select
          all users.
        </li>
        <li>
          Click <b>Bulk Delete</b>.
        </li>
        <li>
          In the confirmation dialog, click <b>OK</b>.
        </li>
      </ol>
      <br />
      <h3>Users List</h3>
      The <b>Users List</b> table includes the following columns:
      <ul>
        <li>
          <b>User Account (Email Address)</b>
          <br />
          The email address of the user registered with {props.productName}.
        </li>
        <li>
          <b>Linked Accounts</b>
          <br />
          Users can add additional email addresses they have in the same domain for which quarantined email should be
          forwarded to this account. Accounts can be linked using the <b>Linked Accounts</b> page when the user is
          logged in. Note that users can only link email addresses in domains that are registered to their{' '}
          {props.productName}
          account.
        </li>
        <li>
          <b>Source</b>
          <br />
          Indicates whether user was entered manually or automatically:
        </li>
        <ul>
          <li>
            <b>Manual</b>
            <br />
            The user was manually added to the service on the <b>Add/Update Users</b> page.
          </li>
          <li>
            <b>LDAP</b>
            <br />
            The user was automatically added to the service with LDAP synchronization. See the{' '}
            <b>Domains &gt; Domain Settings</b> page.
          </li>
          <li>
            <b>Azure_ad</b>
            <br />
            The user was automatically added to the service via Azure AD synchronization. See the{' '}
            <b>Domains &gt; Domain Settings</b> page.
          </li>
        </ul>
        <li>
          <b>Quarantine</b>
          <br />
          Visible if you are an account or domain administrator:
        </li>
        <ul>
          <li>
            <b>Enabled</b>
            <br />
            User&apos;s <b>Default interval for user quarantine notifications</b> is set to <b>Scheduled</b>, with at
            least one block selected.
          </li>
          <li>
            <b>Disabled</b>
            <br />
            User&apos;s <b>Default interval for user quarantine notifications</b> is set to <b>Never</b>.
          </li>
        </ul>
        <li>
          <b>Actions</b>
          <br />
          The <b>Actions</b> column displays the available actions you can take with user accounts based on the account
          source:
        </li>
        <ul>
          <li>
            <b>Edit</b>
            <br />
            Set the user role. If the user role is Help Desk or Domain Admin, select the domains you have verified with{' '}
            {props.productName} that you want this user to manage.
          </li>
          <li>
            <b>Reset Password</b> (<i>available only for Manually added users</i>)
            <br />
            Reset the user password. Click to have {props.productName} send an email to the user with a link, requesting
            that the user change his or her password. The user clicks the link in the email to view a web page that
            prompts the user to change the password for their account.
          </li>
          <li>
            <b>Log in as this user</b>
            <br />
            Click to:
          </li>
          <ul>
            <li>View or change this user&apos;s settings</li>
            <li>View/manage the domains this user manages</li>
            <li>View/search/manage this user&apos;s Message Log</li>
          </ul>
          <li>
            <b>Delete</b>
            <br />
            Delete the user account.
          </li>
        </ul>
      </ul>
    </div>
  )
}

export default UsersList
