import { ChangeEvent, useCallback, useMemo, useState } from 'react'
import { useMessageLogRights } from 'components/libs/userRights/pages/useMessageLogRights'
import { useMessageVariables } from 'components/libs/message/useMessageVariables'

export enum TabName {
  preview,
  source
}

export interface State {
  selectedTabName: TabName
  isPreviewEnabled: boolean
  showTabs: boolean
}

export interface EventHandlers {
  onTabChange: (_: ChangeEvent<Record<string, never>>, value: TabName) => void
}

export type MessageDetailsContentTabsLogic = [State, EventHandlers]

export const useMessageDetailsContentTabsLogic = (): MessageDetailsContentTabsLogic => {
  const { showMsgBody, encrypted } = useMessageVariables()
  const { isMessagePreviewAvailableHelpdesk } = useMessageLogRights()
  const [selectedTabName, setSelectedTabName] = useState<TabName>(
    isMessagePreviewAvailableHelpdesk ? TabName.preview : TabName.source
  )

  const onTabChange = useCallback((_: ChangeEvent<Record<string, never>>, value: TabName) => {
    setSelectedTabName(value)
  }, [])

  const showTabs = useMemo(() => {
    if (encrypted) {
      return true
    }
    return showMsgBody
  }, [showMsgBody, encrypted])

  return useMemo(
    () => [
      {
        selectedTabName,
        isPreviewEnabled: isMessagePreviewAvailableHelpdesk,
        showTabs
      },
      {
        onTabChange
      }
    ],
    [selectedTabName, isMessagePreviewAvailableHelpdesk, showTabs, onTabChange]
  )
}
