import { Box, CircularProgress, useTokens } from '@barracuda-internal/bds-core-v2'
import React, { useMemo } from 'react'

const LoadingOverlayComponent: React.FC = () => {
  const tokens = useTokens()

  return useMemo(
    () => (
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          background: tokens.aliasesColorBgStaticContainerSubtle,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          opacity: 0.5,
          zIndex: 5000,
          padding: tokens.aliasesSpaceContainerSpacious
        }}
      >
        <CircularProgress data-testid="loading-state" />
      </Box>
    ),
    [tokens]
  )
}

export default LoadingOverlayComponent
