import React, { useMemo } from 'react'

import { Button, Grid, Typography } from '@barracuda-internal/bds-core'
import { useFormatMessage } from 'lib/localization'
import routesConfig from 'lib/routesConfig'

import { AvailableSettings, SettingActionValue, SettingActionValueString, SettingValue } from 'types/Settings'

import styles from 'components/pages/inboundSettings/inboundSettingsStyles'
import InboundSenderAuthentication from 'components/pages/support/help/inboundSenderAuthentication/InboundSenderAuthentication'
import { GROUP_TITLE, TOPICS } from 'components/pages/support/config'
import { useSenderAuthLogic } from 'components/pages/inboundSettings/senderAuth/useSenderAuthLogic'
import DomainTable from 'components/libs/domainTable/DomainTable'
import SettingsBlock from 'components/libs/settings/SettingsBlock'
import IpAddressTable from 'components/libs/ipAddressTable/IpAddressTable'
import { PageTitle, PageSubtitle, FormSpacer, FormRow, InlineRadioGroup } from 'components/libs/layout/pages/pageLayout'
import { useSettingsStateLogic } from 'components/libs/useSettingsState'
import { SettingsPageContainer } from 'components/libs/layout/pages/SettingsPageContainer'

const BASE_I18N_KEY = 'ess.inbound_settings.sender_auth'
const BASE_INBOUND_I18N_KEY = 'ess.inbound_settings'

const SenderAuth: React.FC = () => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatInboundMessage = useFormatMessage(BASE_INBOUND_I18N_KEY)

  const [state, eventHandlers] = useSenderAuthLogic()
  const [UseGetSettingsStateLogic] = useSettingsStateLogic()

  return useMemo(
    () => (
      <SettingsPageContainer dirtyCheck isDirtyForm={state.isDisabledNavigation}>
        <PageTitle
          title={formatMessage('title')}
          onOpenSupport={eventHandlers.helpConfig.onHelpClick}
          onCancel={() => routesConfig.INBOUND_SETTINGS_SENDER_AUTH.goto()}
          onSubmit={eventHandlers.onSave}
          submitInProgress={UseGetSettingsStateLogic.isLoading}
          isDisabledSubmit={state.isDisabledSubmit}
          help={{
            content: InboundSenderAuthentication,
            onClose: eventHandlers.helpConfig.onCloseHelp,
            title: TOPICS.senderAuthentication,
            group: GROUP_TITLE.inboundSettings,
            isHelpOpen: eventHandlers.helpConfig.isOpen
          }}
        />
        {/* DMARC SECTION */}
        <FormSpacer />
        <PageSubtitle>{formatMessage('dmarc_dkim_spf_header')}</PageSubtitle>
        <FormSpacer />
        <FormRow rowTitle={formatMessage('dmarc_label')}>
          <InlineRadioGroup
            name={AvailableSettings.DMARC}
            value={state.form[AvailableSettings.DMARC]}
            values={[SettingValue.ENABLED, SettingValue.DISABLED]}
            onChange={eventHandlers.onFormChange(AvailableSettings.DMARC)}
            disabled={UseGetSettingsStateLogic.isLoading}
            labels={[formatMessage('yes'), formatMessage('no')]}
            helperText={formatMessage('dmarc_note')}
          />
        </FormRow>
        <FormSpacer />
        <SettingsBlock disabled={state.form[AvailableSettings.DMARC] === SettingValue.DISABLED}>
          <Grid container direction="row">
            <Grid item xs={3}>
              <Typography variant="body2" data-testid="label:blank" />
            </Grid>
            <Grid item xs={9}>
              <Grid container direction="row" className={classes.flexWrapper}>
                <Typography variant="h4">{formatMessage('dmarc_table')}</Typography>
                <Button
                  className={classes.bulkEdit}
                  onClick={() => eventHandlers.onBulkEdit(routesConfig.INBOUND_SETTINGS_DMARC_EXEMPT_BULK_EDIT)}
                  variant="contained"
                  size="small"
                  data-testid="bulk-button-dmarc"
                >
                  {formatInboundMessage('bulk_edit')}
                </Button>
              </Grid>
              <DomainTable
                data={state.dmarcExemptEntries}
                onAddItem={eventHandlers.onAddDmarcExemptItem}
                onRemoveItem={eventHandlers.onRemoveDmarcExemptItem}
                data-testid="dmarc-table"
                delegateDirtyFormState={eventHandlers.setIsDmarcExemptTableDirty}
              />
            </Grid>
          </Grid>
        </SettingsBlock>
        <FormSpacer />
        {/* DKIM SECTION */}
        <FormRow rowTitle={formatMessage('dkim_label')}>
          <InlineRadioGroup
            name={AvailableSettings.DKIM}
            labels={state.cplActionNumbers.map(action => formatMessage(action.label))}
            value={state.form[AvailableSettings.DKIM]}
            values={state.cplActionNumbers.map(action => action.value)}
            onChange={eventHandlers.onFormChange(AvailableSettings.DKIM)}
            disabled={UseGetSettingsStateLogic.isLoading}
          />
        </FormRow>
        <SettingsBlock disabled={state.form[AvailableSettings.DKIM] === SettingActionValue.OFF}>
          <Grid container direction="row">
            <Grid item xs={3}>
              <Typography variant="body2" data-testid="label:blank" />
            </Grid>
            <Grid item xs={9}>
              <Grid container direction="row" className={classes.flexWrapper}>
                <Typography variant="h4">{formatMessage('dkim_table')}</Typography>
                <Button
                  className={classes.bulkEdit}
                  onClick={() => eventHandlers.onBulkEdit(routesConfig.INBOUND_SETTINGS_DKIM_EXEMPT_BULK_EDIT)}
                  variant="contained"
                  size="small"
                  data-testid="bulk-button-dkim"
                >
                  {formatInboundMessage('bulk_edit')}
                </Button>
              </Grid>
              <DomainTable
                data={state.dkimExemptEntries}
                onAddItem={eventHandlers.onAddDkimExemptItem}
                onRemoveItem={eventHandlers.onRemoveDkimExemptItem}
                data-testid="dkim-table"
                delegateDirtyFormState={eventHandlers.setIsDkimExemptTableDirty}
              />
            </Grid>
          </Grid>
        </SettingsBlock>
        <FormSpacer />
        <FormSpacer />
        {/* SPF SECTION */}
        <FormRow alignTitleToTop rowTitle={formatMessage('spf_label')}>
          <Typography variant="body2">{formatMessage('hard_fail')}</Typography>
          <InlineRadioGroup
            name={AvailableSettings.SPF_HARDFAIL}
            labels={state.cplActionStrings.map(action => formatMessage(action.label))}
            value={state.form[AvailableSettings.SPF_HARDFAIL]}
            values={state.cplActionStrings.map(action => action.value)}
            onChange={eventHandlers.onFormChange(AvailableSettings.SPF_HARDFAIL)}
            disabled={UseGetSettingsStateLogic.isLoading}
          />
        </FormRow>
        <FormSpacer />
        <FormRow alignTitleToTop rowTitle="">
          <Typography variant="body2">{formatMessage('soft_fail')}</Typography>
          <InlineRadioGroup
            name={AvailableSettings.SPF_SOFTFAIL}
            labels={state.cplActionStrings.map(action => formatMessage(action.label))}
            value={state.form[AvailableSettings.SPF_SOFTFAIL]}
            values={state.cplActionStrings.map(action => action.value)}
            onChange={eventHandlers.onFormChange(AvailableSettings.SPF_SOFTFAIL)}
            disabled={
              state.form[AvailableSettings.SPF_HARDFAIL] === SettingActionValueString.OFF ||
              UseGetSettingsStateLogic.isLoading
            }
          />
        </FormRow>
        <SettingsBlock disabled={state.form[AvailableSettings.SPF_HARDFAIL] === SettingActionValueString.OFF}>
          <Grid container direction="row">
            <Grid item xs={3}>
              <Typography variant="body2" data-testid="label:blank" />
            </Grid>
            <Grid item xs={9}>
              <Grid container direction="row" className={classes.flexWrapper}>
                <Typography variant="h4">{formatMessage('spf_exempt')}</Typography>
                <Button
                  className={classes.bulkEdit}
                  onClick={() => eventHandlers.onBulkEdit(routesConfig.INBOUND_SETTINGS_SPF_EXEMPT_BULK_EDIT)}
                  variant="contained"
                  size="small"
                  data-testid="bulk-button-spf"
                >
                  {formatInboundMessage('bulk_edit')}
                </Button>
              </Grid>
              <IpAddressTable
                data={state.spfExemptIpAddressEntries}
                onAddItem={eventHandlers.onAddSpfExemptIpAddressItem}
                onRemoveItem={eventHandlers.onRemoveSpfExemptIpAddressItem}
                data-testid="spf-table"
                delegateDirtyFormState={eventHandlers.setIsIpAddressTableDirty}
              />
            </Grid>
          </Grid>
        </SettingsBlock>
        <SettingsBlock disabled={state.form[AvailableSettings.SPF_HARDFAIL] === SettingActionValueString.OFF}>
          <Grid container direction="row" className={classes.spaceGrid}>
            <Grid item xs={3}>
              <Typography variant="body2" data-testid="label:blank" />
            </Grid>
            <Grid item xs={9}>
              <Grid container direction="row" className={classes.flexWrapper}>
                <Typography variant="h4">{formatMessage('spf_domain_exempt')}</Typography>
                <Button
                  className={classes.bulkEdit}
                  onClick={() => eventHandlers.onBulkEdit(routesConfig.INBOUND_SETTINGS_SPF_DOMAIN_EXEMPT_BULK_EDIT)}
                  variant="contained"
                  size="small"
                  data-testid="bulk-button-spf-domain"
                >
                  {formatInboundMessage('bulk_edit')}
                </Button>
              </Grid>
              <DomainTable
                data={state.spfExemptDomainEntries}
                onAddItem={eventHandlers.onAddSpfExemptDomainItem}
                onRemoveItem={eventHandlers.onRemoveSpfExemptDomainItem}
                data-testid="spf-domain-exempt-table"
                delegateDirtyFormState={eventHandlers.setIsSpfDomainExemptTableDirty}
              />
            </Grid>
          </Grid>
        </SettingsBlock>
        <FormSpacer />
        {/* SPF MISSING SECTION */}
        <FormRow alignTitleToTop rowTitle={formatMessage('spf_missing_label')}>
          <InlineRadioGroup
            name={AvailableSettings.SPF_MISSING}
            labels={state.cplActionNumbers.map(action => formatMessage(action.label))}
            value={state.form[AvailableSettings.SPF_MISSING]}
            values={state.cplActionNumbers.map(action => action.value)}
            onChange={eventHandlers.onFormChange(AvailableSettings.SPF_MISSING)}
            disabled={UseGetSettingsStateLogic.isLoading}
          />
        </FormRow>
        <SettingsBlock disabled={state.form[AvailableSettings.SPF_MISSING] === SettingActionValue.OFF}>
          <Grid container direction="row">
            <Grid item xs={3}>
              <Typography variant="body2" data-testid="label:blank" />
            </Grid>
            <Grid item xs={9}>
              <Grid container direction="row" className={classes.flexWrapper}>
                <Typography variant="h4">{formatMessage('spf_missing_table')}</Typography>
                <Button
                  className={classes.bulkEdit}
                  onClick={() => eventHandlers.onBulkEdit(routesConfig.INBOUND_SETTINGS_SPF_MISSING_EXEMPT_BULK_EDIT)}
                  variant="contained"
                  size="small"
                  data-testid="bulk-button-spf-missing"
                >
                  {formatInboundMessage('bulk_edit')}
                </Button>
              </Grid>
              <IpAddressTable
                data={state.missingSpfExemptIpAddressEntries}
                onAddItem={eventHandlers.onAddMissingSpfExemptIpAddressItem}
                onRemoveItem={eventHandlers.onRemoveMissingSpfExemptIpAddressItem}
                data-testid="spf-missing-table"
                delegateDirtyFormState={eventHandlers.setIsIpAddressTableDirty}
              />
            </Grid>
          </Grid>
        </SettingsBlock>
        <FormSpacer />
        {/* PTR SECTION */}
        <PageSubtitle>{formatMessage('ptr_header')}</PageSubtitle>
        <FormRow rowTitle={formatMessage('ptr_label')}>
          <InlineRadioGroup
            name={AvailableSettings.PTR}
            value={state.form[AvailableSettings.PTR]}
            values={[SettingValue.ENABLED, SettingValue.DISABLED]}
            onChange={eventHandlers.onFormChange(AvailableSettings.PTR)}
            disabled={UseGetSettingsStateLogic.isLoading}
            labels={[formatMessage('yes'), formatMessage('no')]}
          />
        </FormRow>
        <SettingsBlock disabled={state.form[AvailableSettings.PTR] === SettingValue.DISABLED}>
          <Grid container direction="row">
            <Grid item xs={3}>
              <Typography variant="body2" data-testid="label:blank" />
            </Grid>
            <Grid item xs={9}>
              <Grid container direction="row" className={classes.flexWrapper}>
                <Typography variant="h4">{formatMessage('ptr_table')}</Typography>
                <Button
                  className={classes.bulkEdit}
                  onClick={() => eventHandlers.onBulkEdit(routesConfig.INBOUND_SETTINGS_PTR_EXEMPT_BULK_EDIT)}
                  variant="contained"
                  size="small"
                  data-testid="bulk-button-ptr"
                >
                  {formatInboundMessage('bulk_edit')}
                </Button>
              </Grid>
              <IpAddressTable
                data={state.missingPtrExemptIpAddressEntries}
                onAddItem={eventHandlers.onAddMissingPtrExemptIpAddressItem}
                onRemoveItem={eventHandlers.onRemoveMissingPtrExemptIpAddressItem}
                data-testid="ptr-table"
                delegateDirtyFormState={eventHandlers.setIsIpAddressTableDirty}
              />
            </Grid>
          </Grid>
        </SettingsBlock>
        <FormSpacer />
        <FormSpacer />
      </SettingsPageContainer>
    ),
    [
      state.isDisabledNavigation,
      state.isDisabledSubmit,
      state.form,
      state.dmarcExemptEntries,
      state.cplActionNumbers,
      state.dkimExemptEntries,
      state.cplActionStrings,
      state.spfExemptIpAddressEntries,
      state.spfExemptDomainEntries,
      state.missingSpfExemptIpAddressEntries,
      state.missingPtrExemptIpAddressEntries,
      formatMessage,
      eventHandlers,
      UseGetSettingsStateLogic.isLoading,
      classes.flexWrapper,
      classes.bulkEdit,
      classes.spaceGrid,
      formatInboundMessage
    ]
  )
}

export default SenderAuth
