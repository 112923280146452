import { Box, useTokens } from '@barracuda-internal/bds-core-v2'
import React, { PropsWithChildren, useMemo } from 'react'

const SetupFlowStepsContentContainer: React.FC<PropsWithChildren> = ({ children }) => {
  const tokens = useTokens()

  return useMemo(
    () => (
      <Box
        data-testid="SetupFlowStepsContentContainer"
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          width: '700px',
          height: '100%',
          gap: tokens.aliasesSpaceContainerDefault,
          padding: tokens.aliasesSpaceContainerSpacious,
          backgroundColor: tokens.aliasesColorBgStaticContainerSubtle
        }}
      >
        {children}
      </Box>
    ),
    [children, tokens]
  )
}

export default SetupFlowStepsContentContainer
