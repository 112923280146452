import React, { useMemo } from 'react'

import { Button, Chip, Grid, IconButton, Menu, MenuItem, TextField, Typography } from '@barracuda-internal/bds-core'
import { Edit, MenuVertical, Launch, Delete, Search } from '@barracuda-internal/bds-core/dist/Icons/Core'
import { useFormatMessage } from 'lib/localization'
import styles from 'components/pages/reports/scheduledReports/scheduledReportsStyles'

import { PageContainer } from 'components/libs/layout/pages/PageContainer'
import noSchedules from 'assets/images/barracuda/EmailReports.svg'
import {
  MENU_ITEMS,
  useScheduledReportsLogic
} from 'components/pages/reports/scheduledReports/useScheduledReportsLogic'
import { AlarmOffIcon } from 'components/pages/reports/reportList/customizedBDSComponents/AlarmOffIcon'
import ScheduleReport from 'components/libs/scheduleReport/ScheduleReport'
import { AlarmOnIcon } from 'components/pages/reports/reportList/customizedBDSComponents/AlarmOnIcon'

const BASE_I18N_KEY = 'ess.reports.scheduled_reports'
const BASE_REPORTS_I18N_KEY = 'ess.reports.predefined_report_types'

const ScheduledReports: React.FC = () => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatReportsMessage = useFormatMessage(BASE_REPORTS_I18N_KEY)
  const classes = styles()
  const [state, eventHandlers] = useScheduledReportsLogic()

  return useMemo(
    () => (
      <PageContainer
        isLoading={state.isLoading && !state.isScheduleReportOpen}
        isFailedToLoad={state.isGetScheduledReportsListFailed}
        noPadding
      >
        <Grid className={classes.headerWrapper} alignItems="center" container>
          <Grid alignItems="center" container direction="row">
            <Grid xs item>
              <Grid container direction="row">
                <Typography variant="h1">{formatMessage('title')}</Typography>
              </Grid>
            </Grid>
            <Grid xs item>
              <Grid container direction="row" justifyContent="flex-end">
                <Button color="primary" variant="contained" onClick={() => eventHandlers.onSchedule()}>
                  {formatMessage('new_schedule')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid className={classes.searchTextFieldWrapper} alignItems="center" container direction="row">
            <Grid item xs={2}>
              <TextField
                size="small"
                variant="outlined"
                id="table-search"
                placeholder={formatMessage('search')}
                value={state.search}
                onChange={event => eventHandlers.onSearch(event.target.value)}
                data-testid="search-input"
                className={classes.searchTextField}
                InputProps={{
                  endAdornment: (
                    <IconButton size="small" edge="end" data-id="search-button" data-testid="search-button">
                      <Search />
                    </IconButton>
                  )
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid className={classes.contentWrapper} alignItems="center" container>
          {state.scheduledReports.length === 0 ? (
            <Grid item xs={12} className={classes.noSchedulesContainer}>
              <img src={noSchedules} alt="No Scheduled Reports" className={classes.noSchedulesImage} />
            </Grid>
          ) : (
            state.scheduledReports.map((scheduledReport, index: number) => (
              <Grid key={scheduledReport.id} item className={`${classes.card} ${index !== 0 ? 'spacing' : ''}`} xs={10}>
                <Grid container alignItems="center" justifyContent="space-between">
                  <Grid item className={`${!scheduledReport.enabled ? classes.disabled : ''}`}>
                    <Grid container direction="column">
                      <Typography variant="h4">
                        {formatReportsMessage(scheduledReport.reportId)} | {formatMessage(scheduledReport.format)}
                      </Typography>
                      <Typography className={classes.caption} variant="caption">
                        {formatMessage(scheduledReport.relativeDateRange)}{' '}
                        {formatMessage(scheduledReport.scheduledFrequency)}{' '}
                        {formatMessage('scheduled_time', { time: scheduledReport.at })}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container>
                      <IconButton size="small" onClick={() => eventHandlers.onSchedule(scheduledReport)}>
                        <Edit />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={event => eventHandlers.onOpenDotsMenu(event, scheduledReport)}
                        data-testid="dots-menu-button"
                      >
                        <MenuVertical />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className={`${classes.chipRow} ${!scheduledReport.enabled ? classes.disabledChips : ''}`}>
                  {scheduledReport.formattedRecipients.map(recipient => (
                    <Chip key={recipient} className={classes.chip} size="small" label={recipient} />
                  ))}
                </Grid>
                <Grid className={classes.row} container justifyContent="space-between">
                  <Grid item>
                    {!scheduledReport.enabled && (
                      <Grid container alignItems="center">
                        <AlarmOffIcon className={classes.icon} />
                        <Typography>{formatMessage('disabled')}</Typography>
                      </Grid>
                    )}
                    {scheduledReport.enabled && (
                      <Grid container alignItems="center">
                        <AlarmOnIcon className={classes.bdsIcon} />
                        <Typography variant="body2">{`${formatMessage('next_schedule')} ${
                          scheduledReport.formattedDate
                        } ${formatMessage('at')} ${scheduledReport.formattedHours} ${formatMessage('timezone', {
                          timezone: scheduledReport.timezone
                        })}`}</Typography>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item>
                    {scheduledReport.formattedLastDate && (
                      <Grid container direction="column" alignItems="center">
                        <Typography variant="body2">
                          {scheduledReport.formattedLastDate && scheduledReport.formattedLastHours
                            ? `${formatMessage('last_schedule')} ${scheduledReport.formattedLastDate} ${formatMessage(
                                'at'
                              )} ${scheduledReport.formattedLastHours}`
                            : formatMessage('last_schedule')}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            ))
          )}
        </Grid>
        <Menu
          // keepMounted
          anchorEl={state.anchorEl}
          open={!!state.anchorEl}
          onClose={eventHandlers.onCloseDotsMenu}
          data-testid="dots-menu-items"
        >
          {state.menuItems.map((menuItem, index) => (
            <Grid key={menuItem.label}>
              {menuItem.label === MENU_ITEMS.openReport && <hr className={classes.menuSpacing} />}
              <MenuItem
                className={index > 0 ? classes.menuSpacing : ''}
                component="li"
                data-testid="dots-menu-item-download"
                onClick={menuItem.onClick}
              >
                {menuItem.label === MENU_ITEMS.disableSchedule && <AlarmOffIcon className={classes.icon} />}
                {menuItem.label === MENU_ITEMS.enableSchedule && <AlarmOnIcon className={classes.icon} />}
                {menuItem.label === MENU_ITEMS.deleteSchedule && <Delete className={classes.bdsIcon} />}
                {menuItem.label === MENU_ITEMS.openReport && <Launch className={classes.bdsIcon} />}
                {formatMessage(menuItem.label)}
              </MenuItem>
            </Grid>
          ))}
        </Menu>
        {state.isScheduleReportOpen && (
          <ScheduleReport onClose={eventHandlers.onCloseSchedule} report={state.editItem} />
        )}
      </PageContainer>
    ),
    [formatMessage, formatReportsMessage, classes, state, eventHandlers]
  )
}
export default ScheduledReports
