import { AppNames } from 'config/appConfig'
import { AppTypes } from 'types/AppTypes'
import { FEATURES } from 'lib/splitioFeatures'

export default {
  REPORT_LIST: {
    path: 'reports/list',
    legacyPath: 'reports',
    appAccess: [AppNames.admin],
    authorization: {
      appTypeAccess: [AppTypes.admin]
    },
    metadata: {
      sideMenuId: 'REPORTS',
      splitIoFlags: [FEATURES.EGD_React_Reporting_Rollout]
    }
  },

  SCHEDULED_REPORTS: {
    path: 'reports/scheduled',
    appAccess: [AppNames.admin],
    authorization: {
      appTypeAccess: [AppTypes.admin]
    },
    metadata: {
      sideMenuId: 'REPORTS',
      splitIoFlags: [FEATURES.EGD_React_Reporting_Rollout, FEATURES.EGD_React_Scheduled_Reports]
    }
  }
}
