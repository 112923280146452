import { Box, Button, FormControlLabel, Radio, Typography, useTokens } from '@barracuda-internal/bds-core-v2'
import React, { useMemo } from 'react'
import { DeploymentSetupOptions } from '../../../types/egdWizardTypes'

interface RadioBoxProps {
  radioLabel: string
  radioValue: DeploymentSetupOptions
  title: string
  subtitle: string
  buttonText: string
  disabled?: boolean
  bold?: boolean
}

const RadioBox: React.FC<RadioBoxProps> = ({
  radioLabel,
  radioValue,
  title,
  subtitle,
  buttonText,
  bold = false,
  disabled = false
}) => {
  const tokens = useTokens()

  return useMemo(
    () => (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: '1 1 0px',
          minWidth: 232,
          border: '1px solid',
          borderColor: tokens.aliasesColorBorderInteractiveContainerNeutralDefault,
          borderRadius: tokens.aliasesBorderRadiusDefault,
          padding: tokens.aliasesSpaceContainerDefault,
          gap: tokens.aliasesSpaceContainerDense,
          pointerEvents: disabled ? 'none' : '',
          opacity: disabled ? 0.5 : 1
        }}
      >
        <FormControlLabel
          control={<Radio disabled={disabled} bdsSize="small" />}
          variant="toggle"
          label={radioLabel}
          value={radioValue}
        />
        <Typography
          sx={{
            paddingLeft: tokens.aliasesSpaceContainerSpacious,
            fontFamily: tokens.aliasesFontFamilyDefault,
            fontSize: tokens.aliasesFontSizeDefault,
            fontWeight: bold ? tokens.aliasesFontWeightEmphasis : tokens.aliasesFontWeightDefault,
            color: tokens.aliasesColorTextStaticEmphasis,
            lineHeight: tokens.aliasesLineHeightDefault,
            letterSpacing: tokens.aliasesLetterSpacingDefault
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            paddingLeft: tokens.aliasesSpaceContainerSpacious,
            fontFamily: tokens.aliasesFontFamilyDefault,
            fontSize: tokens.aliasesFontSizeCaption,
            fontWeight: tokens.aliasesFontWeightEmphasis,
            color: tokens.aliasesColorTextStaticSubtle,
            lineHeight: tokens.aliasesLineHeightCaption,
            letterSpacing: tokens.aliasesLetterSpacingDefault
          }}
        >
          {subtitle}
        </Typography>
        <Button
          onClick={() => window.open('https://campus.barracuda.com/doc/176259752/', '_blank')}
          bdsType="interactiveSubtle"
          sx={{ alignSelf: 'flex-end' }}
        >
          {buttonText}
        </Button>
      </Box>
    ),
    [tokens, radioLabel, radioValue, title, subtitle, buttonText, bold, disabled]
  )
}

export default RadioBox
