import { configureStore } from '@reduxjs/toolkit'
import appReducers from 'redux/reducers'
import appConfig from 'config/appConfig'
import { SearchSettingsRepositoryFactory } from 'lib/services/searchSettings/SearchSettingsRepositoryFactory'
import { SearchSettingsServiceFactory } from 'lib/services/searchSettings/SearchSettingsServiceFactory'

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any
  }
}

/* eslint-disable no-underscore-dangle */
const isDevtoolsEnabled = !!(
  appConfig.DEBUG_MODE &&
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
)

const searchSettingsRepositoryFactory = SearchSettingsRepositoryFactory()
const searchSettingsServiceFactory = SearchSettingsServiceFactory(searchSettingsRepositoryFactory)

const services = {
  searchSettingsService: searchSettingsServiceFactory
}
type Services = typeof services
export type Extra = {
  services: Services
}

export type AsyncThunkConfig<T = string> = {
  rejectValue: T
  extra: Extra
}
/* eslint-enable no-underscore-dangle */

const store = configureStore({
  reducer: appReducers as any,
  devTools: isDevtoolsEnabled,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: {
          services: {
            searchSettingsService: searchSettingsServiceFactory
          }
        }
      }
    })
})

export default store
