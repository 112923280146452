import React, { PropsWithChildren, useMemo, ReactNode } from 'react'
import { Box } from '@barracuda-internal/bds-core-v2'

const StretchPanelHeader: React.FC<PropsWithChildren> = ({ children }) =>
  useMemo(() => <Box sx={{ flex: 'none', display: 'flex', flexDirection: 'column' }}>{children}</Box>, [children])

const StretchPanelContent: React.FC<PropsWithChildren> = ({ children }) =>
  useMemo(
    () => <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'auto' }}>{children}</Box>,
    [children]
  )

const StretchPanelFooter: React.FC<PropsWithChildren> = ({ children }) =>
  useMemo(() => <Box sx={{ flex: 'none', display: 'flex', flexDirection: 'column' }}>{children}</Box>, [children])

interface StretchPanelProps {
  children: {
    header?: ReactNode
    content: ReactNode
    footer?: ReactNode
  }
}

const StretchPanel: React.FC<StretchPanelProps> = ({ children }) =>
  useMemo(
    () => (
      <Box
        sx={{
          display: 'flex',
          flexGrow: 1,
          flexDirection: 'column',
          justifyContent: 'space-between',
          overflow: 'hidden'
        }}
      >
        {children.header && <StretchPanelHeader>{children.header}</StretchPanelHeader>}
        <StretchPanelContent>{children.content}</StretchPanelContent>
        {children.footer && <StretchPanelFooter>{children.footer}</StretchPanelFooter>}
      </Box>
    ),
    [children.content, children.footer, children.header]
  )

export default StretchPanel
