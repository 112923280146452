import React, { useMemo } from 'react'
import { Alert } from '@barracuda-internal/bds-core'
import styles from 'components/libs/banner/BannerStyles'
import { useFormatMessage } from 'lib/localization'
import { formatDate } from 'lib/datetime'
import config from 'config/appConfig'

const BASE_I18N_KEY = 'ess.global_message_banner'

export interface TrialExpiringBannerProps {
  trialDaysRemaining: number
  expiresAt: number
}

const TrialExpiringBanner: React.FC<TrialExpiringBannerProps> = ({ trialDaysRemaining, expiresAt }) => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  const params = useMemo(() => {
    const expiresAtMs = expiresAt * 1000
    const expiryDate = formatDate(new Date(expiresAtMs), config.DATETIME.DASH_SEPARATED_DATE_WITHOUT_TIME)
    return {
      trial_days_remaining: trialDaysRemaining,
      expiry_date: expiryDate
    }
  }, [expiresAt, trialDaysRemaining])

  return useMemo(
    () => (
      <Alert className={classes.alert} severity="warning">
        {formatMessage('trial_expiring.text', params)}
      </Alert>
    ),
    [classes.alert, formatMessage, params]
  )
}

export default TrialExpiringBanner
