import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ApiStatus, failedResponse, inIdle, inProgress, successResponse } from 'redux/toolkit/api'

import {
  detectEmailServer,
  getEmailServerSettings,
  getMxRecords,
  getOldMxRecords,
  getProvider,
  setEmailServerSettings,
  setRegion,
  saveSetupFlowStep,
  verifyEmailServer,
  addMailServer,
  removeMailServer,
  updateMailServer,
  testMailServer,
  saveDeploymentMethod,
  getIpirScanStatus,
  saveSetupFlowVersion
} from 'redux/features/emailServer/emailServerApiThunks'
import {
  EmailServerConfig,
  EmailServerResult,
  EmailServerSettings,
  MxRecords,
  OldMxRecords,
  Provider
} from 'types/emailServer'
import { DeploymentSetupOptions, EmailFlowSteps, SetupFlowSteps } from 'components/pages/setupFlow/types/egdWizardTypes'
import { CountryData } from 'lib/isoCountries'

interface VerifyDomainState {
  isEmailInputError: boolean
  isMissedServerDetection: boolean
  isMissedServerVerify: boolean
  isEmailServerConfigError: boolean
}

interface EmailFlowState {
  newRecordsError: string | undefined
  oldRecordsError: string | undefined
  validatedSections: EmailFlowSteps[]
}

export interface EmailServerState {
  emailAddress: string
  detectEmailServerApiStatus: ApiStatus
  emailServer: EmailServerResult | undefined
  updatedEmailServer: EmailServerResult | undefined
  verifyEmailServerApiStatus: ApiStatus
  getProviderApiStatus: ApiStatus
  provider: Provider[] | undefined
  getMxRecordsApiStatus: ApiStatus
  mxRecords: MxRecords | undefined
  getOldMxRecordsApiStatus: ApiStatus
  oldMxRecords: OldMxRecords | undefined
  getEmailServerSettingsApiStatus: ApiStatus
  getIpirScanStatusApiStatus: ApiStatus
  emailServerSettings: EmailServerSettings | undefined
  setEmailServerSettingsApiStatus: ApiStatus
  setRegionApiStatus: ApiStatus
  saveSetupFlowStepApiStatus: ApiStatus
  saveSetupFlowVersionApiStatus: ApiStatus
  saveDeploymentMethodApiStatus: ApiStatus
  addMailServerApiStatus: ApiStatus
  removeMailServerApiStatus: ApiStatus
  updateMailServerApiStatus: ApiStatus
  testMailServerApiStatus: ApiStatus
  smtpErrorDetails: string | undefined

  setupFlowVersion: 'v1' | 'v2' | undefined
  setupFlowStep: SetupFlowSteps
  deploymentSetup: DeploymentSetupOptions
  setupFlowError: string | undefined
  ipirScanStatus: boolean
  selectedRegion: CountryData | undefined
  selectedRegionError: boolean

  verifyDomainState: VerifyDomainState
  emailFlowState: EmailFlowState
}

// initialState
export const INITIAL_STATE: EmailServerState = {
  emailAddress: '',
  detectEmailServerApiStatus: inIdle,
  emailServer: undefined,
  updatedEmailServer: undefined,
  verifyEmailServerApiStatus: inIdle,
  getProviderApiStatus: inIdle,
  provider: undefined,
  getMxRecordsApiStatus: inIdle,
  mxRecords: undefined,
  getOldMxRecordsApiStatus: inIdle,
  getIpirScanStatusApiStatus: inIdle,
  oldMxRecords: undefined,
  getEmailServerSettingsApiStatus: inIdle,
  emailServerSettings: undefined,
  setEmailServerSettingsApiStatus: inIdle,
  setRegionApiStatus: inIdle,
  saveSetupFlowStepApiStatus: inIdle,
  saveSetupFlowVersionApiStatus: inIdle,
  saveDeploymentMethodApiStatus: inIdle,
  addMailServerApiStatus: inIdle,
  removeMailServerApiStatus: inIdle,
  updateMailServerApiStatus: inIdle,
  testMailServerApiStatus: inIdle,
  smtpErrorDetails: undefined,

  setupFlowVersion: undefined,
  setupFlowStep: SetupFlowSteps.introduction,
  deploymentSetup: DeploymentSetupOptions.mxRecord,
  setupFlowError: undefined,
  ipirScanStatus: false,
  selectedRegion: undefined,
  selectedRegionError: false,
  verifyDomainState: {
    isEmailInputError: false,
    isMissedServerDetection: false,
    isMissedServerVerify: false,
    isEmailServerConfigError: false
  },
  emailFlowState: {
    newRecordsError: undefined,
    oldRecordsError: undefined,
    validatedSections: []
  }
}

/* eslint-disable no-param-reassign */
export function splitEmailSettings(state: EmailServerState, newSettings: EmailServerSettings) {
  state.emailServerSettings = newSettings
  state.emailAddress = newSettings.email || INITIAL_STATE.emailAddress
  if (newSettings.primaryMx && newSettings.backupMx) {
    state.mxRecords = {
      primary: newSettings.primaryMx,
      backup: newSettings.backupMx
    }
  }
  state.emailServer = newSettings.emailServers?.[0] || INITIAL_STATE.emailServer
  state.provider = newSettings.provider || INITIAL_STATE.provider
  state.oldMxRecords = newSettings.oldMxRecords || INITIAL_STATE.oldMxRecords
  state.setupFlowStep = newSettings.setupFlowStep || INITIAL_STATE.setupFlowStep
}

export const emailServerSlice = createSlice({
  name: 'EMAIL_SERVER',
  initialState: INITIAL_STATE,
  reducers: {
    setSetupFlowStep: (state, action: PayloadAction<SetupFlowSteps>) => {
      state.setupFlowStep = action.payload
    },
    setDeploymentSetup: (state, action: PayloadAction<DeploymentSetupOptions>) => {
      state.deploymentSetup = action.payload
    },
    setSelectedRegion: (state, action: PayloadAction<CountryData>) => {
      state.selectedRegion = action.payload
      state.selectedRegionError = false
    },
    setSelectedRegionError: (state, action: PayloadAction<boolean>) => {
      state.selectedRegionError = action.payload
    },
    setIsEmailInputError: (state, action: PayloadAction<boolean>) => {
      state.verifyDomainState.isEmailInputError = action.payload
    },
    setIsMissedServerDetection: (state, action: PayloadAction<boolean>) => {
      state.verifyDomainState.isMissedServerDetection = action.payload
    },
    setIsMissedServerVerify: (state, action: PayloadAction<boolean>) => {
      state.verifyDomainState.isMissedServerVerify = action.payload
    },
    setIsEmailServerConfigError: (state, action: PayloadAction<boolean>) => {
      state.verifyDomainState.isEmailServerConfigError = action.payload
    },
    setEmailAddress: (state, action: PayloadAction<string>) => {
      state.emailAddress = action.payload
      if (state.emailServer) state.emailServer.verified = false
      if (state.updatedEmailServer) state.updatedEmailServer.verified = false
    },
    setNewMxRecordsError: (state, action: PayloadAction<string | undefined>) => {
      state.emailFlowState.newRecordsError = action.payload
    },
    setOldMxRecordsError: (state, action: PayloadAction<string | undefined>) => {
      state.emailFlowState.oldRecordsError = action.payload
    },
    setVelidatedSections: (state, action: PayloadAction<EmailFlowSteps[]>) => {
      state.emailFlowState.validatedSections = action.payload
    },
    setUpdatedEmailServer: (state, action: PayloadAction<EmailServerConfig | undefined>) => {
      state.updatedEmailServer = action.payload
    },
    resetSetupFlowError: state => {
      state.setupFlowError = INITIAL_STATE.setupFlowError
    },
    resetEmailServer: state => {
      state.detectEmailServerApiStatus = INITIAL_STATE.detectEmailServerApiStatus
      state.emailServer = INITIAL_STATE.emailServer
      state.updatedEmailServer = INITIAL_STATE.updatedEmailServer
    },
    resetVerifyEmailServer: (state, action: PayloadAction<boolean | undefined>) => {
      state.verifyEmailServerApiStatus = INITIAL_STATE.verifyEmailServerApiStatus
      if (state.emailServer && action.payload) state.emailServer.verified = false
    },
    resetProvider: state => {
      state.getProviderApiStatus = INITIAL_STATE.getProviderApiStatus
      state.provider = INITIAL_STATE.provider
    },
    resetMxRecords: state => {
      state.getMxRecordsApiStatus = INITIAL_STATE.getMxRecordsApiStatus
      state.mxRecords = INITIAL_STATE.mxRecords
    },
    resetOldMxRecords: state => {
      state.getOldMxRecordsApiStatus = INITIAL_STATE.getOldMxRecordsApiStatus
      state.oldMxRecords = INITIAL_STATE.oldMxRecords
    },
    resetEmailServerSettings: state => {
      state.getEmailServerSettingsApiStatus = INITIAL_STATE.getEmailServerSettingsApiStatus
      state.setEmailServerSettingsApiStatus = INITIAL_STATE.setEmailServerSettingsApiStatus
      state.emailServerSettings = INITIAL_STATE.emailServerSettings
    },
    resetRegion: state => {
      state.setRegionApiStatus = INITIAL_STATE.setRegionApiStatus
    },
    reset: () => ({
      ...INITIAL_STATE
    }),
    resetAddEmailServer: state => {
      state.addMailServerApiStatus = INITIAL_STATE.addMailServerApiStatus
    },
    resetRemoveEmailServer: state => {
      state.removeMailServerApiStatus = INITIAL_STATE.removeMailServerApiStatus
    },
    resetUpdateEmailServer: state => {
      state.updateMailServerApiStatus = INITIAL_STATE.updateMailServerApiStatus
    },
    resetTestEmailServer: state => {
      state.testMailServerApiStatus = INITIAL_STATE.testMailServerApiStatus
      state.smtpErrorDetails = INITIAL_STATE.smtpErrorDetails
    }
  },
  extraReducers: builder => {
    builder
      // detectEmailServer
      .addCase(detectEmailServer.pending, state => {
        state.detectEmailServerApiStatus = inProgress
        state.emailServer = INITIAL_STATE.emailServer
        state.updatedEmailServer = INITIAL_STATE.updatedEmailServer
      })
      .addCase(detectEmailServer.fulfilled, (state, action) => {
        state.detectEmailServerApiStatus = successResponse
        state.emailServer = action.payload
      })
      .addCase(detectEmailServer.rejected, (state, action) => {
        state.detectEmailServerApiStatus = failedResponse(action.payload)
      })

      // verifyEmailServer
      .addCase(verifyEmailServer.pending, state => {
        state.verifyEmailServerApiStatus = inProgress
      })
      .addCase(verifyEmailServer.fulfilled, (state, action) => {
        state.verifyEmailServerApiStatus = successResponse
        if (action.payload) {
          state.emailServer = { ...action.payload, verified: true }
        }
      })
      .addCase(verifyEmailServer.rejected, (state, action) => {
        state.verifyEmailServerApiStatus = failedResponse(action.payload)
      })

      // getProvider
      .addCase(getProvider.pending, state => {
        state.getProviderApiStatus = inProgress
        state.provider = INITIAL_STATE.provider
      })
      .addCase(getProvider.fulfilled, (state, action) => {
        state.getProviderApiStatus = successResponse
        state.provider = action.payload
      })
      .addCase(getProvider.rejected, (state, action) => {
        state.getProviderApiStatus = failedResponse(action.payload)
      })

      // getMxRecords
      .addCase(getMxRecords.pending, state => {
        state.getMxRecordsApiStatus = inProgress
        state.mxRecords = INITIAL_STATE.mxRecords
      })
      .addCase(getMxRecords.fulfilled, (state, action) => {
        state.getMxRecordsApiStatus = successResponse
        state.mxRecords = action.payload
      })
      .addCase(getMxRecords.rejected, (state, action) => {
        state.getMxRecordsApiStatus = failedResponse(action.payload)
      })

      // getOldMxRecords
      .addCase(getOldMxRecords.pending, state => {
        state.getOldMxRecordsApiStatus = inProgress
        state.oldMxRecords = INITIAL_STATE.oldMxRecords
      })
      .addCase(getOldMxRecords.fulfilled, (state, action) => {
        state.getOldMxRecordsApiStatus = successResponse
        state.oldMxRecords = action.payload
      })
      .addCase(getOldMxRecords.rejected, (state, action) => {
        state.getOldMxRecordsApiStatus = failedResponse(action.payload)
      })

      // getEmailServerSettings
      .addCase(getEmailServerSettings.pending, state => {
        state.getEmailServerSettingsApiStatus = inProgress
      })
      .addCase(getEmailServerSettings.fulfilled, (state, action) => {
        state.getEmailServerSettingsApiStatus = successResponse
        splitEmailSettings(state, action.payload)
      })
      .addCase(getEmailServerSettings.rejected, (state, action) => {
        state.getEmailServerSettingsApiStatus = failedResponse(action.payload)
      })

      // getScanStatus
      .addCase(getIpirScanStatus.pending, state => {
        state.getIpirScanStatusApiStatus = inProgress
      })
      .addCase(getIpirScanStatus.fulfilled, (state, action) => {
        state.getIpirScanStatusApiStatus = successResponse
        state.ipirScanStatus = action.payload.onboarding_completed
      })
      .addCase(getIpirScanStatus.rejected, (state, action) => {
        state.getIpirScanStatusApiStatus = failedResponse(action.payload)
        state.setupFlowError = action.payload
      })

      // setEmailServerSettings
      .addCase(setEmailServerSettings.pending, state => {
        state.setEmailServerSettingsApiStatus = inProgress
      })
      .addCase(setEmailServerSettings.fulfilled, (state, action) => {
        state.setEmailServerSettingsApiStatus = successResponse
        // When wizard is completed the set email server settings returns only value for the 'wizardCompleted'
        // We do not want to remove the latest state and reset it so we just update the 'wizardCompleted' field
        if (action.payload.wizardCompleted && state.emailServerSettings) {
          state.emailServerSettings.wizardCompleted = action.payload.wizardCompleted
        } else {
          splitEmailSettings(state, action.payload)
        }
      })
      .addCase(setEmailServerSettings.rejected, (state, action) => {
        state.setEmailServerSettingsApiStatus = failedResponse(action.payload)
      })

      // setRegion
      .addCase(setRegion.pending, state => {
        state.setRegionApiStatus = inProgress
      })
      .addCase(setRegion.fulfilled, (state, action) => {
        state.setRegionApiStatus = successResponse
        splitEmailSettings(state, action.payload)
      })
      .addCase(setRegion.rejected, (state, action) => {
        state.setRegionApiStatus = failedResponse(action.payload)
      })

      // setSetupFlowStep
      .addCase(saveSetupFlowStep.pending, state => {
        state.saveSetupFlowStepApiStatus = inProgress
      })
      .addCase(saveSetupFlowStep.fulfilled, (state, action) => {
        state.saveSetupFlowStepApiStatus = successResponse
        state.setupFlowStep = action.payload.setupFlowStep || INITIAL_STATE.setupFlowStep
      })
      .addCase(saveSetupFlowStep.rejected, (state, action) => {
        state.saveSetupFlowStepApiStatus = failedResponse(action.payload)
        state.setupFlowError = action.payload
      })

      // saveSetupFlowVersion
      .addCase(saveSetupFlowVersion.pending, state => {
        state.saveSetupFlowVersionApiStatus = inProgress
      })
      .addCase(saveSetupFlowVersion.fulfilled, (state, action) => {
        state.saveSetupFlowVersionApiStatus = successResponse
        state.setupFlowVersion = action.payload.version || INITIAL_STATE.setupFlowVersion
      })
      .addCase(saveSetupFlowVersion.rejected, (state, action) => {
        state.saveSetupFlowVersionApiStatus = failedResponse(action.payload)
        state.setupFlowError = action.payload
      })

      // saveDeploymentMethod
      .addCase(saveDeploymentMethod.pending, state => {
        state.saveDeploymentMethodApiStatus = inProgress
      })
      .addCase(saveDeploymentMethod.fulfilled, (state, action) => {
        state.saveDeploymentMethodApiStatus = successResponse
        splitEmailSettings(state, action.payload)
      })
      .addCase(saveDeploymentMethod.rejected, (state, action) => {
        state.saveDeploymentMethodApiStatus = failedResponse(action.payload)
        state.setupFlowError = action.payload
      })

      // addMailServer
      .addCase(addMailServer.pending, state => {
        state.addMailServerApiStatus = inProgress
      })
      .addCase(addMailServer.fulfilled, state => {
        state.addMailServerApiStatus = successResponse
      })
      .addCase(addMailServer.rejected, (state, action) => {
        state.addMailServerApiStatus = failedResponse(action.payload)
      })

      // removeMailServer
      .addCase(removeMailServer.pending, state => {
        state.removeMailServerApiStatus = inProgress
      })
      .addCase(removeMailServer.fulfilled, state => {
        state.removeMailServerApiStatus = successResponse
      })
      .addCase(removeMailServer.rejected, (state, action) => {
        state.removeMailServerApiStatus = failedResponse(action.payload)
      })

      // updateMailServer
      .addCase(updateMailServer.pending, state => {
        state.updateMailServerApiStatus = inProgress
      })
      .addCase(updateMailServer.fulfilled, state => {
        state.updateMailServerApiStatus = successResponse
      })
      .addCase(updateMailServer.rejected, (state, action) => {
        state.updateMailServerApiStatus = failedResponse(action.payload)
      })

      // testMailServer
      .addCase(testMailServer.pending, state => {
        state.testMailServerApiStatus = inProgress
        state.smtpErrorDetails = INITIAL_STATE.smtpErrorDetails
      })
      .addCase(testMailServer.fulfilled, state => {
        state.testMailServerApiStatus = successResponse
        state.smtpErrorDetails = INITIAL_STATE.smtpErrorDetails
      })
      .addCase(testMailServer.rejected, (state, action) => {
        state.testMailServerApiStatus = failedResponse(action.payload)
        state.smtpErrorDetails = action.payload
      })
  }
})
/* eslint-enable no-param-reassign */

export const {
  setSetupFlowStep,
  setDeploymentSetup,
  setEmailAddress,
  setUpdatedEmailServer,
  setSelectedRegion,
  setSelectedRegionError,
  setIsEmailInputError,
  setIsMissedServerDetection,
  setIsMissedServerVerify,
  setIsEmailServerConfigError,
  setNewMxRecordsError,
  setOldMxRecordsError,
  setVelidatedSections,
  resetSetupFlowError,
  resetEmailServer,
  resetVerifyEmailServer,
  resetProvider,
  resetMxRecords,
  resetOldMxRecords,
  resetEmailServerSettings,
  resetRegion,
  reset,
  resetAddEmailServer,
  resetRemoveEmailServer,
  resetUpdateEmailServer,
  resetTestEmailServer
} = emailServerSlice.actions

export {
  detectEmailServer,
  verifyEmailServer,
  getProvider,
  getMxRecords,
  getOldMxRecords,
  getEmailServerSettings,
  getIpirScanStatus,
  setEmailServerSettings,
  setRegion,
  saveSetupFlowStep,
  saveSetupFlowVersion,
  saveDeploymentMethod,
  addMailServer,
  removeMailServer,
  updateMailServer,
  testMailServer
}

export default emailServerSlice.reducer
