import React, { useMemo } from 'react'

import { Typography, Box, useTokens } from '@barracuda-internal/bds-core-v2'
import { useFormatMessage } from 'lib/localization'

import { EmailFlowSteps } from 'components/pages/setupFlow/types/egdWizardTypes'
import AddNewMxRecords from 'components/pages/setupFlow/views/emailFlow/sections/AddNewMxRecords'
import RemoveOldMxRecords from 'components/pages/setupFlow/views/emailFlow/sections/RemoveOldMxRecords'
import SetupFlowStepsContentContainer from '../../layout/SetupFlowStepsContentContainer'
import ContentTitleComponent from '../../components/ContentTitleComponent'

const BASE_I18N_KEY = 'ess_wizard.email_flow'

const EmailFlow = () => {
  const tokens = useTokens()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  return useMemo(
    () => (
      <SetupFlowStepsContentContainer>
        <ContentTitleComponent title={formatMessage('title')} subtitle={formatMessage('subtitle')} />
        {Object.values(EmailFlowSteps).map((sectionName, index) => (
          <Box
            key={sectionName}
            sx={{
              padding: tokens.aliasesSpaceContainerDefault
            }}
          >
            <Typography
              sx={{
                fontFamily: tokens.aliasesFontFamilyDefault,
                fontSize: tokens.aliasesFontSizeDefault,
                fontWeight: tokens.aliasesFontWeightEmphasis,
                color: tokens.aliasesColorTextStaticEmphasis,
                lineHeight: tokens.aliasesLineHeightDefault
              }}
            >
              {formatMessage(`section_${index + 1}_title`)}
            </Typography>
            <Typography
              sx={{
                fontFamily: tokens.aliasesFontFamilyDefault,
                fontSize: tokens.aliasesFontSizeDefault,
                fontWeight: tokens.aliasesFontWeightDefault,
                color: tokens.aliasesColorTextStaticEmphasis,
                lineHeight: tokens.aliasesLineHeightDefault
              }}
            >
              {formatMessage(`section_${index + 1}_subtitle`)}
            </Typography>
            <Box>
              {sectionName === EmailFlowSteps.addNewMxRecords && <AddNewMxRecords />}
              {sectionName === EmailFlowSteps.removeOldMxRecords && <RemoveOldMxRecords />}
            </Box>
          </Box>
        ))}
      </SetupFlowStepsContentContainer>
    ),
    [tokens, formatMessage]
  )
}

export default EmailFlow
