import { useCallback, useEffect, useMemo, useState } from 'react'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { isFailed, isPending, isSuccess } from 'redux/toolkit/api'
import { DomainsInDB } from 'types/redux/user/UserTypes'
import { DashboardRange } from 'types/stats'
import { getAvailableDomains } from 'redux/features/user/userSlice'
import { isoCountries } from 'lib/isoCountries'
import { getDashboardStats, reset } from 'redux/features/stats/statsSlice'
import { useOverviewRights } from 'components/libs/userRights/pages/useOverviewRights'
import { TRACKING_EVENTS, trackEventInAllServices } from 'lib/monitoring/monitoringService'
import { isMyFeatureOn } from 'lib/splitio'
import { FEATURES } from 'lib/splitioFeatures'

export enum TimeRanges {
  'last24Hours' = 'last_24_hours',
  'last30Days' = 'last_30_days'
}

export const ALL_DOMAINS = 'all'

export type GeoDataChartObject = {
  id: string
  value: number
}

export type GeoDataChartValues = {
  data: GeoDataChartObject[] | undefined
  minCount: number
  maxCount: number
}

export type Footer = {
  accountId: string
  essAccountId: string
  serialNumber: string
}

export interface State {
  shouldShowAllDomainsInSelector: boolean
  selectedTimeRange: TimeRanges
  selectedDomain: DomainsInDB | undefined
  isDomainSelectorDisabled: boolean
  availableDomains: DomainsInDB[] | undefined
  isGetAvailableDomainsInProgress: boolean
  isGetAvailableDomainsFailed: boolean
  geoData: GeoDataChartValues | undefined
  isGetGeoDataStatsInProgress: boolean
  isGetGeoDataStatsFailed: boolean
  isOutboundStatisticsVisible: boolean
  range: DashboardRange
  footerConfig: Footer
  showThreatOrigins: boolean
}

export interface EventHandlers {
  onSelectTimeRange: (e: React.ChangeEvent<{ value: unknown }>) => void
  onSelectDomain: (e: React.ChangeEvent<{ value: unknown }>) => void
}

export type UseDashboardLogic = [State, EventHandlers]

export const useDashboardLogic = (): UseDashboardLogic => {
  const dispatch = useAppDispatch()

  const {
    accessTokenObject,
    availableDomains,
    isGetAvailableDomainsInProgress,
    isGetAvailableDomainsSuccess,
    isGetAvailableDomainsFailed,
    geoData,
    isGetGeoDataStatsInProgress,
    isGetGeoDataStatsFailed
  } = useAppSelector(_store => ({
    accessTokenObject: _store.auth.accessTokenObject,
    availableDomains: _store.user.availableDomains,
    isGetAvailableDomainsInProgress: isPending(_store.user.api.getAvailableDomainsApiStatus),
    isGetAvailableDomainsSuccess: isSuccess(_store.user.api.getAvailableDomainsApiStatus),
    isGetAvailableDomainsFailed: isFailed(_store.user.api.getAvailableDomainsApiStatus),
    dashboardFilters: _store.stats.dashboardFilters,
    geoData: _store.stats.geoData,
    isGetGeoDataStatsInProgress: isPending(_store.stats.api.getGeoDataStats),
    isGetGeoDataStatsFailed: isFailed(_store.stats.api.getGeoDataStats)
  }))
  const [selectedTimeRange, setSelectedTimeRange] = useState<TimeRanges>(TimeRanges.last30Days)
  const [selectedDomain, setSelectedDomain] = useState<DomainsInDB | undefined>()
  const showThreatOrigins = isMyFeatureOn(FEATURES.EGD_React_Admin_Threat_Origins)
  const {
    canViewOutboundStatistics,
    helpers: { isPdDomainIdSet }
  } = useOverviewRights()

  // init
  useEffect(() => {
    dispatch(getAvailableDomains())
    trackEventInAllServices(TRACKING_EVENTS.WEBUI.DASHBOARD_VIEW)
    // eslint-disable-next-line
  }, [])

  // initial domains list is arrived and pdDomain is set
  useEffect(() => {
    if (isGetAvailableDomainsSuccess) {
      if (!!availableDomains?.length && !selectedDomain) {
        let initialDomain: DomainsInDB | undefined
        // pd-domain-id is set
        if (accessTokenObject?.pdDomainId) {
          initialDomain = availableDomains.find(domain => domain.domainId === accessTokenObject.pdDomainId)
          // account has only one domain
        } else if (availableDomains.length === 1) {
          // eslint-disable-next-line prefer-destructuring
          initialDomain = availableDomains[0]
        }

        if (initialDomain) setSelectedDomain(initialDomain)
        // getStats(initialDomain?.domainId)
      }
    }
  }, [isGetAvailableDomainsSuccess, availableDomains, selectedDomain, accessTokenObject, selectedTimeRange])

  useEffect(() => {
    const range = selectedTimeRange === TimeRanges.last24Hours ? DashboardRange.last24Hours : DashboardRange.last30Days
    dispatch(getDashboardStats({ domainId: selectedDomain?.domainId, range, showThreatOrigins }))
  }, [dispatch, selectedDomain, selectedTimeRange, showThreatOrigins])

  // unmount
  useEffect(
    () => () => {
      dispatch(reset())
    },
    [dispatch]
  )

  const geoDataChartValues = useMemo(() => {
    const chartValues: GeoDataChartValues = {
      data: undefined,
      minCount: 0,
      maxCount: 0
    }
    if (!geoData) {
      return chartValues
    }

    return Object.entries(geoData.results).reduce(
      (all: GeoDataChartValues, [country, result]) => ({
        data: [...(all.data || []), { id: isoCountries.alpha2ToAlpha3(country), value: result.count, code2: country }],
        minCount: 0,
        maxCount: Math.max(all.maxCount, result.count)
      }),
      chartValues
    )
  }, [geoData])

  const footerConfig: State['footerConfig'] = useMemo(
    () => ({
      accountId: accessTokenObject?.bccAccountId || '',
      essAccountId: accessTokenObject?.accountId || '',
      serialNumber: accessTokenObject?.serial || '',
      // TODO: Add version information
      version: ''
    }),
    [accessTokenObject]
  )

  const onSelectTimeRange: EventHandlers['onSelectTimeRange'] = useCallback(e => {
    setSelectedTimeRange(e.target.value as TimeRanges)
  }, [])

  const onSelectDomain: EventHandlers['onSelectDomain'] = useCallback(
    e => {
      const selectedDomainObject = availableDomains?.find(domainData => domainData.domainId === e.target.value)
      setSelectedDomain(selectedDomainObject)
    },
    [availableDomains]
  )

  return useMemo(
    () => [
      {
        shouldShowAllDomainsInSelector: !!availableDomains && availableDomains.length > 1,
        selectedTimeRange,
        selectedDomain,
        isDomainSelectorDisabled: isPdDomainIdSet,
        availableDomains,
        isGetAvailableDomainsInProgress,
        isGetAvailableDomainsFailed,
        geoData: geoDataChartValues,
        isGetGeoDataStatsInProgress,
        isGetGeoDataStatsFailed,
        isOutboundStatisticsVisible: canViewOutboundStatistics,
        range: selectedTimeRange === TimeRanges.last24Hours ? DashboardRange.last24Hours : DashboardRange.last30Days,
        footerConfig,
        showThreatOrigins
      },
      { onSelectTimeRange, onSelectDomain }
    ],
    [
      isPdDomainIdSet,
      selectedDomain,
      selectedTimeRange,
      availableDomains,
      isGetAvailableDomainsInProgress,
      canViewOutboundStatistics,
      isGetAvailableDomainsFailed,
      geoDataChartValues,
      isGetGeoDataStatsInProgress,
      isGetGeoDataStatsFailed,
      onSelectTimeRange,
      onSelectDomain,
      footerConfig,
      showThreatOrigins
    ]
  )
}
