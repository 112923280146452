import { colors, makeStyles } from '@barracuda-internal/bds-core'

export default makeStyles(theme => ({
  snackBar: {
    marginLeft: theme.spacing(3),
    marginBottom: 32,
    borderRadius: 32,
    boxShadow: `5px 10px 18px ${colors.scaleGray200}`
  },
  navSubMenu: {
    '& > :nth-child(2)': {
      height: '100%'
    },
    '& > :nth-child(2) > div > :nth-child(2)': {
      height: 'calc(100% - 62px)'
    }
  },
  optOut: {
    margin: theme.spacing(1)
  },
  circularIcon: {
    height: '100% !important',
    width: '100% !important'
  },
  iconButton: {
    '& svg': {
      fontSize: '16px !important'
    }
  }
}))
