import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { trim } from 'lodash'
import routesConfig, { gotoAdminEntryPathFromWizard, gotoEnduserEntryPathFromWizard } from 'lib/routesConfig'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import {
  resetSetupFlowError,
  saveDeploymentMethod,
  saveSetupFlowStep,
  saveSetupFlowVersion,
  setEmailServerSettings,
  setIsEmailInputError,
  setIsMissedServerDetection,
  setIsMissedServerVerify,
  setNewMxRecordsError,
  setOldMxRecordsError,
  setRegion,
  setSelectedRegionError
} from 'redux/features/emailServer/emailServerSlice'
import { isMyFeatureOn } from 'lib/splitio'
import { FEATURES } from 'lib/splitioFeatures'
import { isPending, isSuccess } from 'redux/toolkit/api'
import { isEmailValid } from 'lib/validation'
import appFeatures from 'config/appFeatures'
import { UserRights, useUserRights } from 'components/pages/onboardWizard/onboardWizardLogicUserRights'
import appConfig from 'config/appConfig'
import { setIntercomUser } from 'lib/monitoring/monitoringService'
import { useErrorParam } from '../login/useErrorParam'
import { SetupFlowSteps } from './types/egdWizardTypes'

export interface StepInfo {
  name: string
  skipped?: boolean
  active?: boolean
}

export interface State {
  errors: string[]
  currentStep: SetupFlowSteps
  stepInProgress: boolean
  loading: boolean
  nextDisabled: boolean
  steps: StepInfo[]
}

export interface EventHandlers {
  onExit: () => void
  onNext: () => void
}

export type EmailFlowLogic = [State, EventHandlers]

export const useSetupFlowLogic = (): EmailFlowLogic => {
  const dispatch = useAppDispatch()
  const [params, setParams] = useSearchParams()
  const idpError = params.get('idp_error')
  const idpErrorDescription = params.get('idp_error_description')
  const [error, setError] = useState('')
  const [errorDescription, setErrorDescription] = useState('')
  const returnUrl = params.get('returnUrl')

  useErrorParam({ stayOnRoute: true, setTopErrorMessage: setError, setTopErrorDescription: setErrorDescription })

  const { userFeatures, userHasRight } = useUserRights()
  const [isRedirecting, setIsRedirecting] = useState(false)
  const [isSaveAndExit, setIsSaveAndExit] = useState(false)
  const isEGDReactOverviewEnabled = isMyFeatureOn(FEATURES.EGD_React_Overview_Rollout)

  const {
    user,
    accessToken,
    setupFlowError,
    currentStep,
    emailServerSettings,
    isSaveDeploymentMethodInProgress,
    isSaveSetupFlowStepInProgress,
    isIpirScanStatusInProgress,
    isSetSelectedRegionInProgress,
    selectedRegion,
    emailServerVerified,
    defaultEmailServerConfig,
    emailServerConfig,
    emailAddress,
    verifyDomainState,
    mxRecords,
    oldMxRecords,
    newMxRecordsError,
    oldMxRecordsError,
    isSaveSettingsSuccess,
    isSaveSettingsInProgress,
    isSaveSetupFlowVersionInProgress
  } = useAppSelector(_store => ({
    user: _store.auth.accessTokenObject,
    accessToken: _store.auth.accessToken,
    setupFlowError: _store.emailServer.setupFlowError,
    currentStep: _store.emailServer.setupFlowStep,
    emailServerSettings: _store.emailServer.emailServerSettings,
    isSaveDeploymentMethodInProgress: isPending(_store.emailServer.saveDeploymentMethodApiStatus),
    isSaveSetupFlowStepInProgress: isPending(_store.emailServer.saveSetupFlowStepApiStatus),
    isIpirScanStatusInProgress: isPending(_store.emailServer.getIpirScanStatusApiStatus),
    isSetSelectedRegionInProgress: isPending(_store.emailServer.setRegionApiStatus),
    emailServerVerified: _store.emailServer.emailServer?.verified,
    verifyDomainState: _store.emailServer.verifyDomainState,
    defaultEmailServerConfig: _store.emailServer.emailServer,
    emailServerConfig: _store.emailServer.updatedEmailServer,
    emailAddress: _store.emailServer.emailAddress,
    selectedRegion: _store.emailServer.selectedRegion,
    sectionsIsVerifiedStates: _store.emailServer.emailFlowState.validatedSections,
    mxRecords: _store.emailServer.mxRecords,
    oldMxRecords: _store.emailServer.oldMxRecords,
    newMxRecordsError: _store.emailServer.emailFlowState.newRecordsError,
    oldMxRecordsError: _store.emailServer.emailFlowState.oldRecordsError,
    isSaveSettingsSuccess: isSuccess(_store.emailServer.setEmailServerSettingsApiStatus),
    isSaveSettingsInProgress: isPending(_store.emailServer.setEmailServerSettingsApiStatus),
    isSaveSetupFlowVersionInProgress: isPending(_store.emailServer.saveSetupFlowVersionApiStatus)
  }))

  const migrateToken = useMemo(() => {
    const migrateTokenSecret = emailServerSettings?.migrateToken
    if (!migrateTokenSecret) {
      return ''
    }

    return `?${appConfig.QUERY_PARAMS.MIGRATE_TOKEN}=${encodeURIComponent(migrateTokenSecret)}`
  }, [emailServerSettings])

  // account settings are loaded
  useEffect(() => {
    // 1 - goto endUser app if user is endUser
    if (!appFeatures.DisabledWizardAdminCheck && userHasRight(UserRights.GOTO_ENDUSER_APP)) {
      gotoEnduserEntryPathFromWizard()
      // 2 - goto admin app if the wizard is completed
    } else if (emailServerSettings?.wizardCompleted) {
      gotoAdminEntryPathFromWizard('', isEGDReactOverviewEnabled)
      // 3 - Forward the user to the selected region (if not matched with the current host)
    } else if (
      appFeatures.EnabledRegionChange &&
      emailServerSettings?.url &&
      emailServerSettings.url !== window.location.hostname
    ) {
      window.location.href = `${window.location.origin?.replace(
        window.location.host,
        emailServerSettings?.url || ''
      )}${routesConfig.BCC_LOGIN?.url()}${migrateToken}`
      // default - welcome the user in the wizard
    } else if (currentStep === SetupFlowSteps.introduction && !emailServerSettings?.version) {
      dispatch(saveSetupFlowVersion('v2'))
    }
  }, [emailServerSettings, currentStep, migrateToken, dispatch, userHasRight, isEGDReactOverviewEnabled, userFeatures])

  // completed
  useEffect(() => {
    if (isSaveSettingsSuccess) {
      if (isSaveAndExit) {
        window.location.href = returnUrl || `${appConfig.BCC_PATH}`
        setIsRedirecting(true)
      }
    }
  }, [dispatch, currentStep, isSaveSettingsSuccess, emailServerSettings, isSaveAndExit, returnUrl])

  const onExit = useCallback(() => {
    dispatch(setEmailServerSettings({ completed: false }))
    setIsSaveAndExit(true)
  }, [dispatch])

  const onNext = useCallback(async () => {
    dispatch(resetSetupFlowError())
    params.delete('error')
    params.delete('idp_error')
    params.delete('idp_error_error')
    setParams(params, { replace: true })
    switch (currentStep) {
      case SetupFlowSteps.introduction:
        dispatch(saveSetupFlowStep(SetupFlowSteps.microsoftConnected))
        return
      case SetupFlowSteps.microsoftConnected:
        dispatch(saveSetupFlowStep(SetupFlowSteps.regionSelection))
        return
      case SetupFlowSteps.regionSelection:
        if (selectedRegion) {
          dispatch(
            setRegion({ region: selectedRegion.code.replace('GB', 'UK'), nextStep: SetupFlowSteps.verifyDomain })
          )
        } else {
          dispatch(setSelectedRegionError(true))
        }
        return
      case SetupFlowSteps.verifyDomain: {
        const isValidEmail = isEmailValid(trim(emailAddress))
        setIsEmailInputError(false)
        setIsMissedServerDetection(false)
        setIsMissedServerVerify(false)

        switch (true) {
          case !isValidEmail:
            dispatch(setIsEmailInputError(true))
            break
          case isValidEmail && !defaultEmailServerConfig:
            dispatch(setIsMissedServerDetection(true))
            break
          case !defaultEmailServerConfig?.verified:
            dispatch(setIsMissedServerVerify(true))
            break
          default:
        }

        const isSectionHasError =
          Object.values(verifyDomainState).some(verifyDomainSerror => verifyDomainSerror) ||
          (!!emailServerConfig && !defaultEmailServerConfig?.server?.length)
        if (emailServerVerified && !isSectionHasError) {
          dispatch(setEmailServerSettings({ completed: false, nextStep: SetupFlowSteps.deploymentSetup }))
        }
        return
      }
      case SetupFlowSteps.deploymentSetup:
        dispatch(saveDeploymentMethod(SetupFlowSteps.emailFlow))
        return
      case SetupFlowSteps.emailFlow: {
        const newMxVerified =
          !!mxRecords && !Object.values(mxRecords)?.some(record => !record.verified) && !newMxRecordsError
        const oldMxVerified =
          !!oldMxRecords && !Object.values(oldMxRecords)?.some(record => !record.verified) && !oldMxRecordsError

        if (!newMxVerified) {
          dispatch(setNewMxRecordsError('MX record needs to be verified to complete setup'))
        }

        if (!oldMxVerified) {
          dispatch(setOldMxRecordsError('Removal of old MX records needs to be verified to complete setup'))
        }

        if (newMxVerified && oldMxVerified) {
          if (user && accessToken) {
            setIntercomUser(user, accessToken, { company: { finished_wizard: true } })
          }
          dispatch(setEmailServerSettings({ completed: true }))
        }
        break
      }
      default:
    }
  }, [
    dispatch,
    currentStep,
    emailServerVerified,
    selectedRegion,
    verifyDomainState,
    emailServerConfig,
    defaultEmailServerConfig,
    emailAddress,
    mxRecords,
    newMxRecordsError,
    oldMxRecords,
    oldMxRecordsError,
    accessToken,
    user,
    params,
    setParams
  ])

  const errors = useMemo(() => {
    const authFlowError = idpError || error
    const authFlowErrorDescription = idpErrorDescription || errorDescription
    const displayError = authFlowErrorDescription || authFlowError
    const possibleErrors = [displayError, setupFlowError, newMxRecordsError, oldMxRecordsError]
    return possibleErrors.filter((possibleError): possibleError is string => !!possibleError)
  }, [error, errorDescription, setupFlowError, newMxRecordsError, oldMxRecordsError, idpError, idpErrorDescription])

  const steps = useMemo(
    () =>
      Object.keys(SetupFlowSteps).map(step => {
        const result: StepInfo = { name: step }

        // Microsoft connected skip
        if (
          step === SetupFlowSteps.microsoftConnected &&
          currentStep !== SetupFlowSteps.introduction &&
          currentStep !== SetupFlowSteps.microsoftConnected &&
          !emailServerSettings?.ipirAccountId &&
          !emailServerSettings?.ipirTokenId
        ) {
          result.skipped = true
        }

        if (currentStep === step) {
          result.active = true
        }
        return result
      }),
    [currentStep, emailServerSettings]
  )

  return useMemo(
    () => [
      {
        errors,
        currentStep,
        stepInProgress:
          isSaveSetupFlowStepInProgress ||
          isSaveDeploymentMethodInProgress ||
          isSetSelectedRegionInProgress ||
          isSaveSettingsInProgress ||
          isRedirecting,
        loading: isSaveSetupFlowVersionInProgress || isIpirScanStatusInProgress,
        nextDisabled:
          currentStep === SetupFlowSteps.microsoftConnected &&
          !emailServerSettings?.ipirAccountId &&
          !emailServerSettings?.ipirTokenId,
        steps
      },
      {
        onExit,
        onNext
      }
    ],
    [
      errors,
      currentStep,
      isSaveSetupFlowStepInProgress,
      isSaveDeploymentMethodInProgress,
      isIpirScanStatusInProgress,
      isSetSelectedRegionInProgress,
      isSaveSetupFlowVersionInProgress,
      isSaveSettingsInProgress,
      isRedirecting,
      emailServerSettings,
      steps,
      onExit,
      onNext
    ]
  )
}
