import React from 'react'
import { AccountStatusBanner, AccountStatusBannerType } from 'types/banners'
import AccountDisabledBanner from 'components/libs/banner/accountStatusBanner/AccountDisabledBanner'
import AccountSuspendedBanner from 'components/libs/banner/accountStatusBanner/AccountSuspendedBanner'
import AccountExpiredBanner from 'components/libs/banner/accountStatusBanner/AccountExpiredBanner'
import AccountTerminatedBanner from 'components/libs/banner/accountStatusBanner/AccountTerminatedBanner'
import TrialExpiredBanner from 'components/libs/banner/accountStatusBanner/TrialExpiredBanner'
import TrialExpiringBanner from 'components/libs/banner/accountStatusBanner/TrialExpiringBanner'
import appConfig from 'config/appConfig'

export interface AccountStatusBannerSelectorProps {
  banner: AccountStatusBanner
}
const AccountStatusBannerSelector: React.FC<AccountStatusBannerSelectorProps> = ({ banner }) => {
  if (!appConfig.APP.IS_ADMIN) {
    return null
  }

  switch (banner.banner_type) {
    case AccountStatusBannerType.ACCOUNT_DISABLED: {
      return <AccountDisabledBanner />
    }
    case AccountStatusBannerType.ACCOUNT_SUSPENDED: {
      return <AccountSuspendedBanner />
    }
    case AccountStatusBannerType.ACCOUNT_TERMINATED: {
      return <AccountTerminatedBanner />
    }
    case AccountStatusBannerType.ACCOUNT_EXPIRED: {
      return <AccountExpiredBanner />
    }
    case AccountStatusBannerType.TRIAL_EXPIRED: {
      return <TrialExpiredBanner />
    }
    case AccountStatusBannerType.TRIAL_EXPIRING: {
      return <TrialExpiringBanner trialDaysRemaining={banner.trial_days_remaining} expiresAt={banner.expires_at} />
    }
    case AccountStatusBannerType.NONE:
    default: {
      return null
    }
  }
}

export default AccountStatusBannerSelector
