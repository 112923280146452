import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'

import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Modal,
  Tooltip
} from '@barracuda-internal/bds-core'
import { Block, Close, Delete, Launch, MenuVertical } from '@barracuda-internal/bds-core/dist/Icons/Core'
import { Forward, Reply, ReplyAll, Report, Send } from '@barracuda-internal/bds-core/dist/Icons/Email'

import RedeliveryDialog from 'components/libs/dialog/redeliveryDialog/RedeliveryDialog'
import EmailComposer from 'components/libs/dialog/emailComposer/EmailComposer'
import { useMessageDetailsToolbarLogic } from 'components/libs/message/messageDetails/messageDetailsToolbar/useMessageDetailsToolbarLogic'
import styles from 'components/libs/message/messageDetails/messageDetailsToolbar/messageDetailsToolbarStyles'
import ReportingActionModal from 'components/libs/dialog/reportingActionModal/ReportingActionModal'
import { useFormatMessage } from 'lib/localization'
import ConfirmRedeliverDialog from 'components/libs/dialog/confirmRedeliver/ConfirmRedeliverDialog'
import { FpFnType } from 'types/Messages'

const BASE_18N_KEY = 'ess.message_log.message_detail_toolbar'

const MessageDetailToolbar = () => {
  const formatMessage = useFormatMessage(BASE_18N_KEY)
  const [
    {
      deleteButton: { isDeleteButtonVisible },
      dotsMenu: {
        isDotsMenuEnabled,
        isDotsMenuOpen,
        dotsMenuAnchorElement,
        isDownloadButtonVisible,
        isBlockEmailButtonVisible,
        isBlockDomainButtonVisible
      },
      download: { isDownloadMessagePending, downloadMessageProgressPercent },
      emailComposer: { isComposerOpen, composerEmailType },
      historyButton: { messageHistoryButtonTextId },
      messageDetails: { isMessageDetailLinkVisible, messageDetailLinkHref, isCloseButtonVisible },
      modal: { isModalOpen },
      permissions: {
        isDeliverButtonVisible,
        isDeliverDisabled,
        isEmergencyInboxEnabled,
        isViewAtpReportsButtonVisible,
        canViewMessageHistory
      },
      redeliveryDialog: { isRedeliveryDialogOpen, redeliveryDialogData, isReportButtonVisible },
      reportingModal: {
        isReportingActionModalDisabled,
        isReportingActionModalOpen,
        fpFnType,
        showMarkSpam,
        showMarkHam
      },
      styleProps,
      viewConfig,
      confirmRedeliverDialogLogic
    },
    {
      deleteButton: { onDeleteMessage },
      reportingModal: { onOpenReportingActionModal, onCloseReportingActionModal },
      historyButton: { onToggleMessageHistory },
      dotsMenu: { onOpenDotsMenu, onCloseDotsMenu, onBlockSenderEmail, onBlockSenderDomain },
      download: { onDownloadMessage },
      emailComposer: { onComposerReply, onComposerReplyAll, onComposerForward, onCloseComposer },
      modal: { onCloseModal },
      messageDetails: { onCloseMessageDetails },
      redeliveryDialog: { onOpenRedeliveryDialog, onCloseRedeliveryDialog },
      rejectButton: { onRejectMessage },
      viewAtpReportButton: { onClickViewAtpReports }
    }
  ] = useMessageDetailsToolbarLogic()
  const classes = styles(styleProps)

  return useMemo(
    () => (
      <>
        <ConfirmRedeliverDialog logic={confirmRedeliverDialogLogic} />
        {isRedeliveryDialogOpen && redeliveryDialogData && (
          <RedeliveryDialog onClose={onCloseRedeliveryDialog} showReportButton={isReportButtonVisible} />
        )}
        <Modal className={classes.modal} open={isModalOpen} onClose={onCloseModal} data-testid="modal">
          <>
            {isComposerOpen && composerEmailType && (
              <EmailComposer handleClose={onCloseComposer} emailType={composerEmailType} />
            )}
            {isReportingActionModalOpen && fpFnType && (
              <ReportingActionModal fpFnType={fpFnType} handleClose={onCloseReportingActionModal} />
            )}
          </>
        </Modal>
        <Grid container className={classes.drawerHeader} data-testid="drawer-header">
          <Grid item>
            {isDeliverButtonVisible && (
              <>
                <Tooltip disableFocusListener title="Deliver" placement="top">
                  <Grid className={classes.sendButton}>
                    <IconButton
                      className={classes.toolbarIconButton}
                      size="small"
                      color="secondary"
                      onClick={onOpenRedeliveryDialog}
                      disabled={isDeliverDisabled}
                      data-testid="send-button"
                    >
                      <Send />
                    </IconButton>
                  </Grid>
                </Tooltip>
                {showMarkSpam && (
                  <div className={classes.checkButtonWrapper} data-testid="checkbutton-wrapper">
                    <Button
                      className={classes.toolbarButton}
                      color="secondary"
                      disabled={isReportingActionModalDisabled}
                      onClick={() => onOpenReportingActionModal(FpFnType.FN)}
                      size="medium"
                      startIcon={<Report />}
                      variant="text"
                      data-testid="report-button"
                    >
                      {formatMessage('incorrectly_allowed')}
                    </Button>
                  </div>
                )}
                {showMarkHam && (
                  <div className={classes.checkButtonWrapper} data-testid="checkbutton-wrapper">
                    <Button
                      className={classes.toolbarButton}
                      color="secondary"
                      disabled={isReportingActionModalDisabled}
                      onClick={() => onOpenReportingActionModal(FpFnType.FP)}
                      size="medium"
                      startIcon={<Report />}
                      variant="text"
                      data-testid="report-button"
                    >
                      {formatMessage('incorrectly_blocked')}
                    </Button>
                  </div>
                )}
              </>
            )}
            {viewConfig.detailsToolbar.showReject && (
              <Tooltip disableFocusListener title={formatMessage('menu.reject_message')} placement="top">
                <IconButton
                  className={classes.toolbarIconButton}
                  color="secondary"
                  onClick={onRejectMessage}
                  size="small"
                  data-testid="reject-button"
                >
                  <Block />
                </IconButton>
              </Tooltip>
            )}
            {isDeleteButtonVisible && (
              <Tooltip disableFocusListener title={formatMessage('menu.delete_message')} placement="top">
                <IconButton
                  className={classes.toolbarIconButton}
                  color="secondary"
                  onClick={onDeleteMessage}
                  size="small"
                  data-testid="delete-button"
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            )}
            {viewConfig.detailsToolbar.showHistoryToggle && canViewMessageHistory && (
              <Button
                className={classes.toolbarButton}
                variant="text"
                color="secondary"
                onClick={onToggleMessageHistory}
                data-testid="history-button"
              >
                {formatMessage(messageHistoryButtonTextId)}
              </Button>
            )}
            {isViewAtpReportsButtonVisible && (
              <Button
                className={classes.toolbarButton}
                variant="text"
                color="secondary"
                onClick={onClickViewAtpReports}
                data-testid="history-button"
              >
                {formatMessage('menu.view_atp_reports')}
              </Button>
            )}
            {isDotsMenuEnabled && (
              <>
                <Tooltip disableFocusListener title={formatMessage('menu.actions')} placement="top">
                  <IconButton
                    ref={dotsMenuAnchorElement}
                    className={classes.toolbarIconButton}
                    size="small"
                    onClick={onOpenDotsMenu}
                    data-testid="dots-menu-button"
                  >
                    <MenuVertical />
                  </IconButton>
                </Tooltip>
                <Menu
                  keepMounted
                  anchorEl={dotsMenuAnchorElement.current}
                  open={isDotsMenuOpen}
                  onClose={onCloseDotsMenu}
                  data-testid="dots-menu-items"
                >
                  {isDownloadButtonVisible && (
                    <MenuItem component="li" onClick={onDownloadMessage} data-testid="dots-menu-item-download">
                      {formatMessage('menu.download')}
                    </MenuItem>
                  )}
                  {isBlockEmailButtonVisible && (
                    <MenuItem component="li" onClick={onBlockSenderEmail} data-testid="dots-menu-item-block-email">
                      {formatMessage('menu.add_email')}
                    </MenuItem>
                  )}
                  {isBlockDomainButtonVisible && (
                    <MenuItem component="li" onClick={onBlockSenderDomain} data-testid="dots-menu-item-block-domain">
                      {formatMessage('menu.add_domain')}
                    </MenuItem>
                  )}
                </Menu>
              </>
            )}
          </Grid>
          <Grid item>
            {isDownloadMessagePending && (
              <div className={classes.downloadingWrapper}>
                <CircularProgress size={20} variant="indeterminate" data-testid="download-progress-indeterminate" />
                <span className={classes.downloadingText}>{formatMessage('downloading')}</span>
                {downloadMessageProgressPercent > 0 && (
                  <LinearProgress
                    variant="determinate"
                    value={downloadMessageProgressPercent}
                    data-testid="download-progress-determinate"
                  />
                )}
              </div>
            )}
            {isEmergencyInboxEnabled && (
              <>
                <Tooltip disableFocusListener title={formatMessage('menu.reply')} placement="top">
                  <IconButton
                    className={classes.toolbarIconButton}
                    size="small"
                    color="secondary"
                    onClick={onComposerReply}
                    data-testid="email-composer-reply-button"
                  >
                    <Reply />
                  </IconButton>
                </Tooltip>
                <Tooltip disableFocusListener title={formatMessage('menu.reply_all')} placement="top">
                  <IconButton
                    className={classes.toolbarIconButton}
                    size="small"
                    color="secondary"
                    onClick={onComposerReplyAll}
                    data-testid="email-composer-reply-all-button"
                  >
                    <ReplyAll />
                  </IconButton>
                </Tooltip>
                <Tooltip disableFocusListener title={formatMessage('menu.forward')} placement="top">
                  <IconButton
                    className={classes.toolbarIconButton}
                    size="small"
                    color="secondary"
                    onClick={onComposerForward}
                    data-testid="email-composer-forward-button"
                  >
                    <Forward />
                  </IconButton>
                </Tooltip>
              </>
            )}
            {isMessageDetailLinkVisible && (
              <Link target="_blank" rel="noopener noreferrer" to={messageDetailLinkHref}>
                <IconButton className={classes.toolbarIconButton} size="small" data-testid="message-detail-open">
                  <Launch />
                </IconButton>
              </Link>
            )}
            {isCloseButtonVisible && (
              <Tooltip disableFocusListener title={formatMessage('menu.close')} placement="top">
                <IconButton
                  className={classes.toolbarIconButton}
                  size="small"
                  color="secondary"
                  onClick={onCloseMessageDetails}
                  data-testid="message-detail-close"
                >
                  <Close />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        </Grid>
      </>
    ),
    [
      confirmRedeliverDialogLogic,
      isRedeliveryDialogOpen,
      redeliveryDialogData,
      onCloseRedeliveryDialog,
      isReportButtonVisible,
      classes.modal,
      classes.drawerHeader,
      classes.sendButton,
      classes.toolbarIconButton,
      classes.checkButtonWrapper,
      classes.toolbarButton,
      classes.downloadingWrapper,
      classes.downloadingText,
      isModalOpen,
      onCloseModal,
      isComposerOpen,
      composerEmailType,
      onCloseComposer,
      isReportingActionModalOpen,
      fpFnType,
      onCloseReportingActionModal,
      isDeliverButtonVisible,
      onOpenRedeliveryDialog,
      isDeliverDisabled,
      showMarkSpam,
      isReportingActionModalDisabled,
      formatMessage,
      showMarkHam,
      viewConfig.detailsToolbar.showReject,
      viewConfig.detailsToolbar.showHistoryToggle,
      onRejectMessage,
      isDeleteButtonVisible,
      onDeleteMessage,
      canViewMessageHistory,
      onToggleMessageHistory,
      messageHistoryButtonTextId,
      isViewAtpReportsButtonVisible,
      onClickViewAtpReports,
      isDotsMenuEnabled,
      dotsMenuAnchorElement,
      onOpenDotsMenu,
      isDotsMenuOpen,
      onCloseDotsMenu,
      isDownloadButtonVisible,
      onDownloadMessage,
      isBlockEmailButtonVisible,
      onBlockSenderEmail,
      isBlockDomainButtonVisible,
      onBlockSenderDomain,
      isDownloadMessagePending,
      downloadMessageProgressPercent,
      isEmergencyInboxEnabled,
      onComposerReply,
      onComposerReplyAll,
      onComposerForward,
      isMessageDetailLinkVisible,
      messageDetailLinkHref,
      isCloseButtonVisible,
      onCloseMessageDetails,
      onOpenReportingActionModal
    ]
  )
}

export default MessageDetailToolbar
