import React, { useCallback } from 'react'
import { PushPin } from '@mui/icons-material'
import { Grid, IconButton, Link, List, ListItem, Typography } from '@barracuda-internal/bds-core'

import { Dropdown, SideMenu } from './SideMenu'

import useStyles from './unifiedReportingSideNavigationStyles'

export type UnifiedReportingSideNavigationProps = {
  /**
   * Dropdowns to be rendered in the side menu
   */
  dropdowns: Dropdown[]
  /**
   * Callback function to be called when the search field changes
   */
  onSearchChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined
  /**
   * Callback function to be called when a dropdown item is clicked
   */
  onClickListItem: (dropdownItem: any) => void
  /**
   * Callback function to be called when the push pin icon is clicked
   */
  onClickListItemPin: (dropdownItem: any) => void
  /**
   * Dropdown item that is pinned
   */
  pinnedDropdownItem?: any
  /**
   * Placeholder text for the search field
   */
  placeholderText?: string
  /**
   * Render prop for the dropdown's items
   */
  selectedDropdownItem?: any
  /**
   * Title of the side menu
   */
  title: string
  /**
   * Make item's container scrollable
   */
  scrollableItemContainer?: boolean
}

export function UnifiedReportingSideNavigation({
  dropdowns,
  onSearchChange,
  onClickListItem,
  onClickListItemPin,
  pinnedDropdownItem,
  placeholderText,
  selectedDropdownItem,
  title,
  scrollableItemContainer = false
}: UnifiedReportingSideNavigationProps) {
  const classes = useStyles()

  const renderDropdownItems = useCallback(
    (items: any) => (
      <List component="div" disablePadding>
        {items?.map((dropdownItem: any) => (
          <ListItem
            className={`${classes.listItem} ${classes.listItemSecondary}`}
            key={dropdownItem.id}
            button
            onClick={() => onClickListItem(dropdownItem)}
            selected={dropdownItem.id === selectedDropdownItem?.id}
            id={dropdownItem.id === selectedDropdownItem?.id ? 'selected-report-id' : undefined}
          >
            <Typography className={classes.listItemText} variant="body1">
              {dropdownItem.label}
            </Typography>
            <IconButton
              className={pinnedDropdownItem?.id === dropdownItem.id ? classes.pushPinActive : classes.pushPin}
              onClick={() => onClickListItemPin(dropdownItem)}
              size="small"
            >
              <PushPin />
            </IconButton>
          </ListItem>
        ))}
      </List>
    ),
    [classes, pinnedDropdownItem, onClickListItem, onClickListItemPin, selectedDropdownItem?.id]
  )

  const renderDropdownItemsEmptyState = useCallback(
    (dropdown: any) => (
      <Grid container className={classes.dropdownItemsEmptyStateContainer} spacing={1}>
        <Grid item>
          <Typography variant="caption">{`No items were found for ${dropdown.label}`}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="overline" color="secondary">
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            Add "items" to this dropdown in order to see them here.
          </Typography>
        </Grid>
        <Grid item>
          <Link
            href="https://campus.barracuda.com/product/emailgatewaydefense/doc/167977923/updated-reports-tab"
            underline="always"
          >
            Learn more here
          </Link>
        </Grid>
      </Grid>
    ),
    [classes]
  )

  return (
    <SideMenu
      scrollableItemContainer={scrollableItemContainer}
      dropdowns={dropdowns}
      onChange={onSearchChange}
      placeholderText={placeholderText}
      renderDropdownItems={renderDropdownItems}
      renderDropdownItemsEmptyState={renderDropdownItemsEmptyState}
      title={title}
    />
  )
}
