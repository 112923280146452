export enum AccountStatusBannerType {
  NONE = 'none',
  ACCOUNT_SUSPENDED = 'account_suspended',
  ACCOUNT_DISABLED = 'account_disabled',
  ACCOUNT_TERMINATED = 'account_terminated',
  TRIAL_EXPIRING = 'trial_expiring',
  TRIAL_EXPIRED = 'trial_expired',
  ACCOUNT_EXPIRED = 'account_expired'
}

export interface AccountStatusBanner {
  banner_type: AccountStatusBannerType
  expires_at: number
  trial_days_remaining: number
}

export interface BannerSettings {
  hasMailFlown: boolean
  wizardComplete: boolean
  hasVerifiedDomains: boolean
  accountStatusBanner: AccountStatusBanner
}
