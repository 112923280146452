import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import restClient, { ApiRejectResponse, validateApiError } from 'lib/api/restClient'
import apiRoutes, { ApiRoute } from 'lib/api/apiRoutes'
import { setSessionCookie } from 'lib/cookies'
import { calculateTokenExpiration } from 'lib/datetime'
import { setErrorSnackBar, setSuccessSnackBar } from 'redux/features/app/appSlice'
import camelizeKeys from 'lib/camelizeKeys'
import { DomainInfo, LoginResponse, User, UserFeatures, UserStates, UserType } from 'types/auth'
import { RootState } from 'redux/toolkit/store'
import { LoginSettings, SSOSettingsResponse } from 'types/login'
import appConfig from 'config/appConfig'
import { asyncSleep } from 'lib/utils'
import { isLdapException, shouldRetryLdapAuth } from 'lib/ldap'
import logger from 'lib/logger'
import { SettingsObject } from 'types/Settings'
import { UserRole } from 'config/userRole'

export const resetAll = createAction('ALL/reset')
export const ENFORCE_MANUAL_LOGIN = 'ENFORCE_MANUAL_LOGIN'
export const updateUserStatesAction = createAction<UserStates>('auth/updateUserState')

export interface LoginPayload {
  user_id: string
  password: string
  attempt?: number
}

export type AutoLoginPayload = {
  token: string
}

export interface AzureSSOLoginPayload {
  state: string
  code: string
  session_state: string
}

export type BCCLoginPayload = string
export type Auth0LoginPayload = string

export interface Auth0LoginResponse {
  result?: LoginResponse
  userStates?: UserStates
}

export interface BCCLoginUpdatedPayload {
  cloud_at?: string
  portal_id?: string
  migrate_token?: string
}

export type ValidateSessionIdPayload = string

export interface Login {
  session: LoginResponse
  accessToken: LoginResponse['accessToken']
  accessTokenObject: User
  accessTokenExpires: number
  newRegionUrl?: string
}

export interface SwitchAccount {
  success: boolean
  newRegionUrl?: string
}

export type SwitchAccountPayload = string

export interface ResetPasswordPayload {
  userId: string
  password: string
  hash: string
  expiration: string
}

export interface TemporaryPasscodePayload {
  user_id: string
}

export type GetLoginSettingPayload = TemporaryPasscodePayload
export type PostMixpanelPayload = string

export interface RequestLoginInfoPayload {
  userId: string
  origin: 'LinkExpired' | 'CheckEmail'
}

export interface TemporaryPasscodeRateLimitError {
  expiration_time: number
  message: string
  rate_limit: number
  statusCode: number
}

export type ValidateAccessTokenPayload = string

const DEFAULT_USER_FEATURES: UserFeatures = {
  optInEnabled: false,
  optOutEnabled: false,
  convergedSetupFlowRollout: false,
  userEndpoint: 'TDF',
  isEmailLogEnabled: false
}

// helpers
async function buildAccessTokenObject(accessToken: string, state: RootState): Promise<User> {
  // get additional user information from jwt token
  const userInfoFromAccessToken = JSON.parse(atob(accessToken.split('.')[1]))

  // Get user object
  const userInfoResponse: { data: User } = await restClient(apiRoutes.USERINFO, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })

  let userFeatures: UserFeatures | undefined
  if (
    (appConfig.APP.IS_ADMIN || appConfig.APP.IS_WIZARD) &&
    userInfoResponse.data.roleType === UserRole.ACCOUNT_USER &&
    !userInfoResponse.data.isImpersonated
  ) {
    try {
      const result = await restClient(apiRoutes.GET_BCC_USER_FEATURES, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        urlParams: {
          bccAccountId: userInfoResponse.data.bccAccountId,
          bccUserId: userInfoResponse.data.userId
        }
      })
      userFeatures = result.data
    } catch (e) {
      userFeatures = DEFAULT_USER_FEATURES
    }

    if (userFeatures?.userEndpoint === 'TDF') {
      window.location.href = `${appConfig.LEGACY_UI_URL}/${state.app.activePath.legacyPath}`
      // Don't execute the rest of the code since we are already redirecting to another app
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      await new Promise(() => {})
    }
  }

  return {
    ...userInfoResponse.data,
    portalId: Number(userInfoFromAccessToken.portal_id),
    scope: userInfoFromAccessToken.scope,
    isAzureAdAccount: userInfoResponse.data.type === UserType.azure,
    companyId: userInfoFromAccessToken.company_id,
    companyName: userInfoFromAccessToken.company_name,
    features: userFeatures,
    ...(!!userInfoResponse.data.userInfo && { userInfo: camelizeKeys(userInfoResponse.data.userInfo) })
  }
}

async function createSession(loginResp: LoginResponse, state: RootState): Promise<Login> {
  setSessionCookie(loginResp.sessionId)

  const accessTokenObject = await buildAccessTokenObject(loginResp.accessToken, state)
  const accessTokenExpires = calculateTokenExpiration(loginResp.accessTokenExpires)

  // Build store values
  return {
    session: loginResp,
    accessToken: loginResp.accessToken,
    accessTokenObject,
    accessTokenExpires: accessTokenExpires.getTime()
  }
}

async function createNewSession(
  payload: LoginPayload | AutoLoginPayload | AzureSSOLoginPayload | BCCLoginUpdatedPayload,
  state: RootState,
  apiRoute?: ApiRoute
): Promise<Login> {
  // validate the session
  const loginResp: { data: LoginResponse } = await restClient(apiRoute || apiRoutes.LOGIN, {
    data: { ...payload },
    headers: {
      isToolkitCompatible: true
    }
  })
  return createSession(loginResp.data, state)
}

function handleSessionError(e: any, rejectWithValue: any) {
  if (e?.status === 403) {
    rejectWithValue(ENFORCE_MANUAL_LOGIN)
  } else if (e?.status === 401) {
    return rejectWithValue(401)
  }
  return rejectWithValue(validateApiError(e))
}

// apiThunks
export const validateAccessToken = createAsyncThunk<User, ValidateAccessTokenPayload, ApiRejectResponse>(
  'AUTH/validateAccessToken',
  async (payload, { rejectWithValue, getState }) => {
    try {
      return await buildAccessTokenObject(payload, getState() as RootState)
    } catch (e) {
      return handleSessionError(e, rejectWithValue)
    }
  }
)

export const getDomainInfo = createAsyncThunk<DomainInfo, undefined, ApiRejectResponse>(
  'AUTH/domain-info',
  async (payload, { rejectWithValue }) => {
    try {
      const resp = await restClient(apiRoutes.DOMAIN_INFO)
      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const login = createAsyncThunk<Login, LoginPayload, ApiRejectResponse>(
  'AUTH/login',
  async (payload, { rejectWithValue, getState }) => {
    const currentAttempt = payload.attempt && payload.attempt > 0 ? payload.attempt : 1
    const state = getState() as RootState
    try {
      return await createNewSession(payload, state)
    } catch (e) {
      if (isLdapException(e?.data?.detail) && shouldRetryLdapAuth(e?.data?.detail)) {
        return retryLdapLogin(
          { ...payload, attempt: currentAttempt + 1 },
          () => handleSessionError(e, rejectWithValue),
          state
        )
      }
      return handleSessionError(e, rejectWithValue)
    }
  }
)

const retryLdapLogin = async (
  payload: LoginPayload,
  onMaxAttemptReached: () => any,
  state: RootState
): Promise<Login> => {
  const currentAttempt = payload.attempt && payload.attempt > 0 ? payload.attempt : 1
  if (currentAttempt > appConfig.LDAP.AUTH_RETRY_ATTEMPTS + 1) {
    logger.error(`LDAP authentication failed after ${currentAttempt - 1} attempts. Giving up.`)
    return onMaxAttemptReached()
  }
  const sleep = appConfig.LDAP.AUTH_WAIT_BEFORE_RETRY + 1000 * Math.max(currentAttempt - 2, 0)
  logger.info(
    `LDAP authentication failed in attempt ${currentAttempt - 1}. Retrying in ${Math.floor(sleep / 1000)} seconds`
  )
  await asyncSleep(appConfig.LDAP.AUTH_WAIT_BEFORE_RETRY)
  try {
    return await createNewSession(payload, state)
  } catch (e) {
    if (isLdapException(e?.data?.detail) && shouldRetryLdapAuth(e?.data?.detail)) {
      return retryLdapLogin({ ...payload, attempt: currentAttempt + 1 }, onMaxAttemptReached, state)
    }
    return onMaxAttemptReached()
  }
}

export const temporaryLogin = createAsyncThunk<Login, LoginPayload, ApiRejectResponse>(
  'AUTH/temporaryLogin',
  async (payload, { rejectWithValue, getState }) => {
    try {
      // validate the session
      const loginResp: { data: LoginResponse } = await restClient(apiRoutes.TEMPORARY_LOGIN, {
        data: { ...payload },
        headers: {
          isToolkitCompatible: true
        }
      })
      return await createSession(loginResp.data, getState() as RootState)
    } catch (e) {
      if (e.status === 424) {
        return rejectWithValue(JSON.stringify({ ...e.data.detail, statusCode: e.status }))
      }
      return handleSessionError(e, rejectWithValue)
    }
  }
)

export const autoLogin = createAsyncThunk<Login, AutoLoginPayload, ApiRejectResponse>(
  'AUTH/autoLogin',
  async (payload, { rejectWithValue, getState }) => {
    try {
      return await createNewSession(payload, getState() as RootState)
    } catch (e) {
      return handleSessionError(e, rejectWithValue)
    }
  }
)

export const azureSSOLogin = createAsyncThunk<Login, AzureSSOLoginPayload, ApiRejectResponse>(
  'AUTH/azureSSOLogin',
  async (payload, { rejectWithValue, getState }) => {
    try {
      return await createNewSession(payload, getState() as RootState)
    } catch (e) {
      return handleSessionError(e, rejectWithValue)
    }
  }
)

export const bccLogin = createAsyncThunk<Login, BCCLoginPayload | undefined, ApiRejectResponse>(
  'AUTH/bccLogin',
  async (payload, { rejectWithValue, getState }) => {
    try {
      return await createNewSession(
        {
          cloud_at: undefined, // hardcode the cloud_at token cookie value for BCC login
          portal_id: undefined, // hardcode the current_account cookie value for BCC login
          migrate_token: payload
        },
        getState() as RootState,
        apiRoutes.BCC_LOGIN
      )
    } catch (e) {
      return handleSessionError(e, rejectWithValue)
    }
  }
)

export const switchAccount = createAsyncThunk<SwitchAccount | null, SwitchAccountPayload, ApiRejectResponse>(
  'AUTH/switchAccount',
  async (payload, { rejectWithValue }) => {
    try {
      const resp = await restClient(apiRoutes.SWITCH_ACCOUNT, {
        headers: {
          isToolkitCompatible: true
        },
        data: {
          accountId: payload
        }
      })

      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const auth0Login = createAsyncThunk<Auth0LoginResponse, Auth0LoginPayload, ApiRejectResponse>(
  'AUTH/auth0Login',
  async (payload, { dispatch, rejectWithValue, getState }) => {
    try {
      const loginResp: { data: Auth0LoginResponse } = await restClient(apiRoutes.AUTH0_LOGIN, {
        headers: {
          Authorization: `Bearer ${payload}`,
          isToolkitCompatible: true
        }
      })

      if (loginResp.data.userStates) {
        dispatch(updateUserStatesAction(loginResp.data.userStates))
        return rejectWithValue('invalid user access')
      }
      if (loginResp.data.result) {
        return createSession(loginResp.data.result, getState() as RootState)
      }
      return rejectWithValue('unknown error')
    } catch (e) {
      return handleSessionError(e, rejectWithValue)
    }
  }
)

export const validateSessionId = createAsyncThunk<Login | undefined, ValidateSessionIdPayload, ApiRejectResponse>(
  'AUTH/validateSessionId',
  async (payload, { rejectWithValue, getState }) => {
    try {
      const resp = await restClient(apiRoutes.VALIDATE_SESSION, {
        data: { sessionId: payload },
        headers: {
          isToolkitCompatible: true
        }
      })

      if (resp.data.newRegionUrl) {
        // Switch to the url provided for the selected account
        window.location.href = resp.data.newRegionUrl
        // Don't execute the rest of the code since we are already redirecting to another app
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        await new Promise(() => {})
      } else if (resp?.data?.accessToken) {
        return await createSession(resp.data, getState() as RootState)
      }
      return undefined
    } catch (e) {
      return handleSessionError(e, rejectWithValue)
    }
  }
)

export const logout = createAsyncThunk<undefined, undefined, ApiRejectResponse>(
  'AUTH/logout',
  async (_, { rejectWithValue, dispatch, getState }) => {
    const { accessToken } = (getState() as RootState).auth

    try {
      // invalidate the session data and reset the entire redux store
      dispatch(resetAll())
      // invalidate the session on BE side if accessToken is set
      if (accessToken) {
        await restClient(apiRoutes.LOGOUT, {
          headers: { Authorization: `Bearer ${accessToken}` }
        })
      }
      return undefined
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const resetPassword = createAsyncThunk<undefined, ResetPasswordPayload, ApiRejectResponse>(
  'AUTH/resetPassword',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const resp = await restClient(apiRoutes.RESET_PASSWORD, {
        data: { ...payload }
      })

      dispatch(
        setSuccessSnackBar({
          message: 'reset_password_success'
        })
      )

      return resp.data
    } catch (e) {
      dispatch(
        setErrorSnackBar({
          message: 'reset_password_failure'
        })
      )

      return rejectWithValue(validateApiError(e))
    }
  }
)

export const requestLoginInfo = createAsyncThunk<undefined, RequestLoginInfoPayload, ApiRejectResponse>(
  'AUTH/requestLoginInfo',
  async (payload, { rejectWithValue }) => {
    try {
      const resp = await restClient(apiRoutes.LOGIN_INFO, {
        data: {
          user_id: payload.userId
        }
      })

      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const temporaryPasscode = createAsyncThunk<undefined, TemporaryPasscodePayload, ApiRejectResponse>(
  'AUTH/temporaryPasscode',
  async (payload, { rejectWithValue }) => {
    try {
      await restClient(apiRoutes.TEMPORARY_PASSCODE, {
        data: { ...payload }
      })
      return undefined
    } catch (e) {
      return rejectWithValue(
        e.status === 409 || e.status === 424 ? JSON.stringify({ ...e.data.detail, statusCode: e.status }) : ''
      )
    }
  }
)

export const getLoginSettings = createAsyncThunk<LoginSettings, GetLoginSettingPayload, ApiRejectResponse>(
  'AUTH/getLoginSettings',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await restClient(apiRoutes.LOGIN_SETTINGS_URL, {
        data: {
          ...payload
        }
      })

      return {
        ...response.data,
        userId: payload.user_id
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Failed to get login settings: ', e)
      return rejectWithValue('')
    }
  }
)

export const getSSOSetings = createAsyncThunk<SSOSettingsResponse, undefined, ApiRejectResponse>(
  'AUTH/getSSOSettings',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await restClient(apiRoutes.SSO_SETTINGS)
      return response.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const switchToOldUi = createAsyncThunk<void, SettingsObject, ApiRejectResponse>(
  'AUTH/switchToOldUi',
  async (payload, { rejectWithValue, dispatch, getState }) => {
    try {
      await restClient(apiRoutes.UPDATE_BCC_USER_SETTINGS, {
        data: {
          newSettings: payload
        }
      })
      const { activePath } = (getState() as RootState).app

      // invalidate the session data and reset the entire redux store
      dispatch(resetAll())

      window.location.href = `${appConfig.LEGACY_UI_URL}/${activePath.legacyPath}?src=react`
      // Don't execute the rest of the code since we are already redirecting to another app
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      await new Promise(() => {})

      return undefined
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export interface CreateSessionRequest {
  idTokenJwt: string
}

export const oauth2CreateSession = createAsyncThunk<Login, CreateSessionRequest, ApiRejectResponse>(
  'OAUTH2/oauth2CreateSession',
  async (payload, { rejectWithValue, getState }) => {
    try {
      const resp = await restClient(apiRoutes.OAUTH2_CREATE_SESSION, {
        data: payload
      })
      return await createSession(resp.data, getState() as RootState)
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)
