import restClient from 'lib/api/restClient'
import apiRoutes from 'lib/api/apiRoutes'

import { getTimestamp } from 'lib/datetime'
import {
  SearchSettings,
  SearchSettingsRepository,
  SearchSettingsViewConfig,
  SearchSettingsViewType
} from 'lib/services/searchSettings/searchSettingsRepository'

export const ApiStorageSearchSettingsRepository = (viewConfig: SearchSettingsViewConfig): SearchSettingsRepository => {
  const calcTime = (dateRange: number | undefined, time: number | string | undefined) => {
    let is_seconds_format = false
    const is_time_exit = time !== undefined && time !== ''
    if (is_time_exit && typeof time === 'string') {
      is_seconds_format = /^\d{10}$/.test(time) && !Number.isNaN(Number(time)) && Number(time) > 0
    }

    // if time is seconds format and dateRange is undefined --> return time
    if (is_seconds_format && dateRange === null) {
      return { dateRange: undefined, time }
    }

    // if time is iso format --> convert iso to seconds format and mark dateRange undefined since user probably meant to save start/end
    if (is_time_exit && typeof time === 'string' && !is_seconds_format) {
      return { dateRange: undefined, time: getTimestamp(time) }
    }

    // othewise return undefined
    return { dateRange, time: undefined }
  }

  const getAllMessageLogSearchSettings = async (accountId: string, domainId: string | undefined) => {
    let resp: any = []

    if (domainId) {
      resp = await restClient(apiRoutes.GET_DOMAIN_SAVED_SEARCHES, {
        urlParams: { egdAccountId: accountId, domainId }
      })
    } else {
      resp = await restClient(apiRoutes.GET_ACCOUNT_SAVED_SEARCHES, {
        urlParams: { egdAccountId: accountId }
      })
    }

    return resp.data.result.map((item: any) => ({
      messageSearchFilters: {
        selectedDomainId: item.filters.selected_domain_id,
        direction: item.filters.direction,
        dateRange: calcTime(item.filters.date_range, item.filters.start_iso).dateRange,
        action: item.filters.action,
        deliveryStatus: item.filters.delivery_status,
        reason: item.filters.reason,
        search_str: item.filters.search_str,
        start: calcTime(item.filters.date_range, item.filters.start_iso).time,
        end: calcTime(item.filters.date_range, item.filters.end_iso).time
      },
      id: item.id,
      name: item.name
    }))
  }

  const getAll = async () => {
    try {
      if (viewConfig.type === SearchSettingsViewType.MESSAGE_LOG) {
        return getAllMessageLogSearchSettings(viewConfig.accountId, viewConfig.domainId)
      }
      // TODO: Implement for quarantine
      return []
    } catch (e) {
      throw new Error(`Failed to get account search settings for account_id: ${viewConfig.accountId}`)
    }
  }

  const addMessageLogSearchSettings = async (accountId: string, domainId: string | undefined, item: SearchSettings) => {
    if (
      item.messageSearchFilters.dateRange &&
      item.messageSearchFilters.start &&
      item.messageSearchFilters.start === undefined
    ) {
      throw new Error('Start date is required when date range is set')
    }
    const data = {
      name: item.name,
      filters: {
        selected_domain_id: item.messageSearchFilters.selectedDomainId,
        direction: item.messageSearchFilters.direction,
        date_range: item.messageSearchFilters.dateRange,
        action: item.messageSearchFilters.action,
        delivery_status: item.messageSearchFilters.deliveryStatus,
        reason: item.messageSearchFilters.reason,
        search_str: item.messageSearchFilters.search_str,
        start_iso: item.messageSearchFilters.dateRange === undefined ? item.messageSearchFilters.start : '',
        end_iso: item.messageSearchFilters.dateRange === undefined ? item.messageSearchFilters.end : ''
      }
    }

    if (domainId) {
      await restClient(apiRoutes.SAVE_DOMAIN_SAVED_SEARCHES, {
        urlParams: { egdAccountId: accountId, domainId },
        data
      })
    } else {
      await restClient(apiRoutes.SAVE_ACCOUNT_SAVED_SEARCHES, {
        urlParams: { egdAccountId: accountId },
        data
      })
    }
    return undefined
  }

  const add = async (item: SearchSettings): Promise<void> => {
    try {
      if (viewConfig.type === SearchSettingsViewType.MESSAGE_LOG) {
        return addMessageLogSearchSettings(viewConfig.accountId, viewConfig.domainId, item)
      }
      return undefined
    } catch (e) {
      throw new Error(`Failed to save account search settings for account_id: ${viewConfig.accountId}`)
    }
  }

  const deleteMessageLogSearchSettings = async (accountId: string, domainId: string | undefined, searchId: string) => {
    if (domainId) {
      await restClient(apiRoutes.DELETE_DOMAIN_SAVED_SEARCH, {
        urlParams: { egdAccountId: accountId, domainId, searchId }
      })
    } else {
      await restClient(apiRoutes.DELETE_ACCOUNT_SAVED_SEARCH, {
        urlParams: { egdAccountId: accountId, searchId }
      })
    }
    return undefined
  }

  const deleteFn = async (id: string): Promise<void> => {
    try {
      if (viewConfig.type === SearchSettingsViewType.MESSAGE_LOG) {
        return deleteMessageLogSearchSettings(viewConfig.accountId, viewConfig.domainId, id)
      }
      return undefined
    } catch (e) {
      throw new Error(`Failed to delete account search settings for account_id: ${viewConfig.accountId}`)
    }
  }

  return Object.freeze({
    getAll,
    add,
    delete: deleteFn
  })
}
