import wizardEvents from 'config/trackingEvents/wizardEvents'
import webuiEvents from 'config/trackingEvents/webuiEvents'
import adminEvents from 'config/trackingEvents/adminEvents'
import emailLog from 'config/trackingEvents/emailLogEvents'

const TRACKING_EVENTS = {
  WIZARD: wizardEvents,
  WEBUI: webuiEvents,
  ADMIN: adminEvents,
  EMAIL_LOG: emailLog
} as const

export { TRACKING_EVENTS }
