import { useMemo, useEffect, useCallback, useState, Dispatch, SetStateAction } from 'react'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { AvailableSettings, SenderPolicy, SenderPolicyStatus } from 'types/Settings'
import routesConfig from 'lib/routesConfig'

import {
  getAccountSettings,
  getDomainSettings,
  resetAccountAndDomainSettings,
  updateAccountSettings,
  updateDomainSettings
} from 'redux/features/settings/settingsSlice'

import { isSuccess } from 'redux/toolkit/api'
import { useInboundSettingsRights } from 'components/libs/userRights/pages/useInboundSettingsRights'

export interface State {
  data: SenderPolicy[]
  availablePolicies: SenderPolicyStatus[]
  isDirtyForm: boolean
}

export interface EventHandlers {
  onAdd: (item: SenderPolicy) => void
  onRemove: (id: string) => void
  onBulkEdit: () => void
  helpConfig: {
    isOpen: boolean
    onHelpClick: () => void
    onCloseHelp: () => void
  }
  setDirtyForm: Dispatch<SetStateAction<boolean>>
}

export type UseSenderPoliciesLogic = [State, EventHandlers]

const SETTINGS_LIST = [AvailableSettings.SENDER_POLICIES]

export const useSenderPoliciesLogic = (): UseSenderPoliciesLogic => {
  const dispatch = useAppDispatch()
  const [isHelpDialogOpened, setIsHelpDialogOpened] = useState<boolean>(false)

  const {
    accessTokenObject,
    isUpdateAccountSettingsSuccess,
    isUpdateDomainSettingsSuccess,
    accountSettings,
    domainSettings
  } = useAppSelector(_stores => ({
    accessTokenObject: _stores.auth.accessTokenObject,
    isUpdateAccountSettingsSuccess: isSuccess(_stores.settings.updateAccountSettingsApiStatus),
    isUpdateDomainSettingsSuccess: isSuccess(_stores.settings.updateDomainSettingsApiStatus),
    accountSettings: _stores.settings.accountSettings,
    domainSettings: _stores.settings.domainSettings
  }))
  const { canQuarantineSettings } = useInboundSettingsRights()

  const [isDirtyForm, setDirtyForm] = useState<boolean>(false)

  // init
  useEffect(() => {
    if (accessTokenObject?.pdDomainId) {
      dispatch(getDomainSettings({ domainId: accessTokenObject?.pdDomainId, settings: SETTINGS_LIST }))
    } else {
      dispatch(getAccountSettings(SETTINGS_LIST))
    }
    // eslint-disable-next-line
  }, [])

  const onHelpClick = useCallback(() => {
    setIsHelpDialogOpened(true)
  }, [])

  const onCloseHelp = useCallback(() => {
    setIsHelpDialogOpened(false)
  }, [])

  // update state on add/remove
  useEffect(() => {
    if (isUpdateAccountSettingsSuccess || isUpdateDomainSettingsSuccess) {
      if (accessTokenObject?.pdDomainId) {
        dispatch(getDomainSettings({ domainId: accessTokenObject?.pdDomainId, settings: SETTINGS_LIST }))
      } else {
        dispatch(getAccountSettings(SETTINGS_LIST))
      }
      setDirtyForm(false)
    }
  }, [dispatch, accessTokenObject, isUpdateAccountSettingsSuccess, isUpdateDomainSettingsSuccess])

  // unmount
  useEffect(
    () => () => {
      dispatch(resetAccountAndDomainSettings())
    },
    [dispatch]
  )

  const data = useMemo(() => {
    const settings = accessTokenObject?.pdDomainId ? domainSettings : accountSettings
    return settings.sender_policies ? (JSON.parse(settings.sender_policies as string) as SenderPolicy[]) : []
  }, [accessTokenObject, domainSettings, accountSettings])

  const updateData = useCallback(
    (policies: SenderPolicy[]) => {
      if (accessTokenObject?.pdDomainId) {
        dispatch(
          updateDomainSettings({
            domainId: accessTokenObject.pdDomainId,
            settings: { [AvailableSettings.SENDER_POLICIES]: policies }
          })
        )
      } else {
        dispatch(updateAccountSettings({ settings: { [AvailableSettings.SENDER_POLICIES]: policies } }))
      }
    },
    [dispatch, accessTokenObject]
  )

  const onAdd = useCallback(
    (item: SenderPolicy) => {
      const policies = [...data, item]
      updateData(policies)
    },
    [data, updateData]
  )

  const onRemove = useCallback(
    (id: string) => {
      const policies = data.filter(entry => entry.id !== id)
      updateData(policies)
    },
    [data, updateData]
  )

  const onBulkEdit = useCallback(() => {
    routesConfig.INBOUND_SETTINGS_SENDER_POLICIES_BULK_EDIT.goto()
  }, [])

  const availablePolicies = useMemo(
    () =>
      canQuarantineSettings
        ? [SenderPolicyStatus.block, SenderPolicyStatus.exempt, SenderPolicyStatus.quarantine]
        : [SenderPolicyStatus.block, SenderPolicyStatus.exempt],
    [canQuarantineSettings]
  )

  return useMemo(
    () => [
      {
        data,
        availablePolicies,
        isDirtyForm
      },
      {
        onAdd,
        onRemove,
        onBulkEdit,
        helpConfig: {
          isOpen: isHelpDialogOpened,
          onHelpClick,
          onCloseHelp
        },
        setDirtyForm
      }
    ],
    [data, availablePolicies, isDirtyForm, onAdd, onRemove, onBulkEdit, isHelpDialogOpened, onHelpClick, onCloseHelp]
  )
}
