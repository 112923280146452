import axios from 'axios'
import { v4 as makeUuid } from 'uuid'
import {
  IEmailLogApiClient,
  IEmailLogGetEmailDetailsRequest,
  IEmailLogGetEmailDetailsResponse,
  IEmailLogSearchParams,
  IEmailLogSearchResponse,
  IEmailLogDeliverEmailRequest,
  IEmailLogDeliverEmailResponse,
  IEmailLogMoveToJunkRequest,
  IEmailLogMoveToJunkResponse,
  IEmailLogTrackEventRequest,
  IEmailLogTrackEventResponse
} from '@barracuda-internal/converged-email-log'

export const emailLogApi = axios.create()

export interface IEmailLogApiClientArgs {
  accessToken: string
}

export const EmailLogApiClient = ({ accessToken }: IEmailLogApiClientArgs): IEmailLogApiClient => {
  emailLogApi.defaults.headers.common.Authorization = `Bearer ${accessToken}`

  const search =
    (abortController: AbortController) =>
    async (params: IEmailLogSearchParams): Promise<IEmailLogSearchResponse> => {
      const { bcc_account_id, ...queryParams } = params
      const res = await emailLogApi.get(`/external/v1/email-log/${bcc_account_id}/search`, {
        signal: abortController.signal,
        params: queryParams,
        headers: {
          'X-Request-ID': makeUuid()
        }
      })
      return res.data
    }

  const getEmailDetails =
    (abortController: AbortController) =>
    async (request: IEmailLogGetEmailDetailsRequest): Promise<IEmailLogGetEmailDetailsResponse> => {
      const { bcc_account_id, message_id, origin } = request
      const res = await emailLogApi.get(`/external/v1/email-log/${bcc_account_id}/email/${message_id}`, {
        signal: abortController.signal,
        params: {
          origin
        },
        headers: {
          'X-Request-ID': makeUuid()
        }
      })
      return res.data
    }

  const deliverEmail =
    (abortController: AbortController) =>
    async (request: IEmailLogDeliverEmailRequest): Promise<IEmailLogDeliverEmailResponse> => {
      const { bcc_account_id } = request.payload
      const res = await emailLogApi.post(`/external/v1/email-log/${bcc_account_id}/email/deliver/pre-signed`, request, {
        signal: abortController.signal,
        headers: {
          'X-Request-ID': makeUuid()
        }
      })
      return res.data
    }

  const moveToJunk =
    (abortController: AbortController) =>
    async (request: IEmailLogMoveToJunkRequest): Promise<IEmailLogMoveToJunkResponse> => {
      const { bcc_account_id } = request.payload
      const res = await emailLogApi.post(
        `/external/v1/email-log/${bcc_account_id}/email/move-to-junk/pre-signed`,
        request,
        {
          signal: abortController.signal,
          headers: {
            'X-Request-ID': makeUuid()
          }
        }
      )
      return res.data
    }

  const trackMixpanelEvent = async (request: IEmailLogTrackEventRequest): Promise<IEmailLogTrackEventResponse> => {
    const { bcc_account_id, ...data } = request
    const res = await emailLogApi.post(`/external/v1/email-log/${bcc_account_id}/track-event`, data, {
      headers: {
        'X-Request-ID': makeUuid()
      }
    })
    return res.data
  }

  return Object.freeze({
    search,
    getEmailDetails,
    deliverEmail,
    moveToJunk,
    trackMixpanelEvent
  })
}
