import { Box, Typography, useTokens } from '@barracuda-internal/bds-core-v2'
import React, { useMemo } from 'react'

const ContentTitleComponent: React.FC<{ title: string; subtitle: string }> = ({ title, subtitle }) => {
  const tokens = useTokens()

  return useMemo(
    () => (
      <Box sx={{ paddingRight: tokens.aliasesSpaceContainerDefault, paddingLeft: tokens.aliasesSpaceContainerDefault }}>
        <Typography
          sx={{
            fontFamily: tokens.aliasesFontFamilyHeader,
            fontSize: tokens.aliasesFontSizeH2,
            fontWeight: tokens.aliasesFontWeightDefault,
            color: tokens.aliasesColorTextStaticEmphasis,
            lineHeight: tokens.aliasesLineHeightH2
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontFamily: tokens.aliasesFontFamilyDefault,
            fontSize: tokens.aliasesFontSizeCaption,
            fontWeight: tokens.aliasesFontWeightEmphasis,
            color: tokens.aliasesColorTextStaticSubtle,
            lineHeight: tokens.aliasesLineHeightCaption
          }}
        >
          {subtitle}
        </Typography>
      </Box>
    ),
    [tokens, title, subtitle]
  )
}

export default ContentTitleComponent
