import { makeStyles } from '@barracuda-internal/bds-core'

export interface StyleProps {
  nonClickable: boolean
}

export default makeStyles(() => ({
  wrapper: {
    '& > div': {
      zIndex: 3000,
      '& > div': {
        pointerEvents: ({ nonClickable }: StyleProps) => (nonClickable ? 'none' : 'auto')
      }
    }
  }
}))
