import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { getDomain, saveDomainSettings } from 'redux/features/domains/domainsSlice'
import { resetGetUnaliasedDomainNames, resetSaveDomainSettings } from 'redux/features/domains/domainsActions'
import { FormField, useFormField } from 'lib/useFormField'
import routesConfig from 'lib/routesConfig'
import { getErrorMessage, isFailed, isPending, isSuccess } from 'redux/toolkit/api'
import { DirectoryService, Domain, GetDomainExtraField, RcptToTarget, SpoolingValue } from 'types/domains'
import { ForceTlsValue, SettingValue } from 'types/Settings'
import {
  EditDomainWarningDialogProps,
  EditDomainWarningDialogType
} from 'components/pages/domains/editDomain/editDomainWarningDialogs/EditDomainWarningDialog'
import { TenantAuthorizationStatus } from 'types/azureAd'
import { EncryptionLogo } from 'types/Encryption'
import { reset as resetEncryption } from 'redux/features/encryption/encryptionSlice'
import { LdapSettings } from 'types/ldap'
import appConfig from 'config/appConfig'
import { setErrorSnackBar } from 'redux/features/app/appSlice'
import { resetSyncNow } from 'redux/features/ldap/ldapSlice'

export interface EditDomainFormErrors {
  ldapNewPassword?: string
  ldapSyncWithUserAutoAdd?: string
}

export interface State {
  domainId: string
  hasChanges: boolean
  shouldConfirmNavigation: boolean
  isSaveDisabled: boolean
  isSavePending: boolean
  isGetDomainPending: boolean
  aliasOf: FormField<string>
  domain: Domain | undefined
  error: string
  spooling: FormField<string | undefined>
  userAutoAdd: FormField<SettingValue | undefined>
  senderSpoof: FormField<SettingValue | undefined>
  rcptToTarget: FormField<RcptToTarget | undefined>
  tenantStatus: FormField<TenantAuthorizationStatus>
  autoSync: FormField<0 | 1 | undefined>
  enableSso: FormField<0 | 1 | undefined>
  directoryServices: FormField<DirectoryService | undefined>
  testUser: FormField<string>
  isAzureAdPollStatsInProgress: FormField<boolean>
  isAzureAdLookupUserPending: FormField<boolean>
  forceTls: FormField<ForceTlsValue | undefined>
  inboundTlsAll: FormField<SettingValue | undefined>
  logoDataB64: FormField<EncryptionLogo | undefined>
  allowreplies: FormField<SettingValue | undefined>
  getReadreceipts: FormField<SettingValue | undefined>
  friendlyname: FormField<string | undefined>
  notificationSubject: FormField<string | undefined>
  htmlNotification: FormField<string | undefined>
  txtNotification: FormField<string | undefined>
  ldapHost: FormField<string | undefined>
  ldapPort: FormField<number | undefined>
  ldapSsl: FormField<SettingValue | undefined>
  ldapUsername: FormField<string | undefined>
  ldapNewPassword: FormField<string | undefined>
  ldapBaseDn: FormField<string | undefined>
  ldapAttributes: FormField<string | undefined>
  ldapTestingEmailAddress: FormField<string | undefined>
  ldapDomainLimited: FormField<SettingValue | undefined>
  ldapUserFilter: FormField<string | undefined>
  ldapSync: FormField<SettingValue | undefined>
  ldapAuth: FormField<SettingValue | undefined>
  ldapAuthFilter: FormField<string | undefined>
  srs: FormField<SettingValue | undefined>
  ldapHighestSerial: FormField<string | undefined>
  inProgress: boolean
  warningDialogProps: EditDomainWarningDialogProps
  errors: EditDomainFormErrors
}

export interface EventHandlers {
  onSave: (ignoreWarnings: boolean) => void
  onCancel: () => void
  setShouldConfirmNavigation: (value: boolean) => void
  openWarningDialog: (props: EditDomainWarningDialogProps) => void
  closeWarningDialog: () => void
  setIsSmtpEncryptionTableDirty: Dispatch<SetStateAction<boolean>>
  clearError: (key: keyof EditDomainFormErrors) => void
}

export type EditDomainForm = [State, EventHandlers]

export const EMPTY_DOMAIN_ALIAS_OF_VALUE = '0'

const DEFAULT_WARNING_DIALOG_PROPS = {
  type: EditDomainWarningDialogType.NONE,
  dialogActions: { onConfirm: () => null, onClose: () => null }
}

export const useEditDomainForm = (domainId: string): EditDomainForm => {
  const dispatch = useAppDispatch()
  const {
    isGetDomainPending,
    isGetDomainFailed,
    domain,
    error,
    isSavePending,
    isSaveSuccess,
    isCnameValidationSuccess,
    isPostValidationSuccess,
    isConfirmValidationSuccess,
    isRemoveValidationSuccess,
    isRemoveLogoSuccess,
    isLdapSyncSuccess,
    isUpdateDomainSettingsSuccess
  } = useAppSelector(_store => ({
    isGetDomainPending: isPending(_store.domains.api.getDomainApiStatus),
    isGetDomainFailed: isFailed(_store.domains.api.getDomainApiStatus),
    isGetDomainSuccess: isSuccess(_store.domains.api.getDomainApiStatus),
    domain: _store.domains.domain,
    error: getErrorMessage(_store.domains.api.getDomainApiStatus) || '',
    isSavePending: isPending(_store.domains.api.saveDomainSettingsApiStatus),
    isSaveSuccess: isSuccess(_store.domains.api.saveDomainSettingsApiStatus),
    isSaveFailed: isFailed(_store.domains.api.saveDomainSettingsApiStatus),
    isCnameValidationSuccess: isSuccess(_store.encryption.cnameValidationApiStatus),
    isPostValidationSuccess: isSuccess(_store.encryption.postValidationApiStatus),
    isConfirmValidationSuccess: isSuccess(_store.encryption.confirmValidationApiStatus),
    isRemoveValidationSuccess: isSuccess(_store.encryption.removeValidationApiStatus),
    isRemoveLogoSuccess: isSuccess(_store.encryption.removeLogoApiStatus),
    isLdapSyncSuccess: isSuccess(_store.ldap.api.postLdapSyncNow),
    isUpdateDomainSettingsSuccess: isSuccess(_store.settings.updateDomainSettingsApiStatus)
  }))
  const [warningDialogProps, setWarningDialogProps] =
    useState<EditDomainWarningDialogProps>(DEFAULT_WARNING_DIALOG_PROPS)
  const [shouldConfirmNavigation, setShouldConfirmNavigation] = useState(false)
  const aliasOf = useFormField(EMPTY_DOMAIN_ALIAS_OF_VALUE)
  const spooling = useFormField<string | undefined>(undefined)
  const userAutoAdd = useFormField<SettingValue | undefined>(undefined)
  const senderSpoof = useFormField<SettingValue | undefined>(undefined)
  const rcptToTarget = useFormField<RcptToTarget | undefined>(undefined)
  const tenantStatus = useFormField<TenantAuthorizationStatus>(TenantAuthorizationStatus.UNKNOWN)
  const autoSync = useFormField<0 | 1 | undefined>(undefined)
  const enableSso = useFormField<0 | 1 | undefined>(undefined)
  const directoryServices = useFormField<DirectoryService | undefined>(undefined)
  const testUser = useFormField('')
  const isAzureAdPollStatsInProgress = useFormField(false)
  const isAzureAdLookupUserPending = useFormField(false)
  const forceTls = useFormField<ForceTlsValue | undefined>(undefined)
  const inboundTlsAll = useFormField<SettingValue | undefined>(undefined)
  const [isSmtpEncryptionTableDirty, setIsSmtpEncryptionTableDirty] = useState<boolean>(false)
  const logoDataB64 = useFormField<EncryptionLogo | undefined>(undefined)
  const allowreplies = useFormField<SettingValue | undefined>(undefined)
  const getReadreceipts = useFormField<SettingValue | undefined>(undefined)
  const friendlyname = useFormField<string | undefined>(undefined)
  const notificationSubject = useFormField<string | undefined>(undefined)
  const htmlNotification = useFormField<string | undefined>(undefined)
  const txtNotification = useFormField<string | undefined>(undefined)
  const ldapHost = useFormField<string | undefined>(undefined)
  const ldapPort = useFormField<number | undefined>(undefined)
  const ldapSsl = useFormField<SettingValue | undefined>(undefined)
  const ldapUsername = useFormField<string | undefined>(undefined)
  const ldapNewPassword = useFormField<string | undefined>(undefined)
  const ldapBaseDn = useFormField<string | undefined>(undefined)
  const ldapAttributes = useFormField<string | undefined>(undefined)
  const ldapTestingEmailAddress = useFormField<string | undefined>(undefined)
  const ldapDomainLimited = useFormField<SettingValue | undefined>(undefined)
  const ldapUserFilter = useFormField<string | undefined>(undefined)
  const ldapSync = useFormField<SettingValue | undefined>(undefined)
  const ldapAuth = useFormField<SettingValue | undefined>(undefined)
  const ldapAuthFilter = useFormField<string | undefined>(undefined)
  const srs = useFormField<SettingValue | undefined>(undefined)
  const ldapHighestSerial = useFormField<string | undefined>(undefined)
  const [errors, setErrors] = useState<EditDomainFormErrors>({})

  const isOptionsActive = useMemo(() => aliasOf.value === EMPTY_DOMAIN_ALIAS_OF_VALUE, [aliasOf.value])
  const optionsHasChanges = useMemo(() => {
    if (!isOptionsActive) {
      return false
    }
    return spooling.isChanged || userAutoAdd.isChanged || senderSpoof.isChanged || rcptToTarget.isChanged
  }, [isOptionsActive, rcptToTarget.isChanged, senderSpoof.isChanged, spooling.isChanged, userAutoAdd.isChanged])

  const domainSmptHasChanges = useMemo(
    () => forceTls.isChanged || inboundTlsAll.isChanged,
    [forceTls.isChanged, inboundTlsAll.isChanged]
  )

  const domainEncryptionHasChanges = useMemo(
    () =>
      logoDataB64.isChanged ||
      allowreplies.isChanged ||
      getReadreceipts.isChanged ||
      friendlyname.isChanged ||
      notificationSubject.isChanged ||
      htmlNotification.isChanged ||
      txtNotification.isChanged,
    [
      logoDataB64.isChanged,
      allowreplies.isChanged,
      getReadreceipts.isChanged,
      friendlyname.isChanged,
      notificationSubject.isChanged,
      htmlNotification.isChanged,
      txtNotification.isChanged
    ]
  )

  const azureAdOptionsHasChanges = useMemo(() => {
    if (
      directoryServices.value !== DirectoryService.AZURE_AD ||
      ![TenantAuthorizationStatus.AUTHORIZED, TenantAuthorizationStatus.UNAUTHORIZED].includes(tenantStatus.value)
    ) {
      return false
    }
    return autoSync.isChanged || enableSso.isChanged
  }, [autoSync.isChanged, tenantStatus.value, directoryServices.value, enableSso.isChanged])

  const shouldSaveLdap: boolean = useMemo(
    () =>
      directoryServices.value === DirectoryService.LDAP &&
      (ldapAttributes.isChanged ||
        ldapHost.isChanged ||
        ldapSsl.isChanged ||
        ldapAuthFilter.isChanged ||
        ldapBaseDn.isChanged ||
        ldapDomainLimited.isChanged ||
        ldapNewPassword.isChanged ||
        ldapPort.isChanged ||
        ldapUserFilter.isChanged ||
        ldapUsername.isChanged),
    [
      directoryServices.value,
      ldapAttributes.isChanged,
      ldapHost.isChanged,
      ldapSsl.isChanged,
      ldapAuthFilter.isChanged,
      ldapBaseDn.isChanged,
      ldapDomainLimited.isChanged,
      ldapNewPassword.isChanged,
      ldapPort.isChanged,
      ldapUserFilter.isChanged,
      ldapUsername.isChanged
    ]
  )

  const hasChanges = useMemo(
    () =>
      optionsHasChanges ||
      aliasOf.isChanged ||
      directoryServices.isChanged ||
      azureAdOptionsHasChanges ||
      domainSmptHasChanges ||
      isSmtpEncryptionTableDirty ||
      domainEncryptionHasChanges ||
      shouldSaveLdap ||
      ldapAuth.isChanged ||
      ldapSync.isChanged ||
      srs.isChanged,
    [
      optionsHasChanges,
      aliasOf.isChanged,
      directoryServices.isChanged,
      azureAdOptionsHasChanges,
      domainSmptHasChanges,
      isSmtpEncryptionTableDirty,
      domainEncryptionHasChanges,
      shouldSaveLdap,
      ldapAuth.isChanged,
      ldapSync.isChanged,
      srs.isChanged
    ]
  )

  const isEncryptionSavedSuccessfully = useMemo(
    () =>
      isCnameValidationSuccess ||
      isPostValidationSuccess ||
      isConfirmValidationSuccess ||
      isRemoveValidationSuccess ||
      isRemoveLogoSuccess,
    [
      isCnameValidationSuccess,
      isPostValidationSuccess,
      isConfirmValidationSuccess,
      isRemoveValidationSuccess,
      isRemoveLogoSuccess
    ]
  )

  const shouldConfirmDisableSpooling = useMemo(
    () =>
      isOptionsActive &&
      domain?.emergencyInboxAutoenabled === SettingValue.ENABLED &&
      spooling.isChanged &&
      spooling.value === SpoolingValue.NO,
    [isOptionsActive, domain, spooling.isChanged, spooling.value]
  )

  const getDomainForEdit = useCallback(() => {
    dispatch(getDomain({ domainId, extraFields: [GetDomainExtraField.SETTINGS, GetDomainExtraField.ENCRYPTION] }))
  }, [dispatch, domainId])

  const openWarningDialog = useCallback(
    (dialogProps: EditDomainWarningDialogProps) => setWarningDialogProps(dialogProps),
    []
  )

  const closeWarningDialog = useCallback(() => setWarningDialogProps(DEFAULT_WARNING_DIALOG_PROPS), [])

  const shouldSaveAzureAd: boolean = useMemo(() => {
    if (
      directoryServices.value !== DirectoryService.AZURE_AD ||
      ![TenantAuthorizationStatus.AUTHORIZED, TenantAuthorizationStatus.UNAUTHORIZED].includes(tenantStatus.value)
    ) {
      return false
    }
    return autoSync.isChanged || enableSso.isChanged
  }, [autoSync.isChanged, tenantStatus.value, directoryServices.value, enableSso.isChanged])

  const ldapSettings: LdapSettings = useMemo(
    () => ({
      host: ldapHost.value || '',
      port: ldapPort.value || 0,
      ssl: ldapSsl.value || SettingValue.DISABLED,
      bindDn: ldapUsername.value || '',
      bindPwE: ldapNewPassword.isChanged && ldapNewPassword.value ? ldapNewPassword.value || '' : undefined,
      baseDn: ldapBaseDn.value || '',
      attrs: ldapAttributes.value || '',
      domainLimited: ldapDomainLimited.value,
      userFilter: ldapUserFilter.value,
      authFilter: ldapAuthFilter.value || appConfig.LDAP.DEFAULT_AUTH_FILTER
    }),
    [
      ldapHost.value,
      ldapPort.value,
      ldapSsl.value,
      ldapUsername.value,
      ldapNewPassword,
      ldapBaseDn.value,
      ldapAttributes.value,
      ldapDomainLimited.value,
      ldapUserFilter.value,
      ldapAuthFilter.value
    ]
  )

  const validateForm = useCallback(() => {
    const formErrors: EditDomainFormErrors = {}
    if (
      directoryServices.value === DirectoryService.LDAP &&
      (!domain?.ldap || ldapNewPassword.isChanged) &&
      !ldapNewPassword.value
    ) {
      formErrors.ldapNewPassword = 'directory_services.ldap.errors.ldap_password_missing'
    }
    if (userAutoAdd.value === SettingValue.ENABLED && ldapSync.value === SettingValue.ENABLED) {
      formErrors.ldapSyncWithUserAutoAdd = 'directory_services.ldap.errors.ldap_sync_and_user_auto_add_conflict'
    }
    setErrors(formErrors)
    if (Object.keys(formErrors).length) {
      dispatch(setErrorSnackBar({ message: 'edit_domain_form_has_errors' }))
    }
    return formErrors
  }, [
    directoryServices.value,
    dispatch,
    domain?.ldap,
    ldapNewPassword.isChanged,
    ldapNewPassword.value,
    ldapSync.value,
    userAutoAdd.value
  ])

  const saveForm = useCallback(
    (ignoreWarnings: boolean) => {
      const formErrors = validateForm()
      if (Object.keys(formErrors).length) {
        return
      }
      const settings = {
        aliasOf: aliasOf.isChanged ? { value: aliasOf.value } : undefined,
        spooling:
          isOptionsActive && spooling.isChanged && typeof spooling.value !== 'undefined'
            ? { value: spooling.value }
            : undefined,
        userAutoAdd:
          isOptionsActive && userAutoAdd.isChanged && typeof userAutoAdd.value !== 'undefined'
            ? { value: userAutoAdd.value }
            : undefined,
        senderSpoof:
          isOptionsActive && senderSpoof.isChanged && typeof senderSpoof.value !== 'undefined'
            ? { value: senderSpoof.value }
            : undefined,
        rcptToTarget:
          isOptionsActive && rcptToTarget.isChanged && typeof rcptToTarget.value !== 'undefined'
            ? { value: rcptToTarget.value }
            : undefined,
        directoryServices:
          directoryServices.isChanged && typeof directoryServices.value !== 'undefined'
            ? { value: directoryServices.value }
            : undefined,
        azureAd: shouldSaveAzureAd
          ? {
              value: {
                autoSync: autoSync.value || 0,
                enableSso: enableSso.value || 0
              }
            }
          : undefined,
        forceTls: forceTls.isChanged && typeof forceTls.value !== 'undefined' ? { value: forceTls.value } : undefined,
        inboundTlsAll:
          inboundTlsAll.isChanged && typeof inboundTlsAll.value !== 'undefined'
            ? { value: inboundTlsAll.value }
            : undefined,
        ldap: shouldSaveLdap
          ? {
              value: ldapSettings
            }
          : undefined,
        ldapAuth:
          directoryServices.value === DirectoryService.LDAP &&
          ldapAuth.isChanged &&
          typeof ldapAuth.value !== 'undefined'
            ? { value: ldapAuth.value }
            : undefined,
        ldapSync:
          directoryServices.value === DirectoryService.LDAP &&
          ldapSync.isChanged &&
          typeof ldapSync.value !== 'undefined'
            ? { value: ldapSync.value }
            : undefined,
        srs: srs.isChanged ? { value: srs.value } : undefined
      }
      const encryption = {
        allowreplies:
          allowreplies.isChanged && typeof allowreplies.value !== 'undefined'
            ? { value: allowreplies.value }
            : undefined,
        getReadreceipts:
          getReadreceipts.isChanged && typeof getReadreceipts.value !== 'undefined'
            ? { value: getReadreceipts.value }
            : undefined,
        logoDataB64:
          logoDataB64.isChanged && typeof logoDataB64.value !== 'undefined'
            ? { value: { ...logoDataB64.value } }
            : undefined,
        friendlyname:
          friendlyname.isChanged && typeof friendlyname.value !== 'undefined'
            ? { value: friendlyname.value }
            : undefined,
        notificationSubject:
          notificationSubject.isChanged && typeof notificationSubject.value !== 'undefined'
            ? { value: notificationSubject.value }
            : undefined,
        htmlNotification:
          htmlNotification.isChanged && typeof htmlNotification.value !== 'undefined'
            ? { value: htmlNotification.value }
            : undefined,
        txtNotification:
          txtNotification.isChanged && typeof txtNotification.value !== 'undefined'
            ? { value: txtNotification.value }
            : undefined
      }
      const shouldSave =
        Object.values(settings).some(v => typeof v !== 'undefined') ||
        Object.values(encryption).some(v => typeof v !== 'undefined')
      if (shouldSave) {
        dispatch(
          saveDomainSettings({
            domainId,
            settings,
            encryption,
            ignoreWarnings
          })
        )
      }
    },
    [
      aliasOf.isChanged,
      aliasOf.value,
      isOptionsActive,
      spooling.isChanged,
      spooling.value,
      userAutoAdd.isChanged,
      userAutoAdd.value,
      forceTls.isChanged,
      forceTls.value,
      inboundTlsAll.isChanged,
      inboundTlsAll.value,
      senderSpoof.isChanged,
      senderSpoof.value,
      rcptToTarget.isChanged,
      rcptToTarget.value,
      directoryServices.isChanged,
      directoryServices.value,
      shouldSaveAzureAd,
      autoSync.value,
      enableSso.value,
      allowreplies.isChanged,
      allowreplies.value,
      getReadreceipts.isChanged,
      getReadreceipts.value,
      logoDataB64.isChanged,
      logoDataB64.value,
      friendlyname.isChanged,
      friendlyname.value,
      notificationSubject.isChanged,
      notificationSubject.value,
      htmlNotification.isChanged,
      htmlNotification.value,
      txtNotification.isChanged,
      txtNotification.value,
      shouldSaveLdap,
      ldapSettings,
      ldapAuth.isChanged,
      ldapAuth.value,
      ldapSync.isChanged,
      ldapSync.value,
      srs.isChanged,
      srs.value,
      dispatch,
      domainId,
      validateForm
    ]
  )

  const onSave = useCallback(
    (ignoreWarnings: boolean) => {
      if (domainId) {
        if (!ignoreWarnings && shouldConfirmDisableSpooling) {
          openWarningDialog({
            type: EditDomainWarningDialogType.DISABLE_SPOOLING,
            dialogActions: {
              onClose: closeWarningDialog,
              onConfirm: () => saveForm(ignoreWarnings)
            }
          })
          return
        }
        closeWarningDialog()
        saveForm(ignoreWarnings)
      }
    },
    [domainId, shouldConfirmDisableSpooling, closeWarningDialog, saveForm, openWarningDialog]
  )

  const onCancel = useCallback(() => {
    routesConfig.DOMAINS.goto()
  }, [])

  const clearError = useCallback(
    (key: keyof EditDomainFormErrors) => {
      delete errors[key]
      setErrors({ ...errors })
    },
    [errors]
  )

  const inProgress = useMemo(() => isGetDomainPending || isSavePending, [isGetDomainPending, isSavePending])

  const isSaveDisabled = useMemo(() => !hasChanges || inProgress, [hasChanges, inProgress])

  useEffect(() => {
    if (domainId) {
      getDomainForEdit()
    }
    return () => {
      dispatch(resetSaveDomainSettings())
      dispatch(resetGetUnaliasedDomainNames())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isGetDomainFailed) {
      routesConfig.DOMAINS.goto()
    }
  }, [isGetDomainFailed])

  useEffect(() => {
    if (isSaveSuccess || isEncryptionSavedSuccessfully || isUpdateDomainSettingsSuccess) {
      getDomainForEdit()
      dispatch(resetSaveDomainSettings())
      dispatch(resetEncryption())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaveSuccess, isEncryptionSavedSuccessfully, isUpdateDomainSettingsSuccess])

  useEffect(() => {
    if (isLdapSyncSuccess) {
      dispatch(resetSyncNow())
      getDomainForEdit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLdapSyncSuccess])

  return useMemo(
    () => [
      {
        hasChanges,
        shouldConfirmNavigation,
        aliasOf,
        isSaveDisabled,
        isSavePending,
        isGetDomainPending,
        domain,
        error,
        spooling,
        userAutoAdd,
        senderSpoof,
        rcptToTarget,
        tenantStatus,
        autoSync,
        enableSso,
        forceTls,
        inboundTlsAll,
        logoDataB64,
        allowreplies,
        getReadreceipts,
        friendlyname,
        notificationSubject,
        htmlNotification,
        txtNotification,
        inProgress,
        warningDialogProps,
        domainId,
        directoryServices,
        testUser,
        isAzureAdPollStatsInProgress,
        isAzureAdLookupUserPending,
        isSmtpEncryptionTableDirty,
        ldapHost,
        ldapPort,
        ldapSsl,
        ldapUsername,
        ldapNewPassword,
        ldapBaseDn,
        ldapAttributes,
        ldapTestingEmailAddress,
        ldapDomainLimited,
        ldapUserFilter,
        ldapSync,
        ldapAuth,
        ldapAuthFilter,
        srs,
        ldapHighestSerial,
        errors
      },
      {
        onSave,
        onCancel,
        setShouldConfirmNavigation,
        openWarningDialog,
        closeWarningDialog,
        setIsSmtpEncryptionTableDirty,
        clearError
      }
    ],
    [
      hasChanges,
      shouldConfirmNavigation,
      aliasOf,
      isSaveDisabled,
      isSavePending,
      isGetDomainPending,
      domain,
      error,
      spooling,
      userAutoAdd,
      senderSpoof,
      rcptToTarget,
      tenantStatus,
      autoSync,
      enableSso,
      forceTls,
      inboundTlsAll,
      logoDataB64,
      allowreplies,
      getReadreceipts,
      friendlyname,
      notificationSubject,
      htmlNotification,
      txtNotification,
      inProgress,
      warningDialogProps,
      domainId,
      directoryServices,
      testUser,
      isAzureAdPollStatsInProgress,
      isAzureAdLookupUserPending,
      isSmtpEncryptionTableDirty,
      ldapHost,
      ldapPort,
      ldapSsl,
      ldapUsername,
      ldapNewPassword,
      ldapBaseDn,
      ldapAttributes,
      ldapTestingEmailAddress,
      ldapDomainLimited,
      ldapUserFilter,
      ldapSync,
      ldapAuth,
      ldapAuthFilter,
      srs,
      ldapHighestSerial,
      onSave,
      onCancel,
      openWarningDialog,
      closeWarningDialog,
      errors,
      clearError
    ]
  )
}
