import React from 'react'
import { Line } from 'react-chartjs-2'
import {
  CategoryScale,
  Chart,
  Filler,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip
} from 'chart.js'
import deepmerge from 'deepmerge'

import {
  Grid,
  EmptyState,
  EmptyStateProps,
  ChartDimension,
  LineChartData,
  LineChartOptions
} from '@barracuda-internal/bds-core'
import * as bdsTheme from '@barracuda-internal/bds-core/dist/styles/themes/index'

export type LineChartProps = {
  /**
   * Chart data that is to be displayed in the chart.  See the chart.js docs for data structure
   *
   * Chart.js docs: https://www.chartjs.org/docs/latest/charts/line.html
   */
  chartData: LineChartData
  /**
   * Object that has height and width of the chart
   */
  chartDimension: ChartDimension
  /**
   * Props to render the empty state component when there is no data
   */
  emptyState: EmptyStateProps
  /**
   * Boolean indicating if there is data to display in the chart
   */
  hasEntries: boolean
  /**
   * Optional: Object that overrides chart and/or tooltip options
   *
   * Chart options: https://www.chartjs.org/docs/latest/charts/line.html
   * Tooltip options: https://www.chartjs.org/docs/latest/configuration/tooltip.html
   */
  lineChartOptions?: LineChartOptions | undefined
  /**
   * Optional: boolean to maintain the original canvas aspect ratio (width / height) when resizing. For this option to work properly the chart must be in its own dedicated container.  Set to false by default
   *
   * See chart.js docs: https://www.chartjs.org/docs/latest/api/interfaces/Defaults.html#maintainaspectratio
   */
  maintainAspectRatio?: boolean
  /**
   * Optional: boolean to display chart legend.  Set to false by default
   */
  showLegend?: boolean
  /**
   * Optional: boolean to display stacked area line chart  Set to false by default
   */
  stackedLineChart?: boolean
}

Chart.register(CategoryScale, Filler, LinearScale, LineController, LineElement, PointElement, Title, TimeScale, Tooltip)

export function LineChart({
  chartDimension,
  chartData,
  emptyState,
  hasEntries,
  lineChartOptions = undefined,
  maintainAspectRatio = false,
  showLegend = false,
  stackedLineChart = false
}: LineChartProps) {
  const { height, width } = chartDimension

  // Default line chart config
  const defaultChartConfig = {
    maintainAspectRatio,
    plugins: {
      legend: {
        display: showLegend
      },
      tooltip: {
        borderColor: bdsTheme.default.palette.divider,
        borderWidth: 2,
        boxPadding: 15,
        titleColor: bdsTheme.default.palette.text.primary,
        titleFont: {
          size: 14
        },
        backgroundColor: bdsTheme.default.palette.background.default,
        bodyColor: bdsTheme.default.palette.text.primary,
        bodySpacing: 6,
        padding: bdsTheme.default.spacing(1.5),
        bodyFont: {
          size: 14
        },
        usePointStyle: true
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        stacked: !!stackedLineChart
      }
    }
  } as LineChartOptions

  // If lineChartOptions are provided merge them into defaultChartConfig, if not use defaultChartConfig
  const options = lineChartOptions ? deepmerge(defaultChartConfig, lineChartOptions) : defaultChartConfig

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ width, height }}
    >
      {hasEntries ? <Line data={chartData as any} options={options as any} /> : <EmptyState {...emptyState} />}
    </Grid>
  )
}
