import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import onScroll, { APP_HEADER_HEIGHT, SCROLL_DElAY, scrollToElement } from 'lib/onScroll'
import { isPending, isSuccess } from 'redux/toolkit/api'
import usePreviousValue from 'lib/usePreviousValue'
import { setEmailServerSettings } from 'redux/features/emailServer/emailServerSlice'
import { setIntercomUser, trackEventInAllServices, TRACKING_EVENTS } from 'lib/monitoring/monitoringService'
import { gotoAdminEntryPathFromWizard } from 'lib/routesConfig'

import { SectionsIsVerifiedStates, ServerAndMxSetupSteps } from 'components/pages/onboardWizard/onboardWizardTypes'

export type IsActiveNavItem = boolean
export type IsVerifiedNavItem = boolean
export type IsErroredNavItem = boolean
export type NavItemStatus = [IsActiveNavItem, IsVerifiedNavItem, IsErroredNavItem]
export type NavItemStatuses = { [key in ServerAndMxSetupSteps]: NavItemStatus }
export type ButtonStatuses = { disabled: boolean; inProgress: boolean }

export interface State {
  navItemStatuses: NavItemStatuses
  sectionsIsVerifiedStates: SectionsIsVerifiedStates
  saveAndExitButtonStatuses: ButtonStatuses
  isReqestedToCompleteSetup: boolean
  // added for tests
  prevIsReqestedToCompleteSetup: boolean | undefined
  currentStep: ServerAndMxSetupSteps
}

export interface EventHandlers {
  setSectionVerifiedState: (
    section: ServerAndMxSetupSteps,
    localSetter: Dispatch<SetStateAction<boolean>>
  ) => (result: boolean) => void
  onSelectSection: (newSection: ServerAndMxSetupSteps, disableAutoScroll?: boolean) => void
  setIsSpecifyEmailServerSectionHasError: Dispatch<SetStateAction<boolean>>
  setIsAddMxRecordsSectionHasError: Dispatch<SetStateAction<boolean>>
  setIsRemoveOldMxRecordsSectionHasError: Dispatch<SetStateAction<boolean>>
  onSaveAndExit: () => void
  onCompleteSetup: () => void
}

export interface TestHelpers {
  [key: string]: Dispatch<SetStateAction<any>>
}

export type ServerAndMxSetupLogic = [State, EventHandlers, TestHelpers]

const MIN_HEIGHT_FOR_CONTENT_2_AND_3 = 1000
const SECTIONS = [
  ServerAndMxSetupSteps.specifyEmailServer,
  ServerAndMxSetupSteps.addNewMxRecords,
  ServerAndMxSetupSteps.removeOldMxRecords
]

export function getSectionElement(sectionName: ServerAndMxSetupSteps): HTMLAreaElement | undefined {
  const sectionElement = document.querySelectorAll(`[data-wizard-section=${sectionName}]`)

  if (sectionElement.length) {
    return sectionElement[0] as HTMLAreaElement
  }

  return undefined
}

export const useServerAndMxSetupLogic = (): ServerAndMxSetupLogic => {
  const dispatch = useAppDispatch()
  const { user, accessToken, isSaveSettingsInProgress, isSaveSettingsSuccess, emailServer, mxRecords, oldMxRecords } =
    useAppSelector(_store => ({
      user: _store.auth.accessTokenObject,
      accessToken: _store.auth.accessToken,
      isSaveSettingsInProgress: isPending(_store.emailServer.setEmailServerSettingsApiStatus),
      isSaveSettingsSuccess: isSuccess(_store.emailServer.setEmailServerSettingsApiStatus),
      emailServer: _store.emailServer.emailServer,
      mxRecords: _store.emailServer.mxRecords,
      oldMxRecords: _store.emailServer.oldMxRecords
    }))
  const [currentStep, setCurrentStep] = useState<ServerAndMxSetupSteps>(ServerAndMxSetupSteps.specifyEmailServer)
  const [isReqestedToCompleteSetup, setIsReqestedToCompleteSetup] = useState<boolean>(false)
  const [validatedSections, setVelidatedSections] = useState<ServerAndMxSetupSteps[]>([])
  const [isSpecifyEmailServerSectionHasError, setIsSpecifyEmailServerSectionHasError] = useState<boolean>(false)
  const [isAddMxRecordsSectionHasError, setIsAddMxRecordsSectionHasError] = useState<boolean>(false)
  const [isRemoveOldMxRecordsSectionHasError, setIsRemoveOldMxRecordsSectionHasError] = useState<boolean>(false)
  const prevIsReqestedToCompleteSetup = usePreviousValue(isReqestedToCompleteSetup)

  onScroll({
    cb: ({ currPos }: { currPos: { y: number } }) => {
      const isScrolledToBottom = document.body.clientHeight - window.innerHeight - 20 < currPos.y
      const bottomContentIndex = window.innerHeight > MIN_HEIGHT_FOR_CONTENT_2_AND_3 ? 1 : 2
      const currentSectionIndex = isScrolledToBottom
        ? bottomContentIndex
        : SECTIONS.map(sectionName => {
            const sectionElement = getSectionElement(sectionName)

            return sectionElement ? sectionElement.offsetTop - APP_HEADER_HEIGHT - 50 : 0
          }).reduce(
            (all: number, sectionOffset: number, idx: number) =>
              currPos.y >= sectionOffset - SCROLL_DElAY ? idx : all,
            0
          )

      const activeSection = SECTIONS[currentSectionIndex]

      if (activeSection !== currentStep) {
        setCurrentStep(activeSection)
      }
    },
    deps: [currentStep],
    throttle: 100
  })

  // saved or completed
  useEffect(() => {
    if (isSaveSettingsSuccess) {
      gotoAdminEntryPathFromWizard()
    }
  }, [isSaveSettingsSuccess])

  const sectionsErrorStates = useMemo(
    () => ({
      [ServerAndMxSetupSteps.specifyEmailServer]: isSpecifyEmailServerSectionHasError,
      [ServerAndMxSetupSteps.addNewMxRecords]: isAddMxRecordsSectionHasError,
      [ServerAndMxSetupSteps.removeOldMxRecords]: isRemoveOldMxRecordsSectionHasError
    }),
    [isSpecifyEmailServerSectionHasError, isAddMxRecordsSectionHasError, isRemoveOldMxRecordsSectionHasError]
  )

  // reset isRequestedToCompleteSetup
  useEffect(() => {
    if (isReqestedToCompleteSetup && validatedSections.length === SECTIONS.length) {
      setIsReqestedToCompleteSetup(false)
    } else if (prevIsReqestedToCompleteSetup) {
      const erroredSection = [
        ServerAndMxSetupSteps.specifyEmailServer,
        ServerAndMxSetupSteps.addNewMxRecords,
        ServerAndMxSetupSteps.removeOldMxRecords
      ].find(section => sectionsErrorStates[section])

      if (erroredSection) {
        scrollToElement(getSectionElement(erroredSection))
        setCurrentStep(erroredSection)
      }
    }
  }, [isReqestedToCompleteSetup, sectionsErrorStates, prevIsReqestedToCompleteSetup, validatedSections])

  const sectionsIsVerifiedStates = useMemo(
    () => ({
      [ServerAndMxSetupSteps.specifyEmailServer]:
        !sectionsErrorStates[ServerAndMxSetupSteps.specifyEmailServer] && !!emailServer?.verified,
      [ServerAndMxSetupSteps.addNewMxRecords]:
        !sectionsErrorStates[ServerAndMxSetupSteps.addNewMxRecords] &&
        !!mxRecords &&
        !Object.values(mxRecords)?.some(record => !record.verified),
      [ServerAndMxSetupSteps.removeOldMxRecords]:
        !sectionsErrorStates[ServerAndMxSetupSteps.removeOldMxRecords] &&
        !!oldMxRecords &&
        !oldMxRecords.some(record => !record.verified)
    }),
    [mxRecords, oldMxRecords, emailServer, sectionsErrorStates]
  )

  const navItemStatuses: NavItemStatuses = useMemo(
    () =>
      [
        ServerAndMxSetupSteps.specifyEmailServer,
        ServerAndMxSetupSteps.addNewMxRecords,
        ServerAndMxSetupSteps.removeOldMxRecords
      ].reduce(
        (all, stepName) => ({
          ...all,
          [stepName]: [currentStep === stepName, sectionsIsVerifiedStates[stepName], sectionsErrorStates[stepName]]
        }),
        {} as NavItemStatuses
      ),
    [currentStep, sectionsIsVerifiedStates, sectionsErrorStates]
  )

  const saveAndExitButtonStatuses = useMemo(
    () => ({
      disabled: isSaveSettingsInProgress && prevIsReqestedToCompleteSetup,
      inProgress: isSaveSettingsInProgress && !prevIsReqestedToCompleteSetup
    }),
    [isSaveSettingsInProgress, prevIsReqestedToCompleteSetup]
  )

  const isFinishedSetup = useMemo(
    () => !Object.values(sectionsIsVerifiedStates).some(verified => !verified),
    [sectionsIsVerifiedStates]
  )

  const onSaveAndExit = useCallback(() => {
    trackEventInAllServices(TRACKING_EVENTS.WIZARD.SAVE_AND_EXIT)
    dispatch(setEmailServerSettings({ completed: false }))
  }, [dispatch])

  const onCompleteSetup = useCallback(() => {
    setVelidatedSections([])
    setIsReqestedToCompleteSetup(true)

    if (isFinishedSetup) {
      trackEventInAllServices(TRACKING_EVENTS.WIZARD.COMPLETE)
      if (user && accessToken) {
        setIntercomUser(user, accessToken, { company: { finished_wizard: true } })
      }
      dispatch(setEmailServerSettings({ completed: true }))
    }
  }, [dispatch, isFinishedSetup, user, accessToken])

  const onSelectSection = useCallback((newSection: ServerAndMxSetupSteps, disableAutoScroll = false) => {
    setCurrentStep(newSection)
    if (!disableAutoScroll) {
      scrollToElement(getSectionElement(newSection))
    }
  }, [])

  const setSectionVerifiedState = useCallback(
    (section: ServerAndMxSetupSteps, localSetter: Dispatch<SetStateAction<boolean>>) => (result: boolean) => {
      if (!validatedSections.includes(section)) {
        setVelidatedSections(validatedSections.concat(section))
      }
      localSetter(result)
    },
    [validatedSections]
  )

  return useMemo(
    () => [
      {
        navItemStatuses,
        sectionsIsVerifiedStates,
        saveAndExitButtonStatuses,
        isReqestedToCompleteSetup,
        prevIsReqestedToCompleteSetup,
        currentStep
      },
      {
        setSectionVerifiedState,
        onSelectSection,
        onSaveAndExit,
        onCompleteSetup,
        setIsSpecifyEmailServerSectionHasError,
        setIsAddMxRecordsSectionHasError,
        setIsRemoveOldMxRecordsSectionHasError
      },
      {
        setVelidatedSections
      }
    ],
    [
      navItemStatuses,
      sectionsIsVerifiedStates,
      saveAndExitButtonStatuses,
      isReqestedToCompleteSetup,
      prevIsReqestedToCompleteSetup,
      currentStep,
      onSelectSection,
      onSaveAndExit,
      onCompleteSetup,
      setSectionVerifiedState,
      setVelidatedSections,
      setIsSpecifyEmailServerSectionHasError,
      setIsAddMxRecordsSectionHasError,
      setIsRemoveOldMxRecordsSectionHasError
    ]
  )
}
