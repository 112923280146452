import { Box, useTokens } from '@barracuda-internal/bds-core-v2'
import React, { PropsWithChildren, useMemo } from 'react'

const SetupFlowStepsContainer: React.FC<PropsWithChildren> = ({ children }) => {
  const tokens = useTokens()

  return useMemo(
    () => (
      <Box
        data-testid="SetupFlowStepsContainer"
        sx={{
          flexGrow: 1,
          width: '182px',
          padding: `${tokens.aliasesSpaceContainerSpacious} ${tokens.aliasesSpaceContainerSpacious} ${tokens.aliasesSpaceContainerSpacious} 0`
        }}
      >
        {children}
      </Box>
    ),
    [children, tokens.aliasesSpaceContainerSpacious]
  )
}

export default SetupFlowStepsContainer
