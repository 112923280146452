import { Box } from '@barracuda-internal/bds-core-v2'
import React, { PropsWithChildren, useMemo } from 'react'

const PageContainer: React.FC<PropsWithChildren> = ({ children }) =>
  useMemo(
    () => (
      <Box
        data-testid="PageContainer"
        sx={{
          position: 'fixed',
          top: 0,
          bottom: 0,
          right: 0,
          // sidebar width
          left: '48px',
          backgroundColor: 'hsla(248,0%,100%,1)',
          backgroundImage: `radial-gradient(at 73% 92%, hsla(345, 100%, 100%, 1) 0px, transparent 50%),
                        radial-gradient(at 82% 2%, hsla(110, 100%, 100%, 1) 0px, transparent 50%),
                        radial-gradient(at 58% 89%, hsla(201, 100%, 85%, 0.7) 0px, transparent 50%),
                        radial-gradient(at 89% 27%, hsla(201, 100%, 85%, 0.7) 0px, transparent 50%),
                        radial-gradient(at 1% 29%, hsla(225, 100%, 100%, 1) 0px, transparent 50%),
                        radial-gradient(at 27% 2%, hsla(85, 100%, 100%, 1) 0px, transparent 50%),
                        radial-gradient(at 9% 99%, hsla(48, 100%, 100%, 1) 0px, transparent 50%)`
        }}
      >
        {children}
      </Box>
    ),
    [children]
  )

export default PageContainer
