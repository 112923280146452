import { useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { getEmailServerSettings } from 'redux/features/emailServer/emailServerSlice'
import { isFailed, isPending, isSuccess } from 'redux/toolkit/api'
import useUserRights from 'components/libs/userRights/useUserRights'
import { gotoAdminEntryPathFromWizard } from 'lib/routesConfig'
import { isMyFeatureOn } from 'lib/splitio'
import { FEATURES } from 'lib/splitioFeatures'
import { EGD_PRODUCT_ID, getPamProductData, IP_PRODUCT_ID, IR_PRODUCT_ID } from 'redux/features/pam/pamSlice'

export interface State {
  loading: boolean
  failed: boolean
  newWizard?: boolean
}

export type WizardSelectorLogic = [State]

export const useWizardSelectorLogic = (): WizardSelectorLogic => {
  const dispatch = useAppDispatch()
  const {
    isGetEmailServerSettingsIsSuccess,
    isProductDataSuccess,
    isGetEmailServerSettingsInProgress,
    isGetEmailServerSettingsFailed,
    emailServerSettings,
    productData
  } = useAppSelector(_store => ({
    isGetEmailServerSettingsIsSuccess: isSuccess(_store.emailServer.getEmailServerSettingsApiStatus),
    isProductDataSuccess: isSuccess(_store.pam.api.getProductData),
    isGetEmailServerSettingsInProgress: isPending(_store.emailServer.getEmailServerSettingsApiStatus),
    isGetEmailServerSettingsFailed: isFailed(_store.emailServer.getEmailServerSettingsApiStatus),
    emailServerSettings: _store.emailServer.emailServerSettings,
    productData: _store.pam.productData
  }))
  const { userFeatures } = useUserRights()
  const isEGDReactOverviewEnabled = isMyFeatureOn(FEATURES.EGD_React_Overview_Rollout)

  useEffect(() => {
    dispatch(getEmailServerSettings())
    dispatch(getPamProductData())
    // eslint-disable-next-line
  }, [])

  // eslint-disable-next-line consistent-return
  const newWizard = useMemo(() => {
    if (isGetEmailServerSettingsIsSuccess && isProductDataSuccess) {
      // Enter admin if wizard is completed
      if (emailServerSettings?.wizardCompleted) {
        gotoAdminEntryPathFromWizard('', isEGDReactOverviewEnabled)
        return undefined
      }

      let hasIp = false
      let hasIr = false
      let hasEgd = false
      productData?.data.subscriptionGroups.forEach(subscriptionGroup => {
        subscriptionGroup.subscriptions.forEach(subscription => {
          if (subscription.product.id === EGD_PRODUCT_ID && subscription.isTrial) {
            hasEgd = true
          }
          if (subscription.product.id === IP_PRODUCT_ID && subscription.isTrial) {
            hasIp = true
          }
          if (subscription.product.id === IR_PRODUCT_ID && subscription.isTrial) {
            hasIr = true
          }
        })
      })

      // If there is at least one product missing we show old setup
      if (!hasIp || !hasIr || !hasEgd) {
        return false
      }

      // If there are settings (first step in old wizard is region) and no version means the wizard was already started and should be v1
      if (emailServerSettings?.region && !emailServerSettings.version) {
        return false
      }

      // If there is version 'v1' we should always end up on v1 no matter the split flag
      if (emailServerSettings?.version === 'v1') {
        return false
      }

      // If there is version 'v2' we should always end up on v2 no matter the split flag
      if (emailServerSettings?.version === 'v2') {
        return true
      }

      return !!userFeatures?.convergedSetupFlowRollout
    }
  }, [
    isGetEmailServerSettingsIsSuccess,
    isProductDataSuccess,
    emailServerSettings,
    productData,
    userFeatures,
    isEGDReactOverviewEnabled
  ])

  return useMemo(
    () => [
      {
        newWizard,
        loading: (isGetEmailServerSettingsInProgress || newWizard === undefined) && !isGetEmailServerSettingsFailed,
        failed: isGetEmailServerSettingsFailed
      }
    ],
    [newWizard, isGetEmailServerSettingsInProgress, isGetEmailServerSettingsFailed]
  )
}
