import React, { useMemo } from 'react'
import Loading from 'components/libs/loading/Loading'
import ErrorPage from 'components/libs/errorPage/ErrorPage'
import { useFormatMessage } from 'lib/localization'
import SetupFlow from '../setupFlow/SetupFlow'
import OnboardWizard from '../onboardWizard/OnboardWizard'
import { useWizardSelectorLogic } from './useWizardSelectorLogic'

const BASE_I18N_KEY = 'ess_wizard.error_page'

const WizardSelector: React.FC = () => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [state] = useWizardSelectorLogic()

  return useMemo(() => {
    if (state.loading) {
      return <Loading />
    }
    if (state.failed) {
      return <ErrorPage title={formatMessage('title')} subtitle={formatMessage('subtitle')} />
    }
    if (state.newWizard === true) {
      return <SetupFlow />
    }
    if (state.newWizard === false) {
      return <OnboardWizard />
    }
    return null
  }, [state, formatMessage])
}

export default WizardSelector
