import { Box, Typography, useTokens } from '@barracuda-internal/bds-core-v2'
import React, { useMemo } from 'react'
import { useFormatMessage } from 'lib/localization'
import productLogo from 'assets/images/barracuda/barracuda-teeth.png'

const BASE_I18N_KEY = 'ess'

const LoadingComponent: React.FC = () => {
  const tokens = useTokens()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  return useMemo(
    () => (
      <Box
        sx={{
          height: '100%',
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          padding: tokens.aliasesSpaceContainerSpacious
        }}
      >
        <img style={{ width: '80px' }} alt="" src={productLogo} />
        <Typography
          sx={{
            fontFamily: tokens.aliasesFontFamilyDefault,
            fontSize: tokens.aliasesFontSizeH4,
            fontWeight: tokens.aliasesFontWeightNeutral,
            color: tokens.aliasesColorTextStaticSubtle,
            lineHeight: tokens.aliasesLineHeightH4
          }}
        >
          {formatMessage('loading')}
        </Typography>
      </Box>
    ),
    [tokens, formatMessage]
  )
}

export default LoadingComponent
