import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useFormatMessage } from 'lib/localization'
import { useAppSelector, useAppDispatch } from 'redux/toolkit/hooks'
import { getErrorMessage, isFailed, isPending, isSuccess } from 'redux/toolkit/api'
import { addDomain, getDomainList } from 'redux/features/domains/domainsSlice'
import { resetAddDomain } from 'redux/features/domains/domainsActions'
import { hasTld, isDomainValid, isValidHostName } from 'lib/validation'
import { AddDomainResult } from 'types/domains'
import routesConfig from 'lib/routesConfig'

export interface FormData {
  domainName: string
  mailServer: string
}

export interface FormErrors {
  domainName?: string
  mailServer?: string
}

export interface State {
  formData: FormData
  isAddDomainPending: boolean
  apiError: string
  formErrors: FormErrors
  tldDialog: {
    isTldDialogOpen: boolean
  }
}

export interface EventHandlers {
  onCloseAddDomain: () => void
  onChangeFormData: (e: ChangeEvent<HTMLInputElement>) => void
  onAddDomain: () => void
  onCancel: () => void
  tldDialog: {
    onCancelTldDialog: () => void
    onConfirmTldDialog: () => void
  }
}

export type AddDomainLogic = [State, EventHandlers]

const initialFormData: FormData = {
  domainName: '',
  mailServer: ''
}

export enum FormStatus {
  VALID,
  INVALID,
  REQUIRES_CONFIRMATION
}

export interface AddDomainLogicConfig {
  onClose: () => void
}

const BASE_I18N_KEY = 'ess.app.error'

export const useAddDomainLogic = ({ onClose }: AddDomainLogicConfig): AddDomainLogic => {
  const dispatch = useAppDispatch()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [formData, setFormData] = useState(initialFormData)
  const [formErrors, setFormErrors] = useState<FormErrors>({})
  const [errorDetail, setErrorDetail] = useState<string>('')
  const {
    isAddDomainPending,
    isAddDomainSuccess,
    isAddDomainFailed,
    addDomainError,
    addDomainResponse,
    addDomainApiStatus
  } = useAppSelector(_store => ({
    isAddDomainPending: isPending(_store.domains.api.addDomainApiStatus),
    isAddDomainSuccess: isSuccess(_store.domains.api.addDomainApiStatus),
    isAddDomainFailed: isFailed(_store.domains.api.addDomainApiStatus),
    addDomainError: getErrorMessage(_store.domains.api.addDomainApiStatus),
    addDomainResponse: _store.domains.addDomainResponse,
    addDomainApiStatus: _store.domains.api.addDomainApiStatus
  }))
  const [isTldDialogOpen, setIsTldDialogOpen] = useState(false)
  const [isNoTldConfirmed, setIsNoTldConfirmed] = useState(false)
  const resetForm = useCallback(() => {
    setIsTldDialogOpen(false)
    setIsNoTldConfirmed(false)
    setFormErrors({})
    setFormData(initialFormData)
    dispatch(resetAddDomain())
  }, [dispatch])

  const onCloseAddDomain = useCallback(() => {
    resetForm()
    onClose()
  }, [onClose, resetForm])

  const onChangeFormData = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target
      setFormData({ ...formData, [name]: value })
    },
    [formData]
  )

  const validateDomainName = useCallback((domainName: string) => {
    if (!isDomainValid(domainName)) {
      return 'add_domain.invalid_domain'
    }
    return ''
  }, [])

  const validateMailServer = useCallback((mailServer: string) => {
    if (!mailServer) {
      return 'add_domain.missing_mail_server'
    }
    if (!isValidHostName(mailServer)) {
      return 'add_domain.invalid_mail_server'
    }
    return ''
  }, [])

  const validateForm = useCallback((): FormStatus => {
    const nextFormErrors: FormErrors = {
      domainName: validateDomainName(formData.domainName),
      mailServer: validateMailServer(formData.mailServer)
    }
    setFormErrors(nextFormErrors)
    const isValid = Object.values(nextFormErrors).every(error => !error)
    const isConfirmationRequired = !isNoTldConfirmed && isValid && !hasTld(formData.domainName)

    switch (true) {
      case !isValid:
        return FormStatus.INVALID
      case isValid && isConfirmationRequired:
        return FormStatus.REQUIRES_CONFIRMATION
      default:
        return FormStatus.VALID
    }
  }, [formData.domainName, formData.mailServer, isNoTldConfirmed, validateDomainName, validateMailServer])

  const onAddDomain = useCallback(() => {
    const formStatus = validateForm()
    if (formStatus === FormStatus.VALID) {
      const { domainName, mailServer } = formData
      dispatch(addDomain({ domainName, mailServer }))
      setIsTldDialogOpen(false)
      setIsNoTldConfirmed(false)
    } else if (formStatus === FormStatus.REQUIRES_CONFIRMATION) {
      setIsTldDialogOpen(true)
    }
  }, [dispatch, formData, validateForm])

  const onCancel = useCallback(() => onCloseAddDomain(), [onCloseAddDomain])

  const onCancelTldDialog = useCallback(() => {
    setIsNoTldConfirmed(false)
    setIsTldDialogOpen(false)
  }, [])

  const onConfirmTldDialog = useCallback(() => {
    setIsNoTldConfirmed(true)
  }, [])

  useEffect(
    () => () => resetForm(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    if (isAddDomainSuccess && addDomainResponse) {
      const { result, domainId } = addDomainResponse
      if (result === AddDomainResult.TRANSFER_REQUIRED) {
        routesConfig.VERIFY_DOMAIN_TRANSFER.goto({ domainId: domainId.toFixed(0) })
        return
      }
      onCloseAddDomain()
      dispatch(getDomainList())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isAddDomainSuccess, onCloseAddDomain])

  useEffect(() => {
    if (isNoTldConfirmed) {
      onAddDomain()
    }
  }, [isNoTldConfirmed, onAddDomain])

  useEffect(() => {
    if (!isAddDomainFailed || !addDomainError) {
      return
    }

    let parsedError
    try {
      parsedError = JSON.parse(addDomainError)
    } catch (e) {
      parsedError = undefined
    }

    if (parsedError) {
      const formatMessageText = parsedError.formatMessageParams
        ? formatMessage(parsedError.textId, { parent_domain_name: parsedError.formatMessageParams.parent_domain_name })
        : formatMessage(parsedError.textId)

      setErrorDetail(formatMessageText)
    } else {
      setErrorDetail(formatMessage('add_domain.error'))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addDomainError, isAddDomainFailed, addDomainApiStatus, dispatch])

  return useMemo(
    () => [
      {
        formData,
        isAddDomainPending,
        apiError: errorDetail,
        formErrors,
        tldDialog: { isTldDialogOpen }
      },
      {
        onChangeFormData,
        onCloseAddDomain,
        onCancel,
        onAddDomain,
        tldDialog: { onCancelTldDialog, onConfirmTldDialog }
      }
    ],
    [
      formData,
      formErrors,
      errorDetail,
      isAddDomainPending,
      isTldDialogOpen,
      onAddDomain,
      onCancel,
      onCancelTldDialog,
      onChangeFormData,
      onCloseAddDomain,
      onConfirmTldDialog
    ]
  )
}
