import React, { useCallback, useMemo } from 'react'
import { Grid, LinearProgress } from '@barracuda-internal/bds-core'
import {
  EmailLogProvider,
  EmailLog as BdsEmailLog,
  IEmailLogEventListener
} from '@barracuda-internal/converged-email-log'

import { UiState, useEmailLogLogic } from 'components/pages/emailLog/useEmailLogLogic'
import styles from 'components/pages/emailLog/emailLogStyles'

const EmailLog: React.FC = () => {
  const { uiState, bccAccountId, emailLogApiClient, emailLogLanguage, mixpanelConfig } = useEmailLogLogic()
  const classes = styles()

  const onEmailLogEvent: IEmailLogEventListener = useCallback(evt => {
    // eslint-disable-next-line no-console
    console.log(evt)
  }, [])

  return useMemo(
    () => (
      <EmailLogProvider
        bccAccountId={bccAccountId}
        emailLogApiClient={emailLogApiClient}
        language={emailLogLanguage}
        onEmailLogEvent={onEmailLogEvent}
        mixpanelConfig={mixpanelConfig}
        uiSettingsStorageKey="__CML_UI_SETTINGS"
      >
        <Grid container className={classes.wrapper} data-testid="container">
          {uiState === UiState.INITIALIZING && <LinearProgress />}
          {uiState === UiState.READY && <BdsEmailLog />}
        </Grid>
      </EmailLogProvider>
    ),
    [bccAccountId, emailLogApiClient, emailLogLanguage, onEmailLogEvent, mixpanelConfig, classes.wrapper, uiState]
  )
}

export default EmailLog
