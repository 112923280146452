import { useCallback, useEffect, useMemo } from 'react'

import { EmailLogLanguage, IEmailLogApiClient, IMixpanelConfig } from '@barracuda-internal/converged-email-log'
import { useAppSelector } from 'redux/toolkit/hooks'
import useAppTypeEntryPaths from 'components/libs/routes/useAppTypeEntryPaths'
import { useEmailLogApiClient } from 'components/pages/emailLog/useEmailLogApiClient'
import { Language } from 'types/Language'
import { trackEventInAllServices, TRACKING_EVENTS } from 'lib/monitoring/monitoringService'
import { User } from 'types/auth'
import appConfig from 'config/appConfig'

export enum UiState {
  INITIALIZING,
  READY
}

export interface UseEmailLogLogic {
  uiState: UiState
  bccAccountId: string
  emailLogApiClient: IEmailLogApiClient
  emailLogLanguage: EmailLogLanguage
  mixpanelConfig: IMixpanelConfig
}

export const useEmailLogLogic = (): UseEmailLogLogic => {
  const { appTypeEntryPath } = useAppTypeEntryPaths()
  const { bccAccountId, isEmailLogEnabled, language, accessTokenObject } = useAppSelector(_stores => ({
    bccAccountId: _stores.auth.accessTokenObject?.bccAccountId || '',
    isEmailLogEnabled: _stores.auth.accessTokenObject?.features?.isEmailLogEnabled,
    language: _stores.app.language,
    accessTokenObject: _stores.auth.accessTokenObject
  }))
  const emailLogApiClient = useEmailLogApiClient()

  const uiState = useMemo(() => {
    switch (true) {
      case typeof isEmailLogEnabled !== 'undefined' && !!accessTokenObject:
        return UiState.READY
      default:
        return UiState.INITIALIZING
    }
  }, [accessTokenObject, isEmailLogEnabled])

  const emailLogLanguage = useMemo(() => {
    switch (true) {
      case language === Language.japanese:
        return EmailLogLanguage.JP
      case language === Language.spanish:
        return EmailLogLanguage.ES
      case language === Language.english:
      default:
        return EmailLogLanguage.EN
    }
  }, [language])

  const getMixpanelProfileProperties = useCallback(
    (user: User) => ({
      serial: user.serial,
      account_id: user.accountId,
      user_id: user.userId,
      name: user.userInfo?.name,
      accounts: user.userInfo?.accounts || [],
      email: user.userId
    }),
    []
  )

  const mixpanelConfig = useMemo((): IMixpanelConfig => {
    const profileProperties = accessTokenObject ? getMixpanelProfileProperties(accessTokenObject) : { user_id: '' }
    return {
      product: 'ESS',
      distinctId: profileProperties.user_id,
      enabled: appConfig.MIXPANEL.IS_ENABLED,
      profileProperties
    }
  }, [accessTokenObject, getMixpanelProfileProperties])

  useEffect(() => {
    if (isEmailLogEnabled === false) {
      appTypeEntryPath.goto()
    }
  }, [appTypeEntryPath, isEmailLogEnabled])

  useEffect(() => {
    if (UiState.READY && isEmailLogEnabled) {
      trackEventInAllServices(TRACKING_EVENTS.EMAIL_LOG.CONVERGED_EMAIL_PAGEVIEW)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return useMemo(
    () => ({
      uiState,
      bccAccountId,
      emailLogApiClient,
      emailLogLanguage,
      mixpanelConfig
    }),
    [uiState, bccAccountId, emailLogApiClient, emailLogLanguage, mixpanelConfig]
  )
}
