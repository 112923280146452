import { useCallback, useEffect, useMemo } from 'react'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { getMxRecords, getProvider, setNewMxRecordsError } from 'redux/features/emailServer/emailServerSlice'
import { MxRecords, Provider } from 'types/emailServer'
import { isFailed, isPending, isSuccess } from 'redux/toolkit/api'
import { ProviderWithMxLinks } from 'components/pages/setupFlow/views/emailFlow/sections/providerTypes'
import { useDetectProviderLinksLogic } from 'components/pages/setupFlow/views/emailFlow/sections/useDetectProviderLinksLogic'

export interface State {
  provider: Provider[] | undefined
  getProviderInProgress: boolean
  mxRecords: MxRecords | undefined
  getMxRecordsInProgress: boolean
  verifyRecordButtonStatuses: { disabled: boolean; inProgress: boolean }
  providerWithMxLinks: ProviderWithMxLinks | undefined
  isMissedToVerify: { primary: boolean; backup: boolean }
}

export interface EventHandlers {
  onVerifyRecords: () => void
}

export type AddNewMxRecordsLogic = [State, EventHandlers]

export const useAddNewMxRecordsLogic = (): AddNewMxRecordsLogic => {
  const dispatch = useAppDispatch()
  const {
    detectedDomain,
    getProviderInProgress,
    provider,
    getMxRecordsInProgress,
    getMxRecordsSuccess,
    getMxRecordsFailed,
    mxRecords,
    newRecordsError
  } = useAppSelector(_store => ({
    detectedDomain: _store.emailServer.emailServer?.domainName,
    getProviderInProgress: isPending(_store.emailServer.getMxRecordsApiStatus),
    provider: _store.emailServer.provider,
    getMxRecordsInProgress: isPending(_store.emailServer.getMxRecordsApiStatus),
    getMxRecordsSuccess: isSuccess(_store.emailServer.getMxRecordsApiStatus),
    getMxRecordsFailed: isFailed(_store.emailServer.getMxRecordsApiStatus),
    mxRecords: _store.emailServer.mxRecords,
    newRecordsError: _store.emailServer.emailFlowState.newRecordsError
  }))
  const [providerWithMxLinks] = useDetectProviderLinksLogic()

  // init
  useEffect(() => {
    if (detectedDomain) {
      dispatch(getMxRecords(detectedDomain))
      dispatch(getProvider(detectedDomain))
    }
  }, [dispatch, detectedDomain])

  const isMissedToVerify = useMemo(() => {
    const isAlreadyHitVerifyButton: boolean = !newRecordsError && (getMxRecordsSuccess || getMxRecordsFailed)

    return {
      primary: !mxRecords?.primary.verified && isAlreadyHitVerifyButton,
      backup: !mxRecords?.backup.verified && isAlreadyHitVerifyButton
    }
  }, [newRecordsError, getMxRecordsSuccess, getMxRecordsFailed, mxRecords])

  const onVerifyRecords = useCallback(() => {
    if (detectedDomain) {
      dispatch(setNewMxRecordsError())
      dispatch(getMxRecords(detectedDomain))
    }
  }, [dispatch, detectedDomain])

  const verifyRecordButtonStatuses = useMemo(
    () => ({
      disabled: !mxRecords,
      inProgress: getMxRecordsInProgress
    }),
    [mxRecords, getMxRecordsInProgress]
  )

  return useMemo(
    () => [
      {
        provider,
        getProviderInProgress,
        mxRecords,
        getMxRecordsInProgress,
        verifyRecordButtonStatuses,
        providerWithMxLinks,
        isMissedToVerify
      },
      {
        onVerifyRecords
      }
    ],
    [
      getProviderInProgress,
      provider,
      getMxRecordsInProgress,
      mxRecords,
      verifyRecordButtonStatuses,
      providerWithMxLinks,
      isMissedToVerify,
      onVerifyRecords
    ]
  )
}
