import React, { useMemo } from 'react'
import { Mixpanel } from 'mixpanel-browser'

import { Grid } from '@barracuda-internal/bds-core'
import { LicenseCompliance } from '@barracuda-internal/license-compliance'

import config from 'config/appConfig'

import { useLicenseContentLogic } from 'components/pages/license/useLicenseContentLogic'
import styles from 'components/pages/license/licenseContentStyles'

const LicenseContent: React.FC = () => {
  const { enabled, serial } = useLicenseContentLogic()
  const classes = styles()

  return useMemo(
    () => (
      <Grid container direction="column" className={classes.root} data-testid="container">
        {enabled && (
          <LicenseCompliance
            data-testid="license-content"
            urlPrefix={config.LICENSING_COMPLIANCE_PATH}
            serial={serial}
            mixpanelOptions={{
              mixpanel: window.mixpanel as Mixpanel,
              productPrefix: config.PRODUCT_PREFIX,
              disableMixpanel: !window.mixpanel
            }}
          />
        )}
      </Grid>
    ),
    [classes, enabled, serial]
  )
}

export default LicenseContent
