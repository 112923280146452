/* eslint-disable no-console */
import React, { useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import axios from 'axios'

const TestIPIRFlowCallback: React.FC = () => {
  const [params] = useSearchParams()
  const code = params.get('code')
  const error = params.get('error')
  console.log({ error })

  useEffect(() => {
    console.log('::ipirFlowCallback')
    ;(async () => {
      const res = await axios.post(`${process.env.REACT_APP_GATEWAY_URL}/internal/oauth2/token`, { code })
      console.log(res.data)
    })()
  }, [code])

  return useMemo(() => <div>IPIR Flow callback</div>, [])
}

export default TestIPIRFlowCallback
