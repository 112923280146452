const API_ENDPOINTS = {
  authentication: {
    DOMAIN_INFO: {
      method: 'GET',
      path: '/internal/domain-info'
    },
    LOGIN: {
      method: 'POST',
      path: '/internal/login'
    },
    BCC_LOGIN: {
      method: 'POST',
      path: '/internal/bcc-login'
    },
    SWITCH_ACCOUNT: {
      method: 'POST',
      path: '/internal/switch-account'
    },
    VALIDATE_SESSION: {
      method: 'POST',
      path: '/internal/validate-session'
    },
    AUTH0_LOGIN: {
      method: 'POST',
      path: '/internal/auth0-login'
    },
    LOGOUT: {
      method: 'DELETE',
      path: '/internal/logout'
    },
    USERINFO: {
      method: 'GET',
      path: '/internal/userinfo'
    },
    AZURE_LOGIN_URL: {
      method: 'POST',
      path: '/internal/azure-login-url'
    },
    SSO_SETTINGS: {
      method: 'GET',
      path: '/internal/sso-settings'
    },
    LOGIN_SETTINGS_URL: {
      method: 'POST',
      path: '/internal/login-settings'
    },
    LOGIN_INFO: {
      method: 'POST',
      path: '/internal/login-info'
    },
    RESET_PASSWORD: {
      method: 'POST',
      path: '/internal/reset-password'
    },
    TEMPORARY_PASSCODE: {
      method: 'POST',
      path: '/internal/temporary/passcode'
    },
    TEMPORARY_LOGIN: {
      method: 'POST',
      path: '/internal/temporary/login'
    }
  },
  fpfn: {
    FPFN_REPORT_USER: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/domains/:domainId/users/:userId/messages/:messageId/fpfn-redirect'
    },
    FPFN_REPORT_ACCOUNT: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/messages/:messageId/fpfn-redirect'
    }
  },
  auditLog: {
    AUDIT_LOG: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/audit-log'
    },
    AUDIT_LOG_EXPORT: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/audit-export'
    }
  },
  domains: {
    GET_DOMAIN_LIST: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/domain-manager/domains'
    },
    GET_MANAGED_DOMAIN_LIST: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/domain-manager/managed-domains'
    },
    ADD_DOMAIN: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/domain-manager/domains'
    },
    DELETE_DOMAIN: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/domain-manager/domains/:domainId/delete'
    },
    MANAGE_DOMAIN: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/domain-manager/domains/manage'
    },
    GET_DOMAIN_FOR_VERIFICATION: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/domain-manager/domains/verify/:domainId'
    },
    VERIFY_DOMAIN: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/domain-manager/domains/verify/:domainId'
    },
    GET_DOMAIN: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/domain-manager/domains/:domainId'
    },
    GET_VERIFIED_DOMAIN_LIST: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/domain-manager/verified-domains'
    },
    GET_PD_DOMAIN: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/domain-manager/domain/:domainId'
    },
    GET_UNALIASED_DOMAIN_NAMES: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/domain-manager/unaliased-domains'
    },
    SAVE_DOMAIN_SETTINGS: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/domain-manager/domains/:domainId/settings'
    },
    RESET_DOMAIN_SETTINGS: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/domain-manager/domains/:domainId/reset'
    },
    GET_MOVE_DOMAIN: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/domain-manager/domains/:domainId/move'
    },
    VERIFY_DOMAIN_TRANSFER: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/domain-manager/domains/:domainId/verify-transfer'
    },
    DELETE_DOMAIN_MOVE: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/domain-manager/domains/:domainId/delete-transfer'
    },
    GET_DOMAIN_TRANSFER_STATES: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/domain-manager/domains/transfer-state'
    }
  },
  encryption: {
    CNAME_VALIDATION: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/domains/:domainId/bees/cname-validation'
    },
    POST_VALIDATION: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/domains/:domainId/bees/post-validation'
    },
    CONFIRM_VALIDATION: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/domains/:domainId/bees/confirm-validation'
    },
    REMOVE_VALIDATION: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/domains/:domainId/bees/remove-validation'
    },
    REMOVE_LOGO: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/domains/:domainId/bees/remove-logo'
    }
  },
  atpLog: {
    ATP_LOG: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/users/:userId/atplog'
    },
    ATP_OVERVIEW: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/users/:userId/atplog/overview'
    },
    ATP_FILETYPES: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/users/:userId/atplog/filetypes'
    },
    ATP_LOG_DELETE: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/users/:userId/atplog/delete'
    },
    ATP_LOG_REPORT: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/users/:userId/atplog/report'
    }
  },
  accountManagement: {
    GET_SYSLOG: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/syslog'
    },
    UPDATE_SYSLOG: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/syslog'
    },
    TEST_SYSLOG: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/syslog/test'
    },
    DELETE_SYSLOG: {
      method: 'DELETE',
      path: '/internal/accounts/:bccAccountId/syslog'
    }
  },
  mstore: {
    USER_MESSAGE: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/domains/:domainId/users/:userId/messages/:messageId'
    },
    DOMAIN_MESSAGE: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/domains/:domainId/messages/:messageId'
    },
    SEARCH_USER_MESSAGES: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/domains/:domainId/users/:userId/messages'
    },
    SEARCH_DOMAIN_MESSAGES: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/domains/:domainId/messages'
    },
    SEARCH_ACCOUNT_MESSAGES: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/messages'
    },
    USER_ATTACHMENT: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/domains/:domainId/users/:userId/messages/:messageId/attachments/:attachmentId'
    },
    DOMAIN_ATTACHMENT: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/domains/:domainId/messages/:messageId/attachments/:attachmentId'
    },
    ACCOUNT_WHITELIST: {
      method: 'POST',
      path: '/external/v1/account/:accountId/message-log/action/whitelist'
    },
    DOMAIN_WHITELIST: {
      method: 'POST',
      path: '/external/v1/account/:accountId/domain/:pdDomainId/message-log/action/whitelist'
    },
    USER_WHITELIST: {
      method: 'POST',
      path: '/external/v1/account/:accountId/user/:userId/message-log/action/whitelist'
    },
    ACCOUNT_BLOCK_SENDER: {
      method: 'POST',
      path: '/external/v1/account/:accountId/message-log/action/block-sender'
    },
    DOMAIN_BLOCK_SENDER: {
      method: 'POST',
      path: '/external/v1/account/:accountId/domain/:pdDomainId/message-log/action/block-sender'
    },
    USER_BLOCK_SENDER: {
      method: 'POST',
      path: '/external/v1/account/:accountId/user/:userId/message-log/action/block-sender'
    },
    ACCOUNT_REDELIVER_MESSAGE: {
      method: 'POST',
      path: '/external/v1/account/:accountId/message-log/action/redeliver-message'
    },
    DOMAIN_REDELIVER_MESSAGE: {
      method: 'POST',
      path: '/external/v1/account/:accountId/domain/:pdDomainId/message-log/action/redeliver-message'
    },
    USER_REDELIVER_MESSAGE: {
      method: 'POST',
      path: '/external/v1/account/:accountId/user/:userId/message-log/action/redeliver-message'
    },
    ACCOUNT_DELIVER_MESSAGE: {
      method: 'POST',
      path: '/external/v1/account/:accountId/message-log/action/deliver-message'
    },
    DOMAIN_DELIVER_MESSAGE: {
      method: 'POST',
      path: '/external/v1/account/:accountId/domain/:pdDomainId/message-log/action/deliver-message'
    },
    USER_DELIVER_MESSAGE: {
      method: 'POST',
      path: '/external/v1/account/:accountId/user/:userId/message-log/action/deliver-message'
    },
    DELIVER_NEW: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/users/:userId/messages/deliver-new'
    },
    REDELIVER_MESSAGE_USER: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/domains/:domainId/users/:userId/messages/redeliver'
    },
    REDELIVER_MESSAGE_ACCOUNT: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/messages/redeliver'
    },
    BLOCK_SENDER_USER: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/domains/:domainId/users/:userId/messages/block'
    },
    BLOCK_SENDER_ACCOUNT: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/messages/block'
    },
    ALLOW_SENDER_USER: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/domains/:domainId/users/:userId/messages/allow'
    },
    ALLOW_SENDER_ACCOUNT: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/messages/allow'
    },
    DELETE_MESSAGES_ACCOUNT: {
      method: 'DELETE',
      path: '/internal/accounts/:bccAccountId/messages'
    },
    DELETE_MESSAGES_DOMAIN: {
      method: 'DELETE',
      path: '/internal/accounts/:bccAccountId/domains/:domainId/messages'
    },
    DELETE_MESSAGES_USER: {
      method: 'DELETE',
      path: '/internal/accounts/:bccAccountId/domains/:domainId/users/:userId/messages'
    },
    REJECT_MESSAGES_ACCOUNT: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/messages/reject'
    },
    GET_ACCOUNT_SAVED_SEARCHES: {
      method: 'GET',
      path: '/external/v1/account/:egdAccountId/message-log/search-settings'
    },
    GET_DOMAIN_SAVED_SEARCHES: {
      method: 'GET',
      path: '/external/v1/account/:egdAccountId/domain/:domainId/message-log/search-settings'
    },
    SAVE_ACCOUNT_SAVED_SEARCHES: {
      method: 'POST',
      path: '/external/v1/account/:egdAccountId/message-log/search-settings'
    },
    SAVE_DOMAIN_SAVED_SEARCHES: {
      method: 'POST',
      path: '/external/v1/account/:egdAccountId/domain/:domainId/message-log/search-settings'
    },
    DELETE_ACCOUNT_SAVED_SEARCH: {
      method: 'DELETE',
      path: '/external/v1/account/:egdAccountId/message-log/search-setting/:searchId'
    },
    DELETE_DOMAIN_SAVED_SEARCH: {
      method: 'DELETE',
      path: '/external/v1/account/:egdAccountId/domain/:domainId/message-log/search-setting/:searchId'
    }
  },
  settings: {
    GET_ACCOUNT_SETTINGS: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/settings/get'
    },
    UPDATE_ACCOUNT_SETTINGS: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/settings/update'
    },
    GET_DOMAIN_SETTINGS: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/domains/:domainId/settings/get'
    },
    UPDATE_DOMAIN_SETTINGS: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/domains/:domainId/settings/update'
    },
    SENDER_POLICIES: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/users/:userId/sender-policies?page=0&size=0'
    },
    SAVE_SENDER_POLICY: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/users/:userId/sender-policies'
    },
    DELETE_SENDER_POLICY: {
      method: 'DELETE',
      path: '/internal/accounts/:bccAccountId/users/:userId/sender-policies'
    },
    BULK_EDIT_POLICIES: {
      method: 'PUT',
      path: '/internal/accounts/:bccAccountId/users/:userId/sender-policies'
    },
    PASSWORD: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/users/:userId/password'
    },
    QUARANTINE_NOTIFICATION_USER_SETTINGS: {
      method: 'GET',
      path: 'internal/accounts/:bccAccountId/qns/user-settings'
    },
    QUARANTINE_NOTIFICATION_USERS_SETTINGS: {
      method: 'POST',
      path: 'internal/accounts/:bccAccountId/qns/users-settings'
    },
    QUARANTINE_NOTIFICATION: {
      method: 'GET',
      path: 'internal/accounts/:bccAccountId/users/:userId/qns/settings'
    },
    EDIT_QUARANTINE_NOTIFICATION: {
      method: 'PUT',
      path: 'internal/accounts/:bccAccountId/users/:userId/qns/settings'
    },
    GET_EMAIL_CONTINUITY_ACCOUNT_SETTINGS: {
      method: 'GET',
      path: 'internal/accounts/:bccAccountId/settings/email-continuity'
    },
    UPDATE_EMAIL_CONTINUITY_ACCOUNT_SETTINGS: {
      method: 'POST',
      path: 'internal/accounts/:bccAccountId/settings/email-continuity'
    },
    LINKED_ACCOUNTS: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/users/:userId/linked-accounts'
    },
    SAVE_LINKED_ACCOUNT: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/users/:userId/linked-accounts'
    },
    DELETE_LINKED_ACCOUNT: {
      method: 'DELETE',
      path: '/internal/accounts/:bccAccountId/users/:userId/linked-accounts'
    },
    VERIFY_LINKED_ACCOUNT: {
      method: 'POST',
      path: '/internal/accounts/users/linked-accounts/verify'
    },
    ACCOUNT_PERMISSIONS: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/users/:userId/account-permissions'
    }
  },
  outboundIp: {
    GET_OUTBOUND_IPS: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/users/:userId/outbound-ip'
    },
    REMOVE_OUTBOUND_IPS: {
      method: 'DELETE',
      path: '/internal/accounts/:bccAccountId/users/:userId/outbound-ip'
    },
    ADD_OUTBOUND_IPS: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/users/:userId/outbound-ip'
    },
    BULK_EDIT_OUTBOUND_IPS: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/users/:userId/outbound-ip/bulk-edit'
    }
  },
  emailServer: {
    DETECT_EMAIL_SERVER: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/email-server/detect'
    },
    VERIFY_EMAIL_SERVER: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/domains/:wizardDomainId/email-server/verify'
    },
    GET_PROVIDER: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/domains/:wizardDomainId/email-server/provider'
    },
    GET_MX_RECORDS: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/domains/:wizardDomainId/email-server/mxrecord'
    },
    GET_OLD_MX_RECORDS: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/domains/:wizardDomainId/email-server/old-mxrecord'
    },
    GET_EMAIL_SERVER_SETTINGS: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/email-server/settings'
    },
    SET_EMAIL_SERVER_SETTINGS: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/email-server/settings'
    },
    SET_REGION: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/email-server/select-region'
    },
    SAVE_SETUP_FLOW_STEP: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/email-server/setup-flow-step'
    },
    SAVE_SETUP_FLOW_VERSION: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/email-server/version'
    },
    SAVE_DEPLOYMENT_METHOD: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/email-server/save-deployment-method'
    },
    MIGRATE_REGION: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/email-server/migrate-region'
    },
    ADD_MAIL_SERVER: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/domains/:domainId/email-server'
    },
    REMOVE_MAIL_SERVER: {
      method: 'DELETE',
      path: '/internal/accounts/:bccAccountId/domains/:domainId/email-server/:serverId'
    },
    UPDATE_MAIL_SERVER: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/domains/:domainId/email-server/:serverId'
    },
    TEST_MAIL_SERVER: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/domains/:domainId/email-server/:serverId/test'
    }
  },
  ipir: {
    GET_SCAN_STATUS: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/ipir/scan-status'
    }
  },
  sidebandMailer: {
    REDELIVERY_QUEUE_USER_MESSAGES: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/domains/:domainID/users/:userId/queued-messages'
    },
    REDELIVERY_QUEUE_DOMAIN_MESSAGES: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/domains/:domainID/queued-messages'
    },
    REDELIVERY_QUEUE_ACCOUNT_MESSAGES: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/queued-messages'
    }
  },
  customerFeedback: {
    RECATEGORIZE_USER: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/domains/:domainId/users/:userId/messages/recategorize'
    },
    RECATEGORIZE_ACCOUNT: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/messages/recategorize'
    }
  },
  MIXPANEL: {
    method: 'POST',
    path: '/internal/accounts/:bccAccountId/track-event'
  },
  user: {
    GET_AVAILABLE_DOMAINS: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/domains'
    }
  },
  users: {
    GET_USER_FEATURES: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/users/:userId/user-features'
    },
    GET_BCC_USER_FEATURES: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/bcc-users/:bccUserId/user-features'
    },
    UPDATE_BCC_USER_SETTINGS: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/bcc-users/:bccUserId/settings/update'
    },
    GET_ACCOUNT_USERS: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/users'
    },
    UPDATE_ACCOUNT_USER_ROLE: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/users/:userId/update-role'
    },
    UPDATE_ACCOUNT_USERS: {
      method: 'PUT',
      path: '/internal/accounts/:bccAccountId/users/update'
    },
    DELETE_ACCOUNT_USERS: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/users/delete'
    },
    LOGIN_AS_USER: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/users/:userId/login-as'
    },
    REQUEST_RESET_PASSWORD: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/users/:userId/request-reset-password'
    },
    GET_QUARANTINE_NOTIFICATION_SETTINGS: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/users/quarantine-notification-settings'
    },
    GET_QUARANTINE_NOTIFICATION_SETTINGS_USER: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/users/:userId/quarantine-notification-settings'
    },
    UPDATE_QUARANTINE_NOTIFICATION_SETTINGS: {
      method: 'PUT',
      path: '/internal/accounts/:bccAccountId/users/quarantine-notification-settings'
    },
    GET_POLICIES_SETTINGS: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/users/policies-settings'
    },
    UPDATE_POLICIES_SETTINGS: {
      method: 'PUT',
      path: '/internal/accounts/:bccAccountId/users/policies-settings'
    }
  },
  reports: {
    GET_REPORTS_LIST: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/reports/list'
    },
    GET_REPORT: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/reports/get'
    },
    GET_PAGINATED_REPORT: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/reports/get-paginated'
    },
    SET_CUSTOM_REPORTS: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/reports/custom-list'
    },
    SET_PINNED_REPORT_ID: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/reports/set-pinned-report-id'
    },
    GET_SCHEDULED_REPORTS_LIST: {
      method: 'GET',
      path: '/external/v1/scheduled-reports/:accountId/list'
    },
    CREATE_SCHEDULE_REPORT: {
      method: 'POST',
      path: '/external/v1/scheduled-reports/:accountId/report'
    },
    UPDATE_SCHEDULED_REPORT: {
      method: 'PUT',
      path: '/external/v1/scheduled-reports/:accountId/report'
    },
    DELETE_SCHEDULED_REPORT: {
      method: 'DELETE',
      path: '/external/v1/scheduled-reports/:accountId/:report_id/report'
    }
  },
  stats: {
    GET_ACCOUNT_DASHBOARD_DATA: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/stats/full'
    },
    GET_DOMAIN_DASHBOARD_DATA: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/domains/:domainId/stats/full'
    },
    GET_ACCOUNT_LEVEL_GEO_DATA: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/stats/geodata'
    },
    GET_DOMAIN_LEVEL_GEO_DATA: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/domains/:domainId/stats/geodata'
    },
    GET_ACCOUNT_LEVEL_TOP_DOMAINS: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/stats/top-domains'
    },
    GET_DOMAIN_LEVEL_TOP_DOMAINS: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/domains/:domainId/stats/top-domains'
    },
    GET_ACCOUNT_LEVEL_TOP_BLOCKED: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/stats/top-blocked'
    },
    GET_DOMAIN_LEVEL_TOP_BLOCKED: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/domains/:domainId/stats/top-blocked'
    },
    GET_ACCOUNT_LEVEL_LAST_BLOCKED_ATP: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/stats/last-blocked-atp'
    },
    GET_DOMAIN_LEVEL_LAST_BLOCKED_ATP: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/domains/:domainId/stats/last-blocked-atp'
    },
    GET_ACCOUNT_LEVEL_EMAIL_STATISTICS: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/stats/email-statistics'
    },
    GET_DOMAIN_LEVEL_EMAIL_STATISTICS: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/domains/:domainId/stats/email-statistics'
    },
    GET_ACCOUNT_LEVEL_TOTAL_THREATS_AND_VIRUSES_STATISTICS: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/stats/total-threats-and-viruses'
    },
    GET_DOMAIN_LEVEL_TOTAL_THREATS_AND_VIRUSES_STATISTICS: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/domains/:domainId/stats/total-threats-and-viruses'
    },
    GET_ACCOUNT_LEVEL_UNIQUE_DOMAIN_RECIPIENTS: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/domains/:domainId/stats/unique-recipients'
    }
  },
  azureAd: {
    GET_SIGNED_AZURE_AD_API_URL: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/domains/:domainId/azure-ad-api/signed-url'
    },
    GET_AZURE_AD_API_SYNC_STATS: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/domains/:domainId/azure-ad-api/sync-stats'
    },
    POST_DISABLE_AZURE_AD_OPTIONS: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/domains/:domainId/azure-ad-api/disable'
    },
    POST_RESET_AZURE_AD_SYNC_STATS: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/domains/:domainId/azure-ad-api/reset-sync-stats'
    }
  },
  ldap: {
    GET_LDAP_SYNC_STATS: {
      method: 'GET',
      path: '/internal/accounts/:bcc_account_id/domains/:domain_id/ldap/sync-stats'
    },
    POST_LDAP_TEST: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/domains/:domainId/ldap/test'
    },
    POST_LDAP_SYNC_NOW: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/domains/:domainId/ldap/sync-now'
    }
  },
  graphql: {
    GET_GRAPHQL_QUERY_RESPONSE: {
      method: 'POST',
      path: '/internal/accounts/:bccAccountId/graphql/graphql-query'
    }
  },
  appSettings: {
    GET_PUBLIC_APP_SETTINGS: {
      method: 'GET',
      path: '/internal/app-settings/public'
    }
  },
  oauth2: {
    OAUTH2_GET_CURRENT_SESSION: {
      method: 'GET',
      path: '/internal/oauth2/session/me'
    },
    OAUTH2_CREATE_SESSION: {
      method: 'POST',
      path: '/internal/oauth2/session'
    },
    OAUTH2_CREATE_AUTHENTICATION_PROCESS: {
      method: 'POST',
      path: '/internal/oauth2/process/:authProcessId'
    },
    OAUTH2_TOKEN_EXCHANGE: {
      method: 'POST',
      path: '/internal/oauth2/token'
    }
  },
  banners: {
    GET_BANNER_SETTINGS: {
      method: 'GET',
      path: '/internal/accounts/:bccAccountId/banners/settings'
    }
  },
  pam: {
    GET_PRODUCT_DATA: {
      method: 'GET',
      path: '/internal/pam/product-data'
    }
  }
} as const

export default API_ENDPOINTS
