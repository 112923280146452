import { ChangeEvent, useCallback, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { setDeploymentSetup } from 'redux/features/emailServer/emailServerSlice'
import { DeploymentSetupOptions } from '../../types/egdWizardTypes'

export interface State {
  deploymentSetup: DeploymentSetupOptions
  inlineDisabled: boolean
}

export interface EventHandlers {
  onSelectChange: (e: ChangeEvent<HTMLInputElement>) => void
}

export type DeploymentSetupLogic = [State, EventHandlers]

export const useDeploymentSetupLogic = (): DeploymentSetupLogic => {
  const dispatch = useAppDispatch()

  const { deploymentSetup, emailServerSettings } = useAppSelector(_store => ({
    deploymentSetup: _store.emailServer.deploymentSetup,
    emailServerSettings: _store.emailServer.emailServerSettings
  }))

  const onSelectChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      dispatch(setDeploymentSetup(e.target.value as DeploymentSetupOptions))
    },
    [dispatch]
  )

  return useMemo(
    () => [
      {
        deploymentSetup,
        inlineDisabled: !emailServerSettings?.ipirAccountId && !emailServerSettings?.ipirTokenId
      },
      {
        onSelectChange
      }
    ],
    [deploymentSetup, emailServerSettings, onSelectChange]
  )
}
