import { useSearchParams } from 'react-router-dom'
import { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { getErrorMessage, isFailed, isSuccess } from 'redux/toolkit/api'
import { resetTokenExchange, tokenExchange } from 'redux/features/oauth2/oauth2Slice'
import routesConfig from 'lib/routesConfig'

export const useSetupFlowCallbackLogic = () => {
  const dispatch = useAppDispatch()
  const [params] = useSearchParams()
  const code = params.get('code')
  const error = params.get('error')

  const { isTokenExchangeSuccess, isTokenExchangeFailed, tokenExchangeError, tokenExchangeResponse } = useAppSelector(
    _store => ({
      isTokenExchangeSuccess: isSuccess(_store.oauth2.api.tokenExchangeApiStatus),
      isTokenExchangeFailed: isFailed(_store.oauth2.api.tokenExchangeApiStatus),
      tokenExchangeError: getErrorMessage(_store.oauth2.api.tokenExchangeApiStatus),
      tokenExchangeResponse: _store.oauth2.tokenExchangeResponse
    })
  )

  useEffect(
    () => () => {
      dispatch(resetTokenExchange())
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    if (code) {
      dispatch(tokenExchange({ code }))
      return
    }
    if (error) {
      routesConfig.ONBOARD_WIZARD.goto(undefined, false, '?error=OAuth2AuthorizationCodeRequestFailed')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isTokenExchangeSuccess && tokenExchangeResponse) {
      routesConfig.ONBOARD_WIZARD.goto()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTokenExchangeSuccess])

  useEffect(() => {
    if (isTokenExchangeFailed) {
      const errorCode = tokenExchangeError || 'oauth2.auth_code_flow.token_exchange_failed'
      routesConfig.ONBOARD_WIZARD.goto(undefined, false, `?error=${errorCode}`)
    }
  }, [isTokenExchangeFailed, tokenExchangeError])
}
