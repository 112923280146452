import React, { useMemo } from 'react'

import { Grid } from '@barracuda-internal/bds-core'
import { EpEmailGatewayDefense } from '@barracuda-internal/bds-core/dist/Logos/Products'

import { useOnboardWizardLogic } from 'components/pages/onboardWizard/useOnboardWizardLogic'
import { WizardSteps } from 'components/pages/onboardWizard/onboardWizardTypes'
import { wizardStyles } from 'components/pages/onboardWizard/onboardWizardStyles'
import RegionSelection from 'components/pages/onboardWizard/regionSelection/RegionSelection'
import ServerAndMxSetup from 'components/pages/onboardWizard/serverAndMxSetup/ServerAndMxSetup'

const OnboardWizard: React.FC = () => {
  const [state, eventHandlers] = useOnboardWizardLogic()
  const classes = wizardStyles()

  return useMemo(
    () => (
      <Grid container>
        {state.isGetEmailServerSettingsIsSuccess && (
          <Grid data-testid="content-view" container>
            <EpEmailGatewayDefense className={classes.logo} />
            {state.currentStep === WizardSteps.regionSelection && (
              <RegionSelection gotoNextStep={eventHandlers.gotoNextStep} />
            )}
            {state.currentStep === WizardSteps.serverAndMxSetup && <ServerAndMxSetup />}
          </Grid>
        )}
      </Grid>
    ),
    [state, classes, eventHandlers]
  )
}

export default OnboardWizard
