import React, { useMemo } from 'react'
import { createRoot } from 'react-dom/client'

import { Provider } from 'react-redux'
import geoip from 'i18n-iso-countries'
import geoipEn from 'i18n-iso-countries/langs/en.json'
import deepmerge from 'deepmerge'

import * as bdsTheme from '@barracuda-internal/bds-core/dist/styles/themes'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'

import { BDSProvider as BDSV2Provider } from '@barracuda-internal/bds-core-v2'
import baseMui from '@barracuda-internal/bds-core-v2/dist/styles/themes'
import { createTheme as createMuiV5Theme, ThemeProvider as MuiV5ThemeProvider } from '@mui/material/styles'

import { BDSProvider } from '@barracuda-internal/bds-core'
import { Auth0Provider } from '@auth0/auth0-react'
import reduxStore from 'lib/reduxStore'
import Routes from 'components/libs/routes/Routes'
import { history } from 'lib/browserHistory'
import theme from 'theme'
import registerAxiosHooks from 'lib/api/registerAxiosHooks'
import appConfig from 'config/appConfig'
import { initNewRelicService } from 'lib/monitoring/newRelic/newRelicService'
import { CustomBrowserRouter } from './components/libs/routes/CustomBrowserRouter'
import { IntlWrapper } from './components/libs/intlWrapper'

import '@progress/kendo-theme-material/dist/all.css'
import 'app.scss'
import { UiState, useAppLogic } from './useAppLogic'

// register axios hooks
registerAxiosHooks()

// Register all the supported language localization
geoip.registerLocale(geoipEn)

initNewRelicService()

const ReduxEnabledRoot = () => {
  const { uiState } = useAppLogic()

  return useMemo(() => {
    if (uiState !== UiState.READY) {
      return null
    }

    return (
      <IntlWrapper>
        <BDSProvider useBdsTheme={false}>
          <MuiV5ThemeProvider theme={createMuiV5Theme(baseMui)}>
            <BDSV2Provider useBdsTheme>
              <CustomBrowserRouter history={history}>{Routes}</CustomBrowserRouter>
            </BDSV2Provider>
          </MuiV5ThemeProvider>
        </BDSProvider>
      </IntlWrapper>
    )
  }, [uiState])
}

const AppRoot = () =>
  useMemo(
    () => (
      <ThemeProvider theme={createTheme(deepmerge(bdsTheme.default, theme))}>
        <Provider store={reduxStore}>
          <ReduxEnabledRoot />
        </Provider>
      </ThemeProvider>
    ),
    []
  )

export function App() {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const root = createRoot(document.getElementById('root')!)

  if (appConfig.AUTH0.isEnabled) {
    const auth0Domain = appConfig.AUTH0.domain as string
    const auth0ClientId = appConfig.AUTH0.clientId as string
    const auth0Audience = appConfig.AUTH0.audience as string

    return root.render(
      <Auth0Provider
        domain={auth0Domain}
        clientId={auth0ClientId}
        authorizationParams={{
          audience: auth0Audience
        }}
      >
        <AppRoot />
      </Auth0Provider>
    )
  }

  return root.render(<AppRoot />)
}
