import { DateTime } from 'luxon'
import { isDate, isString } from 'lodash'
import config from 'config/appConfig'
import reduxStore from 'lib/reduxStore'
import { RootState } from 'redux/toolkit/store'

export const DEFAULT_TIMEZONE = 'America/Los_Angeles'

export type DateFormat = Date | string | undefined

// luxon methods
export function luxonDate(date?: DateFormat) {
  switch (true) {
    case isDate(date):
      return DateTime.fromJSDate(date as Date)
    case isString(date):
      return DateTime.fromISO(date as string)
    default:
      return DateTime.now()
  }
}

export function formatDateNoTZWithLocale(
  date: DateFormat,
  language: string,
  format: Intl.DateTimeFormatOptions = DateTime.DATETIME_MED
) {
  const locale = language.replace('_', '-')
  return luxonDate(date).toLocaleString(format, { locale })
}

export function formatDateNoTZ(date: DateFormat, format?: string) {
  return luxonDate(date).toFormat(format || config.DATETIME.DEFAULT_DATE_FORMAT)
}

export function formatDate(date: DateFormat, format?: string, timezone?: string) {
  const userTimezone = (reduxStore.getState() as RootState).app.timezone
  return luxonDate(date)
    .setZone(timezone || userTimezone || DEFAULT_TIMEZONE)
    .toFormat(format || config.DATETIME.DEFAULT_DATE_FORMAT)
}

export function getHours(dateRange: any) {
  return Math.round((dateRange.end - dateRange.start) / 36e5)
}

export function removeHours(date: Date, hours: number) {
  date.setTime(date.getTime() - hours * 36e5)

  return date
}

export function calculateTimeframe(dateRange: any) {
  const timeframe = getHours(dateRange)

  return timeframe > 24 ? Math.ceil(timeframe / 24) * 24 : timeframe
}

export function calculateTokenExpiration(tokenExpirationTime: number) {
  return new Date(now() + tokenExpirationTime * 1000)
}

export function now() {
  return Date.now()
}

export function hasSameDay(date: DateFormat) {
  return DateTime.now().hasSame(luxonDate(date), 'day')
}

export function getPastDate(days: number, format: string = config.DATETIME.DEFAULT_DATE_FORMAT) {
  return DateTime.now()
    .minus({
      days
    })
    .toFormat(format)
}

export function formatTimeToFullLocaleString(time: number) {
  const dateTime = DateTime.fromSeconds(time)
  const userTimezone = (reduxStore.getState() as RootState).app.timezone
  return dateTime.setZone(userTimezone || DEFAULT_TIMEZONE).toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)
}

function parseISOParts(isoString: string) {
  const ISO_format = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})$/
  const time_array = isoString.match(ISO_format)

  if (!time_array) {
    throw new Error('Invalid ISO string')
  }

  const [_, year, month, day, hour, minute, second] = time_array

  return {
    year: parseInt(year, 10),
    month: parseInt(month, 10),
    day: parseInt(day, 10),
    hour: parseInt(hour, 10),
    minute: parseInt(minute, 10),
    second: parseInt(second, 10)
  }
}

export function getTimestamp(dateTimeISO: string) {
  const { year, month, day, hour, minute, second } = parseISOParts(dateTimeISO)
  const userTimezone = (reduxStore.getState() as RootState).app.timezone || DEFAULT_TIMEZONE
  const dateTime = DateTime.fromObject({}).setZone(userTimezone).set({ year, month, day, hour, minute, second })
  return Math.floor(dateTime.toMillis() / 1000)
}

export function validateTimeRange(start: DateTime, end: DateTime, min: DateTime, max: DateTime) {
  switch (true) {
    case start > end:
      return false
    case start < min || end < min:
      return false
    case start > max || end > max:
      return false
    default:
      return true
  }
}
