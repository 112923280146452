import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { useFormatMessage } from 'lib/localization'
import routesConfig, { UiRoute } from 'lib/routesConfig'
import { useUsersRights } from 'components/libs/userRights/pages/useUsersRights'
import { SubMenuItem } from 'components/libs/layout/navbar/sidebarTypes'
import appConfig from 'config/appConfig'

export enum UsersTabs {
  usersList = 'users_list',
  defaultPolicy = 'default_policy',
  addUpdateUsers = 'add_update_users',
  quarantineNotification = 'quarantine_notification',
  emailContinuity = 'email_continuity'
}

const TABS: [UsersTabs, UiRoute][] = [
  [UsersTabs.usersList, routesConfig.USERS_LIST],
  [UsersTabs.defaultPolicy, routesConfig.USERS_DEFAULT_POLICY],
  [UsersTabs.addUpdateUsers, routesConfig.USERS_ADD_UPDATE_USERS],
  [UsersTabs.quarantineNotification, routesConfig.USERS_QUARANTINE_NOTIFICATION],
  [UsersTabs.emailContinuity, routesConfig.USERS_EMAIL_CONTINUITY]
]

const BASE_I18N_KEY = 'ess.users.tabs'

export const useUsersSubmenuItemsLogic = (): [SubMenuItem[]] => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const { canAddUpdateUsers, canUpdateEmailContinuitySettings } = useUsersRights()

  const usersListItems: SubMenuItem[] = useMemo(() => {
    const subMenuItems = TABS.reduce((tabs: SubMenuItem[], tab) => {
      const [tabId, routeConfig] = tab
      if (
        !routeConfig ||
        (tabId === UsersTabs.emailContinuity && !canUpdateEmailContinuitySettings) ||
        (tabId === UsersTabs.addUpdateUsers && !canAddUpdateUsers)
      ) {
        return tabs
      }

      return [
        ...tabs,
        {
          name: formatMessage(tabId),
          path: routeConfig.path
        }
      ]
    }, [])
    return subMenuItems
  }, [canUpdateEmailContinuitySettings, formatMessage, canAddUpdateUsers])

  return useMemo(() => [usersListItems], [usersListItems])
}
