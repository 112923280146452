import React, { useMemo } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Typography,
  useTokens
} from '@barracuda-internal/bds-core-v2'
import { useFormatMessage } from 'lib/localization'
import { useContinueDialogLogic } from './useContinueDialogLogic'

const BASE_I18N_KEY = 'ess_wizard.microsoft_connected.dialog'

export interface ContinueDialogProps {
  onClose: () => void
}

const ContinueDialog: React.FC<ContinueDialogProps> = ({ onClose }) => {
  const tokens = useTokens()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [eventHandlers] = useContinueDialogLogic()

  return useMemo(
    () => (
      <Dialog open aria-hidden bdsDialogPadding="spacious" bdsDialogWidth="medium" onClose={onClose}>
        <DialogTitle onClose={onClose} showCloseIcon title={formatMessage('title')} />
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: tokens.aliasesSpaceContainerDefault
            }}
          >
            <Typography
              sx={{
                fontFamily: tokens.aliasesFontFamilyDefault,
                fontSize: tokens.aliasesFontSizeDefault,
                fontWeight: tokens.aliasesFontWeightDefault,
                color: tokens.aliasesColorTextStaticEmphasis,
                lineHeight: tokens.aliasesLineHeightDefault
              }}
            >
              {formatMessage('content_1')}
            </Typography>
            <Box>
              <Typography
                sx={{
                  fontFamily: tokens.aliasesFontFamilyDefault,
                  fontSize: tokens.aliasesFontSizeDefault,
                  fontWeight: tokens.aliasesFontWeightDefault,
                  color: tokens.aliasesColorTextStaticEmphasis,
                  lineHeight: tokens.aliasesLineHeightDefault
                }}
              >
                {`\u2022 ${formatMessage('bullet_1')}`}
              </Typography>
              <Typography
                sx={{
                  fontFamily: tokens.aliasesFontFamilyDefault,
                  fontSize: tokens.aliasesFontSizeDefault,
                  fontWeight: tokens.aliasesFontWeightDefault,
                  color: tokens.aliasesColorTextStaticEmphasis,
                  lineHeight: tokens.aliasesLineHeightDefault
                }}
              >
                {`\u2022 ${formatMessage('bullet_2')}`}
              </Typography>
              <Typography
                sx={{
                  fontFamily: tokens.aliasesFontFamilyDefault,
                  fontSize: tokens.aliasesFontSizeDefault,
                  fontWeight: tokens.aliasesFontWeightDefault,
                  color: tokens.aliasesColorTextStaticEmphasis,
                  lineHeight: tokens.aliasesLineHeightDefault
                }}
              >
                {`\u2022 ${formatMessage('bullet_3')}`}
              </Typography>
            </Box>
            <Typography
              sx={{
                fontFamily: tokens.aliasesFontFamilyDefault,
                fontSize: tokens.aliasesFontSizeDefault,
                fontWeight: tokens.aliasesFontWeightDefault,
                color: tokens.aliasesColorTextStaticEmphasis,
                lineHeight: tokens.aliasesLineHeightDefault
              }}
            >
              {formatMessage('content_2')}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button bdsType="interactiveEmphasis" onClick={eventHandlers.onOkay}>
            {formatMessage('okay')}
          </Button>
        </DialogActions>
      </Dialog>
    ),
    [eventHandlers, formatMessage, onClose, tokens]
  )
}

export default ContinueDialog
