import { UserType } from 'types/auth'
import { UserRole } from 'config/userRole'

export interface IdTokenMetadata {
  bccAccountId: string
  accountId: string
  domainId: number
  queryString?: string | null
}

export interface IdToken {
  iss: string
  sub: string
  aud: string
  exp: number
  iat: number
  auth_time: number
  nonce: string
  acr?: string | null
  amr?: string | null
  azp?: string | null
  metadata?: IdTokenMetadata | null
}

export enum OAuth2Connection {
  ESS_DB = 'ess_db',
  TEMP_PASSCODE = 'temp_passcode',
  LDAP = 'ldap',
  MS_ENTRA = 'ms_entra',
  TDF = 'tdf',
  IPIR = 'ipir'
}

export interface OAuth2ProcessDataPublic {
  id: string
  startedAtIso: string
  availableConnections: OAuth2Connection[]
  queryString: string
}

export interface EGDIdentity {
  preferredUserId: string
  primaryUserId: string | null
  region: string
  domainId: number
  accountId: string
  bccAccountId: string
  isTempPasscodeEnabled: boolean
  isReactEnabled: boolean
  isReactGreenAppEnabled: boolean
  isAzureSsoEnabled: boolean
  userType: UserType
  roleType: UserRole
}

export enum OAuth2Exceptions {
  OAuth2ProcessNotFound = 'OAuth2ProcessNotFound'
}
