import React, { useMemo } from 'react'

import { Grid, Typography, Link, Button, Alert } from '@barracuda-internal/bds-core'
import { Launch } from '@barracuda-internal/bds-core/dist/Icons/Core'
import { orderBy } from 'lodash'

import appConfig from 'config/appConfig'
import { useFormatMessage } from 'lib/localization'
import { trackEventInAllServices, TRACKING_EVENTS } from 'lib/monitoring/monitoringService'
import styles from 'components/pages/setupFlow/views/emailFlow/sections/sectionStyles'
import { useRemoveOldMxRecordsLogic } from 'components/pages/setupFlow/views/emailFlow/sections/useRemoveOldMxRecordsLogic'
import MxRecordRow from 'components/pages/setupFlow/views/emailFlow/sections/mxRow/MxRecordRow'

const BASE_I18N_KEY = 'ess_wizard.email_flow.remove_old_mx_records'

const RemoveOldMxRecords = () => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  const [state, eventHandlers] = useRemoveOldMxRecordsLogic()

  return useMemo(
    () => (
      <Grid container direction="column">
        <Typography className={classes.removeOldRecordssubtitle} variant="body1">
          {formatMessage('subtitle')}
        </Typography>

        <Link
          data-testid="provider-mx-help-link"
          className={classes.mxHelpLink}
          variant="body1"
          onClick={() => {
            trackEventInAllServices(TRACKING_EVENTS.WIZARD.STEP_3_VISIT_EDIT_MX_LINK_URL)
          }}
          href={
            state.providerWithMxLinks?.removeMxLink ||
            state.providerWithMxLinks?.addMxLink ||
            appConfig.LINKS.BARRACUDA_SETUP_MX_RECORDS
          }
          target="_blank"
          underline="hover"
        >
          {formatMessage(state.providerWithMxLinks ? 'edit_mx_record_custom_provider_link' : 'edit_mx_record_link', {
            providerName: state.providerWithMxLinks?.providerName
          })}
          <Launch className={classes.launchIcon} />
        </Link>

        {/* MX RECORRDS DATA */}
        <Grid container direction="row">
          <Grid className={classes.tableWrapper} container direction="column">
            {/* TABLE HEADER */}
            <Grid className={classes.tableHeader} container direction="row" alignItems="center">
              <Grid item xs={2} md={1}>
                <Typography variant="h5" align="center">
                  {formatMessage('priority')}
                </Typography>
              </Grid>
              <Grid className={classes.domainColumn} item xs={7} md={9}>
                <Typography variant="h5">{formatMessage('domain')}</Typography>
              </Grid>
              <Grid item xs={3} md={2}>
                <Typography variant="h5">{formatMessage('status')}</Typography>
              </Grid>
            </Grid>

            {/* EMPTY TABLE CONTENT */}
            {!state.oldMxRecords && <Grid className={classes.tableRow} container direction="row" alignItems="center" />}

            {/* TABLE CONTENT */}
            {state.oldMxRecords && (
              <Grid data-testid="old-mx-records-data" container>
                {!state.oldMxRecords.length && (
                  <Grid
                    data-testid="success-record-message"
                    className={classes.tableRow}
                    container
                    direction="row"
                    alignItems="center"
                  >
                    <MxRecordRow disabledCopy verified domain={formatMessage('success_record_message')} />
                  </Grid>
                )}

                {!!state.oldMxRecords.length &&
                  orderBy(state.oldMxRecords, ['domain', 'priority']).map((mxRecord, recordIdx) => (
                    <Grid
                      key={`${mxRecord.domain}${mxRecord.priority}`}
                      className={classes.tableRow}
                      container
                      direction="row"
                      alignItems="center"
                    >
                      <MxRecordRow {...mxRecord} isMissedToVerify={state.isMissedToVerify[recordIdx]} />
                    </Grid>
                  ))}
              </Grid>
            )}
          </Grid>

          <Grid container justifyContent="flex-end">
            <Button
              className={classes.verifyRecordsButton}
              data-testid="verify-button"
              variant="outlined"
              disabled={state.verifyUpdateButtonStatuses.disabled}
              isLoading={state.verifyUpdateButtonStatuses.inProgress}
              onClick={eventHandlers.onVerifyUpdate}
              color="primary"
            >
              {formatMessage('verify_update')}
            </Button>
          </Grid>

          <Grid className={classes.infoWrapper} container direction="column">
            <Alert severity="info">{formatMessage('mx_update_note')}</Alert>
          </Grid>
        </Grid>
      </Grid>
    ),
    [classes, formatMessage, state, eventHandlers]
  )
}

export default RemoveOldMxRecords
