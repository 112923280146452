import { makeStyles, colors } from '@barracuda-internal/bds-core'

export default makeStyles(theme => ({
  bannerContainer: {
    backgroundColor: colors.scaleGray050,
    minHeight: theme.spacing(4),
    padding: theme.spacing(0.5, 2)
  },
  bannerMessage: {
    alignItems: 'center',
    display: 'flex'
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  text: {
    lineHeight: 'normal'
  },
  button: {
    minWidth: 123
  },
  iframeWrapper: {
    flexGrow: 1
  },
  iframe: {
    width: '100%',
    border: 0,
    overflow: 'hidden'
  }
}))
