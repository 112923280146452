import { Box, useTokens } from '@barracuda-internal/bds-core-v2'
import React, { PropsWithChildren, useMemo } from 'react'

const PageActionContainer: React.FC<PropsWithChildren> = ({ children }) => {
  const tokens = useTokens()

  return useMemo(
    () => (
      <Box
        data-testid="PageActionContainer"
        sx={{
          flexGrow: 1,
          gap: tokens.aliasesSpaceComponentSpacious,
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center'
        }}
      >
        {children}
      </Box>
    ),
    [children, tokens.aliasesSpaceComponentSpacious]
  )
}

export default PageActionContainer
