import React, { useMemo } from 'react'

import { camelCase, snakeCase } from 'lodash'
import { DateTime } from 'luxon'
import { MaterialReactTable } from 'material-react-table'
import { CriticalOutline as CriticalOutlineIcon } from '@barracuda-internal/bds-core/dist/Icons/Feedback'
import {
  Checkbox,
  Grid,
  ListItemText,
  SelectLabel,
  SelectMenuItem,
  SelectWrapper,
  Typography,
  BitFilterAltIcon,
  ConditionCheckListFilter
} from '@barracuda-internal/bds-core'

import { useFormatMessage } from 'lib/localization'
import { ALL_DOMAINS } from 'components/pages/reports/reportList/useReportsListLogic'
import styles from 'components/pages/reports/reportsStyles'
import {
  BarracudaReportTypes,
  EmailFlows,
  FieldNames,
  FilterConditions,
  ListTop,
  ReportsListItem,
  paginatedReports
} from 'types/reports'
import { SearchField } from 'components/pages/reports/reportList/customizedBDSComponents/SearchField'
import { Pagination } from 'components/pages/reports/reportList/customizedBDSComponents/Pagination'
import Select from 'components/libs/bds/Select'
import { useDataTableLogic } from 'components/pages/reports/reportList/useDataTableLogic'
import DateRangeSelector from 'components/pages/reports/reportList/DateRangeSelector'
import { MinMaxFilter } from 'components/pages/reports/reportList/customizedBDSComponents/MinMaxFilter'

export type IsSearchable = boolean
export type IsRangeSelectorVisible = boolean
export type IsDomainsSelectorVisible = boolean
export type IsDirectionSelectorVisible = boolean
export type IsTopSelectorVisible = boolean
export type TableControllerConfig = [
  IsSearchable,
  IsRangeSelectorVisible,
  IsDomainsSelectorVisible,
  IsDirectionSelectorVisible,
  IsTopSelectorVisible
]

const TABLE_CONTROLLERS_CONFIG: { [keys in BarracudaReportTypes]: TableControllerConfig } = {
  [BarracudaReportTypes.inboundTraffic]: [false, true, true, true, false],
  [BarracudaReportTypes.outboundTraffic]: [false, true, true, true, false],
  [BarracudaReportTypes.inboundBlockedEmails]: [false, true, true, false, false],
  [BarracudaReportTypes.topInboundEmailSenders]: [true, true, true, true, true],
  [BarracudaReportTypes.topInboundEmailRecipients]: [true, true, true, false, true],
  [BarracudaReportTypes.topInboundBlockedSenders]: [true, true, true, true, true],
  [BarracudaReportTypes.topInboundBlockedRecipients]: [true, true, true, false, true],
  [BarracudaReportTypes.topOutboundEmailSenders]: [true, true, true, true, true],
  [BarracudaReportTypes.topOutboundBlockedSenders]: [true, true, true, true, true]
}

const DISABLED_LIST_TOP_ITEMS = [ListTop.all]

export interface ReportTableProps {
  selectedListItem: ReportsListItem | undefined
}

const BASE_I18N_KEY = 'ess.reports'

const ReportTable = ({ selectedListItem }: ReportTableProps) => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [
    {
      columns,
      filteredReport,
      paginationConfig,
      filterValues,
      cachedFilterValues,
      updateFilterValues,
      domainSelectorValue,
      onUpdateColumnFilter,
      isLoading,
      isFailedToLoad,
      onOpenCachedDataSelector,
      onCloseCachedDataSelector,
      filtersRefCallback,
      tableWrapperRef,
      tableControlWrapperRef,
      tableHeight,
      emailsList,
      minMaxAbsolutes,
      availableDomains
    }
  ] = useDataTableLogic(selectedListItem)

  return useMemo(() => {
    if (!selectedListItem) {
      return null
    }

    const [
      isSearchable,
      isRangeSelectorVisible,
      isDomainsSelectorVisible,
      isDirectionSelectorVisible,
      isTopSelectorVisible
    ] = TABLE_CONTROLLERS_CONFIG[selectedListItem.type]
    const dateRangeError = cachedFilterValues?.errors.dateRange || filterValues.errors.dateRange
    const paginatedReport = paginatedReports.includes(selectedListItem.type)
    return (
      <Grid ref={tableWrapperRef} className={classes.dataTableWrapper} container direction="column">
        {/* Toolbar */}
        <Grid ref={tableControlWrapperRef} container className={classes.tableControlsWrapper} direction="row">
          <Grid className={classes.tableControllerItem} item xs>
            <SearchField
              id="table-search"
              disabled={!isSearchable || isLoading || isFailedToLoad}
              value={filterValues.search}
              onChange={updateFilterValues('search')}
            />
          </Grid>
          {isRangeSelectorVisible && (
            <Grid item className={classes.tableControllerItem}>
              <DateRangeSelector
                disabled={isLoading}
                onOpen={onOpenCachedDataSelector}
                onClose={onCloseCachedDataSelector}
                onChange={updateFilterValues('startDate')}
                errorMessage={dateRangeError && formatMessage(dateRangeError)}
                value={[
                  DateTime.fromISO(cachedFilterValues?.startDate || filterValues.startDate || ''),
                  DateTime.fromISO(cachedFilterValues?.endDate || filterValues.endDate || '')
                ]}
              />
            </Grid>
          )}
          {isDomainsSelectorVisible && (
            <Grid item className={classes.tableControllerItem}>
              <SelectWrapper className={classes.domainsWrapper} variant="outlined">
                <SelectLabel>{formatMessage('domain')}</SelectLabel>
                <Select
                  multiple
                  disabled={isLoading}
                  onOpen={onOpenCachedDataSelector}
                  onClose={onCloseCachedDataSelector}
                  data-testid="domains-selector"
                  label={formatMessage('domain')}
                  className={classes.selector}
                  renderValue={(): string => domainSelectorValue}
                  onChange={updateFilterValues('domainIds')}
                  value={cachedFilterValues?.domainIds || filterValues.domainIds}
                >
                  <SelectMenuItem data-testid="select-all-domains" value={ALL_DOMAINS}>
                    <Checkbox
                      checked={(cachedFilterValues?.domainIds || filterValues.domainIds).includes(ALL_DOMAINS)}
                    />
                    <ListItemText secondary={formatMessage('all_domains')} />
                  </SelectMenuItem>
                  {availableDomains?.map(domainConfig => (
                    <SelectMenuItem
                      data-testid={domainConfig.domainId}
                      key={domainConfig.domainId}
                      value={domainConfig.domainId}
                    >
                      <Checkbox
                        checked={(cachedFilterValues?.domainIds || filterValues.domainIds).includes(
                          domainConfig.domainId
                        )}
                      />
                      <ListItemText secondary={domainConfig.domainName} />
                    </SelectMenuItem>
                  ))}
                </Select>
              </SelectWrapper>
            </Grid>
          )}
          {isDirectionSelectorVisible && (
            <Grid item className={classes.tableControllerItem}>
              <SelectWrapper variant="outlined">
                <SelectLabel>{formatMessage('email_flow')}</SelectLabel>
                <Select
                  data-testid="directions-selector"
                  disabled={isLoading}
                  label={formatMessage('email_flow')}
                  className={classes.selector}
                  onChange={updateFilterValues('direction')}
                  value={filterValues.direction}
                >
                  {Object.values(EmailFlows).map(direction => (
                    <SelectMenuItem data-testid={direction} key={direction} value={direction}>
                      {formatMessage(direction.toLowerCase())}
                    </SelectMenuItem>
                  ))}
                </Select>
              </SelectWrapper>
            </Grid>
          )}
          {isTopSelectorVisible && (
            <Grid item className={classes.tableControllerItem}>
              <SelectWrapper variant="outlined">
                <SelectLabel>{formatMessage('display')}</SelectLabel>
                <Select
                  data-testid="top-selector"
                  disabled={isLoading}
                  label={formatMessage('display')}
                  className={classes.selector}
                  onChange={updateFilterValues('listTop')}
                  value={filterValues.listTop}
                >
                  {Object.values(ListTop)
                    .filter(listTop => !DISABLED_LIST_TOP_ITEMS.includes(listTop))
                    .map(listTop => (
                      <SelectMenuItem data-testid={listTop} key={listTop} value={listTop}>
                        {listTop === ListTop.all && formatMessage('display_all')}
                        {listTop !== ListTop.all && formatMessage('top', { count: listTop.replace(/^\D+/g, '') })}
                      </SelectMenuItem>
                    ))}
                </Select>
              </SelectWrapper>
            </Grid>
          )}
        </Grid>

        {/* Table */}
        <MaterialReactTable
          columns={columns.map(column => ({
            ...column,
            ...(![FieldNames.date, FieldNames.senderEmail, FieldNames.recipientEmail].includes(
              column.id as FieldNames
            ) && {
              enableColumnActions: true,
              renderColumnActionsMenuItems: () => [
                <Grid
                  ref={filtersRefCallback}
                  className={classes.customFilterWrapper}
                  data-filter-id={column.id}
                  key="numberConditionCheckboxFilter"
                >
                  <MinMaxFilter
                    min={
                      (cachedFilterValues?.columnFilters || filterValues.columnFilters)[column.id as FieldNames]?.min ||
                      0
                    }
                    max={
                      (cachedFilterValues?.columnFilters || filterValues.columnFilters)[column.id as FieldNames]?.max ||
                      0
                    }
                    absoluteMin={0}
                    absoluteMax={minMaxAbsolutes[camelCase(column.id as FieldNames)] || 0}
                    onChangeMax={onUpdateColumnFilter(column.id as FieldNames, 'max')}
                    onChangeMin={onUpdateColumnFilter(column.id as FieldNames, 'min')}
                    onDecrementMax={onUpdateColumnFilter(column.id as FieldNames, 'max', -1)}
                    onDecrementMin={onUpdateColumnFilter(column.id as FieldNames, 'min', -1)}
                    onIncrementMax={onUpdateColumnFilter(column.id as FieldNames, 'max', 1)}
                    onIncrementMin={onUpdateColumnFilter(column.id as FieldNames, 'min', 1)}
                  />
                </Grid>
              ]
            }),
            ...([FieldNames.senderEmail, FieldNames.recipientEmail].includes(column.id as FieldNames) && {
              enableColumnActions: true,
              renderColumnActionsMenuItems: () => [
                <Grid
                  ref={filtersRefCallback}
                  className={classes.customFilterWrapper}
                  key="emailConditionCheckboxFilter"
                >
                  <ConditionCheckListFilter
                    checkboxOptions={emailsList.map(email => ({
                      label: email,
                      value: email
                    }))}
                    checkboxOptionsChecked={
                      (cachedFilterValues?.columnFilters || filterValues.columnFilters)[column.id as FieldNames]
                        ?.values || emailsList
                    }
                    condition={
                      (cachedFilterValues?.columnFilters || filterValues.columnFilters)[column.id as FieldNames]
                        ?.condition || FilterConditions.equals
                    }
                    conditions={Object.values(FilterConditions).map(condition => ({
                      value: condition,
                      label: formatMessage(snakeCase(`conditions.${condition}`))
                    }))}
                    onConditionChange={onUpdateColumnFilter(column.id as FieldNames, 'condition')}
                    onCheckboxChange={onUpdateColumnFilter(column.id as FieldNames, 'values')}
                    onSelectAllChange={onUpdateColumnFilter(column.id as FieldNames, 'values')}
                  />
                </Grid>
              ]
            })
          }))}
          data={(filteredReport?.data as any) || []}
          renderBottomToolbar={() => (isLoading ? <data /> : <Pagination {...paginationConfig} />)}
          muiTableContainerProps={{ sx: { maxHeight: `${tableHeight}px` } }}
          enableTopToolbar={false}
          columnFilterDisplayMode="popover"
          initialState={{
            columnOrder: columns.map(column => column.accessorKey) as string[]
          }}
          renderEmptyRowsFallback={() => (
            <Grid container alignItems="center" justifyContent="center" direction="column">
              {isFailedToLoad && (
                <>
                  <CriticalOutlineIcon className={classes.errorTableIcon} />
                  <Typography>{formatMessage('unable_to_load')}</Typography>
                </>
              )}
              {!isFailedToLoad && !isLoading && (
                <Typography className={classes.emptyTableText}>{formatMessage('no_data_found')}</Typography>
              )}
            </Grid>
          )}
          state={{
            pagination: {
              pageIndex: paginatedReport ? 0 : filterValues.page - 1,
              pageSize: filterValues.pageSize
            },
            isLoading
          }}
          icons={{
            MoreVertIcon: (props: any) => <BitFilterAltIcon className="icon-filter-alt" {...props} />
          }}
          enableStickyHeader
        />
      </Grid>
    )
  }, [
    selectedListItem,
    cachedFilterValues?.errors.dateRange,
    cachedFilterValues?.startDate,
    cachedFilterValues?.endDate,
    cachedFilterValues?.domainIds,
    cachedFilterValues?.columnFilters,
    filterValues.errors.dateRange,
    filterValues.search,
    filterValues.startDate,
    filterValues.endDate,
    filterValues.domainIds,
    filterValues.direction,
    filterValues.listTop,
    filterValues.page,
    filterValues.pageSize,
    filterValues.columnFilters,
    tableWrapperRef,
    classes.dataTableWrapper,
    classes.tableControlsWrapper,
    classes.tableControllerItem,
    classes.domainsWrapper,
    classes.selector,
    classes.customFilterWrapper,
    classes.errorTableIcon,
    classes.emptyTableText,
    tableControlWrapperRef,
    isLoading,
    isFailedToLoad,
    updateFilterValues,
    onOpenCachedDataSelector,
    onCloseCachedDataSelector,
    formatMessage,
    availableDomains,
    columns,
    filteredReport?.data,
    tableHeight,
    domainSelectorValue,
    filtersRefCallback,
    minMaxAbsolutes,
    onUpdateColumnFilter,
    emailsList,
    paginationConfig
  ])
}
export default ReportTable
