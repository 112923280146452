import React from 'react'

import Loading from 'components/libs/loading/Loading'
import { useSetupFlowAuthorizeLogic } from './useSetupFlowAuthorizeLogic'

function SetupFlowAuthorize() {
  useSetupFlowAuthorizeLogic()

  return <Loading />
}

export default SetupFlowAuthorize
