import React from 'react'

import Loading from 'components/libs/loading/Loading'
import { useSetupFlowCallbackLogic } from './useSetupFlowCallbackLogic'

function SetupFlowCallback() {
  useSetupFlowCallbackLogic()

  return <Loading />
}

export default SetupFlowCallback
