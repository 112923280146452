import { createAsyncThunk } from '@reduxjs/toolkit'

import restClient, { ApiRejectResponse, validateApiError } from 'lib/api/restClient'
import apiRoutes from 'lib/api/apiRoutes'

export type GetPamProductDataRequest = void

interface Bundle {
  id: string
  name: string
}
interface AppUrls {
  main: string
}
interface UxOptions {
  showInNavigation: boolean
  msAdminRequired: boolean
  openInNewTab: boolean
  hoistedInNavigation: boolean
}
interface Product {
  id: string
  name: string
  appUrls: AppUrls
  category: any
  uxOptions: UxOptions
}
interface SubscriptionGroups {
  isBundle: any
  bundle: Bundle
  id: string
  subscriptions: Subscriptions[]
}
interface Subscriptions {
  isTrial: any
  isExpired: boolean
  id: string
  status: 'NEW' | 'ACTIVE' | 'EXPIRING'
  productRequestStatus: 'NEW' | 'PROCESSING' | 'FAILED' | 'SUCCESS'
  isProductOfInterest: boolean
  isNavigable: boolean
  serial: string
  product: Product
  firstValueStatus: any
}
export interface PamProductDataResponse {
  data: {
    subscriptionGroups: SubscriptionGroups[]
    pendingActivations: any[]
    error: {
      code: string
    }
  }
}

export const getPamProductData = createAsyncThunk<PamProductDataResponse, GetPamProductDataRequest, ApiRejectResponse>(
  'PAM/getPamProductData',
  async (payload, { rejectWithValue }) => {
    try {
      const resp = await restClient(apiRoutes.GET_PRODUCT_DATA)
      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)
