import React, { useMemo } from 'react'

import { Button, Grid, MenuItem, Select, Typography } from '@barracuda-internal/bds-core'
import { useFormatMessage } from 'lib/localization'

import { GROUP_TITLE, TOPICS } from 'components/pages/support/config'
import outboundSettingsStyles from 'components/pages/outboundSettings/outboundSettingsStyles'
import OutboundContentPolicies from 'components/pages/support/help/outboundContentPolicies/OutboundContentPolicies'
import AttachmentTable from 'components/pages/outboundSettings/contentPolicies/AttachmentTable'
import {
  SELECT_NAMES,
  useContentPoliciesLogic
} from 'components/pages/outboundSettings/contentPolicies/useContentPoliciesLogic'
import MessageTable from 'components/pages/outboundSettings/contentPolicies/MessageTable'
import PredefinedFiltersTable from 'components/pages/outboundSettings/contentPolicies/PredefinedFiltersTable'
import PredefinedFilterExceptionsTable from 'components/pages/outboundSettings/contentPolicies/PredefinedFilterExceptionsTable'
import { PageTitle, FormSpacer } from 'components/libs/layout/pages/pageLayout'
import { useSettingsStateLogic } from 'components/libs/useSettingsState'
import { SettingsPageContainer } from 'components/libs/layout/pages/SettingsPageContainer'

const BASE_I18N_KEY = 'ess.inbound_settings.content_policies'

const ContentPolicies: React.FC = () => {
  const inboundClasses = outboundSettingsStyles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [state, eventHandlers] = useContentPoliciesLogic()
  const [UseGetSettingsStateLogic] = useSettingsStateLogic()

  return useMemo(
    () => (
      <SettingsPageContainer
        isLoading={UseGetSettingsStateLogic.isLoading}
        isFailedToLoad={UseGetSettingsStateLogic.isFailedToLoad}
        dirtyCheck
        isDirtyForm={state.hasPageChanges}
      >
        <PageTitle
          title={formatMessage('title')}
          onOpenSupport={eventHandlers.helpConfig.onHelpClick}
          help={{
            content: OutboundContentPolicies,
            onClose: eventHandlers.helpConfig.onCloseHelp,
            title: TOPICS.outboundContentPolicies,
            group: GROUP_TITLE.outboundSettings,
            isHelpOpen: eventHandlers.helpConfig.isOpen
          }}
        />
        <FormSpacer />
        <AttachmentTable delegateDirtyFormState={eventHandlers.setIsAttachmentTableDirty} />
        <Grid container alignItems="center" className={inboundClasses.selectRowWrapper}>
          <Typography variant="body2">{formatMessage('archive_filtering')}</Typography>
          <Select
            variant="outlined"
            className={inboundClasses.headerDropdown}
            name={SELECT_NAMES.ARCHIVE}
            value={state.form.outbound_password_archive_filtering}
            onChange={eventHandlers.handleOnSelectChange}
            SelectDisplayProps={{ style: { padding: 5 } }}
            data-testid="archive-field"
          >
            {state.actionOptions.map(action => (
              <MenuItem key={action} value={action}>
                {formatMessage(action)}
              </MenuItem>
            ))}
          </Select>
          <Button
            color="primary"
            onClick={() => {
              eventHandlers.onSave(SELECT_NAMES.ARCHIVE)
            }}
            variant="contained"
            size="small"
            data-testid="save-button"
          >
            {formatMessage('save')}
          </Button>
        </Grid>
        <Grid container alignItems="center" className={inboundClasses.selectRowWrapper}>
          <Typography variant="body2">{formatMessage('msoffice_filtering')}</Typography>
          <Select
            variant="outlined"
            className={inboundClasses.headerDropdown}
            name={SELECT_NAMES.MSOFFICE}
            value={state.form.outbound_password_msoffice_filtering}
            onChange={eventHandlers.handleOnSelectChange}
            SelectDisplayProps={{ style: { padding: 5 } }}
            data-testid="msoffice-field"
          >
            {state.actionOptions.map(action => (
              <MenuItem key={action} value={action}>
                {formatMessage(action)}
              </MenuItem>
            ))}
          </Select>
          <Button
            color="primary"
            onClick={() => {
              eventHandlers.onSave(SELECT_NAMES.MSOFFICE)
            }}
            variant="contained"
            size="small"
            data-testid="save-button"
          >
            {formatMessage('save')}
          </Button>
        </Grid>
        <Grid container alignItems="center" className={inboundClasses.selectRowWrapper}>
          <Typography variant="body2">{formatMessage('pdf_filtering')}</Typography>
          <Select
            variant="outlined"
            className={inboundClasses.headerDropdown}
            name={SELECT_NAMES.PDF}
            value={state.form.outbound_password_pdf_filtering}
            onChange={eventHandlers.handleOnSelectChange}
            SelectDisplayProps={{ style: { padding: 5 } }}
            data-testid="pdf-field"
          >
            {state.actionOptions.map(action => (
              <MenuItem key={action} value={action}>
                {formatMessage(action)}
              </MenuItem>
            ))}
          </Select>
          <Button
            color="primary"
            onClick={() => {
              eventHandlers.onSave(SELECT_NAMES.PDF)
            }}
            variant="contained"
            size="small"
            data-testid="save-button"
          >
            {formatMessage('save')}
          </Button>
        </Grid>
        <Grid container className={inboundClasses.selectRowWrapper}>
          <MessageTable delegateDirtyFormState={eventHandlers.setIsMessageTableDirty} />
        </Grid>
        <Grid container className={inboundClasses.selectRowWrapper}>
          <PredefinedFiltersTable delegateDirtyFormState={eventHandlers.setIsFilterTableDirty} />
        </Grid>
        <Grid container className={inboundClasses.selectRowWrapper}>
          <PredefinedFilterExceptionsTable delegateDirtyFormState={eventHandlers.setIsFilterExceptionsTableDirty} />
        </Grid>
        <FormSpacer />
        <FormSpacer />
      </SettingsPageContainer>
    ),
    [UseGetSettingsStateLogic, state, formatMessage, eventHandlers, inboundClasses]
  )
}

export default ContentPolicies
