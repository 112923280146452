import React, { useMemo } from 'react'

import { Grid, Typography, Link, Button, Tooltip, Alert } from '@barracuda-internal/bds-core'
import { Launch } from '@barracuda-internal/bds-core/dist/Icons/Core'

import appConfig from 'config/appConfig'
import { useFormatMessage } from 'lib/localization'

import { trackEventInAllServices, TRACKING_EVENTS } from 'lib/monitoring/monitoringService'
import { useAddNewMxRecordsLogic } from 'components/pages/setupFlow/views/emailFlow/sections/useAddNewMxRecordsLogic'
import styles from 'components/pages/setupFlow/views/emailFlow/sections/sectionStyles'
import MxRecordRow from 'components/pages/setupFlow/views/emailFlow/sections/mxRow/MxRecordRow'

const BASE_I18N_KEY = 'ess_wizard.email_flow.add_new_mx_records'

const AddNewMxRecords = () => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  const [state, eventHandlers] = useAddNewMxRecordsLogic()

  return useMemo(
    () => (
      <Grid container direction="column">
        {!!state.provider?.length && (
          <Typography data-testid="provider-text" className={classes.subtitle} variant="body1">
            {formatMessage('title')}
          </Typography>
        )}

        {/* PROVIDER DATA */}
        {!!state.provider?.length && (
          <Grid className={classes.providerContainer} container direction="row">
            <Grid className={classes.tableWrapper} container direction="column">
              {/* TABLE HEADER */}
              <Grid className={classes.tableHeader} container direction="row" alignItems="center">
                <Grid item xs={6}>
                  <Typography variant="h5">{formatMessage('dns_hosting_provider')}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h5">{formatMessage('link')}</Typography>
                </Grid>
              </Grid>

              {/* TABLE CONTENT */}
              <Grid className={classes.tableRow} container direction="row" alignItems="center">
                {state.provider?.map(provider => (
                  <Grid data-testid="provider-data" key={provider.providerName} container direction="row">
                    <Grid className={classes.textColumn} item xs={6}>
                      <Tooltip title={provider.providerName} placement="top-start">
                        <Typography data-testid="provider-name" variant="body1" noWrap>
                          {provider.providerName}
                        </Typography>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container direction="row">
                        <Grid item style={{ maxWidth: '90%' }}>
                          <Typography data-testid="provider-website" noWrap>
                            <Link
                              variant="body1"
                              href={provider.url}
                              target="_blank"
                              underline="hover"
                              onClick={() => {
                                trackEventInAllServices(TRACKING_EVENTS.WIZARD.STEP_2_VISIT_PROVIDER_URL, {
                                  provider: state.provider?.[0]
                                })
                              }}
                            >
                              {formatMessage('provider_website', { provider: provider.shortName })}
                            </Link>
                          </Typography>
                        </Grid>
                        <Link
                          variant="body1"
                          href={provider.url}
                          target="_blank"
                          underline="hover"
                          onClick={() => {
                            trackEventInAllServices(TRACKING_EVENTS.WIZARD.STEP_2_VISIT_PROVIDER_URL, {
                              provider: state.provider?.[0]
                            })
                          }}
                        >
                          <Launch className={classes.launchIcon} />
                        </Link>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        )}

        <Link
          data-testid="provider-mx-help-link"
          className={classes.mxHelpLink}
          variant="body1"
          onClick={() => {
            trackEventInAllServices(TRACKING_EVENTS.WIZARD.STEP_2_VISIT_ADD_MX_LINK_URL, {
              provider: state.provider?.[0]
            })
          }}
          href={state.providerWithMxLinks?.addMxLink || appConfig.LINKS.BARRACUDA_SETUP_MX_RECORDS}
          target="_blank"
          underline="hover"
        >
          {formatMessage(state.providerWithMxLinks ? 'add_mx_record_custom_provider_link' : 'add_mx_record_link', {
            providerName: state.providerWithMxLinks?.providerName
          })}
          <Launch className={classes.launchIcon} />
        </Link>

        {/* MX RECORRDS DATA */}
        <Grid container direction="row">
          <Grid className={classes.tableWrapper} container direction="column">
            {/* TABLE HEADER */}
            <Grid className={classes.tableHeader} container direction="row" alignItems="center">
              <Grid item xs={3} md={2}>
                <Typography variant="h5" align="center">
                  {formatMessage('mx_record')}
                </Typography>
              </Grid>
              <Grid item xs={2} md={1}>
                <Typography variant="h5" align="center">
                  {formatMessage('priority')}
                </Typography>
              </Grid>
              <Grid className={classes.domainColumn} item xs={4} md={7}>
                <Typography variant="h5">{formatMessage('domain')}</Typography>
              </Grid>
              <Grid item xs={3} md={2}>
                <Typography variant="h5">{formatMessage('status')}</Typography>
              </Grid>
            </Grid>

            {/* TABLE CONTENT */}
            <Grid className={classes.tableRow} container direction="row" alignItems="center">
              {/* PRIMARY MX RECORD */}
              {state.mxRecords && (
                <MxRecordRow
                  record={formatMessage('primary')}
                  {...state.mxRecords.primary}
                  isMissedToVerify={state.isMissedToVerify.primary}
                />
              )}
            </Grid>

            {state.mxRecords && (
              <Grid
                data-testid="mx-records-data"
                className={classes.tableRow}
                container
                direction="row"
                alignItems="center"
              >
                {/* backup MX RECORD */}
                {state.mxRecords && (
                  <MxRecordRow
                    record={formatMessage('backup')}
                    {...state.mxRecords.backup}
                    isMissedToVerify={state.isMissedToVerify.backup}
                  />
                )}
              </Grid>
            )}
          </Grid>

          <Grid container justifyContent="flex-end">
            <Button
              data-testid="verify-button"
              className={classes.verifyRecordsButton}
              variant="outlined"
              disabled={state.verifyRecordButtonStatuses.disabled}
              isLoading={state.verifyRecordButtonStatuses.inProgress}
              onClick={eventHandlers.onVerifyRecords}
              color="primary"
            >
              {formatMessage('verify_records')}
            </Button>
          </Grid>

          <Grid className={classes.infoWrapper} container direction="column">
            <Alert severity="info">{formatMessage('mx_update_note')}</Alert>
          </Grid>
        </Grid>
      </Grid>
    ),
    [classes, formatMessage, state, eventHandlers]
  )
}

export default AddNewMxRecords
