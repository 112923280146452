import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { getIpirScanStatus } from 'redux/features/emailServer/emailServerSlice'
import { isFailed, isSuccess } from 'redux/toolkit/api'
import routesConfig from 'lib/routesConfig'

export interface State {
  connected: boolean
  userName?: string
  initials?: string
  ipirScanStatus: boolean
  skipDialogOpen: boolean
}

export interface EventHandlers {
  onConnect: () => void
  onSkip: () => void
  onClose: () => void
}

export type MicrosoftConnectedLogic = [State, EventHandlers]

export const useMicrosoftConnectedLogic = (): MicrosoftConnectedLogic => {
  const dispatch = useAppDispatch()
  const [skipDialogOpen, setSkipDialogOpen] = useState(false)

  const {
    userName,
    userEmail,
    ipirAccountId,
    ipirTokenId,
    ipirScanStatus,
    isIpirScanStatusSuccess,
    isIpirScanStatusFailed
  } = useAppSelector(_store => ({
    userName: _store.auth.accessTokenObject?.userInfo?.name,
    userEmail: _store.auth.accessTokenObject?.userId,
    ipirAccountId: _store.emailServer.emailServerSettings?.ipirAccountId,
    ipirTokenId: _store.emailServer.emailServerSettings?.ipirTokenId,
    ipirScanStatus: _store.emailServer.ipirScanStatus,
    isIpirScanStatusSuccess: isSuccess(_store.emailServer.getIpirScanStatusApiStatus),
    isIpirScanStatusFailed: isFailed(_store.emailServer.getIpirScanStatusApiStatus)
  }))

  useEffect(() => {
    if (!isIpirScanStatusSuccess && !isIpirScanStatusFailed && ipirAccountId && ipirTokenId) {
      dispatch(getIpirScanStatus())
    }
  }, [dispatch, isIpirScanStatusSuccess, isIpirScanStatusFailed, ipirAccountId, ipirTokenId])

  const initials = useMemo(() => {
    let result = ''
    if (userName) {
      result = userName.match(/(\b\S)?/g)?.join('') || ''
    } else if (userEmail) {
      result = userEmail.charAt(0)
    }

    return result
  }, [userEmail, userName])

  const onConnect = useCallback(() => {
    routesConfig.SETUP_FLOW_IPIR_AUTHORIZE.goto()
  }, [])

  const onSkip = useCallback(() => {
    setSkipDialogOpen(true)
  }, [])

  const onClose = useCallback(() => {
    setSkipDialogOpen(false)
  }, [])

  return useMemo(
    () => [
      {
        connected: !!ipirAccountId && !!ipirTokenId,
        userName,
        initials,
        ipirScanStatus,
        skipDialogOpen
      },
      {
        onConnect,
        onSkip,
        onClose
      }
    ],
    [userName, initials, ipirScanStatus, ipirAccountId, ipirTokenId, skipDialogOpen, onConnect, onSkip, onClose]
  )
}
