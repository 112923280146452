import { MxRecord } from 'types/emailServer'
import { DomainEntry, ForceTlsValue, SettingValue } from 'types/Settings'
import { LdapSettings } from 'types/ldap'

export interface EmailServer {
  id: string
  host: string
  type: string
  preference: string
  verified: boolean
}

export enum DomainStatus {
  NEW = 'NEW',
  VERIFIED = 'VERIFIED'
}

export interface DomainBase {
  domainId: string
  domainName: string
  type: string
  status: DomainStatus
}

export enum MxStatus {
  NOT_CHECKED = 'NOT_CHECKED',
  CONFIGURED = 'CONFIGURED',
  MISCONFIGURED = 'MISCONFIGURED',
  INLINEMISCONFIGURED = 'INLINEMISCONFIGURED',
  INLINECONFIGURED = 'INLINECONFIGURED'
}

export interface UniqueSetting {
  settingName: string
  domainSetting: any
  accountSetting: any
}

export interface Domain extends DomainBase {
  recipients: string
  mailServers: EmailServer[]
  settings: string
  domainOptions: string
  created: string
  modified: string
  uniqueRecipientCount?: number
  aliases?: DomainBase[]
  primaryMxRecord?: MxRecord
  backupMxRecord?: MxRecord
  outboundSmarthost?: string
  mxStatus?: MxStatus
  uniqueSettings?: UniqueSetting[] | null
  emergencyInboxEnabled?: SettingValue
  cnameToken?: number
  techEmail?: string
  verifiedByOtherAccount?: boolean
  aliasOf?: string
  spooling?: string
  userAutoAdd?: SettingValue
  senderSpoof?: SettingValue
  rcptToTarget?: RcptToTarget
  emergencyInboxAutoenabled?: SettingValue
  directoryServices?: DirectoryService
  azureAd?: AzureAdSettings
  inboundTlsAll?: SettingValue
  inboundTlsDomains?: DomainEntry[]
  inboundTlsDomainExemptions?: DomainEntry[]
  forceTls?: ForceTlsValue
  encryptionConnect?: boolean
  encryptionConfirmed?: string
  encryptionType?: string
  encryptionVerification?: string
  encryptionAllowReplies?: SettingValue
  encryptionReadReceipts?: SettingValue
  encryptionImage?: string
  encryptionDisplayName?: string
  encryptionNotificationSubject?: string
  encryptionHtmlPart?: string
  encryptionTextPart?: string
  ldap?: LdapSettings
  ldapSync?: SettingValue
  ldapAuth?: SettingValue
  ldapLastSync?: number
  ldapHighestSerial?: string
  srs?: SettingValue
  domainMoveState?: string
}

export enum VerificationMethod {
  MX_RECORDS = 'mx_records',
  CNAME_RECORD = 'cname_record',
  POSTMASTER_EMAIL = 'postmaster_email',
  TECH_EMAIL = 'tech_email',
  TOKEN_CHECK = 'token_check',
  CPL_PROBE = 'cpl_probe'
}

export enum VerificationResult {
  VERIFIED_NOW = 'verified_now',
  ALREADY_VERIFIED = 'already_verified',
  ALREADY_VERIFIED_BY_OTHER_ACCOUNT = 'already_verified_by_other_account',
  EMAIL_SENT = 'email_sent',
  MX_ERROR = 'mx_error',
  CNAME_ERROR = 'cname_error',
  INVALID_OR_EXPIRED = 'invalid_or_expired',
  EMAIL_ERROR = 'email_error',
  TECH_EMAIL_NOT_FOUND = 'tech_email_not_found',
  CPL_VERIFICATION_STARTED = 'cpl_verification_started',
  CPL_VERIFICATION_FAILED = 'cpl_verification_failed'
}

export enum GetDomainExtraField {
  ALIAS = 'alias',
  SETTINGS = 'settings',
  RECIPIENTS_COUNT = 'recipients_count',
  TECH_EMAIL = 'tech_email',
  MX_STATUS_CHECK = 'mx_status_check',
  UNIQUE_SETTINGS = 'unique_settings',
  ENCRYPTION = 'encryption'
}

export enum EncryptionStatus {
  UNABLE_TO_CONNECT = 'unable_to_connect',
  VALIDATED = 'validated',
  NOT_VALIDATED = 'not_validated',
  PENDING_CNAME = 'pending_cname',
  PENDING_POST = 'pending_post'
}

export enum DomainEncryptionType {
  CNAME = 'cname',
  POST = 'post'
}

export interface DomainNameById {
  domainId: number
  domainName: string
}

export interface Setting<T> {
  value: T
}

export interface DomainSettings {
  aliasOf?: Setting<string> | null
  spooling?: Setting<string> | null
  userAutoAdd?: Setting<SettingValue> | null
  senderSpoof?: Setting<SettingValue> | null
  rcptToTarget?: Setting<RcptToTarget> | null
  directoryServices?: Setting<DirectoryService> | null
  ldapHighestSerial?: Setting<string> | null
}

export interface ChangedSetting<T> {
  oldSetting: T
  newSetting: T
}

export interface ChangedDomainSettings {
  aliasOf: ChangedSetting<string> | null
  spooling: ChangedSetting<string> | null
  userAutoAdd: ChangedSetting<SettingValue> | null
  senderSpoof: ChangedSetting<SettingValue> | null
  rcptToTarget: ChangedSetting<RcptToTarget> | null
  directoryServices?: ChangedSetting<DirectoryService> | null
  ldapHighestSerial?: ChangedSetting<string> | null
}

export enum SpoolingValue {
  NO = '0',
  YES = '96'
}

export enum RcptToTarget {
  NONE = 'none',
  DOMAIN = 'domain'
}

export interface ChangedAccountSettings {
  emergencyInboxAutoenabled: ChangedSetting<SettingValue> | null
}

export enum DirectoryService {
  LDAP = 'ldap',
  AZURE_AD = 'azure',
  NONE = '0'
}

export interface AzureAdSettings {
  autoSync: 0 | 1
  enableSso: 0 | 1
}

export enum DomainType {
  ESS = 'ESS',
  HYBRID = 'HYBRID'
}

export enum DomainMoveState {
  TRANSFER_PENDING = 'transfer_pending',
  TO_TRANSFER = 'to_transfer',
  TRANSFER_COMPLETE = 'transfer_complete'
}

export enum AddDomainResult {
  DOMAIN_CREATED = 'domain_created',
  TRANSFER_REQUIRED = 'transfer_required'
}

export interface MoveDomain {
  accountId: string
  domainId: number
  domainName: string
  cnameRecord: string | null
  transferState: string | null
}

export enum DomainExceptions {
  UnverifiedDomainTransferAttemptException = 'UnverifiedDomainTransferAttemptException',
  DomainTransferAlreadyCompleteException = 'DomainTransferAlreadyCompleteException',
  DomainTransferNotFoundException = 'DomainTransferNotFoundException',
  DomainExistsInOtherAccountException = 'DomainExistsInOtherAccountException',
  DomainNotFoundException = 'DomainNotFoundException',
  RequiredCnameRecordNotFoundException = 'RequiredCnameRecordNotFoundException',
  DuplicateTransferAttemptException = 'DuplicateTransferAttemptException',
  UnauthorizedDomainTransferAccess = 'UnauthorizedDomainTransferAccess',
  DeleteDomainOutboundIpConflict = 'DeleteDomainOutboundIpConflict',
  DeleteDomainOnlyVerifiedDomainConflict = 'DeleteDomainOnlyVerifiedDomainConflict'
}

export enum DeleteDomainConfirmationType {
  CONFIRM_DELETION = 'CONFIRM_DELETION',
  CONFIRM_DELETION_WHEN_ONLY_VERIFIED = 'CONFIRM_DELETION_WHEN_ONLY_VERIFIED'
}

export interface DomainTransferStateRecord {
  domainId: number
  transferState: string
}
