import { useEffect } from 'react'
import { v4 as makeUuid } from 'uuid'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { isFailed, isSuccess } from 'redux/toolkit/api'
import { createAuthenticationProcess } from 'redux/features/oauth2/oauth2Slice'
import routesConfig from 'lib/routesConfig'
import { OAuth2Connection } from 'types/oauth2'

enum APP_TYPES {
  SENTINEL = 'Sentinel',
  INLINE = 'Inline'
}

export const useSetupFlowAuthorizeLogic = () => {
  const dispatch = useAppDispatch()
  const redirect_uri = `${window.location.origin}/wizard/setup/v2/ipir/callback`
  const connection = OAuth2Connection.IPIR

  const {
    isCreateProcessSuccess,
    isCreateProcessFailed,
    authProcess,
    accountId,
    bccAccountId,
    email,
    ipir_account_id,
    ipir_token_id
  } = useAppSelector(_store => ({
    isCreateProcessSuccess: isSuccess(_store.oauth2.api.createAuthenticationProcessApiStatus),
    isCreateProcessFailed: isFailed(_store.oauth2.api.createAuthenticationProcessApiStatus),
    authProcess: _store.oauth2.createAuthenticationProcessResponse,
    accountId: _store.auth.accessTokenObject?.accountId || '',
    bccAccountId: _store.auth.accessTokenObject?.bccAccountId || '',
    email: _store.auth.accessTokenObject?.userId || '',
    ipir_account_id: _store.emailServer.emailServerSettings?.ipirAccountId,
    ipir_token_id: _store.emailServer.emailServerSettings?.ipirTokenId
  }))

  useEffect(() => {
    const authProcessId = makeUuid()

    const metadata = {
      egd_account_id: accountId,
      bcc_account_id: bccAccountId,
      email,
      ipir_account_id,
      ipir_token_id,
      app_type: APP_TYPES.SENTINEL
    }
    dispatch(createAuthenticationProcess({ authProcessId, metadata }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isCreateProcessSuccess && authProcess) {
      window.location.href = `${process.env.REACT_APP_GATEWAY_URL}/internal/oauth2/authorize?auth_process_id=setup|${authProcess.id}&redirect_uri=${redirect_uri}&connection=${connection}`
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateProcessSuccess])

  useEffect(() => {
    if (isCreateProcessFailed) {
      routesConfig.ONBOARD_WIZARD.goto(undefined, false, '?error=OAuth2AuthorizationCodeRequestFailed')
    }
  }, [isCreateProcessFailed])
}
