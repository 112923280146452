import React, { useMemo } from 'react'
import { Alert, Link } from '@barracuda-internal/bds-core'
import styles from 'components/libs/banner/BannerStyles'
import { useFormatMessage } from 'lib/localization'

const BASE_I18N_KEY = 'ess.global_message_banner'

const AccountExpiredBanner = () => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  return useMemo(
    () => (
      <Alert className={classes.alert} severity="warning">
        {formatMessage('account_expired.text', {
          a: (txt: any): any => (
            <Link
              target="_blank"
              className={classes.inlineBannerLink}
              href="https://www.barracuda.com/purchase/salesrep"
            >
              {txt}
            </Link>
          )
        })}
      </Alert>
    ),
    [classes.alert, classes.inlineBannerLink, formatMessage]
  )
}

export default AccountExpiredBanner
