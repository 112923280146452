import { createAsyncThunk } from '@reduxjs/toolkit'

import restClient, { ApiRejectResponse, validateApiError } from 'lib/api/restClient'
import apiRoutes from 'lib/api/apiRoutes'
import {
  AddDomainResult,
  ChangedAccountSettings,
  ChangedDomainSettings,
  DeleteDomainConfirmationType,
  Domain,
  DomainExceptions,
  DomainNameById,
  DomainSettings,
  DomainTransferStateRecord,
  GetDomainExtraField,
  MoveDomain,
  VerificationMethod,
  VerificationResult
} from 'types/domains'
import { setErrorSnackBar, setSuccessSnackBar, setWarningSnackBar } from 'redux/features/app/appSlice'
import config from 'config/appConfig'
import logger from 'lib/logger'
import { DomainsInDB } from 'types/redux/user/UserTypes'
import camelizeKeys from 'lib/camelizeKeys'
import { RootState } from 'redux/toolkit/store'
import { UserRole } from 'config/userRole'
import { User } from 'types/auth'
import { EncryptionSettings } from 'types/Encryption'
import {
  clearDomainUniqueSettings,
  resetAddDomain,
  resetDeleteDomain,
  resetGetDomain,
  resetGetDomainForVerification,
  resetManageDomain,
  resetSaveDomainSettings,
  resetVerifyDomain
} from 'redux/features/domains/domainsActions'
import {
  resetAddEmailServer,
  resetRemoveEmailServer,
  resetTestEmailServer,
  resetUpdateEmailServer
} from 'redux/features/emailServer/emailServerSlice'
import { QuarantineNotifierException } from 'types/qns'

export interface GetDomainListRequestParams {
  page: number
  size: number
  sortField?: string
  sortDirection?: 'asc' | 'desc'
  domainNameFilter?: string
}

export type GetDomainListRequest = GetDomainListRequestParams | undefined

export interface GetDomainListResponse {
  items: Domain[]
  totalCount: number
}

export const getDomainList = createAsyncThunk<GetDomainListResponse, GetDomainListRequest, ApiRejectResponse>(
  'DOMAINS/getDomainList',
  async (payload, { rejectWithValue, getState }) => {
    try {
      const rootState = getState() as RootState
      const tableState = rootState.dataTables.domains
      const user = rootState.auth.accessTokenObject
      const { domainNameFilter } = rootState.domains
      const { skip, take, sort } = tableState
      const tablePage = take === 0 ? 1 : skip / take + 1
      const params = {
        page: tablePage,
        size: take,
        sortField: sort?.[0]?.field,
        sortDirection: sort?.[0]?.dir,
        domainNameFilter,
        ...payload
      }

      const apiRoute =
        user?.roleType === UserRole.ACCOUNT_USER ? apiRoutes.GET_DOMAIN_LIST : apiRoutes.GET_MANAGED_DOMAIN_LIST

      const resp = await restClient(apiRoute, {
        headers: {
          isToolkitCompatible: true
        },
        params
      })
      return resp.data as any
    } catch (e) {
      if (e?.message) {
        logger.error(`Failed to get domain list: ${e?.message}`)
      }
      return rejectWithValue(validateApiError(e))
    }
  }
)

export interface AddDomainRequest {
  domainName: string
  mailServer: string
}

export interface AddDomainResponse {
  result: AddDomainResult
  domainId: number
}

export const addDomain = createAsyncThunk<AddDomainResponse, AddDomainRequest, ApiRejectResponse>(
  'DOMAINS/addDomain',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { data }: { data: AddDomainResponse } = await restClient(apiRoutes.ADD_DOMAIN, {
        headers: {
          isToolkitCompatible: true
        },
        data: payload
      })
      const message =
        data.result === AddDomainResult.DOMAIN_CREATED ? 'add_domain_success' : 'add_domain_transfer_required'
      dispatch(setSuccessSnackBar({ message }))
      return data
    } catch (e) {
      if (e?.message) {
        logger.error(`Failed to add domain: ${e?.message}`)
      }

      let message: string
      let snackbarParams: string[] | undefined
      let formatMessageParams: Record<string, any> | undefined
      let detail: any = e?.data?.detail

      try {
        detail = JSON.parse(detail)
      } catch (parseError) {
        detail = undefined
      }
      const exceptionClassName: string | undefined = detail?.exception

      switch (true) {
        case exceptionClassName === 'DuplicateDomainException': {
          message = 'add_domain_error_duplicate'
          break
        }
        case exceptionClassName === 'DomainExistsInOtherAccountException': {
          message = 'add_domain_error_exist_in_other_account'
          break
        }
        case exceptionClassName === 'AliasDomainException': {
          const aliasDomain = detail?.metadata.parent_domain_name
          message = 'add_domain_error_alias'
          snackbarParams = [aliasDomain]
          formatMessageParams = { parent_domain_name: aliasDomain }
          break
        }
        case exceptionClassName === 'QuarantineNotifierException': {
          message = 'add_domain_error_quarantine_notifier'
          break
        }
        case exceptionClassName === 'BlacklistedDomainException': {
          message = 'add_domain_error_blacklisted_domain_name'
          break
        }
        default: {
          message = 'add_domain_failure'
        }
      }
      dispatch(
        setErrorSnackBar({
          message,
          params: snackbarParams
        })
      )
      return rejectWithValue(JSON.stringify({ textId: message, formatMessageParams }))
    }
  }
)

export interface DeleteDomainRequest {
  domainId: string
  confirmationType: DeleteDomainConfirmationType
}

export type DeleteDomainResponse = DomainsInDB

export const deleteDomain = createAsyncThunk<DeleteDomainResponse, DeleteDomainRequest, ApiRejectResponse>(
  'DOMAINS/deleteDomain',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const resp = await restClient(apiRoutes.DELETE_DOMAIN, {
        headers: {
          isToolkitCompatible: true
        },
        urlParams: {
          domainId: payload.domainId
        },
        data: {
          confirmationType: payload.confirmationType
        }
      })
      dispatch(
        setSuccessSnackBar({
          message: 'delete_domain_success'
        })
      )
      return resp.data
    } catch (e) {
      if (e?.message) {
        logger.error(`Failed to delete domain: ${e?.message}`)
      }
      const isNotFound = e?.data?.detail === DomainExceptions.DomainNotFoundException
      const isQuarantineNotifierError = e?.data?.detail === QuarantineNotifierException
      const isOutboundIpConflict = e?.data?.detail === DomainExceptions.DeleteDomainOutboundIpConflict
      const isOnlyVerifiedDomainConflict = e?.data?.detail === DomainExceptions.DeleteDomainOnlyVerifiedDomainConflict
      let message = ''
      let snackbarFn: typeof setErrorSnackBar | typeof setWarningSnackBar = setErrorSnackBar
      let showSnackbar = true
      switch (true) {
        case isNotFound: {
          message = 'delete_domain_failure_not_found'
          break
        }
        case isQuarantineNotifierError: {
          message = 'delete_domain_failure_quarantine_notifier'
          snackbarFn = setWarningSnackBar
          break
        }
        case isOnlyVerifiedDomainConflict: // fall through
        case isOutboundIpConflict: {
          showSnackbar = false
          break
        }
        default: {
          message = config.DEFAULT_ERROR_MESSAGE
        }
      }
      if (showSnackbar) {
        dispatch(snackbarFn({ message: message === config.DEFAULT_ERROR_MESSAGE ? 'delete_domain_failure' : message }))
      }
      return rejectWithValue(e?.data?.detail || message)
    }
  }
)

export interface ManageDomainRequest {
  pdDomainId: string | undefined
}

export interface ManageDomainResponse {
  pdDomainId: string | null
  pdDomainName: string | null
}

export const manageDomain = createAsyncThunk<ManageDomainResponse, ManageDomainRequest, ApiRejectResponse>(
  'DOMAINS/manageDomain',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const resp = await restClient(apiRoutes.MANAGE_DOMAIN, {
        headers: {
          isToolkitCompatible: true
        },
        data: payload
      })
      return resp.data
    } catch (e) {
      if (e?.message) {
        logger.error(`Manage domain call failed: ${e?.message}`)
      }
      const isForbidden = e?.status === 403
      const message = isForbidden ? 'manage_domain_failure_forbidden' : 'manage_domain_failure'
      dispatch(setErrorSnackBar({ message }))
      return rejectWithValue(message)
    }
  }
)

export interface GetDomainForVerificationRequest {
  domainId: string
}

export type GetDomainForVerificationResponse = Domain

export const getDomainForVerification = createAsyncThunk<
  GetDomainForVerificationResponse,
  GetDomainForVerificationRequest,
  ApiRejectResponse
>('DOMAINS/getDomainForVerification', async (payload, { rejectWithValue }) => {
  try {
    const resp = await restClient(apiRoutes.GET_DOMAIN_FOR_VERIFICATION, {
      headers: {
        isToolkitCompatible: true
      },
      urlParams: { ...payload }
    })
    return resp.data as any
  } catch (e) {
    if (e?.message) {
      logger.error(`Failed to get domain for verification: ${e?.message}`)
    }
    return rejectWithValue(validateApiError(e))
  }
})

export interface VerifyDomainRequest {
  domainId: string
  method: VerificationMethod
  token?: string
}

export type VerifyDomainResponse = {
  result: VerificationResult
  email?: string
  domainName?: string
}

export const verifyDomain = createAsyncThunk<VerifyDomainResponse, VerifyDomainRequest, ApiRejectResponse>(
  'DOMAINS/verifyDomain',
  async (payload, { rejectWithValue, dispatch }) => {
    const { domainId, method, token } = payload
    try {
      const resp = await restClient(apiRoutes.VERIFY_DOMAIN, {
        headers: {
          isToolkitCompatible: true
        },
        urlParams: { domainId },
        data: { method, token }
      })
      const { result, email, domainName } = resp.data as VerifyDomainResponse
      let snackbarMessage
      let snackbarParams
      switch (true) {
        case result === VerificationResult.EMAIL_SENT: {
          snackbarMessage = 'verify_domain_success.email_sent'
          snackbarParams = [email]
          break
        }
        case result === VerificationResult.CPL_VERIFICATION_STARTED: {
          snackbarMessage = 'verify_domain_success.cpl_verification_started'
          snackbarParams = [domainName]
          break
        }
        case result === VerificationResult.VERIFIED_NOW:
        default: {
          snackbarMessage = 'verify_domain_success.verified_now'
          snackbarParams = [domainName]
          break
        }
      }
      dispatch(setSuccessSnackBar({ message: snackbarMessage, params: snackbarParams }))
      return resp.data as any
    } catch (e) {
      if (e?.message) {
        logger.error(`Failed to verify domain: ${e?.message}`)
      }
      const { result, email, domainName } = camelizeKeys(e?.data?.detail || {}) as VerifyDomainResponse
      let snackbarMessage
      let snackbarParams
      let setSnackbar: typeof setErrorSnackBar | typeof setWarningSnackBar = setErrorSnackBar
      switch (true) {
        case result === VerificationResult.MX_ERROR: {
          snackbarMessage = 'verify_domain_failure.mx_error'
          snackbarParams = [domainName]
          break
        }
        case result === VerificationResult.CNAME_ERROR: {
          snackbarMessage = 'verify_domain_failure.cname_error'
          break
        }
        case result === VerificationResult.INVALID_OR_EXPIRED: {
          snackbarMessage = 'verify_domain_failure.invalid_or_expired'
          break
        }
        case result === VerificationResult.EMAIL_ERROR: {
          snackbarMessage = 'verify_domain_failure.email_send_failure'
          snackbarParams = [email]
          break
        }
        case result === VerificationResult.TECH_EMAIL_NOT_FOUND: {
          snackbarMessage = 'verify_domain_failure.tech_email_not_found'
          snackbarParams = [domainName]
          break
        }
        case result === VerificationResult.CPL_VERIFICATION_FAILED: {
          snackbarMessage = 'verify_domain_failure.cpl_verification_failed'
          break
        }
        case result === VerificationResult.ALREADY_VERIFIED: {
          snackbarMessage = 'verify_domain_failure.already_verified'
          snackbarParams = [domainName]
          setSnackbar = setWarningSnackBar
          break
        }
        case result === VerificationResult.ALREADY_VERIFIED_BY_OTHER_ACCOUNT: {
          snackbarMessage = 'verify_domain_failure.verified_by_other_account'
          setSnackbar = setWarningSnackBar
          break
        }
        default: {
          snackbarMessage = 'verify_domain_failure.default'
        }
      }
      dispatch(setSnackbar({ message: snackbarMessage, params: snackbarParams }))
      return rejectWithValue(validateApiError(e))
    }
  }
)

export interface GetDomainRequest {
  domainId: string
  extraFields?: GetDomainExtraField[]
}

export type GetDomainResponse = Domain

export const getDomain = createAsyncThunk<GetDomainResponse, GetDomainRequest, ApiRejectResponse>(
  'DOMAINS/getDomain',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { extraFields, ...urlParams } = payload
      const strExtraFields = extraFields ? extraFields.join(',') : undefined
      const resp = await restClient(apiRoutes.GET_DOMAIN, {
        headers: {
          isToolkitCompatible: true
        },
        urlParams,
        params: { extraFields: strExtraFields }
      })
      return resp.data as any
    } catch (e) {
      if (e?.message) {
        logger.error(`Failed to get domain: ${e?.message}`)
      }
      const isForbidden = e?.status === 403
      const message = isForbidden ? 'edit_domain_failure_forbidden' : 'get_domain_failure'
      dispatch(setErrorSnackBar({ message }))
      return rejectWithValue(message)
    }
  }
)

export const getVerifiedDomainList = createAsyncThunk<Domain[], undefined, ApiRejectResponse>(
  'DOMAINS/getVerifiedDomainList',
  async (_, { rejectWithValue }) => {
    try {
      const resp = await restClient(apiRoutes.GET_VERIFIED_DOMAIN_LIST, {})
      return resp.data as any
    } catch (e) {
      if (e?.message) {
        logger.error(`Failed to get verified domain list: ${e?.message}`)
      }
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const getPdDomain = createAsyncThunk<Domain, undefined, ApiRejectResponse>(
  'DOMAINS/getPdDomain',
  async (_, { rejectWithValue, getState }) => {
    try {
      const pdDomainId = (getState() as RootState).auth.accessTokenObject?.pdDomainId
      const resp = await restClient(apiRoutes.GET_PD_DOMAIN, {
        urlParams: { domainId: pdDomainId }
      })
      return resp.data as any
    } catch (e) {
      if (e?.message) {
        logger.error(`Failed to get pd domain: ${e?.message}`)
      }
      return rejectWithValue(validateApiError(e))
    }
  }
)

export type GetUnaliasedDomainNamesRequest = void

export type GetUnaliasedDomainNamesResponse = DomainNameById[]

export const getUnaliasedDomainNames = createAsyncThunk<
  GetUnaliasedDomainNamesResponse,
  GetUnaliasedDomainNamesRequest,
  ApiRejectResponse
>('DOMAINS/getUnaliasedDomains', async (payload, { rejectWithValue }) => {
  try {
    const resp = await restClient(apiRoutes.GET_UNALIASED_DOMAIN_NAMES)
    return resp.data as any
  } catch (e) {
    if (e?.message) {
      logger.error(`Failed to get unaliased domain names: ${e?.message}`)
    }
    return rejectWithValue(validateApiError(e))
  }
})

export type SaveDomainSettingsRequest = {
  domainId: string
  settings: DomainSettings
  encryption: EncryptionSettings
  ignoreWarnings: boolean
}

export interface SaveDomainSettingsResponse {
  changedDomainSettings: ChangedDomainSettings
  changedAccountSettings: ChangedAccountSettings
}

export const saveDomainSettings = createAsyncThunk<
  SaveDomainSettingsResponse,
  SaveDomainSettingsRequest,
  ApiRejectResponse
>('DOMAINS/saveDomainSettings', async (payload, { rejectWithValue, dispatch }) => {
  try {
    const { domainId, ...data } = payload
    const resp = await restClient(apiRoutes.SAVE_DOMAIN_SETTINGS, {
      data,
      urlParams: { domainId }
    })
    dispatch(setSuccessSnackBar({ message: 'save_domain_settings_success' }))
    return resp.data as any
  } catch (e) {
    if (e?.message) {
      logger.error(`Failed to save domain settings: ${e?.message}`)
    }
    if (e?.data?.detail === 'UnconfirmedSettingUpdateException') {
      dispatch(setWarningSnackBar({ message: 'save_domain_settings_unconfirmed_warning' }))
    } else {
      dispatch(setErrorSnackBar({ message: 'save_domain_settings_failure' }))
    }
    return rejectWithValue(validateApiError(e))
  }
})

export interface ResetDomainSettingsRequest {
  domainId: string
}

export type ResetDomainSettingsResponse = void

export const resetDomainSettings = createAsyncThunk<
  ResetDomainSettingsResponse,
  ResetDomainSettingsRequest,
  ApiRejectResponse
>('DOMAINS/resetDomainSettings', async (payload, { rejectWithValue, dispatch }) => {
  try {
    const { domainId } = payload
    const resp = await restClient(apiRoutes.RESET_DOMAIN_SETTINGS, {
      urlParams: { domainId }
    })
    dispatch(clearDomainUniqueSettings(domainId))
    dispatch(setSuccessSnackBar({ message: 'reset_domain_settings_success' }))
    return resp.data as any
  } catch (e) {
    if (e?.message) {
      logger.error(`Failed to save domain settings: ${e?.message}`)
    }
    dispatch(setErrorSnackBar({ message: 'reset_domain_settings_failure' }))
    return rejectWithValue(validateApiError(e))
  }
})

export const resetEditDomain = createAsyncThunk<void, void, ApiRejectResponse>(
  'DOMAINS/resetEditDomain',
  (payload, { dispatch }) => {
    dispatch(resetAddDomain())
    dispatch(resetDeleteDomain())
    dispatch(resetManageDomain())
    dispatch(resetVerifyDomain())
    dispatch(resetSaveDomainSettings())
    dispatch(resetGetDomain())
    dispatch(resetGetDomainForVerification())
    dispatch(resetAddEmailServer())
    dispatch(resetRemoveEmailServer())
    dispatch(resetUpdateEmailServer())
    dispatch(resetTestEmailServer())
  }
)

export interface GetMoveDomainRequest {
  domainId: string
}

export type GetMoveDomainResponse = MoveDomain

export const getMoveDomain = createAsyncThunk<GetMoveDomainResponse, GetMoveDomainRequest, ApiRejectResponse>(
  'DOMAINS/getMoveDomain',
  async (payload, { rejectWithValue }) => {
    try {
      const { domainId } = payload
      const resp = await restClient(apiRoutes.GET_MOVE_DOMAIN, {
        urlParams: { domainId }
      })
      return resp.data
    } catch (e) {
      if (e?.message) {
        logger.error(`Failed to get domain transfer details: ${e?.message}`)
      }
      return rejectWithValue(e?.data?.detail || validateApiError(e))
    }
  }
)

export interface PostVerifyDomainTransferRequest {
  domainId: string
}

export interface PostVerifyDomainTransferResponse {
  accountId: string
  domainId: number
  domainName: string
}

export const postVerifyDomainTransfer = createAsyncThunk<
  PostVerifyDomainTransferResponse,
  PostVerifyDomainTransferRequest,
  ApiRejectResponse
>('DOMAINS/postVerifyDomainTransfer', async (payload, { rejectWithValue, dispatch }) => {
  try {
    const { domainId } = payload
    const res = await restClient(apiRoutes.VERIFY_DOMAIN_TRANSFER, {
      urlParams: { domainId },
      timeout: config.DOMAIN_MOVE.VERIFY_DOMAIN_TIMEOUT
    })
    dispatch(setSuccessSnackBar({ message: 'verify_domain_transfer_success' }))
    return res.data
  } catch (e) {
    if (e?.message) {
      logger.error(`Failed to verify domain transfer: ${e?.message}`)
    }
    dispatch(setErrorSnackBar({ message: 'verify_domain_transfer_failure' }))
    return rejectWithValue(e?.data?.detail || validateApiError(e))
  }
})

export interface DeleteDomainMoveRequest {
  domainId: string
}

export interface DeleteDomainMoveResponse {
  accountId: string
  domainId: number
  domainName: string
}

export const deleteDomainMove = createAsyncThunk<DeleteDomainMoveResponse, DeleteDomainMoveRequest, ApiRejectResponse>(
  'DOMAINS/deleteDomainMove',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const resp = await restClient(apiRoutes.DELETE_DOMAIN_MOVE, {
        headers: {
          isToolkitCompatible: true
        },
        urlParams: {
          domainId: payload.domainId
        }
      })
      dispatch(
        setSuccessSnackBar({
          message: 'delete_domain_move_success',
          params: [resp.data?.domainName]
        })
      )
      return resp.data
    } catch (e) {
      if (e?.message) {
        logger.error(`Failed to delete domain transfer: ${e?.message}`)
      }
      const isNotFound = e?.data?.detail === DomainExceptions.DomainTransferNotFoundException
      const isUnauthorized = e?.data?.detail === DomainExceptions.UnauthorizedDomainTransferAccess
      let message: string
      switch (true) {
        case isNotFound: {
          message = 'delete_domain_move_failure_not_found'
          break
        }
        case isUnauthorized: {
          message = 'delete_domain_move_failure_unauthorized'
          break
        }
        default: {
          message = config.DEFAULT_ERROR_MESSAGE
        }
      }
      dispatch(
        setErrorSnackBar({ message: message === config.DEFAULT_ERROR_MESSAGE ? 'delete_domain_move_failure' : message })
      )
      return rejectWithValue(message)
    }
  }
)

export interface GetDomainTransferStatesRequest {
  domainIds: string[]
}

export type GetDomainTransferStatesResponse = Record<number, DomainTransferStateRecord | null>

export const getDomainTransferStates = createAsyncThunk<
  GetDomainTransferStatesResponse,
  GetDomainTransferStatesRequest,
  ApiRejectResponse
>('DOMAINS/getDomainTransferStates', async (payload, { rejectWithValue, dispatch }) => {
  try {
    const resp = await restClient(apiRoutes.GET_DOMAIN_TRANSFER_STATES, {
      headers: {
        isToolkitCompatible: true
      },
      data: payload
    })
    return resp.data
  } catch (e) {
    if (e?.message) {
      logger.error(`Failed to get domain transfer states: ${e?.message}`)
    }
    return rejectWithValue(validateApiError(e))
  }
})
