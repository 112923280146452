import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { camelCase, filter, isEqual, isEqualWith, trim } from 'lodash'
import { type MRT_ColumnDef } from 'material-react-table'
import { DateTime } from 'luxon'
import { PaginationProps } from 'components/pages/reports/reportList/customizedBDSComponents/Pagination'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import {
  BarracudaReportTypes,
  breakdownReports,
  DateTypeRecord,
  EmailFlows,
  FieldNames,
  FilterConditions,
  isDateTypeRecord,
  isDateTypeReport,
  isDateTypeTableReport,
  isEmailTypeReport,
  isEmailTypeTableReport,
  PageState,
  paginatedReports,
  RelativeDateRanges,
  ReportFilter,
  ReportIntervals,
  ReportsListItem,
  ReportsResponse,
  ReportsResponseTable,
  RETENTION_POLICY_DAY_RANGE,
  TableFilters
} from 'types/reports'
import { useFormatMessage } from 'lib/localization'
import { DomainsInDB } from 'types/redux/user/UserTypes'
import { setSearchValue, updateFilters, updatePagination } from 'redux/features/reports/reportsSlice'
import toggleInArray from 'lib/toggleInArray'
import { isFailed, isPending } from 'redux/toolkit/api'
import { formatDateNoTZWithLocale, formatDateNoTZ, validateTimeRange } from 'lib/datetime'

const TRAFFIC_COLUMNS: FieldNames[] = [
  FieldNames.totalDeferred,
  FieldNames.totalBlocked,
  FieldNames.totalQuarantined,
  FieldNames.totalAllowed
]
const OUTBOUND_TRAFFIC_COLUMNS: FieldNames[] = [
  FieldNames.totalDeferred,
  FieldNames.totalBlocked,
  FieldNames.totalQuarantined,
  FieldNames.totalAllowed,
  FieldNames.totalEncrypted
]
const TOP_COLUMNS: FieldNames[] = [
  FieldNames.blockedByRateControl,
  FieldNames.blockedByPolicy,
  FieldNames.blockedBySpam,
  FieldNames.blockedForVirus,
  FieldNames.blockedByATP,
  FieldNames.blockedOther
]

export type Columns = string[]
export type DataTableConfig = [Columns]
type DataTableReportConfig = {
  [key in BarracudaReportTypes]: DataTableConfig
}
export type DataTableConfigs = {
  [key in EmailFlows]: DataTableReportConfig
}

const REPORT_DATA_TABLE_CONFIG: DataTableConfigs = {
  [EmailFlows.inbound]: {
    [BarracudaReportTypes.inboundTraffic]: [[FieldNames.date, ...TRAFFIC_COLUMNS]],
    [BarracudaReportTypes.outboundTraffic]: [[FieldNames.date, ...TRAFFIC_COLUMNS]],
    [BarracudaReportTypes.inboundBlockedEmails]: [[FieldNames.date, ...TOP_COLUMNS]],
    [BarracudaReportTypes.topInboundEmailSenders]: [[FieldNames.senderEmail, ...TRAFFIC_COLUMNS]],
    [BarracudaReportTypes.topInboundEmailRecipients]: [[FieldNames.recipientEmail, ...TRAFFIC_COLUMNS]],
    [BarracudaReportTypes.topInboundBlockedSenders]: [[FieldNames.senderEmail, ...TOP_COLUMNS]],
    [BarracudaReportTypes.topInboundBlockedRecipients]: [[FieldNames.recipientEmail, ...TOP_COLUMNS]],
    [BarracudaReportTypes.topOutboundEmailSenders]: [[FieldNames.senderEmail, ...TRAFFIC_COLUMNS]],
    [BarracudaReportTypes.topOutboundBlockedSenders]: [[FieldNames.recipientEmail, ...TOP_COLUMNS]]
  },
  [EmailFlows.outbound]: {
    [BarracudaReportTypes.inboundTraffic]: [[FieldNames.date, ...OUTBOUND_TRAFFIC_COLUMNS]],
    [BarracudaReportTypes.outboundTraffic]: [[FieldNames.date, ...OUTBOUND_TRAFFIC_COLUMNS]],
    [BarracudaReportTypes.inboundBlockedEmails]: [[FieldNames.date, ...TOP_COLUMNS]],
    [BarracudaReportTypes.topInboundEmailSenders]: [[FieldNames.senderEmail, ...OUTBOUND_TRAFFIC_COLUMNS]],
    [BarracudaReportTypes.topInboundEmailRecipients]: [[FieldNames.recipientEmail, ...OUTBOUND_TRAFFIC_COLUMNS]],
    [BarracudaReportTypes.topInboundBlockedSenders]: [[FieldNames.senderEmail, ...TOP_COLUMNS]],
    [BarracudaReportTypes.topInboundBlockedRecipients]: [[FieldNames.recipientEmail, ...TOP_COLUMNS]],
    [BarracudaReportTypes.topOutboundEmailSenders]: [[FieldNames.senderEmail, ...OUTBOUND_TRAFFIC_COLUMNS]],
    [BarracudaReportTypes.topOutboundBlockedSenders]: [[FieldNames.recipientEmail, ...TOP_COLUMNS]]
  }
}

const BASE_I18N_KEY = 'ess.reports'
const AVAILABLE_PAGE_SIZES = [5, 10, 15, 20]
const TABLE_CONTROL_DEFAULT_HEIGHT = 72
const TABLE_DEFAULT_SIZE = 300
export const ALL_DOMAINS = 'all_domains'

export interface UseDateTableLogic {
  columns: MRT_ColumnDef<ReportsResponse['results']>[]
  filteredReport?: ReportsResponseTable
  paginationConfig: PaginationProps
  filterValues: TableFilters
  cachedFilterValues?: TableFilters
  updateFilterValues: (props: keyof TableFilters) => (newValue: any) => void
  domainSelectorValue: string
  onUpdateColumnFilter: (column: FieldNames, props: keyof ReportFilter, changeValue?: 1 | -1) => (newValue: any) => void
  isLoading: boolean
  isFailedToLoad: boolean
  onOpenCachedDataSelector: () => void
  onCloseCachedDataSelector: () => void
  filtersRefCallback: (node: any) => void
  tableWrapperRef: MutableRefObject<HTMLDivElement | null>
  tableControlWrapperRef: MutableRefObject<HTMLDivElement | null>
  tableHeight: number
  emailsList: string[]
  minMaxAbsolutes: MinMaxAbsolutes
  availableDomains?: DomainsInDB[]
}

export interface PaginationConfig {
  pageSize: number
  page: number
}

export type MinMaxAbsolutes = { [key: string]: number }
export const useDataTableLogic = (selectedListItem: ReportsListItem | undefined): [UseDateTableLogic] => {
  const dispatch = useAppDispatch()
  const {
    report,
    filterValues,
    isLoading,
    isGetReportFailed,
    searchValue,
    isInitialFilters,
    availableDomains,
    language
  } = useAppSelector(_store => ({
    availableDomains: _store.user.availableDomains,
    report: _store.reports.report,
    filterValues: _store.reports.filters,
    isLoading: isPending(_store.reports.api.getReportApiStatus),
    isGetReportFailed: isFailed(_store.reports.api.getReportApiStatus),
    searchValue: _store.reports.searchValue,
    isInitialFilters: _store.reports.isInitialFilters,
    language: _store.app.language
  }))
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [cachedFilterValues, setCachedFilterValues] = useState<TableFilters | undefined>()
  const [emailsList, setEmailsList] = useState<string[]>([])
  const [minMaxAbsolutes, setMinMaxAbsolutes] = useState<MinMaxAbsolutes>({})

  // table height managagers
  const tableWrapperRef = useRef<HTMLDivElement>(null)
  const tableControlWrapperRef = useRef<HTMLDivElement>(null)
  const [tableHeight, setTableHeight] = useState<number>(TABLE_DEFAULT_SIZE)
  const updateTableHeight = useCallback(() => {
    if (tableWrapperRef.current) {
      const tableWrapperDimensions = tableWrapperRef.current.getBoundingClientRect()
      const tableControlDimensions = tableControlWrapperRef.current?.getBoundingClientRect()
      setTableHeight(
        (tableWrapperDimensions.height || TABLE_DEFAULT_SIZE) -
          (tableControlDimensions?.height || TABLE_CONTROL_DEFAULT_HEIGHT) -
          550
      )
    }
  }, [])
  useEffect(() => {
    updateTableHeight()
    window.addEventListener('resize', updateTableHeight)
    return () => {
      window.removeEventListener('resize', updateTableHeight)
    }
  }, [updateTableHeight])

  // update the table filter's configs list when report is changed
  useEffect(() => {
    if (isInitialFilters && report) {
      if (isEmailTypeReport(report)) {
        const emails = report.results.map(record => record.senderEmail || record.recipientEmail).sort()
        setEmailsList(emails)
      }
      if (isDateTypeReport(report)) {
        const collectedAbsolutes = (report.results as DateTypeRecord[]).reduce(
          (all: MinMaxAbsolutes, record: DateTypeRecord) => {
            let updatedAll = { ...all }
            Object.keys(record).forEach(recordKey => {
              updatedAll = {
                ...updatedAll,
                ...(typeof record[recordKey as keyof DateTypeRecord] === 'number' && {
                  [recordKey]: Math.max(all[recordKey] || 0, Number(record[recordKey as keyof DateTypeRecord]) || 0)
                })
              }
            })

            return updatedAll
          },
          {}
        )

        setMinMaxAbsolutes(collectedAbsolutes)
      }
    }
  }, [report, isInitialFilters])

  // we need to store these values in refs to avoid unnecessary recalculations of the filtersRefCallback hook method
  const isFilterOpened = useRef(false)
  const cachedFilterValuesRef = useRef(cachedFilterValues)
  useEffect(() => {
    if (!isEqual(cachedFilterValues, filterValues)) {
      cachedFilterValuesRef.current = cachedFilterValues
    } else {
      cachedFilterValuesRef.current = undefined
    }
  }, [cachedFilterValues, filterValues])

  const updateFilterValues = useCallback(
    (prop: keyof TableFilters) =>
      (newValue: any, selectorConfig?: { shortcut?: { relativeDateRange?: RelativeDateRanges } }) => {
        switch (prop) {
          case 'search':
            dispatch(setSearchValue(newValue.target.value))
            break
          case 'direction':
          case 'listTop':
            dispatch(updateFilters({ [prop]: newValue.target.value }))
            break
          case 'domainIds':
            if (
              newValue.target.value.slice(-1)[0] === ALL_DOMAINS ||
              newValue.target.value.length === availableDomains?.length
            ) {
              setCachedFilterValues({ ...(cachedFilterValues || filterValues), [prop]: [ALL_DOMAINS] })
            } else {
              setCachedFilterValues({
                ...(cachedFilterValues || filterValues),
                [prop]: newValue.target.value.filter((value: string) => value !== ALL_DOMAINS)
              })
            }
            break
          case 'startDate':
          case 'endDate': {
            const isValidDate = validateTimeRange(
              newValue[0],
              newValue[1],
              DateTime.now().startOf('day').minus({ day: RETENTION_POLICY_DAY_RANGE }),
              DateTime.now()
            )

            setCachedFilterValues({
              ...(cachedFilterValues || filterValues),
              ...(newValue[0] && { startDate: newValue[0].toISODate() }),
              ...(newValue[1] && { endDate: newValue[1].toISODate() }),
              isValid: !!isValidDate,
              errors: {
                ...(cachedFilterValues?.errors || filterValues.errors),
                dateRange: isValidDate ? '' : 'invalid_date'
              },
              relativeDateRange: selectorConfig?.shortcut?.relativeDateRange || null
            })
            break
          }
          default:
            dispatch(updateFilters({ [prop]: newValue }))
        }
      },
    [dispatch, availableDomains, cachedFilterValues, filterValues]
  )

  const columns = useMemo<MRT_ColumnDef<ReportsResponse['results']>[]>(() => {
    const type = selectedListItem?.type || BarracudaReportTypes.inboundTraffic
    const tableConfig = REPORT_DATA_TABLE_CONFIG[filterValues.direction][type]
    const [columnsList] = tableConfig

    return columnsList.map((column: string) => ({
      id: column,
      accessorKey: camelCase(column),
      header: formatMessage(`labels.${column.toLowerCase()}`),
      enableColumnActions: false,
      enableColumnFilter: false
    }))
  }, [filterValues.direction, selectedListItem, formatMessage])

  const domainSelectorValue: string[] = useMemo(() => {
    if (filterValues.domainIds.includes(ALL_DOMAINS)) {
      return [formatMessage('all_domains')]
    }

    return (
      availableDomains?.reduce((all: string[], domain: DomainsInDB) => {
        if (filterValues.domainIds.includes(domain.domainId)) {
          return [...all, domain.domainName]
        }

        return all
      }, []) || []
    )
  }, [filterValues.domainIds, availableDomains, formatMessage])

  const onUpdateColumnFilter = useCallback(
    (column: FieldNames, props: keyof ReportFilter, changeValue?: 1 | -1) => (newValue: any) => {
      const defaultConditionFilter = {
        fieldName: column,
        values: emailsList,
        condition: FilterConditions.equals
      }
      const isEqualConditionFilters = (newFilters: Partial<ReportFilter>): [boolean, ReportFilter] => {
        const newColumnFilter = {
          ...(currentFilters[column] || {
            ...defaultConditionFilter
          }),
          ...newFilters
        }
        const isEmptyValueFilter = isEqualWith(
          newColumnFilter,
          defaultConditionFilter,
          (objValue: ReportFilter, otherValue: ReportFilter) =>
            objValue.condition === otherValue.condition &&
            objValue.values?.length === defaultConditionFilter.values.length
        )

        return [isEmptyValueFilter, newColumnFilter]
      }
      const currentFilters = cachedFilterValues?.columnFilters || filterValues.columnFilters
      switch (props) {
        case 'max':
        case 'min':
          // eslint-disable-next-line no-case-declarations
          const currentColumnFilter = {
            ...(currentFilters[column] || { fieldName: column, min: 0, max: 0 })
          }
          if (!changeValue) {
            currentColumnFilter[props] = parseInt(newValue.target.value, 10)
          } else {
            currentColumnFilter[props] = Math.max(Number(currentColumnFilter[props] || 0) + changeValue, 0)
          }
          if (currentColumnFilter.min !== undefined && currentColumnFilter.max !== undefined) {
            if (props === 'min') {
              currentColumnFilter.max = Math.min(
                Math.max(currentColumnFilter.max, newValue.target.value),
                minMaxAbsolutes[camelCase(column)]
              )
              currentColumnFilter.min = Math.min(currentColumnFilter.max, currentColumnFilter.min)
            } else {
              currentColumnFilter.min = Math.max(Math.min(currentColumnFilter.min, newValue.target.value), 0)
              currentColumnFilter.max = Math.min(
                Math.max(currentColumnFilter.max, currentColumnFilter.min),
                minMaxAbsolutes[camelCase(column)] || 0
              )
            }
          }
          if (!isEqual(currentColumnFilter, filterValues.columnFilters[column])) {
            const isEmptyFilter = !currentColumnFilter.min && !currentColumnFilter.max
            setCachedFilterValues({
              ...(cachedFilterValues || filterValues),
              columnFilters: {
                ...currentFilters,
                [column]: isEmptyFilter ? undefined : { ...currentColumnFilter }
              }
            })
          }
          break
        case 'values':
          /* eslint-disable no-case-declarations */
          const currentColumnFilterValues = {
            ...(currentFilters[column] || { ...defaultConditionFilter })
          }

          let newEmails = toggleInArray(currentColumnFilterValues.values || [], newValue.target.value)
          if (!newValue.target.value) {
            newEmails = currentColumnFilterValues.values?.length === emailsList.length ? [] : emailsList
          }

          const [isEmptyValueFilter, newValueFilter] = isEqualConditionFilters({ values: newEmails })
          setCachedFilterValues({
            ...(cachedFilterValues || filterValues),
            columnFilters: {
              ...currentFilters,
              [column]: isEmptyValueFilter ? undefined : newValueFilter
            }
          })
          break
        case 'condition':
          const [isEmptyConditionFilter, newConditionFilter] = isEqualConditionFilters({
            condition: newValue.target.value
          })
          setCachedFilterValues({
            ...(cachedFilterValues || filterValues),
            columnFilters: {
              ...currentFilters,
              [column]: isEmptyConditionFilter ? undefined : newConditionFilter
            }
          })
          break
        default:
      }
    },
    [filterValues, emailsList, cachedFilterValues, minMaxAbsolutes]
  )

  const reportData = useMemo(() => {
    updateTableHeight()
    if (!report) {
      return undefined
    }

    return {
      data: report.results.map(reportRecord => ({
        ...reportRecord,
        ...(isDateTypeRecord(reportRecord) &&
          reportRecord.reportDate && {
            reportDate:
              filterValues.interval === ReportIntervals.hourly
                ? formatDateNoTZWithLocale(reportRecord.reportDate, language)
                : formatDateNoTZ(reportRecord.reportDate)
          })
      }))
    } as ReportsResponseTable
  }, [language, report, updateTableHeight, filterValues.interval])

  const filteredReportData: ReportsResponseTable | undefined = useMemo(() => {
    if (!reportData) {
      return undefined
    }

    if (isEmailTypeTableReport(reportData)) {
      return {
        data: reportData.data.filter(reportRecord => {
          if (!searchValue.length) {
            return true
          }

          return (reportRecord.senderEmail || reportRecord.recipientEmail)
            ?.toLowerCase()
            .includes(trim(searchValue.toLowerCase()))
        })
      }
    }

    if (isDateTypeTableReport(reportData)) {
      return {
        data: reportData.data.filter(reportRecord => {
          if (Object.prototype.hasOwnProperty.call(reportRecord, 'total')) {
            return !!reportRecord.total
          }

          // add together the individual values since some cases the report doesn't have total property
          return !!Object.values(reportRecord).reduce((sum: number, prop: number | string) => {
            if (Number.isInteger(prop)) {
              return sum + Number(prop)
            }

            return sum
          }, 0)
        })
      }
    }

    return reportData
  }, [reportData, searchValue])

  const onOpenCachedDataSelector = useCallback(() => {
    setCachedFilterValues(filterValues)
  }, [filterValues])

  const onCloseCachedDataSelector = useCallback(() => {
    if (cachedFilterValues) {
      dispatch(updateFilters(cachedFilterValues))
      setCachedFilterValues(undefined)
    }
  }, [dispatch, cachedFilterValues])

  const filtersRefCallback = useCallback(
    (node: any) => {
      if (node && !isFilterOpened.current) {
        isFilterOpened.current = true
        onOpenCachedDataSelector()
      } else if (!node && isFilterOpened.current && cachedFilterValuesRef.current) {
        isFilterOpened.current = false
        dispatch(updateFilters(cachedFilterValuesRef.current))
        setCachedFilterValues(undefined)
      }
    },
    [onOpenCachedDataSelector, dispatch]
  )

  const paginationConfig = useMemo<PaginationProps>(() => {
    const isPaginatedReport = paginatedReports.includes(selectedListItem?.type || BarracudaReportTypes.inboundTraffic)
    return {
      itemsPerPage: filterValues.pageSize,
      itemsPerPageOptions: AVAILABLE_PAGE_SIZES,
      onMenuItemClick: (value: number) => {
        dispatch(updatePagination({ ...filterValues, pageSize: value, isDirty: isPaginatedReport }))
      },
      onPageChange: (event: React.ChangeEvent<unknown>, value: number) => {
        dispatch(updatePagination({ ...filterValues, page: value, isDirty: isPaginatedReport }))
      },
      page: filterValues.page,
      totalItems: report?.extra.pagination?.totalRecords || filteredReportData?.data.length || 0
    }
  }, [dispatch, selectedListItem, report, filterValues, filteredReportData])

  return useMemo(
    () => [
      {
        columns,
        filteredReport: filteredReportData,
        paginationConfig,
        filterValues: { ...filterValues, search: searchValue },
        updateFilterValues,
        domainSelectorValue: domainSelectorValue.join(', '),
        onUpdateColumnFilter,
        isLoading,
        isFailedToLoad: isGetReportFailed,
        onOpenCachedDataSelector,
        onCloseCachedDataSelector,
        filtersRefCallback,
        cachedFilterValues,
        tableWrapperRef,
        tableControlWrapperRef,
        tableHeight,
        emailsList,
        minMaxAbsolutes,
        availableDomains
      }
    ],
    [
      searchValue,
      columns,
      paginationConfig,
      filterValues,
      updateFilterValues,
      domainSelectorValue,
      onUpdateColumnFilter,
      isLoading,
      onOpenCachedDataSelector,
      cachedFilterValues,
      filtersRefCallback,
      onCloseCachedDataSelector,
      tableHeight,
      filteredReportData,
      isGetReportFailed,
      emailsList,
      minMaxAbsolutes,
      availableDomains
    ]
  )
}
