import React, { useMemo } from 'react'

import {
  Grid,
  LinearProgress,
  SelectLabel,
  SelectMenuItem,
  SelectWrapper,
  Typography
} from '@barracuda-internal/bds-core'

import Choropleth from 'components/libs/nivo/choropleth/Choropleth'
import Select from 'components/libs/bds/Select'
import styles from 'components/pages/overview/dashboard/dashboardStyles'
import { useFormatMessage } from 'lib/localization'
import ContentLoader from 'components/libs/contentLoader/ContentLoader'

import { ALL_DOMAINS, TimeRanges, useDashboardLogic } from 'components/pages/overview/dashboard/useDashboardLogic'
import TopDomains from 'components/pages/overview/dashboard/topDomains/TopDomains'
import InboundTopBlocked from 'components/pages/overview/dashboard/inboundTopBlocked/InboundTopBlocked'
import OutboundTopBlocked from 'components/pages/overview/dashboard/outboundTopBlocked/OutboundTopBlocked'
import LastBlocked from 'components/pages/overview/dashboard/lastBlocked/LastBlocked'
import InboundEmailStatistics from 'components/pages/overview/dashboard/emailStatisticsCharts/InboundEmailStatistics'
import OutboundEmailStatistics from 'components/pages/overview/dashboard/emailStatisticsCharts/OutboundEmailStatistics'
import TotalThreatsAndViruses from 'components/pages/overview/dashboard/totalThreatsVirusesChart/TotalThreatsVirusesChart'
import ContentLoaderMessage from 'components/libs/contentLoader/ContentLoaderMessage'
import { PageContainer, PageTitle } from 'components/libs/layout/pages/pageLayout'
import { GROUP_TITLE, TOPICS, useSupportModalLogic } from 'components/pages/support/useSupportModalLogic'
import DashboardSupport from 'components/pages/support/help/status/Status'

const BASE_I18N_KEY = 'ess.overview.dashboard'

const Dashboard: React.FC = () => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [state, eventHandlers] = useDashboardLogic()
  const [onOpenSupport, HelpModal] = useSupportModalLogic({
    content: DashboardSupport,
    title: TOPICS.dashboard,
    groupTitle: GROUP_TITLE.dashboard
  })

  return useMemo(
    () => (
      <PageContainer isFailedToLoad={state.isGetAvailableDomainsFailed}>
        <HelpModal />
        <PageTitle title={formatMessage('title')} onOpenSupport={onOpenSupport} />
        {state.isGetAvailableDomainsInProgress && <LinearProgress data-testid="loading-state" />}
        {state.availableDomains && (
          <Grid
            container
            className={classes.dashboardContentWrapper}
            data-testid="dashboard-content"
            direction="column"
          >
            <Grid className={classes.SelectorsWrapper} container direction="row">
              <Grid data-testid="domain-selector" item>
                <SelectWrapper className={classes.domainSelector} variant="outlined">
                  <SelectLabel>{formatMessage('select_domain')}</SelectLabel>
                  <Select
                    data-testid="domains-selector"
                    label={formatMessage('select_domain')}
                    onChange={eventHandlers.onSelectDomain}
                    disabled={state.isDomainSelectorDisabled}
                    value={state.selectedDomain?.domainId || (state.shouldShowAllDomainsInSelector ? ALL_DOMAINS : '')}
                  >
                    {state.shouldShowAllDomainsInSelector && (
                      <SelectMenuItem data-testid="select-all-domains" value={ALL_DOMAINS}>
                        {formatMessage('all')}
                      </SelectMenuItem>
                    )}
                    {state.availableDomains?.map(domain => (
                      <SelectMenuItem data-testid={domain.domainId} key={domain.domainId} value={domain.domainId}>
                        {domain.domainName}
                      </SelectMenuItem>
                    ))}
                  </Select>
                </SelectWrapper>
              </Grid>

              <Grid item>
                <SelectWrapper variant="outlined">
                  <SelectLabel>{formatMessage('time')}</SelectLabel>
                  <Select
                    data-testid="time-range-selector"
                    label={formatMessage('time')}
                    onChange={eventHandlers.onSelectTimeRange}
                    value={state.selectedTimeRange}
                  >
                    {Object.values(TimeRanges).map(range => (
                      <SelectMenuItem data-testid={range} key={range} value={range}>
                        {formatMessage(range)}
                      </SelectMenuItem>
                    ))}
                  </Select>
                </SelectWrapper>
              </Grid>
            </Grid>

            {state.showThreatOrigins && (
              <Grid className={classes.contentSectionWrapper} container direction="row">
                <Grid data-testid="threat-origins-section" className={classes.threatOriginsWrapper} item>
                  <Grid container direction="column">
                    <Typography className={classes.subtitle} variant="subtitle1">
                      {formatMessage('threat_origins')}
                    </Typography>
                    <ContentLoader
                      isLoading={state.isGetGeoDataStatsInProgress}
                      isFailed={state.isGetGeoDataStatsFailed}
                    >
                      <Grid item className={classes.geomap}>
                        <Choropleth data={state.geoData?.data || []} />
                      </Grid>
                    </ContentLoader>
                  </Grid>
                </Grid>
                <Grid className={classes.tableWrapper} item>
                  <TopDomains domainId={state.selectedDomain?.domainId} range={state.range} />
                </Grid>
              </Grid>
            )}

            <Grid className={classes.contentSectionWrapper} container direction="row">
              <Grid data-testid="inbound-email-section" className={classes.chartWrapper} item>
                <InboundEmailStatistics range={state.range} domainId={state.selectedDomain?.domainId} />
              </Grid>
              <Grid className={classes.tableWrapper} item>
                <InboundTopBlocked domainId={state.selectedDomain?.domainId} range={state.range} />
              </Grid>
              {!state.showThreatOrigins && (
                <Grid className={classes.tableWrapper} item>
                  <TopDomains domainId={state.selectedDomain?.domainId} range={state.range} />
                </Grid>
              )}
            </Grid>

            {state.isOutboundStatisticsVisible && (
              <Grid className={classes.contentSectionWrapper} container direction="row">
                <Grid data-testid="outbound-email-section" className={classes.chartWrapper} item>
                  <OutboundEmailStatistics range={state.range} domainId={state.selectedDomain?.domainId} />
                </Grid>
                <Grid className={classes.tableWrapper} item>
                  <OutboundTopBlocked domainId={state.selectedDomain?.domainId} range={state.range} />
                </Grid>
              </Grid>
            )}

            <Grid className={classes.contentSectionWrapper} container direction="row">
              <Grid data-testid="total-section" className={classes.chartWrapper} item>
                <TotalThreatsAndViruses range={state.range} domainId={state.selectedDomain?.domainId} />
              </Grid>
              <Grid className={classes.tableWrapper} item>
                <LastBlocked domainId={state.selectedDomain?.domainId} range={state.range} />
              </Grid>
            </Grid>
          </Grid>
        )}
        {state.availableDomains && (
          <Grid className={classes.footer} container direction="column">
            <Typography className={classes.footerText} variant="body2">{`${formatMessage('footer.account_id')}: ${
              state.footerConfig.accountId
            } (${state.footerConfig.essAccountId})`}</Typography>
            <Typography className={classes.footerText} variant="body2">{`${formatMessage('footer.serial_number')}: ${
              state.footerConfig.serialNumber
            }`}</Typography>
          </Grid>
        )}
      </PageContainer>
    ),
    [classes, formatMessage, state, eventHandlers, onOpenSupport, HelpModal]
  )
}

export default Dashboard
