import React, { useMemo } from 'react'
import IncompleteWizardBanner from 'components/libs/banner/IncompleteWizardBanner'
import GlobalMessageBanner from 'components/libs/banner/globalMessageBanner/GlobalMessageBanner'
import { useBannersLogic } from 'components/libs/banner/useBannersLogic'
import PreMailflowBanner from 'components/libs/banner/PreMailflowBanner'
import AccountStatusBannerSelector from 'components/libs/banner/accountStatusBanner/AccountStatusBannerSelector'

const Banners = () => {
  const [{ wizardUrl, incompleteWizardBanner, preMailflowBanner, accountStatusBannerSelectorProps }] = useBannersLogic()
  return useMemo(
    () => (
      <>
        {incompleteWizardBanner && <IncompleteWizardBanner wizardUrl={wizardUrl} />}
        {preMailflowBanner && <PreMailflowBanner />}
        <AccountStatusBannerSelector {...accountStatusBannerSelectorProps} />
        <GlobalMessageBanner />
      </>
    ),
    [accountStatusBannerSelectorProps, incompleteWizardBanner, preMailflowBanner, wizardUrl]
  )
}

export default Banners
