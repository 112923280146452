import { useCallback, useMemo } from 'react'
import { useAppDispatch } from 'redux/toolkit/hooks'
import { saveSetupFlowStep } from 'redux/features/emailServer/emailServerSlice'
import { SetupFlowSteps } from '../../types/egdWizardTypes'

export interface EventHandlers {
  onOkay: () => void
}

export type ContinueDialogLogic = [EventHandlers]

export const useContinueDialogLogic = (): ContinueDialogLogic => {
  const dispatch = useAppDispatch()

  const onOkay = useCallback(() => {
    dispatch(saveSetupFlowStep(SetupFlowSteps.regionSelection))
  }, [dispatch])

  return useMemo(
    () => [
      {
        onOkay
      }
    ],
    [onOkay]
  )
}
